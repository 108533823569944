import React, { useState, useEffect } from "react";
import {
    Autocomplete,
    Box,
    Grid,
    TextField,
    Typography,
    InputAdornment,
} from "@mui/material";
import api from "../../api/api";
import { useAuth } from "../../features/auth/AuthContext";
import { ReactSVG } from "react-svg";
import { search, suggestion_icon } from "../../assets/images/images";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


function AdminUniversalSearch() {

    const { token } = useAuth();
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);
    const [key, setKey] = useState(0); // For forcing re-render
    const navigate = useNavigate();
    const globalState = useSelector((state) => state.globalState);

    const handleOptionSelect = (event, value, reason) => {
        if (value && value.item_type) {
            let path;
            switch (value.item_type) {
                case "Course":
                    path = `/course/${value.item_id}/view`
                    break;
                case "Lesson":
                case "Assignment":
                    path = `/course/${value.related_course_id}/view`
                    break;
                case "Student":
                    path = `/student-detail/${value.item_id}`;
                    break;
                case "Teacher":
                    path = `/TeacherProfile/${value.item_id}/`;
                    break;
                default:
                    path = `/course/${value.item_id}/view`
            }

            if (path) {
                setInputValue("");
                navigate(path);
                setKey(prevKey => prevKey + 1); // Increment the key to force re-render
            }
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            if (options.length) {
                handleOptionSelect(e, options[0], "")
            }
        }
    }

    useEffect(() => {

        const fetchSuggestions = async () => {
            try {
                const response = await api.get(
                    `super-admin/school/universal-search-admin?search=${inputValue}`,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Authorization: `Token ${token}`,
                        },
                    }
                );
                setOptions(response.data.results);
            } catch (error) {
                setOptions([]);
            }
        };

        // Debounce the fetch suggestions to avoid too many API calls
        const timerId = setTimeout(() => {
            fetchSuggestions();
        }, 300);

        // Clear the timeout when the component is unmounted or the inputValue is changed
        return () => clearTimeout(timerId);
    }, [inputValue, token]);

    return (
        <Grid key={key} container justifyContent="center" sx={{ width: { xs: 150, sm: 300 } }}>
            <Autocomplete
                fullWidth
                freeSolo
                inputValue={inputValue}
                onInputChange={(_, newValue) => setInputValue(newValue)}
                onChange={handleOptionSelect}
                onKeyUp={handleKeyPress}
                options={options}
                getOptionLabel={(option) => option.name || ""}
                noOptionsText="No results found"
                renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.uid}>
                        <ReactSVG src={suggestion_icon} />
                        <Grid pl={2}>
                            <Typography
                                fontSize={14}
                                fontWeight={300}
                                variant="subtitle1"
                                color="#252525"
                            >
                                {option.name}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="#0068FF"
                                fontSize={12}
                                fontWeight={300}
                                style={{ marginLeft: "auto" }}
                            >
                                ({option.item_type})
                            </Typography>
                        </Grid>
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Search"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ReactSVG src={search} style={{ margin: "0 10px" }} />
                                </InputAdornment>
                            ),
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "20px",
                                backgroundColor: "rgba(242, 242, 242, 1)",
                                "& fieldset": {
                                    border: "none",
                                },
                                "&.Mui-focused fieldset": {
                                    borderColor: "transparent",
                                },
                            },
                            "& .MuiAutocomplete-inputRoot": {
                                padding: 0,
                            },
                        }}
                    />
                )}
            />
        </Grid>
    )
}

export default AdminUniversalSearch