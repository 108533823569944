import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { google_drive, uplode_file } from "../../assets/images/images";
import { useGoogleLogin } from '@react-oauth/google';
import { useAuth } from '../../features/auth/AuthContext';
import { submitGoogleToken, resetsubmitgoogleToken } from "../../state/actions/submitGoogleTokenAction";
import { getGoogleTokenAction, resetGoogleToken } from "../../state/actions/getGoogleTokenAction";

const GoogleDriveUpload = ({
  setShowUploadPop,
  handleFileChange }) => {
  const googleAccessToken = useSelector(state => state.getGoogleAccessToken);
  const [accessToken, setAccessToken] = useState(googleAccessToken?.googleToken?.google_token?.token);
  const [pickerInited, setPickerInited] = useState(false);
  const [statusCode, setStatusCode] = useState(null);
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();
  const { token } = useAuth();

  const googleToken = useSelector((state) => state.submitGoogleToken);

  // Create and display the Google Picker
  const createPicker = (accToken) => {
    if (!accToken) {
      console.error('No access token found');
      return;
    }

    const picker = new window.google.picker.PickerBuilder()
      .addView(window.google.picker.ViewId.DOCS)
      .setOAuthToken(accToken)
      .setDeveloperKey(process.env.REACT_APP_GOOGLEDRIVE_API_KEY) // Replace with your API key
      .setCallback((data) => pickerCallback(data, accToken))
      .enableFeature(window.google.picker.Feature.MULTISELECT_ENABLED)
      .build();
    picker.setVisible(true);
  };

  const pickerCallback = async (data, accToken) => {

    if (data.action === window.google.picker.Action.PICKED) {

      const files = await Promise.all(
        data.docs.map(async (doc) => {
          try {
            let downloadUrl;
            if (doc.mimeType === "application/vnd.google-apps.spreadsheet") {
              // Export Google Spreadsheet as XLSX
              downloadUrl = `https://www.googleapis.com/drive/v3/files/${doc.id}/export?mimeType=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
              doc.mimeType = "application/xlsx";
              doc.name = `${doc.name}.xlsx`;
            } else if (doc.mimeType === "application/vnd.google-apps.document") {
              // Export Google Docs as DOCX
              downloadUrl = `https://www.googleapis.com/drive/v3/files/${doc.id}/export?mimeType=application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
              doc.mimeType = "application/docx";
              doc.name = `${doc.name}.docx`;
            } else {
              // For other file types, download the original file
              downloadUrl = `https://www.googleapis.com/drive/v3/files/${doc.id}?alt=media`;
            }

            const response = await fetch(downloadUrl, {
              headers: new Headers({
                Authorization: `Bearer ${accToken}`,
              }),
            });

            if (!response.ok) {
              throw new Error(`Failed to fetch file: ${doc.name}, Status: ${response.status}`);
            }

            const blob = await response.blob();

            // Validate blob size and file integrity
            if (blob.size === 0) {
              toast.error(`${doc.name} seems corrupted.`);
              return null;
            }

            const mimeType = doc.mimeType || blob.type;

            // Return the constructed File object
            return new File([blob], doc.name, {
              type: mimeType,
              lastModified: new Date(doc.lastEditedUtc).getTime(),
            });
          } catch (error) {
            console.error("Error fetching file:", error);
            return null;
          }
        })
      );


      const failedFiles = files.some((file) => file === null);

      if (failedFiles) {
        if (statusCode === 403) {
          toast.error("File/s too large to upload.");
          return;
        }
        else {
          toast.error("Failed to upload file/s.");
        }
        return;
      }

      const filteredFiles = files.filter((file) => file !== null);

      const dataTransfer = new DataTransfer();
      filteredFiles.forEach((file) => dataTransfer.items.add(file));
      const fileList = dataTransfer.files;

      const event = new Event("change", { bubbles: true });
      Object.defineProperty(event, "target", {
        writable: true,
        value: { files: fileList },
      });
      setStatusCode(null);
      handleFileChange(event);
    } else if (data.action === "cancel") {
      console.log("User clicked cancel/close button");
    } else {
      console.log("Unhandled action: ", data);
    }
  };

  // Request an access token if not already available
  const handleOpenPicker = () => {
    if (!accessToken) {
      loginWithGoogle();
    } else {
      loginWithGoogle();
      // createPicker(accessToken);
    }
    setShowUploadPop(false);
  };


  const onSuccess = async (tokenResponse) => {

    const response = await fetch('https://oauth2.googleapis.com/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        code: tokenResponse.code,
        client_id: process.env.REACT_APP_GOOGLEDRIVE_CLIENTID,
        client_secret: process.env.REACT_APP_GOOGLEDRIVE_SECRET_KEY,
        // redirect_uri: 'http://localhost:3000',
        redirect_uri: process.env.REACT_APP_SHARE_URL, // Ensure this matches with your console setup
        grant_type: 'authorization_code',
        access_type: 'offline'
      })
    });

    const tokenData = await response.json();

    if (tokenData) {

      const creds = {
        google_token: {
          token: tokenData.access_token,
          refresh_token: tokenData.refresh_token || null,
          token_uri: "https://oauth2.googleapis.com/token",
          client_id: process.env.REACT_APP_GOOGLEDRIVE_CLIENTID,
          client_secret: process.env.REACT_APP_GOOGLEDRIVE_SECRET_KEY,
          scopes: tokenResponse.scope ? tokenData.scope.split(' ') : [],
          expiry: new Date(Date.now() + tokenData.expires_in * 1000).toISOString()
        }
      };

      dispatch(submitGoogleToken(creds, token))
      setAccessToken(tokenData?.access_token);
      createPicker(tokenData?.access_token)
    }
  };

  const onFailure = (error) => {
    console.error("Login failed:", error);
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess,
    onError: onFailure, // Changed from onFailure to onError as per the GIS library
    scope: [
      "https://www.googleapis.com/auth/drive.file",
      "https://www.googleapis.com/auth/drive",
      "https://www.googleapis.com/auth/drive.metadata.readonly",
      "https://www.googleapis.com/auth/drive.metadata",
    ].join(" "),
    access_type: 'offline',
    approval_prompt: 'force',
    prompt: 'consent',
    flow: 'auth-code'
  });

  useEffect(() => {
    const loadGoogleScripts = () => {
      const apiScript = document.createElement('script');
      apiScript.src = 'https://apis.google.com/js/api.js'; // Load Google APIs
      apiScript.async = true;
      apiScript.onload = () => {
        window.gapi.load('picker', () => {
          setPickerInited(true); // Set pickerInited to true when Picker API is loaded
        });
      };
      document.body.appendChild(apiScript);
    };

    loadGoogleScripts();
  }, []);

  useEffect(() => {
    if (googleToken?.google_token !== null) {
      dispatch(getGoogleTokenAction(token));
    }
  }, [googleToken])

  useEffect(() => {
    if (googleAccessToken?.googleToken) {
      setAccessToken(googleAccessToken?.googleToken?.google_token?.token)
    }
  }, [googleAccessToken])

  useEffect(() => {
    return () => {
      dispatch(resetsubmitgoogleToken());
    }
  }, []);

  return (

    <>
      <div
        className="google-drive-popup-folder"
        onClick={handleOpenPicker}
        disabled={!pickerInited}
        style={{ cursor: "pointer" }}
      >
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
        />
        <div className="google-drive-image">
          <img src={google_drive} alt="Google Drive" />
        </div>
        <p>{accessToken === undefined ? "Connect To Drive" : "Select From Drive"}</p>
      </div>
    </>
  );
};

export default GoogleDriveUpload;