import React, { useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";

import { formatApiErrors } from "../utils/formatApiErrors";
import { google_logo } from "../assets/images/images";
import {
  GoogleAuthProvider,
  auth,
  signInWithPopup,
} from "../config/firebaseConfig";
import api from "../api/api";
import ChangePassword from "../components/ChangePassword/ChangePassword";

const GoogleInUp = (props) => {

  const [userId, setuserId] = useState("");
  const [changePassword, setchangePassword] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get("redirect");
  if (redirectPath) {
    localStorage.setItem("redirectAfterSignup", redirectPath);
  }
  const redirectLink = localStorage.getItem("redirectAfterSignup");

  const redirectCourse = searchParams.get("redirectToCourse");
  if (redirectCourse) {
    localStorage.setItem("redirectToCourse", redirectCourse);
  }

  const redirectToCoursePath = localStorage.getItem("redirectToCourse");

  const loginWithGoogle = async (e) => {
    e.preventDefault();
    if (props.activeTab === "" || props.activeTab === null) {
      props.setRoleValid(true);
      return;
    }
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // console.log("result", result);
      // return;
      // const user = result?._tokenResponse?.email;
      const user = result._tokenResponse;
      // const userEmailid = result?._tokenResponse?.email;
     
      if (user) {
        const { email,emailVerified, localId } = user;
       
        handleUserLogin(email, emailVerified, localId);
      }
    } catch (error) {
      console.error("Error with Google login: ", error);
      toast.error("Login failed. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const onChangePasswordClose = () => {
    setchangePassword(!changePassword);
  };

  const handleUserLogin = async (email, emailVerified, localId) => {
    const failedErrorMessage =
      "Oops, something went wrong. Please try again later.";
    try {
      if (emailVerified) {
        let role = 2;
        if (props.activeTab === "Student") {
          role = 1;
        } else if (props.activeTab === "Teacher") {
          role = 2;
        } else if (props.activeTab === "Parent") {
          role = 3;
        }
        try {
          const response = await api.post(`auth/google-login/?role=${role}`, {
            email: email,
            role: role,
            key: localId,
          });
          if (response?.status === 400) {
            toast.dismiss();
            const error = formatApiErrors(response?.data);
            toast.error(
              error || response?.data?.message || failedErrorMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
            props.setLoading(false);
            return;
          }
          if (response?.data?.first_login &&
            response?.data?.token === undefined) {
            toast.dismiss();
            setuserId(response?.data?.user_id);
            setchangePassword(true);
          }
          if (response?.data?.token === undefined) {
            toast.dismiss();
            const error = formatApiErrors(response?.data);
            toast.error(
              error || response?.data?.message || failedErrorMessage,
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
            props.setLoading(false);
            return;
          }
          props.login(response?.data?.token, props.activeTab, email || "");
          toast.dismiss();
          toast.success("Login successful.", {
            position: toast.POSITION.TOP_CENTER,
          });
          if (props.activeTab === "Student") {
          } else if (props.activeTab === "Teacher") {
            navigate("/dashboard/home", { state: { role: props.activeTab } });
          } else if (props.activeTab === "Parent") {
            role = "parent";
          }
          props.setLoading(false);
        } catch (error) {
          toast.dismiss();
          const errorMessage = formatApiErrors(error?.response?.data);
          toast.error(errorMessage || failedErrorMessage, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.setLoading(false);
        }
      }
    } catch (error) {
      console.error("Error fetching user info", error);
    }
  };
  return (
    <>
      <button
        disabled={props.loading}
        className="login-btns"
        onClick={loginWithGoogle}
      >
        <ReactSVG src={google_logo} />
        <p>{props.forContent}</p>
      </button>
      {changePassword && (
        <ChangePassword
          changePassword={changePassword}
          onChangePasswordClose={onChangePasswordClose}
          userId={userId}
        />
      )}
    </>
  );
};

export default GoogleInUp;
