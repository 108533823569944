import React from "react";

import { course_demo_image } from "../../../../../../assets/images/images";
import LinearWithValueLabel from "../../../../../../utils/LinearProgresslabel";
import { useNavigate, useParams } from "react-router-dom";

const CourseCard = (props) => {
  const navigate = useNavigate();
  const { student_id } = useParams();

  const lessonStatsHandler = (id) => {
    const course = props.course;
    navigate(`/student-detail/${student_id}/course/${id}/lesson-stats`, { state: { teacher_id: props.course.teacher_id,course:course } })
  }

  const assignmentStatsHandler = (id) => {
    navigate(`/student-detail/${student_id}/course/${id}/assignment-stats`)
  }
  const viewCourseHandler = () => {
    const course = props.course;
    const teacher_id = props.course.teacher_id;
      navigate(`/course/${props?.course?.course_id}/view`, {
          state: { course, teacher_id },
      });
}
const onteacherViewProfile = (tid) => {
  navigate(`/TeacherProfile/${tid}`);
};
  return (
    <div
      className="studentDashboar-Suggested-Courses-folder"
      id="studentCourseCard-folder"
    >
      <div className="studentDashboar-Suggested-Courses-image" onClick={viewCourseHandler}>
        <img src={props?.course?.image_url || course_demo_image} alt="" />
      </div>
      <div className="studentDashboar-Suggested-Courses-profile-folder" >
        <div className="studentDashboar-Suggested-Courses-profile">
          <div className="studentDashboar-profile-image" onClick={() => {
            onteacherViewProfile(props.course.teacher_id);
          }}>
            <img src={props?.course?.ai_avatar} alt="" />
          </div>
          <div className="studentDashboar-Suggested-Courses-profile-title">
            <h2 className="tudentDashboar-profile-card-title" onClick={() => {
            onteacherViewProfile(props.course.teacher_id);
          }}>
              {props?.course?.teacher_name}
            </h2>
            <p  onClick={() => {
            onteacherViewProfile(props.course.teacher_id);
          }}>Teacher</p>
          </div>
        </div>
        <div className="studentDashboar-Suggested-Courses-profile-rate"  onClick={() => {
            onteacherViewProfile(props.course.teacher_id);
          }}>
          {props?.course?.rate && (
            <>
              {" "}
              <a href="#">
                <i className="fa-solid fa-star"></i>
              </a>
              <p className="rate">{props?.course?.rate}</p>
            </>
          )}
          {props?.course?.number_of_review && (
            <p className="number">({props?.course?.number_of_review})</p>
          )}
        </div>
      </div>
      <p className="studentDashboar-profile-course-name" onClick={viewCourseHandler}>
        {props?.course?.subject}
      </p>
      <h2 className="studentDashboar-profile-course-title" onClick={viewCourseHandler}>
        {props?.course?.name}
      </h2>
      <div className="studentDashboar-profile-btn-folder">
        <button type="button" onClick={() => lessonStatsHandler(props?.course?.course_id)}>
          Lesson Stats
        </button>
      </div>
    </div>
  );
};

export default CourseCard;
