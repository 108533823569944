import StudentAssignmentPreview from "../student/lessons/StudentAssignmentPreview";
import { useSelector } from 'react-redux';
import TeacherAssignmentPreview from "../teacher/lessons/TeacherAssignmentPreview";
import { useLocation } from "react-router-dom";
import StudentLessonChatbot from "../../../../../components/studentLessonChatbot/StudentLessonChatbot";


function AssignmentPreview() {
    const location = useLocation();
    const role = useSelector((state) => state.globalState.role);
    return role === 'Student' ?
        <>
            <StudentAssignmentPreview />
            <StudentLessonChatbot
                ai_name={`${location?.state?.lesson?.teacher?.ai_name}`}
                teacher_id={location?.state?.lesson?.teacher?.user_id}
                courseId={location?.state?.course?.course_id}
                lesson_id={location?.state?.lesson?.lesson_id}
                assignment_id={location?.state?.assignment?.assignment_id}
                lesson_name={location?.state?.lesson?.name ?? ""}
                ai_tone={location?.state?.lesson?.teacher?.ai_tone}
                ai_avatar={location?.state?.lesson?.teacher?.ai_avatar}
                ai_welcome_msg={location?.state?.lesson?.teacher?.ai_welcome_msg}
                page_infojson={location?.state?.assignment?.assignment_json}
            />
        </> : <TeacherAssignmentPreview />;
}

export default AssignmentPreview;