import {
  Box,
  FormControl,
  FormHelperText,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Waveform } from "@uiball/loaders";
import { format } from "date-fns";
import { marked } from "marked";
import React, { useEffect, useRef, useState, useCallback } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api, { liveBaseURL, wsURL } from "../../../../../../api/api";
import ass_img from "../../../../../../assets/images/ass-img.png";
import Calimg from "../../../../../../assets/images/calendar.png";
import go_btn from "../../../../../../assets/images/go-btn-img.png";
import {
  Course_created_successfully_image,
  google_drive,
  uplode_file,
} from "../../../../../../assets/images/images";
import jpg from "../../../../../../assets/images/jpg.png";
import pdf_img from "../../../../../../assets/images/pdf.png";
import png_img from "../../../../../../assets/images/png.png";
import xlsx_img from "../../../../../../assets/images/xls.png";
import csv_img from "../../../../../../assets/images/csv-file.png";
import doc_img from "../../../../../../assets/images/docx-file.png";
import file_img_storage from "../../../../../../assets/images/file-storage.png";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import CommonTextField from "../../../../../../utils/CommonTextField";
import LoaderComponent from "../../../../../../components/LoaderComponent/LoaderComponent";
import {
  checkifDateisAfterToday,
  isRequired,
  parseKaTeX,
} from "../../../../../../utils/validator";
import "../../../../styles/dashboard.css";
import "../../../../styles/lesson.css";
import "../../../../styles/responsive_lesson.css";
import GoogleDocFile from "./google_docs";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { TRANSFORMERS } from "@lexical/markdown";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
  $getRoot,
  $insertNodes,
} from "lexical";

import { $getSelection, $isRangeSelection } from "lexical";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import AOS from "aos";
import "aos/dist/aos.css";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { updateGlobalState } from "../../../../../../state/global/slice.tsx";
import { AssignmentType } from "../../../../../../utils/consts.js";
import "../../../editor/css/styles.css";
import AutoLinkPlugin from "../../../editor/plugins/AutoLinkPlugin";
import CodeHighlightPlugin from "../../../editor/plugins/CodeHighlightPlugin";
import HtmlPlugin from "../../../editor/plugins/HtmlPlugin.tsx";
import ListMaxIndentLevelPlugin from "../../../editor/plugins/ListMaxIndentLevelPlugin";
import ToolbarPlugin from "../../../editor/plugins/ToolbarPlugin";
import ExampleTheme from "../../../editor/themes/ExampleTheme";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import AWSS3FileDelete from "../../../../../../components/AWSFileUpload/AWSS3FileDelete.jsx";
import AWSS3FileUpload from "../../../../../../components/AWSFileUpload/AWSS3FileUpload.jsx";
import BackButton from "../../../../../../components/BackButton/BackButton.js";
import PreprocessAndRenderLatex from "../../../../../../components/ProcessRenderLatex";
import UniversalChatbot from "../../../../../../components/universalChatbot/UniversalChatbot.js";
import { binaryStringToArrayBuffer } from "../../../../../../utils/utlis";
import Dictaphone from "../../../../../speechtotext/SpeechtoText.jsx";
import SpeechGenerator from "../../../../../texttospeech/TexttoSpeech.jsx";
import MathComponent from "./MathComponent";
import "../../../../../speechtotext/SpeechtoText.css";
import {
  getTextToSpeech,
  resetTextToSpeech,
} from "../../../../../../state/actions/getTextToSpeechAction";
import { data, error } from "jquery";
import { red } from "@mui/material/colors";
import { arrayIncludes } from "@mui/x-date-pickers/internals/utils/utils";
import { AttachFileOutlined } from "@mui/icons-material";
import FileUpload from "../../../../../../components/FileUploadToS3/FileUpload.js";
import { MathNode } from "./MathNode.js";

const NewLessons = () => {
  const { token } = useAuth();
  const [workboardText, setWorkboardText] = useState("");

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [editloading, setEditLoading] = useState(false);
  const [checkAssignmentopen, setCheckAssignmentOpen] = useState(false);
  const location = useLocation();

  const [openStudentView, setopenStudentView] = useState(false);
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.globalState);
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const texttospeech = useSelector((state) => state.texttospeech);
  const lastTranscriptRef = useRef("");
  const [rubrics, setRubrics] = useState({});
  const course = location.state?.course || {}; // default to Student if no role is passed
  const teacher_id =
    location.state?.teacher_id ??
    globalState.teacher_details?.data?.teacher_id ??
    "";
  const [lesson, setLesson] = useState((location.state?.lesson) || {});
  const [isCopied, setIsCopied] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFileLink, setuploadedFileLink] = useState([]);
  const [progressTracker, setprogressTracker] = useState([]);
  const fileInputRef = useRef(null);
  const [assignment_id, setassignment_id] = useState("");
  const [eachAssignment_id, setEachAssignment_id] = useState(null);
  const [eachRubric_id, setEachRubric_id] = useState(null);
  const [showCloseBTN, setShowCloseBTN] = useState(false);
  const [showUploadPop, setShowUploadPop] = useState(false);
  const [isSaveChecker, setisSaveChecker] = useState(false);
  const [getschoologystatus, setschoologystatus] = useState(false);
  const [showInputTooltip, setShowInputTooltip] = useState(false);
  const [latextextvalue,setLatextextvalue] = useState("");
  const [oldMathNode, setOldMathNode] = useState(null);
  const [oldnodeeditor, setOldNodeEditor] = useState(null);
  const showUploadPopupcall = () => {
    if (showUploadPop) {
      setShowUploadPop(false);
    } else {
      setShowUploadPop(true);
    }
  };
  const {
    isListening,
    transcript,
    startListening,
    stopListening,
  } = Dictaphone({ continuous: true, interimResults: true, lang: "en-US" });
  const handleIconClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (e) => {
    const updatedFiles = Array.from(e.target.files);
    setSelectedFiles((prevFiles) => [...prevFiles, ...updatedFiles]);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
  };
  const textBoxRef = useRef(null);

  const getFileIcon = (fileName) => {
    const extension = fileName
      .split(".")
      .pop()
      .toLowerCase();

    switch (extension) {
      case "pdf":
        return pdf_img;
      case "doc":
        return doc_img;
      case "docx":
        return doc_img;
      case "xls":
        return xlsx_img;
      case "xlsx":
        return xlsx_img;
      case "csv":
        return csv_img;
      case "jpg":
        return jpg;
      case "jpeg":
        return jpg;
      case "png":
        return png_img;
      default:
        return file_img_storage; // Default icon if the file type is not recognized
    }
  };

  const getFileType = (fileName) => {
    const extension = fileName
      .split(".")
      .pop()
      .toLowerCase();
    switch (extension) {
      case "pdf":
        return "PDF";
      case "doc":
        return "Word";
      case "docx":
        return "Word";
      case "xls":
        return "Excel";
      case "xlsx":
        return "Excel";
      case "jpg":
        return "Image";
      case "jpeg":
        return "Image";
      case "png":
        return "Image";
      default:
        return "PDF"; // Default file type if not recognized
    }
  };
  const [chatHist, setChatHist] = useState([]);
  const [chatHistCopy, setChatHistCopy] = useState([]);

  const [isNotSavePopUp, setIsNotSavePopUp] = useState(false);
  const [callAddAssignmentFun, setCallAddAssignmentFun] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [isAssignment, setIsAssignment] = useState(false);
  const [isRubric, setIsRubric] = useState(false);
  const [activeTab, setActiveTab] = useState("lesson");
  const [room_id, setRoom_id] = useState("");
  const [defaultWorkboardData, setDefaultWorkboardData] = useState({
    LESSON: {
      name: "LESSON",
      isChanged: false,
      data: "",
    },
    ASSIGNMENT: {
      name: "ASSIGNMENT",
      isChanged: false,
      data: "",
    },
    RUBRIC: {
      name: "RUBRIC",
      isChanged: false,
      data: "",
    },
  });

  const updateDefaultWorkboardData = (name, data) => {
    setDefaultWorkboardData((prev) => ({
      ...prev,
      [name]: {
        ...prev[name],
        data,
      },
    }));
  };

  const savedFormData = JSON.parse(localStorage.getItem("lesson_formState"));


  const [formState, setFormState] = useState(() => {
    const savedFormData = localStorage.getItem("lesson_formState");
    const lessonDetails = localStorage.getItem("lessonDetails");
    var _savedFormData = {};
    if (savedFormData) {
      _savedFormData = JSON.parse(savedFormData);
      const roomid_saved = localStorage.getItem("roomId");
      if (roomid_saved) {
        setRoom_id(roomid_saved);
      }
    }

    if (savedFormData && lessonDetails) {
      if (JSON.parse(savedFormData).description !== JSON.parse(lessonDetails).data) {
        _savedFormData.description = JSON.parse(lessonDetails).data;
      }
    }
    return {
      ...(_savedFormData.start_date
        ? _savedFormData
        : {
          name: lesson?.name ?? null,
          assignment_no: null,
          start_date: lesson?.start_date ?? dayjs().format("YYYY-MM-DD"),
          end_date: lesson?.end_date ?? null,
          description: lesson?.description ?? null,
          chat_query: null,
          chat_hist: null,
        }),
    };
  });

  // const [formState, setFormState] = useState({
  //   name: lesson?.name ?? null,
  //   assignment_no: null,
  //   start_date: lesson?.start_date ?? null,
  //   end_date: lesson?.end_date ?? null,
  //   description: lesson?.description ?? null,
  //   chat_query: null,
  //   chat_hist: null,
  // });
  const [formLessonError, setFormLessonErrorState] = useState({
    name: "",
    start_date: "",
    end_date: "",
  });
  const getAssignmentTypeValue = (type) => {
    return Object.values(AssignmentType).find((item) => {
      return item.name === type;
    })?.value;
  };
  const getAssignmentTypeName = (type) => {
    return Object.values(AssignmentType).find((item) => {
      return item.value === type;
    })?.name;
  };

  const [formAssignmentState, setFormAssignmentState] = useState({
    name: null,
    assignment: null,
    assignment_json: null,
    lesson: null,
    duration_minutes: null,
    start_date: dayjs().format("YYYY-MM-DD"),
    end_date: null,
    type: null,
  });



  const [formErrorAssignmentState, setFormErrorAssignmentState] = useState({
    name: "",
    assignment: "",
    lesson: "",
    duration_minutes: "",
    start_date: "",
    end_date: "",
    type: "",
  });

  const [voices, setVoices] = useState([]);
  useEffect(() => {
    const voice = speechSynthesis.getVoices();
    if (Array.isArray(voice) && voice.length > 0) {
      setVoices(voice);
      return;
    }
    if ("onvoiceschanged" in speechSynthesis) {
      speechSynthesis.onvoiceschanged = function () {
        const voice = speechSynthesis.getVoices();
        setVoices(voice);
      };
    }
    getAssignment(lesson?.lesson_id);
  }, []);

  const handleAssignmentInputChange = (event) => {
    const { name, value } = event.target;
    let errors = {};
    if (name === "name") {
      errors.name = isRequired(value);
    }
    if (name === "assignment") {
      errors.assignment = isRequired(value);
    }
    if (name === "duration_minutes") {
      errors.duration_minutes = isRequired(value);
    }
    if (name === "start_date") {
      errors.start_date = isRequired(value);
    }
    if (name === "end_date") {
      errors.end_date = isRequired(value);
    }
    if (name === "type") {
      errors.type = isRequired(value);
    }
    setFormErrorAssignmentState(errors);

    setFormAssignmentState((prev) => ({ ...prev, [name]: value }));
  };

  const handleAssignmentInputChangedate = (name, date) => {
    let errors = {};
    if (name === "start_date") {
      errors.start_date = isRequired(format(new Date(date), "yyyy-MM-dd"));
    }
    setFormErrorAssignmentState(errors);
    setFormAssignmentState((prev) => ({
      ...prev,
      [name]: format(new Date(date), "yyyy-MM-dd"), // Use the provided name
    }));
  };

  const currentDate = dayjs();
  const startDate = dayjs(formState.start_date);

  const minDate = currentDate.isAfter(startDate)
    ? currentDate.format("MMM DD, YYYY")
    : startDate.format("MMM DD, YYYY");

  const courseStartDate = dayjs(course?.start_date);

  const lessonMinDate = currentDate.isAfter(courseStartDate)
    ? currentDate.format("MMM DD, YYYY")
    : courseStartDate.format("MMM DD, YYYY");

  const isValidDateAssignmentStartDate = (currentDate, selectedDate) => {
    return currentDate.isSameOrAfter(minDate, "day");
  };
  const isValidDateAssignmentEndDate = (currentDate, selectedDate) => {
    // Choose the correct start date based on whether formAssignmentState?.start_date is defined
    const startDate = moment(
      formAssignmentState?.start_date
        ? formAssignmentState.start_date
        : formState.start_date
    ).add(1, "days");

    // Disable dates that are before the start date (making only dates after start date selectable)
    return !currentDate.isBefore(startDate);
  };

  const isValidDateLessonStartDate = (currentDate) => {
    const endDate = course?.end_date ? moment(course?.end_date) : null;
    const lessonendDate = formState?.end_date
      ? moment(formState?.end_date)
      : null;
    const isAfterMinDate = currentDate.isSameOrAfter(lessonMinDate, "day");
    const isBeforeEndDate = lessonendDate
      ? lessonendDate
        ? currentDate.isSameOrBefore(lessonendDate, "day")
        : true
      : endDate
        ? currentDate.isSameOrBefore(endDate, "day")
        : true;
    return isAfterMinDate && isBeforeEndDate;
  };
  const isValidDateLessonEndDate = (currentDate, selectedDate) => {
    const startDate = moment(course?.start_date);
    const endDate = course?.end_date ? moment(course?.end_date) : null;
    const startDateLesson = moment(formState?.start_date ?? "");
    const today = moment(); // Get the current date
    // Check if endDate is provided and if it's not expired
    if (endDate && endDate.isBefore(today)) {
      return false; // endDate is expired, return false
    }

    // Disable dates between start and end dates, inclusive
    return endDate
      ? !currentDate.isBefore(startDate) &&
      !currentDate.isAfter(endDate) &&
      currentDate.isAfter(startDateLesson)
      : !currentDate.isBefore(startDate) &&
      currentDate.isAfter(startDateLesson);
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const messagesEndRef = useRef(null);
  // Load form data from localStorage when the component mounts

  const createChatRoom = async (user_id) => {
    const payload = {
      user_id: user_id,
      against_user_id: user_id,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      const response = await api.post(
        `ai/api/lesson_create_chat_room/`,
        payload,
        headers
      );
      if (response.status === 200 || response.status === 201) {
        setRoom_id(response?.data?.room_id);
        localStorage.setItem("roomId", response?.data?.room_id);
        return response?.data?.room_id;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };
  const getChatHistorybyRoomid = (roomid) => {
    const payload = {
      room_id: roomid,
    };
    const url = `ai/api/get-chat-history-by-room-id/`;
    callchathistfunction(url, payload);
  };
  const getChatHistory = (lessonId) => {
    const payload = {
      lesson_id: lessonId,
    };
    const url = `ai/api/lesson_chat_history/`;
    callchathistfunction(url, payload);
  };

  const callchathistfunction = async (url, payload) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    try {
      const response = await api.post(url, payload, headers);

      if (response.status === 200 || response.status === 201) {
        const getPastHistory = response?.data?.data?.histories?.map((item) => {
          return {
            uuid: item?.uuid,
            query: item?.query,
            answer: marked(
              item?.answer
                ?.replace(
                  /(\\\()(.*?)(\\\))/g,
                  (match, p1, p2, p3) => `$$${p1}${p2}${p3}$$`
                )
                .replace(/\\_/, "_")
            ),
            file: item?.file_uploads,
          };
        });
        if (payload?.lesson_id) {
          if (response?.data?.data?.room_id) {
            setRoom_id(response?.data?.data?.room_id);
            localStorage.setItem("roomId", response?.data?.data?.room_id);
          } else {
            getUserId().then((user_id) => {
              if (user_id !== null) {
                createChatRoom(user_id).then((roomId) => {
                  if (roomId !== "") {
                    setRoom_id(roomId);
                    localStorage.setItem("roomId", roomId);
                  }
                });
              }
            });
          }
        }

        const getPastHistoryCopy = response?.data?.data?.histories.map((item) => {
          return item.answer.replace(/#/g, '')
            .replace(/##/g, '')
            .replace(/###/g, '')
            .replace(/####/g, '')
            .replace(/\*\*/g, '')
            // .replace(/```jsx/g, '')  
            .replace(/```/g, '"')
            .replace(/__/g, '"')
            .replace(/\$\$/g, '');
        })


        console.warn(1);
        console.warn(1, getPastHistory);
        console.warn(1, response);

        setChatHist(getPastHistory);
        setChatHistCopy(getPastHistoryCopy);
      }
    } catch (error) {
      // console.log(error);
    }
  };
  const getUserId = async () => {
    try {
      const response = await api.get(`auth/get-user-id/`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });
      if (response.status === 200 || response.status === 201) {
        updateState("user_id", response?.data?.user_id);
        return response?.data?.user_id;
      } else {
        return null;
      }
    } catch (error) {
      // console.log(error);
      return null;
    }
  };
  const sendFiles = async () => {

    const files = await Promise.all(
      [...uploadedFileLink].map((file) => ({
        original_file_name: file.name,
        link: file.location,
        file_id: file.file_id,
      }))
    );

    const payload = {
      room_id,
      lesson_name: formState.name,
      course_id: course?.course_id,
      query: formState.chat_query,
      files,
    };
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`,
    };
    try {
      const response = await api.post(`ai/api/upload/`, payload, headers);
      if (response.status === 200 || response.status === 201) {
      }
    } catch (error) {
      setSelectedFiles([]);
    }
  };
  const [assignments, setAssignments] = useState([]);


  const getRubric = async (lesson_id, assignment_data) => {
    await api
      .get(`assignments/get-rubric-by-lesson-id/${lesson_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })

      .then((response) => {

        if (response.status === 200) {
          const getAssignmentIds = assignment_data.map((val) => val.rubric_id)
          const get_rubics = response?.data?.data;
          const rubricOrders = []

          for (let i = 0; i < getAssignmentIds.length; i++) {

            if (getAssignmentIds[i] === null) {

              const arr = {
                data: '<p class="editor-paragraph" dir="ltr"> <b><strong class="editor-text-bold" style="white-space: pre-wrap;">This Rubric is generated by the system</strong></b></p>',
                Id: null
              }
              rubricOrders.push(arr)
            } else {
              for (let j = 0; j < get_rubics.length; j++) {
                if (getAssignmentIds[i] === get_rubics[j].rubric_id) {

                  const arr = {
                    data: get_rubics[j].rubric,
                    Id: get_rubics[j].rubric_id
                  };
                  rubricOrders.push(arr)
                  break;
                }
              }
            }
          }
          setRubrics(rubricOrders);

          const rubricLog = get_rubics?.map((item) => item?.rubric).join("  ");

          updateWorkboardDataByName(workboards.RUBRIC.name, rubricLog);
          const getid = get_rubics?.length > 0 ? get_rubics[0]?.rubric_id : "";
          setrubric_id(getid);


          const rubricIds = get_rubics?.map(item => item.rubric_id) || [];
          localStorage.setItem('Rubric_id', JSON.stringify(rubricIds));

          const newArray = get_rubics.map(item => {
            return {
              data: item.rubric,
              Id: item.rubric_id
            };
          });

          const globleSaveData = JSON.parse(localStorage.getItem('GlobleSaveData'));
          const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));

          globleSaveData.RUBRIC.data = rubricOrders
          globleOnChange.RUBRIC.data = rubricOrders

          setGlobleOnChange(globleOnChange)
          setGlobleSaveData(globleSaveData)




          localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
          localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));

        }
      })
      .catch((error) => { });
  };



  // const getLeson = async (lesson_id) => {
  //   await api
  //     .get(`/lessons/get-lesson/${lesson_id}/`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Token ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       console.warn(response);
  //       // if (response.status === 200) {
  //       //   const get_rubics = response?.data?.data;
  //       //   setRubrics(get_rubics);
  //       //   const rubricLog = get_rubics?.map((item) => item?.rubric).join("  ");
  //       //   updateWorkboardDataByName(workboards.RUBRIC.name, rubricLog);
  //       //   const getid = get_rubics?.length > 0 ? get_rubics[0]?.rubric_id : "";
  //       //   setrubric_id(getid);

  //       //   const rubricIds = get_rubics?.map(item => item.rubric_id) || [];
  //       //   localStorage.setItem('Rubric_id', JSON.stringify(rubricIds));

  //       //   const newArray = get_rubics.map(item => {
  //       //     return {
  //       //       data: item.rubric,
  //       //       Id: item.rubric_id
  //       //     };
  //       //   });

  //       //   const globleSaveData = JSON.parse(localStorage.getItem('GlobleSaveData'));
  //       //   const globleOnChange = JSON.parse(localStorage.getItem("globleOnChangeData"));

  //       //   globleSaveData.RUBRIC.data = newArray
  //       //   globleOnChange.RUBRIC.data = newArray

  //       //   setGlobleOnChange(globleOnChange)
  //       //   setGlobleSaveData(globleSaveData)

  //       //   localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
  //       //   localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));

  //       // }
  //     })
  //     .catch((error) => { });
  // };

  const [getassignmentdetails, setGetAssignmentdetails] = useState([]);

  const getAssignment = async (lesson_id) => {
    await api
      .get(`assignments/get-assignments-by-lesson/${lesson_id}/?sort=Oldest`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {

          setGetAssignmentdetails(response?.data?.data);

          if (
            typeof lesson?.lesson_id === "undefined" ||
            lesson?.lesson_id === null ||
            lesson.lesson_id === ""
          ) {
            const getData = JSON.parse(localStorage.getItem("GlobleSaveData"))
            getRubric(getData.LESSON.data.data.Id, response?.data?.data);
          } else {
            getRubric(lesson?.lesson_id, response?.data?.data);
          }
          const get_assignment = response?.data?.data;
          const assignmentsLog = get_assignment
            ?.map((item) => item?.assignment)
            .join("  ");
          updateWorkboardDataByName(workboards.ASSIGNMENT.name, assignmentsLog);
          const getid =
            get_assignment?.length > 0 ? get_assignment[0]?.assignment_id : "";

          if (get_assignment?.length > 0) {
          }

          setassignment_id(getid);

          const assignmentIds =
            get_assignment?.map((item) => item.assignment_id) || [];
          localStorage.setItem("Assingment_Id", JSON.stringify(assignmentIds));

          const assignmentdatadetails =
            get_assignment?.map((item) => item.assignment) || [];

          let getNewArray = [];
          get_assignment.map((item) => {
            getNewArray.push(item.assignment);
          });

          const newArray = get_assignment.map((item) => {
            return {
              data: item.assignment,
              Id: item.assignment_id,
            };
          });
          const newFormAssignmentArrya = get_assignment.map((item) => {
            return {
              name: item.name,
              assignment: null,
              assignment_json: null,
              lesson: null,
              duration_minutes: item.duration_minutes,
              start_date: item.start_date,
              end_date: item.end_date,
              type: item.type,
            };
          });

          const globleSaveData = JSON.parse(
            localStorage.getItem("GlobleSaveData")
          );
          const globleOnChange = JSON.parse(
            localStorage.getItem("globleOnChangeData")
          );

          globleSaveData.ASSIGNMENT.data = newArray;
          globleSaveData.ASSIGNMENT.formSaveAssignment = newFormAssignmentArrya;
          globleOnChange.ASSIGNMENT.data = getNewArray;
          globleOnChange.ASSIGNMENT.formAssignment = newFormAssignmentArrya;

          setGlobleOnChange(globleOnChange);
          setGlobleSaveData(globleSaveData);

          localStorage.setItem(
            "GlobleSaveData",
            JSON.stringify(globleSaveData)
          );
          localStorage.setItem(
            "globleOnChangeData",
            JSON.stringify(globleOnChange)
          );
        }
      })
      .catch((error) => { });
  };
  useEffect(() => {

    if (
      typeof lesson?.lesson_id === "undefined" ||
      lesson?.lesson_id === null ||
      lesson.lesson_id === ""
    ) {
      const roomid_saved = localStorage.getItem("roomId");
      if (roomid_saved) {
        getChatHistorybyRoomid(roomid_saved);
      }
      getUserId().then((user_id) => {
        if (user_id !== null && roomid_saved == null) {
          createChatRoom(user_id).then((roomId) => {
            if (roomId !== "") {
              setRoom_id(roomId);
            }
          });
        }
      });
    } else {



      localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
      localStorage.setItem(
        "globleOnChangeData",
        JSON.stringify(globleOnChange)
      );
      getChatHistory(lesson?.lesson_id);
      getAssignment(lesson?.lesson_id);
      // getLeson(lesson?.lesson_id);
    }
  }, []);
  const [getSocket, setSocket] = useState(null);
  const renderPlainHtml = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const onCopyClicked = (chat, index) => {
    // Set isCopied to true
    setIsCopied(true);

    // After 2 seconds, reset isCopied to false
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);

    // const { answer } = chat;


    // Create a new textarea element to hold the text you want to copy
    const textArea = document.createElement("textarea");
    // textArea.value = marked(answer);
    textArea.value = chatHistCopy[index]

    // Append the textarea to the document
    document.body.appendChild(textArea);

    // Select the text within the textarea
    textArea.select();

    try {
      // Check if the Clipboard API is available
      if (navigator.clipboard && window.isSecureContext) {
        // Use the Clipboard API to copy the selected text to the clipboard
        navigator.clipboard
          .writeText(textArea.value)
          .then(() => {
            toast.success("Text copied to clipboard", {
              position: toast.POSITION.TOP_CENTER,
            });
            setIsCopied(true);
          })
          .catch((error) => {
            toast.error("Failed to copy text", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      } else {
        // Fallback method for non-SSL environments
        document.execCommand("copy");
        toast.success("Text copied to clipboard", {
          position: toast.POSITION.TOP_CENTER,
        });
        setIsCopied(true);
      }
    } catch (error) {
      toast.error("Failed to copy text", {
        position: toast.POSITION.TOP_CENTER,
      });
    } finally {
      // Remove the textarea element
      document.body.removeChild(textArea);
    }
  };

  const [shouldReconnect, setShouldReconnect] = useState(true);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);
  let combinedMessage = [];
  console.warn('combinedMessage', combinedMessage);

  // Create the WebSocket connection when the component mounts

  // const initializeWebSocket = () => {
  //   if (!room_id) return; // Ensure room_id is available

  //   const newWebSocketURL = `${wsURL}ws/lesson/${room_id}/?course_id=${course?.course_id}&teacher_id=${teacher_id}`;
  //   const socket = new WebSocket(newWebSocketURL);

  //   socket.onopen = () => {
  //     console.log("WebSocket connection opened");
  //     setReconnectAttempts(0); // Reset reconnect attempts on successful connection
  //   };

  //   socket.onmessage = (event) => {
  //     try {
  //       const data = JSON.parse(event.data);

  //       if (data && data.message) {
  //         const message = data.message;

  //         combinedMessage.push(message);
  //         let status = false;
  //         let chatdatares = combinedMessage.join("");

  //         if (
  //           chatdatares.includes("<STARTOFTURN>") ||
  //           chatdatares.includes("<ENDOFTURN>")
  //         ) {
  //           chatdatares = chatdatares
  //             .replace(/<STARTOFTURN>/g, "")
  //             .replace(/<ENDOFTURN>/g, "");

  //           if (combinedMessage.join("").includes("<ENDOFTURN>")) {
  //             status = true;
  //             combinedMessage.length = 0;
  //             combinedMessage.push(chatdatares);
  //           }
  //         }

  //         setChatHist((prevChatHist) => {
  //           const updatedChatHist = Array.isArray(prevChatHist)
  //             ? [...prevChatHist]
  //             : [];
  //           if (updatedChatHist.length > 0) {
  //             const lastQuery = updatedChatHist[updatedChatHist.length - 1];
  //             if (lastQuery) {
  //               lastQuery.answer = marked(chatdatares);
  //             }
  //           }
  //           if (status) {
  //             combinedMessage.length = 0;
  //           }
  //           return updatedChatHist;
  //         });
  //         scrollToBottom();
  //       } else {
  //       }
  //     } catch (error) {}

  //     // Handle the received message as needed
  //   };

  //   // socket.onclose = (event) => {
  //   //   console.log("WebSocket closed. Attempting to reconnect...");
  //   //   if (shouldReconnect) {
  //   //     const delay = Math.min(10000, 500 * (2 ** reconnectAttempts)); // Exponential backoff
  //   //     setTimeout(() => {
  //   //       setReconnectAttempts(reconnectAttempts + 1);
  //   //       initializeWebSocket(); // Attempt to reconnect
  //   //     }, delay);
  //   //   }
  //   // };
  //   socket.onclose = (event) => {
  //     console.log("WebSocket closed with code:", event.code, "reason:", event.reason, "Attempting to reconnect...");
  //     initiateReconnection();
  //   };
  //   socket.onerror = (error) => {
  //     console.error("WebSocket encountered an error:", error);
  //     initiateReconnection();
  //   };

  //   setSocket(socket);
  // };
  // const initiateReconnection = () => {
  //   if (shouldReconnect) {
  //     // Prevent immediate reconnection attempts that can lead to a loop
  //     setTimeout(() => {
  //       console.log("Attempting to reconnect...");
  //       initializeWebSocket(); // Attempt to reconnect
  //     }, calculateReconnectDelay());
  //   }
  // };
  // const calculateReconnectDelay = () => {
  //   const maxDelay = 10000; // Maximum delay of 10 seconds
  //   let delay = 500 * (2 ** reconnectAttempts); // Exponential backoff
  //   delay = Math.min(maxDelay, delay);
  //   setReconnectAttempts(prev => prev + 1);
  //   return delay;
  // };




  // recconect login on 24-10-2024

  useEffect(() => {
    if (!room_id) return;

    let socket = null;
    let reconnectInterval = 1000; // Initial reconnect interval in ms
    const maxReconnectInterval = 30000; // Max reconnect interval
    const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
    const maxReconnectAttempts = 5; // Maximum number of reconnect attempts

    const connectWebSocket = () => {
      const newWebSocketURL = `${wsURL}ws/lesson/${room_id}/?course_id=${course?.course_id}&teacher_id=${teacher_id}`;
      socket = new WebSocket(newWebSocketURL);

      // Set the WebSocket state variable
      setSocket(socket);

      // Reset reconnection state when successfully connected
      socket.onopen = () => {
        console.log('WebSocket connection opened');
        setReconnectAttempts(0); // Reset reconnect attempts
        reconnectInterval = 1000; // Reset reconnect interval
      };

      // Event handler for incoming messages from the WebSocket server
      socket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);

          if (data && data.message) {
            const message = data.message;
            setShowCloseBTN(true);
            combinedMessage.push(message);

            let status = false;
            let uuid = Number(data.uuid);
            let chatdatares = combinedMessage.join("");
            let chatdataresCopy = combinedMessage.join("");
            if (
              chatdatares.includes("<STARTOFTURN>") ||
              chatdatares.includes("<ENDOFTURN>")
            ) {
              chatdatares = chatdatares
                .replace(/<STARTOFTURN>/g, "")
                .replace(/<ENDOFTURN>/g, "")
                .replace(/(\\\()(.*?)(\\\))/g, (match, p1, p2, p3) => `$$${p1}${p2}${p3}$$`)
                .replace(/\\_/, "_");

              // chatdataresCopy = chatdataresCopy.replace(/<STARTOFTURN>/g, "")
              //   .replace(
              //     /(\\\()(.*?)(\\\))/g,
              //     (match, p1, p2, p3) => `$$${p1}${p2}${p3}$$`
              //   )
              //   .replace(/\\_/, "_");
              if (combinedMessage.join("").includes("<ENDOFTURN>")) {
                status = true;
                combinedMessage.length = 0;
                combinedMessage.push(chatdatares);
                setShowCloseBTN(false);
              }
            }

            // const processString = (str) => {
            //   let lines = str.split('\n');

            //   let processedString = lines.join('\n');

            //   processedString = processedString
            //     .replace(/#/g, '')
            //     .replace(/##/g, '')
            //     .replace(/###/g, '')
            //     .replace(/####/g, '')
            //     .replace(/\*\*/g, '')
            //     // .replace(/```jsx/g, '')  
            //     .replace(/```/g, '"')
            //     .replace(/__/g, '"')
            //     .replace(/\$\$/g, '');
            //   // .replace(/```html/g, '');     



            //   if (processedString.includes('<ENDOFTURN>')) {
            //     processedString = processedString.replace(/<ENDOFTURN>/g, "")
            //     setChatHistCopy((prevChatHist) => [
            //       ...prevChatHist,
            //       processedString
            //     ])
            //   }
            // };


            // processString(chatdataresCopy);

            setChatHist((prevChatHist) => {
              const updatedChatHist = Array.isArray(prevChatHist)
                ? [...prevChatHist]
                : [];
              if (updatedChatHist.length > 0) {
                const lastQuery = updatedChatHist[updatedChatHist.length - 1];
                if (lastQuery) {
                  lastQuery.answer = marked(chatdatares);
                  lastQuery.uuid = uuid;
                }
              }
              if (status) {
                combinedMessage.length = 0;
              }
              return updatedChatHist;
            });
            setSelectedFiles([]);
            setuploadedFileLink([]);
            scrollToBottom();
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      // Reconnect logic on WebSocket close
      socket.onclose = () => {
        if (reconnectAttempts < maxReconnectAttempts) {
          setTimeout(() => {
            setReconnectAttempts((prev) => prev + 1);
            reconnectInterval = Math.min(
              reconnectInterval * reconnectDecay,
              maxReconnectInterval
            );
            console.log(`Reconnecting... Attempt ${reconnectAttempts + 1}`);
            connectWebSocket(); // Attempt to reconnect
          }, reconnectInterval);
        } else {
          console.error("Maximum reconnection attempts reached. Cannot reconnect.");
        }
      };

      // WebSocket error handling
      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
        socket.close(); // Ensure the socket is closed properly
      };
    };

    // Connect the WebSocket initially
    connectWebSocket();

    // Cleanup function to close WebSocket when component unmounts or room_id changes
    return () => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.close();
      }
    };
  }, [room_id]);



  // useEffect(() => {
  //   // Create a WebSocket connection with the new URL
  //   if (room_id) {
  //     const newWebSocketURL = `${wsURL}ws/lesson/${room_id}/?course_id=${course?.course_id}&teacher_id=${teacher_id}`;
  //     let reconnectInterval = 1000; // Initial reconnect interval in ms
  //     const maxReconnectInterval = 30000; // Max reconnect interval
  //     let reconnectAttempts = 0; // Number of reconnect attempts
  //     const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
  //     const maxReconnectAttempts = 3; // Maximum number of reconnect attempts

  //     let socket = new WebSocket(newWebSocketURL);
  //     // Set the socket state variable
  //     setSocket(socket);

  //     // Event handler for when the WebSocket connection is established
  //     socket.onopen = () => {
  //       reconnectAttempts = 0; // Reset reconnect attempts on successful connection
  //       reconnectInterval = 1000; // Reset reconnect interval
  //     };

  //     // Event handler for incoming messages from the WebSocket server
  //     socket.onmessage = (event) => {
  //       try {
  //         const data = JSON.parse(event.data);

  //         if (data && data.message) {
  //           const message = data.message;
  //           setShowCloseBTN(true);
  //           combinedMessage.push(message);
  //           let status = false;
  //           let uuid = Number(data.uuid);
  //           let chatdatares = combinedMessage.join("");
  //           if (
  //             chatdatares.includes("<STARTOFTURN>") ||
  //             chatdatares.includes("<ENDOFTURN>")
  //           ) {
  //             chatdatares = chatdatares
  //               .replace(/<STARTOFTURN>/g, "")
  //               .replace(/<ENDOFTURN>/g, "")
  //               .replace(
  //                 /(\\\()(.*?)(\\\))/g,
  //                 (match, p1, p2, p3) => `$$${p1}${p2}${p3}$$`
  //               )
  //               .replace(/\\_/, "_");
  //             if (combinedMessage.join("").includes("<ENDOFTURN>")) {
  //               status = true;
  //               combinedMessage.length = 0;
  //               combinedMessage.push(chatdatares);
  //               setShowCloseBTN(false);
  //             }
  //           }

  //           setChatHist((prevChatHist) => {
  //             const updatedChatHist = Array.isArray(prevChatHist)
  //               ? [...prevChatHist]
  //               : [];
  //             if (updatedChatHist.length > 0) {
  //               const lastQuery = updatedChatHist[updatedChatHist.length - 1];
  //               if (lastQuery) {
  //                 lastQuery.answer = marked(chatdatares);
  //                 lastQuery.uuid = uuid;
  //               }
  //             }
  //             if (status) {
  //               combinedMessage.length = 0;
  //             }
  //             return updatedChatHist;
  //           });
  //           setSelectedFiles([]);
  //           setuploadedFileLink([]);
  //           scrollToBottom();
  //         } else {
  //         }
  //       } catch (error) {}

  //       // Handle the received message as needed
  //     };

  //     socket.onclose = () => {
  //       if (reconnectAttempts < maxReconnectAttempts) {
  //         setTimeout(() => {
  //           reconnectAttempts++;
  //           socket.close(); // Make sure to close old sockets if not yet closed
  //           socket = new WebSocket(newWebSocketURL); // Create a new socket and attempt to connect
  //           reconnectInterval = Math.min(
  //             reconnectInterval * reconnectDecay,
  //             maxReconnectInterval
  //           );
  //         }, reconnectInterval);
  //       } else {
  //       }
  //     };

  //     socket.onerror = (error) => {
  //       console.error("WebSocket error:", error);
  //       socket.close(); // Ensure the socket is closed properly
  //     };

  //     // Event handler for when the WebSocket connection is closed
  //     socket.addEventListener("close", (event) => {});

  //     // Event handler for WebSocket errors
  //     socket.addEventListener("error", (error) => {});

  //     // Cleanup the WebSocket connection when the component unmounts
  //     return () => {
  //       if (socket.readyState === 1) {
  //         // <-- This is important
  //         socket.close();
  //       }
  //     };
  //   }
  // }, [room_id]);




  // The empty dependency array ensures that this effect runs only once when the component mounts
  // useEffect(() => {
  //   initializeWebSocket();

  //   return () => {
  //     setShouldReconnect(false); // Prevent reconnection attempts after component unmount
  //     if (getSocket) {
  //       getSocket.close(1000, "Component unmounting");
  //     }
  //   };
  // }, [room_id]);
  const getname = (name) => {
    var fullName = name;
    // Split the name into words
    const words = fullName?.split(" ");

    // Initialize variables for the first letter of the first and last names
    var firstNameInitial = "";
    var lastNameInitial = "";

    // Check if there is a last name
    if (words?.length > 1) {
      firstNameInitial = words[0]?.charAt(0)?.toUpperCase();
      lastNameInitial = words[1]?.charAt(0)?.toUpperCase();
    }

    // Combine the uppercase initials
    var initials = firstNameInitial + lastNameInitial;

    return initials === "" ? "T" : initials;
  };
  function getAssignmentChatQuery() {
    setPopupVisible(false);
    const {
      name,
      start_date,
      end_date,
      duration_minutes,
      type,
    } = formAssignmentState;
    const prompt_message = formState?.chat_query;
    let endDateText = end_date ? `Assignment End Date: ${end_date}` : "";
    return `Assignment Name: ${name} 
      Start Date: ${start_date} 
      ${endDateText}  
      Assignment Timer: ${duration_minutes} mins
      Choose Assignment type: ${getAssignmentTypeName(type)}  
      
      ${prompt_message}`;
  }
  function getRubricChatQuery() {
    return `${formState?.chat_query}`;
  }

  // close the coonection if user click the close button
  const closeConnection = () => {
    getSocket.close();
    setShowCloseBTN(false);
  };
  const sendGenerateLession = async (e) => {
    e.preventDefault();
    scrollToBottom();

    if (!formState.name) {
      toast.error("Please enter Lesson Name.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    combinedMessage = [];
    if (isAssignment) {
      let errors = {};
      if (isRequired(formAssignmentState?.name)) {
        errors.name = "Name is required";
      }
      if (isRequired(formAssignmentState?.duration_minutes)) {
        errors.duration_minutes = "Timer is required";
      }
      if (isRequired(formAssignmentState?.start_date)) {
        errors.start_date = "Start date is required";
      }
      if (isRequired(formAssignmentState?.type)) {
        errors.type = "Type is required";
      }

      setFormErrorAssignmentState(errors);

      if (Object.values(errors).some((error) => error)) {
        return;
      } else {
        setPopupVisible(false);
      }
    }

    const teacher_details = globalState.teacher_details?.data;
    const hist = chatHist.map((item) => {
      return {
        query: item.query,
        answer: Array(item.answer)
          ?.map((item) => item)
          .join(""),
      };
    });
    // console.log("getSocket", getSocket);
    if (getSocket) {
      const payload = {
        query: isAssignment
          ? getAssignmentChatQuery()
          : isRubric
            ? getRubricChatQuery()
            : formState.chat_query,
        message: hist,
        teacher_name: teacher_details?.name,
        chatbot_name: teacher_details?.ai_name,
        course_name: course?.name,
        subject: course?.subject,
        lesson_name: formState.name,
      };
      // console.log("payload", payload);
      if (selectedFiles.length > 0) {
        sendFiles();
      }

      // Update chatHist with the new query

      setChatHist((prevChatHist) => [
        ...prevChatHist,
        {
          query: payload.query,
          answer: "",
          file:
            selectedFiles.length > 0
              ? selectedFiles.map((item) => ({
                original_name: item?.name,
              }))
              : [],
        },
      ]);
      const payloadJSON = JSON.stringify(payload);
      if (selectedFiles.length === 0) {
        getSocket.send(payloadJSON);
      }
      // Send the JSON data over the WebSocket connection

      setFormState((prev) => ({ ...prev, chat_query: "" }));
    }
    // Add functionality to enhance the description here
    setSelectedFiles([]);
    setprogressTracker([]);
  };

  // Save form data to localStorage whenever formState changes

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormState((prev) => ({
      ...prev,
      [name]: value,
      chat_hist: chatHist,
    }));
  };
  const handleInputKeyPress = (event) => {
    const { name, value } = event.target;
    if (value !== "" || value !== 0) {
      setFormState((prev) => ({ ...prev, [name]: value }));
      setFormState((prev) => ({ ...prev, chat_hist: chatHist }));

      if (event.key === "Enter" && !event.shiftKey) {
        if (value !== "" && value !== 0) {
          // Condition 1: Check if name is provided.
          const isNameProvided = formState.name;

          // Condition 2: If chat_query exists, ensure it's not null or an empty string.
          const isChatQueryValid = formState.chat_query
            ? formState.chat_query !== null && formState.chat_query !== ""
            : true;

          // Condition 3: If selectedFiles exist, ensure they correspond to progressTracker with all statuses 'Completed'.
          const isSelectedFilesValid = selectedFiles.length
            ? selectedFiles.length === progressTracker.length &&
            progressTracker.every((obj) => obj.status === "Completed")
            : true;

          if (!isNameProvided) {
            toast.error("Please enter Lesson Name.", {
              position: toast.POSITION.TOP_CENTER,
            });
          }

          if (isNameProvided && isChatQueryValid && isSelectedFilesValid) {
            sendGenerateLession(event);
          }
        }
        event.preventDefault();
      }
    }
  };

  const handleInputChangedate = (name, date) => {
    if (lesson_id !== "") {
      let errors = {};
      if (name === "end_date") {
        errors.end_date = checkifDateisAfterToday(
          format(new Date(date), "yyyy-MM-dd")
        );
      }
      setFormLessonErrorState((prev) => ({ ...prev, [name]: errors[name] }));
    }

    if (name === "start_date") {
      const startDate = moment();
      const endDate = date;
      if (endDate.isAfter(startDate)) {
        setPopupForLessonStartDateNote(true);
        setTimeout(() => {
          setPopupForLessonStartDateNote(false);
        }, 3000);
      }
    }
    setFormState((prev) => ({
      ...prev,
      [name]: format(new Date(date), "yyyy-MM-dd"), // Use the provided name
    }));
  };

  const [lesson_id, setlesson_id] = useState(lesson?.lesson_id ?? "");

  const [rubric_id, setrubric_id] = useState("");
  const [clickSaveBTN, setClickSaveBTN] = useState(false);
  const [clickSaveRubricBTN, setClickSaveRubricBTN] = useState(false);

  const [assingmentListOfID, setAssingmentListOfID] = useState([]);

  const [disabledRubric, setDisabledRubric] = useState(false);
  const [globleOnChangeDataSave, setGlobleOnChangeDataSave] = useState(false);

  const handleAssinmentRubricSubmit = async (e, schoologystatus) => {
    if (isSaveChecker) {
      return; // If the button is already disabled, do nothing
    }

    setisSaveChecker(true);
    setGlobleOnChangeDataSave(true);
    if (!isNotSavePopUp) {
      e.preventDefault();
    }
    if (activeWorkboard.name === "Assignment") {
      setClickSaveBTN(true);
    }
    if (activeWorkboard.name === "Rubric") {
      setClickSaveRubricBTN(true);
    }

    if (!checkAssignmentopen) {
      if (isAssignment) {
        let errors = {};
        if (isRequired(formAssignmentState?.name)) {
          errors.name = "Name is required";
        }
        if (isRequired(formAssignmentState?.duration_minutes)) {
          errors.duration_minutes = "Timer is required";
        }
        if (isRequired(formAssignmentState?.start_date)) {
          errors.start_date = "Start date is required";
        }
        if (isRequired(formAssignmentState?.type)) {
          errors.type = "Type is required";
        }
        setFormErrorAssignmentState(errors);

        if (Object.values(errors).some((error) => error)) {
          setisSaveChecker(false);
          return;
        }
      }
    }



    const payload = isAssignment
      ? previesAssignment
        ? {
          name:
            getassignmentdetails.length > 0
              ? getassignmentdetails[assignmentsCountTwo]?.name
              : formAssignmentState?.name,
          assignment: workboards.ASSIGNMENT.data,
          lesson: lesson_id,
          duration_minutes:
            getassignmentdetails.length > 0
              ? getassignmentdetails[assignmentsCountTwo]?.duration_minutes
              : formAssignmentState?.duration_minutes,
          start_date:
            getassignmentdetails.length > 0
              ? getassignmentdetails[assignmentsCountTwo]?.start_date
              : formAssignmentState?.start_date,
          end_date:
            getassignmentdetails.length > 0
              ? getassignmentdetails[assignmentsCountTwo]?.end_date
              : formAssignmentState?.end_date,
          type:
            getassignmentdetails.length > 0
              ? getassignmentdetails[assignmentsCountTwo]?.type
              : formAssignmentState.type,
          push_to_schoology: schoologystatus,
          ...(globleSaveData.RUBRIC.data[assignmentsCountTwo] && globleSaveData.RUBRIC.data[assignmentsCountTwo].Id && { rubric: globleSaveData.RUBRIC.data[assignmentsCountTwo].Id })
        }
        : {
          name: formAssignmentState?.name,
          assignment: workboards.ASSIGNMENT.data,
          lesson: lesson_id,
          duration_minutes: formAssignmentState?.duration_minutes,
          start_date: formAssignmentState?.start_date,
          end_date: formAssignmentState?.end_date,
          type: formAssignmentState.type,
          push_to_schoology: schoologystatus,
          ...(globleSaveData.RUBRIC.data[assignmentsCountTwo] && globleSaveData.RUBRIC.data[assignmentsCountTwo].Id && { rubric: globleSaveData.RUBRIC.data[assignmentsCountTwo].Id })
        }
      : isRubric
        ? {
          rubric: workboards.RUBRIC.data,
          lesson: lesson_id,
          push_to_schoology: schoologystatus,
          ...(globleSaveData.ASSIGNMENT.data[rubricCounttTwo] && globleSaveData.ASSIGNMENT.data[rubricCounttTwo].Id && { assignment: globleSaveData.ASSIGNMENT.data[rubricCounttTwo].Id })
        }
        : {
          name: formState?.name ?? "",
          start_date: formState?.start_date ?? "",
          end_date: formState?.end_date,
          description: workboards.LESSON.data,
          course: course?.course_id ?? "",
          teacher: teacher_id,
          status: 3,
          user_query: { question: chatHist[0]?.query ?? "" },
          ai_answer: { answer: chatHist[0]?.answer ?? "" },
          room_id: room_id,
          is_personalize: isSwitchOn,
          is_file_download: isSwitchOn2,
          lesson_preview: isSwitchOnLessonPreview,
          virtual_assistant: isSwitchOnVirtualAssistant,
          push_to_schoology: schoologystatus,
        };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };

    const url = isAssignment
      ? // ? assignment_id !== ""
      // ? assingmentListOfID.includes(eachAssignment_id) && `assignments/update-assignment/${assignment_id}/`
      // ? assignment_id !== ""
      //   ? `assignments/update-assignment/${assignment_id}/`
      //   : "assignments/create-assignment/"
      // : isRubric
      // ? assignment_id !== ""
      //   ? previesAssignment ? `assignments/update-assignment/${eachAssignment_id}/`
      //     : `assignments/update-assignment/${assignment_id}/`
      //   : "assignments/create-assignment/"

      // ? assignment_id !== "" ? (eachAssignment_id[assignmentsCountTwo] ? `assignments/update-assignment/${eachAssignment_id}/` : "assignments/create-assignment/") : "assignments/create-assignment/"

      previesAssignment
        ? eachAssignment_id
          ? (eachAssignment_id === null ? "assignments/create-assignment/" : `assignments/update-assignment/${eachAssignment_id}/`)
          : "assignments/create-assignment/"
        : "assignments/create-assignment/"
      : isRubric
        ? rubric_id !== ""
          ? previesRubric
            ? (eachRubric_id === null ? "assignments/create-rubric/" : `assignments/update-rubric/${eachRubric_id}/`)
            : "assignments/create-rubric/"
          : "assignments/create-rubric/"
        : // ? `assignments/update-rubric/${rubric_id}/`
        // : "assignments/create-rubric/"
        lesson_id !== ""
          ? `lessons/update-lesson/${lesson_id}/`
          : "lessons/create-lesson/";
    try {
      const response = (isAssignment
        ? assignment_id !== ""
          ? previesAssignment
            ? (eachAssignment_id === null ? false : true)
            : false
          : false
        : // ? previesAssignment
        //   ? true
        //   : false
        isRubric
          ? rubric_id !== ""
            ? previesRubric
              ? (eachRubric_id === null ? false : true)
              : false
            : false
          : lesson_id !== ""
            ? true
            : false)
        ? await api.put(url, payload, { headers: headers })
        : await api.post(url, payload, { headers: headers });
      if (response.status === 200 || response.status === 201) {
        // setLesson(response)
        setisSaveChecker(false);

        setShowCourseSuccessfully(true);
        updateDefaultWorkboardData(
          isAssignment
            ? defaultWorkboardData.ASSIGNMENT.name
            : isRubric
              ? defaultWorkboardData.RUBRIC.name
              : defaultWorkboardData.LESSON.name,
          isAssignment
            ? response?.data?.data?.assignment
            : isRubric
              ? response?.data?.data?.rubric
              : response?.data?.data?.description
        );
        updateGlobleSaveData(
          isAssignment
            ? globleSaveData.ASSIGNMENT.name
            : isRubric
              ? globleSaveData.RUBRIC.name
              : globleSaveData.LESSON.name,
          isAssignment
            ? response?.data?.data?.assignment
            : isRubric
              ? response?.data?.data?.rubric
              : response?.data?.data?.description
        );
        const getid = isAssignment
          ? response?.data?.data?.assignment_id
          : isRubric
            ? response?.data?.data?.rubric_id
            : response?.data?.data?.lesson_id;

        setTimeout(() => {
          setShowCourseSuccessfully(false);

          document.body.style.overflow = "auto";

          const globleOnChangeData = JSON.parse(
            localStorage.getItem("globleOnChangeData")
          );
          if (isAssignment) {
            const globleInputData = {
              data:
                activeWorkboard.name === "Assignment"
                  ? globleOnChangeData.ASSIGNMENT.data[assignmentsCountTwo]
                  : activeWorkboard.name === "Rubric" &&
                  globleOnChangeData.RUBRIC.data[rubricCounttTwo],
              Id: getid,
            };
            updateGlobleSaveData(
              activeWorkboard.name,
              globleInputData,
              formAssignmentState
            );
            if (!previesAssignment) {
              const existingIds =
                JSON.parse(localStorage.getItem("Assingment_Id")) || [];
              const updatedIds = [...existingIds, getid];
              localStorage.setItem("Assingment_Id", JSON.stringify(updatedIds));
              setPreviesAssignment(true);
              setEachAssignment_id(getid);
              setPopupVisible(false);

              setFormState((prev) => ({
                ...prev,
                chat_query: "",
              }));
              setassignment_id(getid);
            }
            if (assignment_id === "") {
              setAssingmentListOfID((prev) => [...prev, getid]);
            }
          } else if (isRubric) {
            const globleInputData = {
              data:
                activeWorkboard.name === "Assignment"
                  ? globleOnChangeData.ASSIGNMENT.data[assignmentsCountTwo]
                  : activeWorkboard.name === "Rubric" &&
                  globleOnChangeData.RUBRIC.data[rubricCounttTwo],
              Id: getid,
            };
            updateGlobleSaveData(activeWorkboard.name, globleInputData);

            if (!previesRubric) {
              const existingIds =
                JSON.parse(localStorage.getItem("Rubric_id")) || [];
              const updatedIds = [...existingIds, getid];
              localStorage.setItem("Rubric_id", JSON.stringify(updatedIds));
              setPreviesRubric(true);
              setEachRubric_id(getid);
            }
            if (rubric_id === "") {
              setrubric_id(getid);
            }
          } else {
            const globleInputData = {
              data: globleOnChangeData.LESSON.data,
              Id: getid,
            };

            localStorage.setItem("lessonDetails", JSON.stringify(globleInputData));

            updateGlobleSaveData(activeWorkboard.name, globleInputData);
            setLesson(response?.data?.data);
            if (lesson_id === "") {
              setlesson_id(getid);
            }
          }

          if (isAssignment) {
            const newArray = {
              data: response?.data?.data?.assignment,
              Id: getid,
            };

            const newFormAssignmentArrya = {
              name: formAssignmentState.name,
              assignment: null,
              assignment_json: null,
              lesson: null,
              duration_minutes: formAssignmentState.duration_minutes,
              start_date: formAssignmentState.start_date,
              end_date: formAssignmentState.end_date,
              type: formAssignmentState.type,
            };

            const globleSaveData = JSON.parse(
              localStorage.getItem("GlobleSaveData")
            );

            globleSaveData.ASSIGNMENT.data[assignmentsCountTwo] = newArray;
            globleSaveData.ASSIGNMENT.formSaveAssignment[
              assignmentsCountTwo
            ] = newFormAssignmentArrya;

            setGlobleSaveData(globleSaveData);

            localStorage.setItem(
              "GlobleSaveData",
              JSON.stringify(globleSaveData)
            );
          }
        }, 1000);

        //

        //
      }

      setLoading(false);
    } catch (error) {
      if (error.response.data.message == 'You cannot modify the rubric as it has already been submitted by the student.' || error.response.data.message == 'You cannot create the rubric because the student has already submitted the assignment.') {
        toast.error(error.response.data.message)
      }
      if (error.response.data.message == `You cannot modify the assignment as it has already been submitted by the student.`) {
        toast.error(error.response.data.message)
      }
      setisSaveChecker(false);
      setLoading(false);
    }
  };

  const [showCourseSuccessfully, setShowCourseSuccessfully] = useState(false);
  useEffect(() => {
    if (lesson?.description !== "" && lesson?.description !== undefined) {
      const getData = JSON.parse(localStorage.getItem("lessonDetails"))
      updateDefaultWorkboardData(
        defaultWorkboardData.LESSON.name,
        getData ? (lesson.description !== getData.data ? getData.data : getData.data) : lesson.description
      );

      updateWorkboardDataByName(workboards.LESSON.name, getData ? (lesson.description !== getData.data ? getData.data : getData.data) : lesson.description);
    }
  }, [lesson?.description]);

  const handleForm1Submit = async (e, schoologystatus) => {
    e.preventDefault();
    if (isSaveChecker) {
      return; // If the button is already disabled, do nothing
    }
    setisSaveChecker(true);
    setLoading(true);
    setEditLoading(true);

    const { name, start_date } = formState;
    toast.dismiss();
    if (!name) {
      toast.error("Please enter Lesson Name.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
      setisSaveChecker(false);
      return;
    } else if (name && name.length > 75) {
      toast.error(
        "Please don't enter more than 75 characters in Lesson Name.",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      setLoading(false);
      setisSaveChecker(false);
      return;
    }
    toast.dismiss();
    if (!start_date) {
      toast.error("Please enter a valid start date.", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
      setisSaveChecker(false);
      return;
    } else {

      try {
        const url =
          lesson_id !== ""
            ? `lessons/update-lesson/${lesson_id}/`
            : "lessons/create-lesson/";

        const payload = {
          name: formState?.name ?? "",
          start_date: formState?.start_date ?? "",
          end_date: formState?.end_date,
          description: workboards.LESSON.data,
          course: course?.course_id ?? "",
          teacher: teacher_id,
          status: 3,
          user_query: { question: chatHist[0]?.query ?? "" },
          ai_answer: { answer: chatHist[0]?.answer ?? "" },
          room_id: room_id,
          is_personalize: isSwitchOn,
          is_file_download: isSwitchOn2,
          lesson_preview: isSwitchOnLessonPreview,
          virtual_assistant: isSwitchOnVirtualAssistant,
          push_to_schoology: schoologystatus,
        };

        const headers = {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        };

        const response =
          lesson_id !== ""
            ? await api.put(url, payload, { headers: headers })
            : await api.post(url, payload, { headers: headers });

        if (response.status === 200 || response.status === 201) {
          // const globleData = {
          //   data: workboards.LESSON.data,
          //   Id: Math.floor(100000 + Math.random() * 900000)
          // }

          // updateGlobleSaveData(globleSaveData.LESSON.name, globleData)

          setisSaveChecker(false);

          setShowCourseSuccessfully(true);
          const getlesson_id = response?.data?.data?.lesson_id;
          const lessonData = {
            Id: getlesson_id,
            data: response?.data?.data?.description,
          };


          localStorage.setItem("lessonDetails", JSON.stringify(lessonData));
          updateDefaultWorkboardData(
            defaultWorkboardData.LESSON.name,
            response?.data?.data?.description || ""
          );
          setLesson(lessonData);
          updateGlobleSaveData(activeWorkboard.name, lessonData);

          setTimeout(() => {
            setShowCourseSuccessfully(false);
            document.body.style.overflow = "auto";
            if (lesson_id === "") {
              setlesson_id(getlesson_id);
            }
          }, 1000);
        }

        setLoading(false);
      } catch (error) {
        // console.log(error);
        setLoading(false);
        setisSaveChecker(false);
      }
    }
  };

  const [isSwitchOn, setIsSwitchOn] = useState(lesson?.is_personalize ?? false);
  const [isSwitchOn2, setIsSwitchOn2] = useState(
    lesson?.is_file_download ?? false
  );
  const [isSwitchOnLessonPreview, setIsSwitchOnLessonPreview] = useState(
    lesson?.lesson_preview ?? false
  );
  const [isSwitchOnVirtualAssistant, setIsSwitchOnVirtualAssistant] = useState(
    lesson?.virtual_assistant ?? true
  );
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (!isSwitchOn) {
      setShowPopup(false); // Hide the popup when the switch is turned on
    }
  }, [isSwitchOn]);

  const handleSwitchToggle = () => {
    if (!isSwitchOn) {
      setShowPopup(true); // Display the popup when the switch is turned off
      setTimeout(() => {
        setShowPopup(false); // Hide the popup after 3 seconds
      }, 3000);
    }
    setIsSwitchOn(!isSwitchOn);
  };

  const handleSwitchToggleDownload = () => {
    if (!isSwitchOn2) {
    }
    setIsSwitchOn2(!isSwitchOn2);
  };

  const handleSwitchToggleLessonPreview = () => {
    if (!isSwitchOnLessonPreview) {
    }
    setIsSwitchOnLessonPreview(!isSwitchOnLessonPreview);
  };

  const handleSwitchToggleVirtualAssistant = () => {
    if (!isSwitchOnVirtualAssistant) {
    }
    setIsSwitchOnVirtualAssistant(!isSwitchOnVirtualAssistant);
  };

  function onError(error) {
    throw error;
  }

  function OnChangePlugin() {
    const [editor] = useLexicalComposerContext();
    
    useEffect(() => {

      // const removeMathNode = () => {
     
      //   const handleClick = (event) => {
      //     event.stopPropagation(); // Prevent other click handlers from interfering

      //     const parentSpan = event.target.closest('.math-node');

      //     if (parentSpan) {
      //       // console.log("Clicked MathNode Span:", parentSpan.innerHTML);

      //       // Get the corresponding Lexical node key from the DOM element (you can add this in your MathNode's DOM)
      //       const nodeKey = parentSpan.getAttribute('data-lexical-key');
      //       // console.log(`MathNode key: ${nodeKey}`);
      //       setOldMathNode(nodeKey);
      //       if (nodeKey) {
      //         // Remove the node from the Lexical editor
      //         if (parentSpan.className.includes('selected-math-node')) {
      //           editor.update(() => {
      //             const nodeToDelete = $getNodeByKey(nodeKey); // Get the node by its key
      //             // if (nodeToDelete) {
      //             //   setShowInputTooltip(true);
      //             //   const annotation = parentSpan.querySelector(
      //             //     'annotation[encoding="application/x-tex"]'
      //             //   );
      //             //   if (annotation) {
      //             //     const latexValue = annotation.textContent; // Extract the LaTeX content
      //             //     // console.log("LaTeX Value:", latexValue);
      //             //     setLatextextvalue(latexValue);
      //             //     setOldNodeEditor(nodeToDelete);
      //             //     // Assuming you have an input element with id 'latexInput'
      //             //     // const latexInput = document.getElementById("latexInput");
      //             //     // if (latexInput) {
      //             //     //   latexInput.value = latexValue; // Set the LaTeX value in the textbox
      //             //     // }
      //             //   }
      //             //   // nodeToDelete.remove(); // Remove the node from the editor
      //             //   // console.log(`Removed MathNode with key: ${nodeKey}`);
      //             // }
      //           });
      //         } else {
      //           parentSpan.className = parentSpan.className + ' selected-math-node';
      //         }
      //       }
      //     }
      //   };

      //   // Add click event listener to the document to capture all clicks
      //   document.addEventListener('click', handleClick);
      // }

      // removeMathNode();
      const removeMathNode = () => {
        const handleClick = (event) => {
          event.stopPropagation(); // Prevent interference with other click handlers
      
          const parentSpan = event.target.closest('.math-node');
      
          if (parentSpan) {
            // Get the corresponding Lexical node key from the DOM element
            const nodeKey = parentSpan.getAttribute('data-lexical-key');
            setOldMathNode(nodeKey); // Store the nodeKey for updates
      
            if (nodeKey) {
              editor.update(() => {
                const nodeToDelete = $getNodeByKey(nodeKey); // Get the node by key
      
                if (nodeToDelete) {
                  // Highlight the MathNode visually
                  if (parentSpan.className.includes('selected-math-node')) {
                    parentSpan.classList.remove('selected-math-node');
                    setShowInputTooltip(true); // Hide the tooltip
                  } else {
                    // Add selection class and show tooltip for editing
                    parentSpan.className += ' selected-math-node';
                    setShowInputTooltip(true);
      
                    const annotation = parentSpan.querySelector(
                      'annotation[encoding="application/x-tex"]'
                    );
      
                    if (annotation) {
                      const latexValue = annotation.textContent; // Extract LaTeX content
                      setLatextextvalue(latexValue); // Set the LaTeX value
                    }
                  }
                }
              });
            }
          }
        };
      
        // Attach click event listener
        document.addEventListener('click', handleClick);
      };
      
      removeMathNode();
      
      return editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          if (activeWorkboard.name === "Assignment") {
            const globleOnChange = JSON.parse(
              localStorage.getItem("globleOnChangeData")
            );

            if (!globleOnChangeDataSave) {
              if (!isNotSavePopUp) {
                updateGlobleOnChangeData(
                  activeWorkboard.name,
                  $generateHtmlFromNodes(editor)
                );
              }
            } else {
              setGlobleOnChangeDataSave(false);
            }
            // }
          } else {
            updateGlobleOnChangeData(
              activeWorkboard.name,
              $generateHtmlFromNodes(editor)
            );
          }
          updateWorkboardDataByName(
            activeWorkboard.name,
            $generateHtmlFromNodes(editor)
          );
        });
      });
    }, [editor, onChange]);
  }

  function stripStyles(html) {
    // Create a temporary DOM element to manipulate
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    // Remove all style attributes
    const elements = tempDiv.querySelectorAll("[style]");
    elements.forEach((el) => el.removeAttribute("style"));

    // Return the cleaned HTML content
    return tempDiv.innerHTML;
  }

  function OnChangeRubricPlugin({ onChangeRubricData, rubricCounttTwo }) {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {

      const handleUpdate = ({ editorState }) => {
        editorState.read(() => {
          const selection = $getSelection();
          if ($isRangeSelection(selection)) {
            const htmlContent = $generateHtmlFromNodes(editor);
            const currentCleanedContent = stripStyles(htmlContent);
            const previousCleanedContent = stripStyles(
              onChangeRubricData[rubricCounttTwo] || ""
            );
            // Only call the update functions if the content has changed
            if (
              currentCleanedContent !== previousCleanedContent ||
              htmlContent === ""
            ) {
              setDisabledRubric(false);
              updateWorkboardDataByName(activeWorkboard.name, htmlContent);
              updateGlobleOnChangeData(activeWorkboard.name, htmlContent);
            } else {
              updateWorkboardDataByName(
                activeWorkboard.name,
                onChangeRubricData[rubricCounttTwo]
              );
              updateGlobleOnChangeData(
                activeWorkboard.name,
                onChangeRubricData[rubricCounttTwo]
              );
              setDisabledRubric(true);
            }
          }
        });
      };

      const unregisterUpdateListener = editor.registerUpdateListener(
        handleUpdate
      );


      return () => {
        unregisterUpdateListener();
      };
    }, [editor]);

    return null;
  }

  function Placeholder() {
    return <div className="editor-placeholder">Write / Paste content here</div>;
  }

  function onChange(editorState) {

  }


  const [globleOnChange, setGlobleOnChange] = useState({
    LESSON: {
      name: "Lesson",
      data: "",
    },
    ASSIGNMENT: {
      name: "Assignment",
      data: [],
      formAssignment: [],
    },
    RUBRIC: {
      name: "Rubric",
      data: [],
    },
  });

  useEffect(() => {
    localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));
  }, [globleOnChange]);

  const [globleSaveData, setGlobleSaveData] = useState({
    LESSON: {
      name: "Lesson",
      data: "",
    },
    ASSIGNMENT: {
      name: "Assignment",
      data: [],
      formSaveAssignment: [],
    },
    RUBRIC: {
      name: "Rubric",
      data: [],
    },
  });

  useEffect(() => {


    if (globleSaveData[activeWorkboard.name.toLocaleUpperCase()].data) {


      localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
    }
  }, [globleSaveData]);



  useEffect(() => {

    const getData = JSON.parse(localStorage.getItem("lessonDetails"))

    if ((typeof lesson?.lesson_id === "undefined" || lesson?.lesson_id === null || lesson.lesson_id === "")) {

      if (getData && getData.data !== '') {
        getChatHistory(getData.Id);
        getAssignment(getData.Id);
        const lessonDeta = {
          description: getData.data,
          lesson_id: getData.Id
        }
        setLesson(lessonDeta);
        setlesson_id(getData.Id)
        updateDefaultWorkboardData(
          defaultWorkboardData.LESSON.name,
          getData.data
        );
        updateWorkboardDataByName(workboards.LESSON.name, getData.data);
        setFormState((prev) => ({ ...prev, description: getData.data }));
        const globleSaveData = JSON.parse(localStorage.getItem("GlobleSaveData"));
        const globleOnChange = JSON.parse(
          localStorage.getItem("globleOnChangeData")
        );

        const newLessonArrya = {
          data: {
            data: getData.data,
            Id: getData.Id,
          },
        };

        globleSaveData.LESSON.data = newLessonArrya;
        globleOnChange.LESSON.data = newLessonArrya;



        localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
        localStorage.setItem(
          "globleOnChangeData",
          JSON.stringify(globleOnChange)
        );

      }

    } else {
      if (getData && getData.data !== '') {
        if (lesson?.description !== getData.data) {
          getChatHistory(getData.Id);
          getAssignment(getData.Id);
          const lessonDeta = {
            description: getData.data,
            lesson_id: getData.Id
          }
          setLesson(lessonDeta);
          setlesson_id(getData.Id)
          updateDefaultWorkboardData(
            defaultWorkboardData.LESSON.name,
            getData.data
          );
          updateWorkboardDataByName(workboards.LESSON.name, getData.data);
          setFormState((prev) => ({ ...prev, description: getData.data }));
          const globleSaveData = JSON.parse(localStorage.getItem("GlobleSaveData"));
          const globleOnChange = JSON.parse(
            localStorage.getItem("globleOnChangeData")
          );

          const newLessonArrya = {
            data: {
              data: getData.data,
              Id: getData.Id,
            },
          };

          globleSaveData.LESSON.data = newLessonArrya;
          globleOnChange.LESSON.data = newLessonArrya;



          localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
          localStorage.setItem(
            "globleOnChangeData",
            JSON.stringify(globleOnChange)
          );

        }
      }
    }



  }, [])

  useEffect(() => {
    // console.warn(4)
    // console.warn(globleSaveData)

    // localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
    // localStorage.setItem("globleOnChangeData", JSON.stringify(globleOnChange));


    if (location.state?.lesson !== undefined) {
      const getData = JSON.parse(localStorage.getItem("lessonDetails"))

      const globleSaveData = JSON.parse(localStorage.getItem("GlobleSaveData"));
      const globleOnChange = JSON.parse(
        localStorage.getItem("globleOnChangeData")
      );

      const newLessonArrya = {
        data: {
          data: getData ? (location.state?.lesson.description !== getData.data ? getData.data : getData.data) : location.state?.lesson.description,
          Id: getData ? (location.state?.lesson.lesson_id !== getData.Id ? getData.Id : getData.Id) : location.state?.lesson.lesson_id,
        },
      };

      globleSaveData.LESSON.data = newLessonArrya;
      globleOnChange.LESSON.data = newLessonArrya;


      localStorage.setItem("lessonDetails", JSON.stringify({
        data: getData ? (location.state?.lesson.description !== getData.data ? getData.data : getData.data) : location.state?.lesson.description,
        Id: getData ? (location.state?.lesson.lesson_id !== getData.Id ? getData.Id : getData.Id) : location.state?.lesson.lesson_id,
      },));
      localStorage.setItem("GlobleSaveData", JSON.stringify(globleSaveData));
      localStorage.setItem(
        "globleOnChangeData",
        JSON.stringify(globleOnChange)
      );
    }

  }, [location.state?.lesson]);

  const updateGlobleOnChangeData = (
    name,
    newData,
    assingmentpopUp,
    assingmentpopUpData,
    newDataForOnlySave
  ) => {
    const globleOnChangeData = JSON.parse(
      localStorage.getItem("globleOnChangeData")
    );

    setGlobleOnChange((prevWorkboards) => {
      // Find the key of the workboard we want to update
      const workboardKey = Object.keys(prevWorkboards).find(
        (key) => prevWorkboards[key].name === name
      );
      // If we found a workboard with the provided name
      if (workboardKey) {
        let updatedData;
        let updatedFormAssignment;

        if (isAssignment) {
          updatedData = prevWorkboards[workboardKey].data
          updatedData[Number(assignmentsCountTwo)] = newData;
        } else if (isRubric) {
          // Create a copy of the existing data array
          updatedData = [...prevWorkboards[workboardKey].data];
          // Update the specific index
          updatedData[rubricCounttTwo] = newData;
        } else {
          updatedData = newData;
        }

        const updatedWorkboards = {
          ...prevWorkboards, // Copy all the existing workboards
          [workboardKey]: {
            ...prevWorkboards[workboardKey], // Copy all the existing properties of the workboard
            data: updatedData, // Update the data property with the new array
          },
        };

        // Store updated state in localStorage
        // localStorage.setItem("globleOnChangeData", JSON.stringify(updatedWorkboards));
        return updatedWorkboards;
      } else {
        // If no workboard was found with the given name, return the previous state
        return prevWorkboards;
      }
    });
  };

  const updateGlobleSaveData = (name, newData, formAssignmentState) => {
    setGlobleSaveData((prevWorkboards) => {
      // Find the key of the workboard we want to update
      const workboardKey = Object.keys(prevWorkboards).find(
        (key) => prevWorkboards[key].name === name
      );
      // If we found a workboard with the provided name

      if (workboardKey) {
        let updatedData;
        let updatedFormAssignment;

        if (isAssignment) {
          // Create a copy of the existing data array
          updatedData = Array.isArray(prevWorkboards[workboardKey].data)
            ? [...prevWorkboards[workboardKey].data]
            : [];
          updatedData[assignmentsCountTwo] = newData;

        } else if (isRubric) {
          // Create a copy of the existing data array
          updatedData = [...prevWorkboards[workboardKey].data];
          // Update the specific index
          updatedData[rubricCounttTwo] = newData;
        } else {
          updatedData = newData;
        }

        const updatedWorkboards = {
          ...prevWorkboards, // Copy all the existing workboards
          [workboardKey]: {
            ...prevWorkboards[workboardKey], // Copy all the existing properties of the workboard
            data: updatedData, // Update the data property with the new array
          },
        };

        // Store updated state in localStorage
        return updatedWorkboards;
      } else {
        // If no workboard was found with the given name, return the previous state
        return prevWorkboards;
      }
    });
  };

  const [hideRubricPopUp, setHideRubricPopUp] = useState(false);
  const [showRubricPopUp, setShowRubricPopUp] = useState(false);

  const [assignmentsCount, setAssignmentCounts] = useState([]);
  const [assignmentsCountTwo, setAssignmentCountsTwo] = useState(0);



  const [showAssingment, setShowAssingment] = useState(false);
  const [previesAssignment, setPreviesAssignment] = useState(false);
  const [previesRubric, setPreviesRubric] = useState(false);
  const [selectedAssignment, setSelectedAssignment] = useState("Assignment");
  const [onChangeAssignmentData, setOnChangeAssignmentData] = useState([]);

  useEffect(() => {
    if (getassignmentdetails.length > 0) {
      setSelectedAssignment(`Assignment ${getassignmentdetails.length}`);
      const initialArray = new Array(getassignmentdetails.length)
        .fill("")
        .map((_, index) => `Assignment ${index + 1}`);
      setAssignmentCounts(initialArray);
      setOnChangeAssignmentData(
        getassignmentdetails?.map((item) => item.assignment)
      );
    }
  }, [getassignmentdetails]);

  const [rubricCount, setRubricCount] = useState([]);
  const [rubricCounttTwo, setRubricCountTwo] = useState(0);
  const [onChangeRubricData, setOnChangeRubricData] = useState([]);
  const [selectedRubric, setSelectedRubric] = useState("Rubric");
  const [showDropdownRubric, setShowDropdownRubric] = useState(false);

  useEffect(() => {
    if (rubrics.length > 0) {
      setSelectedRubric(`Rubric ${rubrics.length}`);
      const initialArray = new Array(rubrics.length)
        .fill("")
        .map((_, index) => `rubric ${index + 1}`);
      setRubricCount(initialArray);
      // setAssignmentCountsTwo(initialArray.length)
      setOnChangeRubricData(rubrics?.map((item) => item.data));
    }
  }, [rubrics]);


  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [updateFuntion, setUpdateFuntion] = useState(false);
  const [isRubricConfirmModalData, setIsRubricConfirmModalData] = useState({
    selectedRubric: null,
    selectedRubricIndex: null,
  });
  const [
    isAssingmentConfirmModalData,
    setIsAssingmentConfirmModalData,
  ] = useState({
    selectedAssingment: null,
    selectedAssingmentIndex: null,
  });
  const [afterConfirmation, setAfterConfirmation] = useState(null);

  const handleSavePopUp = () => {
    handleAssinmentRubricSubmit();
    setIsNotSavePopUp(false);
    setCallAddAssignmentFun(true);
  };
  const handleCancelPopUp = () => {
    setIsNotSavePopUp(false);
    setCallAddAssignmentFun(true);
  };

  function UpdateActiveWorkbaordPlugin({
    chat,
    setAssignmentCounts,
    assignmentsCount,
    showAssingment,
    setShowAssingment,
    selectedAssignment,
    setSelectedAssignment,
    onChangeAssignmentData,
    setOnChangeAssignmentData,
    setAssignmentCountsTwo,
    editWorkboardStatues,
    setEditWorkboardStatues,
    assignmentsCountTwo,
    showDropdownRubric,
    setShowDropdownRubric,
    rubricCount,
    setRubricCount,
    rubricCounttTwo,
    setRubricCountTwo,
    onChangeRubricData,
    setOnChangeRubricData,
    selectedRubric,
    setSelectedRubric,
    previesCountAssignment,
    setPreviesAssignment,
    setPreviesRubric,
    setFormAssignmentState,
    updateFuntion,
    setUpdateFuntion,
    isRubricConfirmModalData,
    setIsRubricConfirmModalData,
    afterConfirmation,
    setAfterConfirmation,
    isAssingmentConfirmModalData,
    setIsAssingmentConfirmModalData,
    callAddAssignmentFun,
    setCallAddAssignmentFun,
  }) {
    const [editor] = useLexicalComposerContext();



    function update(
      workboard,
      workboardName,
      previesAssignment,
      removeWorkboardAssignment
    ) {
      if (
        previesAssignment === "previesAssignment" ||
        previesAssignment === "previesRubric"
      ) {
        setActiveWorkboard({
          name: workboardName,
          data: workboard,
        });
      } else {
        setActiveWorkboard(workboard);
      }
      if (workboardName === workboards.ASSIGNMENT.name) {
        // setPopupVisible(true);
        setIsAssignment(true);

        setIsRubric(false);
      } else if (workboardName === workboards.RUBRIC.name) {
        setPopupVisible(false);
        setIsAssignment(false);
        setIsRubric(true);
        setFormState((prev) => ({
          ...prev,
          chat_query: "",
        }));
      } else if (workboardName === workboards.LESSON.name) {
        setPopupVisible(false);
        setIsAssignment(false);
        setIsRubric(false);
        setFormState((prev) => ({
          ...prev,
          chat_query: "",
        }));
      }
      editor.update(() => {
        const root = $getRoot();
        // Clear the root node
        root.clear();

        // Get the active workboard data
        if (
          previesAssignment === "previesAssignment" ||
          previesAssignment === "previesRubric"
        ) {
          var activeWorkboardData = workboard;
        } else {
          var activeWorkboardData = workboard.data;
        }

        if (workboardName === "Assignment") {
          if (
            previesAssignment !== "previesAssignment" &&
            removeWorkboardAssignment !== "removeWorkboardAssignment"
          ) {
            if (activeWorkboardData.length > 2) {
              const getassignmentdetailsCondition =
                getassignmentdetails[assignmentsCountTwo] !== undefined;

              if (getassignmentdetailsCondition) {
                if (
                  formAssignmentState.name !== "" &&
                  formAssignmentState.name !== null &&
                  getassignmentdetails[assignmentsCountTwo].name !== "" &&
                  getassignmentdetails[assignmentsCountTwo].name !== null &&
                  formAssignmentState.type !== null &&
                  getassignmentdetails[assignmentsCountTwo].type !== null &&
                  formAssignmentState.duration_minutes !== 0 &&
                  formAssignmentState.duration_minutes !== null &&
                  getassignmentdetails[assignmentsCountTwo].duration_minutes !==
                  0 &&
                  getassignmentdetails[assignmentsCountTwo].duration_minutes !==
                  null
                ) {
                  setOnChangeAssignmentData((prev) => {
                    const newState = [...prev]; // Create a copy of the previous state array
                    newState[assignmentsCountTwo] = workboards.ASSIGNMENT.data;
                    return newState; // Return the new state
                  });
                }
              } else {
                if (
                  formAssignmentState.name !== "" &&
                  formAssignmentState.name !== null &&
                  formAssignmentState.type !== null &&
                  formAssignmentState.duration_minutes !== 0 &&
                  formAssignmentState.duration_minutes !== null
                ) {
                  setOnChangeAssignmentData((prev) => {
                    const newState = [...prev]; // Create a copy of the previous state array
                    newState[assignmentsCountTwo] = workboards.ASSIGNMENT.data;
                    return newState; // Return the new state
                  });
                }
              }
            }
          }

          if (removeWorkboardAssignment === "removeWorkboardAssignment") {
            activeWorkboardData = "";
          }
        }

        if (workboardName === "Rubric") {
          if (
            previesAssignment !== "previesRubric" &&
            removeWorkboardAssignment !== "removeWorkboardRubric"
          ) {
            if (activeWorkboardData.length > 2) {
              setOnChangeRubricData((prev) => {
                const newState = [...prev]; // Create a copy of the previous state array
                newState[rubricCounttTwo] = workboards.RUBRIC?.data; // Update the specific index
                return newState; // Return the new state
              });
            }
          }

          if (removeWorkboardAssignment === "removeWorkboardRubric") {
            activeWorkboardData = "";
          }
        }

        // Parse the HTML string into a DOM
        const parser = new DOMParser();
        const dom = parser.parseFromString(activeWorkboardData, "text/html");


        // Generate nodes from the DOM
        const nodes = $generateNodesFromDOM(editor, dom);

        // Insert the nodes into the root node
        try {
          $insertNodes(nodes);
        } catch (error) {
          console.error(error);
          const root = $getRoot();
          const p = $createParagraphNode();
          p.append($createTextNode(activeWorkboardData));
          root.append(nodes);
        }

        // Select the root node
        root.select();
      });
    }



    if (updateFuntion) {
      if (
        afterConfirmation === "addRubric" ||
        afterConfirmation === "LessonToAddRubric"
      ) {

        update("", "Rubric", "previesAssignment", "removeWorkboardRubric");
      } else if (
        afterConfirmation === "Rubric" ||
        afterConfirmation === "LessonToRubric"
      ) {
        update(
          onChangeRubricData[isRubricConfirmModalData.selectedRubricIndex],
          "Rubric",
          "previesRubric"
        );
      } else if (
        afterConfirmation === "Assignment" ||
        afterConfirmation === "LessonToAssignment"
      ) {
        update(
          onChangeAssignmentData[
          isAssingmentConfirmModalData.selectedAssingmentIndex
          ],
          "Assignment",
          "previesAssignment"
        );
      } else if (
        afterConfirmation === "addAssingment" ||
        afterConfirmation === "LessonToAddAssignment"
      ) {
        update(
          "",
          "Assignment",
          "previesAssignment",
          "removeWorkboardAssignment"
        );
      } else if (afterConfirmation === "Lesson") {
        update(
          lesson?.description,
          "Lesson",
          "previesAssignment",
          "removeWorkboardAssignment"
        );
      }

      setUpdateFuntion(false);
    }

    if (clickSaveBTN) {
      const workboardItem = Object.values(workboards).find(
        (item) => item.name === "Assignment"
      );
      update(workboardItem, "Assignment");
      setClickSaveBTN(false);
    }

    if (clickSaveRubricBTN) {
      const workboardItem = Object.values(workboards).find(
        (item) => item.name === "Rubric"
      );

      update(workboardItem, "Rubric");
      setClickSaveRubricBTN(false);
    }

    const activeTabBgColor = {
      background: "#cfe5fb",
      // You can add other styles here if needed
    };

    const [showDropdown] = useState(false);

    const dropdownRef = useRef(null);
    const dropdownRubricRef = useRef(null);

    const handleAddAssignment = () => {
      if (assignmentsCount.length - 1 === assignmentsCountTwo) {
        const globleInputData = {
          data: workboards.ASSIGNMENT.data,
          Id: null,
        };

        const globleOnChange = JSON.parse(
          localStorage.getItem("globleOnChangeData")
        );

        const globleSaveDataUpdate = globleOnChange.ASSIGNMENT.data;

        globleSaveDataUpdate.push(workboards.ASSIGNMENT.data);

        globleOnChange.ASSIGNMENT.data = globleSaveDataUpdate;

        setGlobleOnChange(globleOnChange);

        localStorage.setItem(
          "globleOnChangeData",
          JSON.stringify(globleOnChange)
        );

      }
      updateDefaultWorkboardData(defaultWorkboardData.ASSIGNMENT.name, "");

      const newAssignment = `Assignment ${assignmentsCount.length + 1}`;
      setAssignmentCounts([...assignmentsCount, newAssignment]);
      setAssignmentCountsTwo(assignmentsCount.length);
      setShowAssingment(false);
      setSelectedAssignment(newAssignment);
      setActiveTab("lesson");
      setPreviesAssignment(false);
      setPopupVisible(true);
      setIsAssignment(true);
      if (!checkAssignmentopen) {
        setFormState((prev) => ({
          ...prev,
          chat_query: "Create Assignment" + " " + (assignmentsCount.length + 1),
        }));
      }

      setFormAssignmentState((prevState) => ({
        ...prevState,
        name: "",
        duration_minutes: null,
        start_date: dayjs().format("YYYY-MM-DD"),
        type: null,
      }));

      const sumOfCount = 5 * assignmentDurationCount;

      assignmentDurationChangeTime(-sumOfCount);

      localStorage.setItem("assignmentDurationCount", 0);
    };

    if (callAddAssignmentFun) {
      handleAddAssignment();
      setCallAddAssignmentFun(false);
    }

    const toggleAssingmentDropdown = () => {
      setShowAssingment((prev) => !prev);
      setShowDropdownRubric(false);
    };

    // const toggleDropdown = (workboardName) => {
    //   setDropdownVisibility(prevState => ({
    //     ...prevState,
    //     [workboardName]: !prevState[workboardName]
    //   }));
    // };

    const handleAddRubric = () => {
      if (rubricCounttTwo.length - 1 === rubricCounttTwo) {
        const globleInputData = {
          data: workboards.RUBRIC.data,
          Id: null,
        };
        updateGlobleOnChangeData(globleOnChange.RUBRIC.name, globleInputData);
      }

      updateDefaultWorkboardData(defaultWorkboardData.RUBRIC.name, "");

      const newRubric = `Rubric ${rubricCount.length + 1}`;
      setRubricCount([...rubricCount, newRubric]);
      setRubricCountTwo(rubricCount.length);
      setShowDropdownRubric(false);
      setSelectedRubric(newRubric);
      setPreviesRubric(false);
      setActiveWorkboard(workboards.RUBRIC);
      setActiveTab("lesson");

      setPopupVisible(false);
      setIsAssignment(false);
      setFormState((prev) => ({
        ...prev,
        chat_query: "",
      }));
    };

    const toggleDropdownRubric = () => {
      setShowAssingment(false);
      if (Object.entries(lesson).length !== 0) {
        setShowDropdownRubric(!showDropdownRubric);
      }
    };

    const handleAssignmentClick = (assignment, index) => {
      const globleOnChangeData = JSON.parse(
        localStorage.getItem("globleOnChangeData")
      );

      if (assignmentsCount.length === Number(assignmentsCountTwo) + 1) {
        if (globleOnChange.ASSIGNMENT.formAssignment === undefined) {
          const globleInputData = {
            data: workboards.ASSIGNMENT.data,
            Id: null,
          };
          updateGlobleOnChangeData(
            globleOnChange.ASSIGNMENT.name,
            globleInputData,
            "formAssignmentState_true",
            formAssignmentState
          );
        }
      }

      setSelectedAssignment(assignment);
      setAssignmentCountsTwo(index);
      setShowDropdown(false);
      setShowAssingment(false);
      if (
        globleSaveData.ASSIGNMENT.data[index] !== undefined &&
        globleSaveData.ASSIGNMENT.data[index] !== null
      ) {
        if (globleSaveData.ASSIGNMENT.data[index].Id) {
          const GlobleSaveData = JSON.parse(
            localStorage.getItem("GlobleSaveData")
          );

          setPreviesAssignment(true);
          setEachAssignment_id(GlobleSaveData.ASSIGNMENT.data[index].Id);
          setassignment_id(GlobleSaveData.ASSIGNMENT.data[index].Id);
          setPopupVisible(false);
          if (!checkAssignmentopen) {
            setFormState((prev) => ({
              ...prev,
              chat_query: "",
            }));
          }

          setFormAssignmentState((prevState) => ({
            ...prevState,
            name: GlobleSaveData.ASSIGNMENT.formSaveAssignment[index].name,
            duration_minutes:
              GlobleSaveData.ASSIGNMENT.formSaveAssignment[index]
                .duration_minutes,
            start_date: dayjs().format("YYYY-MM-DD"),
            type: GlobleSaveData.ASSIGNMENT.formSaveAssignment[index].type,
            end_date:
              GlobleSaveData.ASSIGNMENT.formSaveAssignment[index].end_date,
          }));
        } else {
          setEachAssignment_id(null)
        }
      } else {
        const globleOnChangeData = JSON.parse(
          localStorage.getItem("globleOnChangeData")
        );

        if (
          globleOnChangeData.ASSIGNMENT.formAssignment[index] !== undefined &&
          globleOnChangeData.ASSIGNMENT.formAssignment[index] !== null
        ) {
          setPreviesAssignment(false);
          setEachAssignment_id(null);

          setPopupVisible(true);
          if (!checkAssignmentopen) {
            setFormState((prev) => ({
              ...prev,
              chat_query: "Create Assignment" + " " + (Number(index) + 1),
            }));
          }

          setFormAssignmentState((prevState) => ({
            ...prevState,
            name: globleOnChangeData.ASSIGNMENT.formAssignment[index].name,
            duration_minutes:
              globleOnChangeData.ASSIGNMENT.formAssignment[index]
                .duration_minutes,
            start_date: dayjs().format("YYYY-MM-DD"),
            type: globleOnChangeData.ASSIGNMENT.formAssignment[index].type,
            end_date:
              globleOnChangeData.ASSIGNMENT.formAssignment[index].end_date,
          }));

        } else {
          setPopupVisible(true);
          setIsAssignment(true);
          if (!checkAssignmentopen) {
            setFormState((prev) => ({
              ...prev,
              chat_query: "Create Assignment" + " " + (Number(index) + 1),
            }));
          }

          setFormAssignmentState((prevState) => ({
            ...prevState,
            name: "",
            duration_minutes: null,
            start_date: dayjs().format("YYYY-MM-DD"),
            type: null,
          }));

          const sumOfCount = 5 * assignmentDurationCount;

          assignmentDurationChangeTime(-sumOfCount);

          localStorage.setItem("assignmentDurationCount", 0);
        }
      }

      if (globleOnChangeData.ASSIGNMENT.data[index].data !== undefined) {
        update(
          globleOnChangeData.ASSIGNMENT.data[index].data,
          "Assignment",
          "previesAssignment"
        );
        updateWorkboardDataByName(
          "Assignment",
          globleOnChangeData.ASSIGNMENT.data[index].data
        );
      } else {
        update(
          globleOnChangeData.ASSIGNMENT.data[index],
          "Assignment",
          "previesAssignment"
        );
        updateWorkboardDataByName(
          "Assignment",
          globleOnChangeData.ASSIGNMENT.data[index]
        );
      }
      setActiveWorkboard(workboards.ASSIGNMENT);

      setIsAssignment(true);
    };

    const handleRubricClick = (rubric, index) => {

      const globleOnChangeData = JSON.parse(
        localStorage.getItem("globleOnChangeData")
      );

      if (rubricCounttTwo.length === Number(rubricCounttTwo) + 1) {
        if (
          globleOnChange.RUBRIC.formAssignment[rubricCounttTwo] === undefined
        ) {
          const globleInputData = {
            data: workboards.RUBRIC.data,
            Id: null,
          };
          updateGlobleOnChangeData(globleOnChange.RUBRIC.name, globleInputData);
        }
      }

      setSelectedRubric(rubric);
      setRubricCountTwo(index);
      setShowDropdownRubric(false);

      if (
        globleSaveData.RUBRIC.data[index] !== undefined &&
        globleSaveData.RUBRIC.data[index] !== null
      ) {
        if (globleSaveData.RUBRIC.data[index].Id) {
          setPreviesRubric(true);
          setEachRubric_id(globleSaveData.RUBRIC.data[index].Id);

          setrubric_id(globleSaveData.RUBRIC.data[index].Id);
        } else {
          setEachRubric_id(null);

        }
      } else {
        setPreviesRubric(false);
        setEachRubric_id(null);
      }

      if (globleOnChangeData.RUBRIC.data[index].data !== undefined) {
        update(
          globleOnChangeData.RUBRIC.data[index].data,
          "Rubric",
          "previesRubric"
        );
        updateWorkboardDataByName(
          "Rubric",
          globleOnChangeData.RUBRIC.data[index].data
        );
      } else {
        update(
          globleOnChangeData.RUBRIC.data[index],
          "Rubric",
          "previesRubric"
        );
        updateWorkboardDataByName(
          "Rubric",
          globleOnChangeData.RUBRIC.data[index]
        );
      }
      setActiveWorkboard(workboards.RUBRIC);

      setPopupVisible(false);
      setIsAssignment(false);
      setFormState((prev) => ({
        ...prev,
        chat_query: "",
      }));
    };

    return (
      <div className="edit-active-lesson-btn-container">
        {Object.values(workboards).map((workboard, index) => {
          return (
            <>
              {workboard.name === workboards.LESSON.name ? (
                <div className="tab-dropdown">
                  <button
                    key={index} // It's important to provide a unique key for list items in React
                    type="button"
                    style={lessonbuttonStyle}
                    // className={`${activeWorkboard.name === workboard.name ? "active" : ""
                    //   } ${`${defaultWorkboardData.LESSON?.data !==
                    //     workboards.LESSON?.data
                    //     ? ""
                    //     : "completed"
                    //   }`
                    //   }`}
                    className={`dropbtn ${activeWorkboard.name === workboard.name ? "active" : ""
                      } ${defaultWorkboardData.LESSON?.data !==
                        workboards.LESSON?.data
                        ? ""
                        : "completed"
                      }`}
                    // disabled={
                    //   typeof lesson_id === "undefined" ||
                    //   lesson_id === null ||
                    //   lesson_id === ""
                    // }
                    onClick={() => {
                      setShowDropdownRubric(false);
                      setShowAssingment(false);
                      update(
                        Object.values(workboards).find(
                          (item) => item.name === "Lesson"
                        ),
                        workboard?.name
                      );
                    }}
                  >
                    {" "}
                    {workboard?.name}
                  </button>
                </div>
              ) : (
                ""
              )}

              {workboard.name === workboards.ASSIGNMENT.name ? (
                <div className="tab-dropdown" ref={dropdownRef}>
                  <button
                    className="dropbtn"
                    onClick={toggleAssingmentDropdown}
                    style={{
                      cursor:
                        Object.entries(lesson).length === 0
                          ? "not-allowed"
                          : "auto",
                      opacity:
                        Object.entries(lesson).length === 0 ? "0.5" : "1",
                      color: activeWorkboard.name === "Assignment" ? '#0068ff' : 'black'
                    }}
                    disabled={Object.entries(lesson).length === 0}
                  >
                    {selectedAssignment}
                    <span
                      style={{
                        transform: showAssingment
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    >
                      <i className="fa-solid fa-angle-down"></i>
                    </span>
                  </button>

                  {showAssingment && (
                    <div className="dropdown-content">
                      {assignmentsCount.map((assignment, index) => (




                        <div
                          key={index}
                          className="assignment-item"
                          onClick={() => {
                            const globleOnChangeData = JSON.parse(
                              localStorage.getItem("globleOnChangeData")
                            );

                            if (
                              activeWorkboard.name === "Assignment" &&
                              globleOnChangeData.ASSIGNMENT.data.length >
                              globleOnChangeData.ASSIGNMENT.formAssignment
                                .length
                            ) {
                              if (
                                globleOnChangeData.ASSIGNMENT.formAssignment[
                                assignmentsCountTwo
                                ] === undefined
                              ) {
                                const newFormAssignmentArrya = {
                                  name: formAssignmentState.name,
                                  assignment: null,
                                  assignment_json: null,
                                  lesson: null,
                                  duration_minutes:
                                    formAssignmentState.duration_minutes,
                                  start_date: formAssignmentState.start_date,
                                  end_date: formAssignmentState.end_date,
                                  type: formAssignmentState.type,
                                };

                                const globleOnChange = JSON.parse(
                                  localStorage.getItem("globleOnChangeData")
                                );

                                const globleOnChangeUpdateFormAssignment =
                                  globleOnChange.ASSIGNMENT.formAssignment;
                                globleOnChangeUpdateFormAssignment.push(
                                  newFormAssignmentArrya
                                );

                                globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment;

                                setGlobleOnChange(globleOnChange);

                                localStorage.setItem(
                                  "globleOnChangeData",
                                  JSON.stringify(globleOnChange)
                                );
                              } else {
                                const newFormAssignmentArrya = {
                                  name: formAssignmentState.name,
                                  assignment: null,
                                  assignment_json: null,
                                  lesson: null,
                                  duration_minutes:
                                    formAssignmentState.duration_minutes,
                                  start_date: formAssignmentState.start_date,
                                  end_date: formAssignmentState.end_date,
                                  type: formAssignmentState.type,
                                };

                                const globleOnChangeUpdateFormAssignment =
                                  globleOnChange.ASSIGNMENT.formAssignment;

                                globleOnChangeUpdateFormAssignment[
                                  assignmentsCountTwo
                                ] = newFormAssignmentArrya;
                                globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment;

                                setGlobleOnChange(globleOnChange);

                                localStorage.setItem(
                                  "globleOnChangeData",
                                  JSON.stringify(globleOnChange)
                                );
                              }
                            } else {
                              const newFormAssignmentArrya = {
                                name: formAssignmentState.name,
                                assignment: null,
                                assignment_json: null,
                                lesson: null,
                                duration_minutes:
                                  formAssignmentState.duration_minutes,
                                start_date: formAssignmentState.start_date,
                                end_date: formAssignmentState.end_date,
                                type: formAssignmentState.type,
                              };

                              const globleOnChangeUpdateFormAssignment =
                                globleOnChange.ASSIGNMENT.formAssignment;

                              globleOnChangeUpdateFormAssignment[
                                assignmentsCountTwo
                              ] = newFormAssignmentArrya;
                              globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment;

                              setGlobleOnChange(globleOnChange);

                              localStorage.setItem(
                                "globleOnChangeData",
                                JSON.stringify(globleOnChange)
                              );
                            }

                            handleAssignmentClick(assignment, index);
                          }}
                        >
                          {assignment}
                        </div>
                      ))}







                      <div
                        id="add-assignment"
                        onClick={() => {
                          setEachAssignment_id(null);
                          setassignment_id("");
                          if (!checkAssignmentopen) {
                            if (isAssignment) {
                              let errors = {};
                              if (isRequired(formAssignmentState?.name)) {
                                errors.name = "Name is required";
                              }
                              if (
                                isRequired(
                                  formAssignmentState?.duration_minutes
                                )
                              ) {
                                errors.duration_minutes = "Timer is required";
                              }
                              if (isRequired(formAssignmentState?.start_date)) {
                                errors.start_date = "Start date is required";
                              }
                              if (isRequired(formAssignmentState?.type)) {
                                errors.type = "Type is required";
                              }
                              console.log("errors", errors);

                              setFormErrorAssignmentState(errors);

                              if (
                                Object.values(errors).some((error) => error)
                              ) {
                                console.log("errors", errors);
                                return;
                              }
                            }
                          }
                          const getdata = JSON.parse(localStorage.getItem("GlobleSaveData"));
                          const assignment = getdata.ASSIGNMENT.data.length
                          const rubric = getdata.RUBRIC.data.length


                          if (rubricCount.length === 0) {
                            if (assignment > rubric) {

                              setShowRubricPopUp(true);
                            }
                          } else {
                            if (assignment > rubric) {
                              setShowRubricPopUp(true);
                            }
                          }

                          const globleOnChangeData = JSON.parse(
                            localStorage.getItem("globleOnChangeData")
                          );

                          if (
                            activeWorkboard.name === "Assignment" &&
                            globleOnChangeData.ASSIGNMENT.data.length >
                            globleOnChangeData.ASSIGNMENT.formAssignment
                              .length
                          ) {
                            if (
                              globleOnChangeData.ASSIGNMENT.formAssignment[
                              assignmentsCountTwo
                              ] === undefined
                            ) {
                              const newFormAssignmentArrya = {
                                name: formAssignmentState.name,
                                assignment: null,
                                assignment_json: null,
                                lesson: null,
                                duration_minutes:
                                  formAssignmentState.duration_minutes,
                                start_date: formAssignmentState.start_date,
                                end_date: formAssignmentState.end_date,
                                type: formAssignmentState.type,
                              };

                              const globleOnChange = JSON.parse(
                                localStorage.getItem("globleOnChangeData")
                              );

                              const globleOnChangeUpdateFormAssignment =
                                globleOnChange.ASSIGNMENT.formAssignment;
                              globleOnChangeUpdateFormAssignment.push(
                                newFormAssignmentArrya
                              );

                              globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment;

                              setGlobleOnChange(globleOnChange);

                              localStorage.setItem(
                                "globleOnChangeData",
                                JSON.stringify(globleOnChange)
                              );
                            } else {
                              const newFormAssignmentArrya = {
                                name: formAssignmentState.name,
                                assignment: null,
                                assignment_json: null,
                                lesson: null,
                                duration_minutes:
                                  formAssignmentState.duration_minutes,
                                start_date: formAssignmentState.start_date,
                                end_date: formAssignmentState.end_date,
                                type: formAssignmentState.type,
                              };
                              const globleOnChange = JSON.parse(
                                localStorage.getItem("globleOnChangeData")
                              );

                              const globleOnChangeUpdateFormAssignment =
                                globleOnChange.ASSIGNMENT.formAssignment;

                              globleOnChangeUpdateFormAssignment[
                                assignmentsCountTwo
                              ] = newFormAssignmentArrya;
                              globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment;

                              setGlobleOnChange(globleOnChange);

                              localStorage.setItem(
                                "globleOnChangeData",
                                JSON.stringify(globleOnChange)
                              );
                            }
                          } else {
                            const newFormAssignmentArrya = {
                              name: formAssignmentState.name,
                              assignment: null,
                              assignment_json: null,
                              lesson: null,
                              duration_minutes:
                                formAssignmentState.duration_minutes,
                              start_date: formAssignmentState.start_date,
                              end_date: formAssignmentState.end_date,
                              type: formAssignmentState.type,
                            };
                            const globleOnChange = JSON.parse(
                              localStorage.getItem("globleOnChangeData")
                            );

                            const globleOnChangeUpdateFormAssignment =
                              globleOnChange.ASSIGNMENT.formAssignment;

                            globleOnChangeUpdateFormAssignment[
                              assignmentsCountTwo
                            ] = newFormAssignmentArrya;
                            globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment;

                            setGlobleOnChange(globleOnChange);
                            localStorage.setItem(
                              "globleOnChangeData",
                              JSON.stringify(globleOnChange)
                            );
                          }

                          handleAddAssignment();

                          const workboardItem = Object.values(workboards).find(
                            (item) => item.name === workboard.name
                          );
                          update(
                            workboardItem,
                            workboard.name,
                            "no_previesAssignment",
                            "removeWorkboardAssignment"
                          );
                        }}
                      >
                        + Add Assignment
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              {workboard.name === workboards.RUBRIC.name ? (
                <div className="tab-dropdown" ref={dropdownRubricRef}>
                  <button
                    className="dropbtn"
                    onClick={toggleDropdownRubric}
                    style={{
                      cursor:
                        Object.entries(lesson).length === 0
                          ? "not-allowed"
                          : "auto",
                      opacity:
                        Object.entries(lesson).length === 0 ? "0.5" : "1",
                      color: activeWorkboard.name === "Rubric" ? '#0068ff' : 'black',
                    }}
                  >
                    {selectedRubric}
                    <span
                      style={{
                        transform: showDropdownRubric
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    >
                      <i className="fa-solid fa-angle-down"></i>
                    </span>
                  </button>

                  {showDropdownRubric && (
                    <div className="dropdown-content">
                      {rubricCount.map((rubric, index) => (
                        <div
                          key={index}
                          className="assignment-item"
                          onClick={() => {
                            handleRubricClick(rubric, index);
                          }}
                        >
                          {rubric}
                        </div>
                      ))}

                      <div
                        id="add-assignment"
                        onClick={() => {
                          setEachRubric_id(null);
                          setrubric_id("");
                          handleAddRubric();
                          const workboardItem = Object.values(workboards).find(
                            (item) => item.name === workboard.name
                          );
                          update(
                            workboardItem,
                            workboard.name,
                            "no_previesAssignment",
                            "removeWorkboardRubric"
                          );
                        }}
                      >
                        + Add Rubric
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          );
        })}
      </div>
    );
  }

  function UpdatePlugin({ chat, index }) {
    const [editor] = useLexicalComposerContext();
    const { answer } = chat;
    function update() {
      setActiveWorkboard(
        isAssignment
          ? workboards.ASSIGNMENT
          : isRubric
            ? workboards.RUBRIC
            : workboards.LESSON
      );
      setActiveTab("workboard");
      editor.update(() => {
        const parser = new DOMParser();
        const last_chatHist = chatHist[index];
        const finalAnswer = last_chatHist.answer
          .replace("<STARTOFTURN>", "")
          .replace("<ENDOFTURN>", "");
        const dom = parser.parseFromString(finalAnswer, "text/html");
        let nodes = $generateNodesFromDOM(editor, dom);
        try {
          $insertNodes(nodes);
          const htmlString = $generateHtmlFromNodes(editor, null);
          let root = $getRoot();
          root.clear();
          const dom = parser.parseFromString(`${htmlString}<h1> </h1>`, "text/html");
          nodes = $generateNodesFromDOM(editor, dom);
          $insertNodes(nodes);

        } catch (error) {
          console.error(error);
          const root = $getRoot();
          const p = $createParagraphNode();
          p.append($createTextNode(answer));
          root.append(p);
        } finally {
          scrollToBottom();
        }
      });

    }
    return (
      <>
        <i
          data-tooltip-id="update-plugin"
          onClick={() => update()}
          className="fa-solid fa-arrow-right"
        ></i>
        <Tooltip
          id="update-plugin"
          place="bottom"
          content="Add to Workboard"
          type="light"
          effect="float"
        />
      </>
    );
  }

  const editorConfig = {
    theme: ExampleTheme,
    onError(error) {
      throw error;
    },
    editable: true,

    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      MathNode
    ],
  };

  const [isPopupVisible, setPopupVisible] = useState(false);

  const toggleAssignmentPopup = (e) => {
    if (isAssignment) {
      let errors = {};
      if (isRequired(formAssignmentState?.name)) {
        errors.name = "Name is required";
      }
      if (isRequired(formAssignmentState?.duration_minutes)) {
        errors.duration_minutes = "Timer is required";
      }
      if (isRequired(formAssignmentState?.start_date)) {
        errors.start_date = "Start date is required";
      }
      if (isRequired(formAssignmentState?.type)) {
        errors.type = "Type is required";
      }
      console.log("errors", errors);

      setFormErrorAssignmentState(errors);

      if (Object.values(errors).some((error) => error)) {
        console.log("errors", errors);
        return;
      }
    }
    setPopupVisible(!isPopupVisible);
    setIsAssignment(true);
    setIsRubric(false);
    setFormState((prev) => ({ ...prev, chat_query: "" }));
    if (lesson_id !== "") {
      let errors = {};
      errors.end_date = checkifDateisAfterToday(
        format(new Date(formState?.end_date), "yyyy-MM-dd")
      );
      setFormLessonErrorState((prev) => ({
        ...prev,
        end_date: errors.end_date,
      }));
    }
  };
  const toggleRubricPopup = (e) => {
    setPopupVisible(false);
    setIsAssignment(false);
    setIsRubric(true);
  };
  const [workboards, setWorkboards] = useState({
    LESSON: {
      name: "Lesson",
      data: "",
    },
    ASSIGNMENT: {
      name: "Assignment",
      data: assignments?.length > 0 ? assignments : "",
    },
    RUBRIC: {
      name: "Rubric",
      data: rubrics?.length > 0 ? rubrics : "",
    },
  });

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  useEffect(() => {
    if (activeWorkboard.name === "Assignment") {
      setWorkboardText(stripHtmlTags(workboards.ASSIGNMENT.data).trim());
    } else if (activeWorkboard.name === "Rubric") {
      setWorkboardText(stripHtmlTags(workboards.RUBRIC.data).trim());
    } else {
      setWorkboardText(stripHtmlTags(workboards.LESSON.data).trim());
    }
  }, [workboards])

  const lessonFunction = () => {
    if (activeWorkboard.name === "Assignment") {
      if (
        workboards?.ASSIGNMENT?.data !==
        onChangeAssignmentData[assignmentsCountTwo]
      ) {
        setAfterConfirmation("Lesson");
        setIsConfirmModalOpen(true);
      } else if (
        workboards?.ASSIGNMENT?.data ===
        onChangeAssignmentData[assignmentsCountTwo]
      ) {
        setAfterConfirmation("Lesson");
        setUpdateFuntion(true);
        setIsAssignment(false);
        setIsRubric(false);
        setActiveWorkboard(workboards.LESSON);
      }
    } else if (activeWorkboard.name === "Rubric") {
      if (workboards?.RUBRIC?.data != onChangeRubricData[rubricCounttTwo]) {
        setAfterConfirmation("Lesson");
        setIsConfirmModalOpen(true);
      } else if (
        workboards?.RUBRIC?.data == onChangeRubricData[rubricCounttTwo]
      ) {
        setAfterConfirmation("Lesson");
        setUpdateFuntion(true);
        setIsAssignment(false);
        setIsRubric(false);
        setActiveWorkboard(workboards.LESSON);
      }
    } else {
      setAfterConfirmation("Lesson");
      setUpdateFuntion(true);
      setIsAssignment(false);
      setIsRubric(false);
      setActiveWorkboard(workboards.LESSON);
    }
  };
  const [activeWorkboard, setActiveWorkboard] = useState(workboards.LESSON);
  const [editWorkboardStatues, setEditWorkboardStatues] = useState(false);


  const assignmentbuttonStylebackgroundColor =
    workboards.ASSIGNMENT.data !== "" ? "#b5ff9fcc" : "transparent"; // Replace 'defaultColor' with your desired default color
  const lessonbuttonStylebackgroundColor =
    workboards.LESSON.data !== "" ? "#b5ff9fcc" : "transparent";
  const rubricbuttonStylebackgroundColor =
    workboards.RUBRIC.data !== "" ? "#b5ff9fcc" : "transparent";
  // Inline styling for the button
  const assignmentbuttonStyle = {
    background: assignmentbuttonStylebackgroundColor,
    // You can add other styles here if needed
  };
  const lessonbuttonStyle = {
    background: lessonbuttonStylebackgroundColor,
    color: activeWorkboard.name === "Lesson" ? '#0068ff' : 'black'
    // You can add other styles here if needed
  };
  const rubricbuttonStyle = {
    background: rubricbuttonStylebackgroundColor,
    // You can add other styles here if needed
  };


  useEffect(() => {
    const globleOnChangeData = JSON.parse(
      localStorage.getItem("globleOnChangeData")
    );

    if (
      globleOnChangeData.ASSIGNMENT.data.length >
      globleOnChangeData.ASSIGNMENT.formAssignment.length
    ) {
      const newFormAssignmentArrya = {
        name: formAssignmentState.name,
        assignment: null,
        assignment_json: null,
        lesson: null,
        duration_minutes: formAssignmentState.duration_minutes,
        start_date: formAssignmentState.start_date,
        end_date: formAssignmentState.end_date,
        type: formAssignmentState.type,
      };

      const globleOnChange = JSON.parse(
        localStorage.getItem("globleOnChangeData")
      );

      const globleOnChangeUpdateFormAssignment =
        globleOnChange.ASSIGNMENT.formAssignment;
      globleOnChangeUpdateFormAssignment.push(newFormAssignmentArrya);

      globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment;

      setGlobleOnChange(globleOnChange);

      localStorage.setItem(
        "globleOnChangeData",
        JSON.stringify(globleOnChange)
      );
    } else {
      const newFormAssignmentArrya = {
        name: formAssignmentState.name,
        assignment: null,
        assignment_json: null,
        lesson: null,
        duration_minutes: formAssignmentState.duration_minutes,
        start_date: formAssignmentState.start_date,
        end_date: formAssignmentState.end_date,
        type: formAssignmentState.type,
      };
      const globleOnChange = JSON.parse(
        localStorage.getItem("globleOnChangeData")
      );

      const globleOnChangeUpdateFormAssignment =
        globleOnChange.ASSIGNMENT.formAssignment;

      globleOnChangeUpdateFormAssignment[
        assignmentsCountTwo
      ] = newFormAssignmentArrya;
      globleOnChange.ASSIGNMENT.formAssignment = globleOnChangeUpdateFormAssignment;

      setGlobleOnChange(globleOnChange);

      localStorage.setItem(
        "globleOnChangeData",
        JSON.stringify(globleOnChange)
      );
    }
  }, [activeWorkboard.name]);

  const updateWorkboardDataByName = (name, newData, prevRub) => {

    setWorkboards((prevWorkboards) => {
      // Find the key of the workboard we want to update
      const workboardKey = Object.keys(prevWorkboards).find(
        (key) => prevWorkboards[key].name === name
      );
      // If we found a workboard with the provided name
      if (workboardKey) {
        return {
          ...prevWorkboards, // Copy all the existing workboards
          [workboardKey]: {
            // Use computed property names to overwrite the specific workboard
            ...prevWorkboards[workboardKey], // Copy all the existing properties of the workboard
            data: newData, // Update the data property
          },
        };
      } else {
        // If no workboard was found with the given name, return the previous state
        return prevWorkboards;
      }
    });
  };

  useEffect(() => {
    const globleOnChangeData = JSON.parse(
      localStorage.getItem("globleOnChangeData")
    );
    if (globleOnChangeData) {
      const workboardName = activeWorkboard.name.toUpperCase();
      updateWorkboardDataByName(
        globleOnChangeData[workboardName].name,
        globleOnChangeData[workboardName].data
      );
    }
  }, [activeWorkboard.name]);

  const [showDropdown, setShowDropdown] = useState(false); // State to control visibility

  const getShareLink = ({ platform, course, baseURL }) => {
    let shareUrl = "";
    const courseDescription =
      course?.description?.length > 100
        ? `${course?.description?.substring(0, 100)}...`
        : course?.description;
    // trim the description to 150 characters

    const data = `${courseDescription}. View the course here: ${baseURL}.`;
    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${baseURL}&quote=${data}`;
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${baseURL} ${data}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${data}`;
        break;
      case "instagram":
        shareUrl = `https://www.instagram.com/?url=${baseURL}`;
        break;
      case "share":
        shareUrl = baseURL;
        break;
      default:
        shareUrl = baseURL;
        return;
    }

    return shareUrl;
  };
  const getPlatform = {
    FACEBOOK: "facebook",
    WHATSAPP: "whatsapp",
    TWITTER: "twitter",
    INSTAGRAM: "instagram",
    SHARE: "share",
  };
  const [sharelink, setSharelink] = useState("");
  const onShare = async (platform, isOpenNewWindow = true) => {
    setLoading(true);

    if (!course?.course_id) {
      toast.error("Course not found");
      setLoading(false);
      return;
    }

    const getURL = `http://${liveBaseURL}/dashboard`;
    const getShareableLink = getShareLink({
      platform: platform,
      course,
      baseURL: getURL,
    });
    setSharelink(getShareableLink);
    if (isOpenNewWindow) {
      window.open(getShareableLink, "_blank");
    }
  };

  const onClickCopy = () => {
    navigator.clipboard.writeText(sharelink);
    toast.success("Copied to clipboard", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [showDownloadFile, setshowDownloadFile] = useState(false);
  const modalRef = useRef();
  const modalContentRef = useRef(); // Reference to the modal content

  // Function to open the modal
  const openModal = () => {
    setshowDownloadFile(true);
  };

  // Event listener to close modal if clicked outside the modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      ) {
        toggledownlodefile();
      }
    };

    // Attach the event listener
    if (showDownloadFile) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDownloadFile]);

  const toggledownlodefile = () => {
    setFileNameDownload("");
    setFileNameError("");
    setshowDownloadFile(false);
  };

  useEffect(() => {
    AOS.init({
      duration: 500,
    });
  }, []);

  const [fileNameError, setFileNameError] = useState("");
  const [fileNameDownload, setFileNameDownload] = useState("");
  const [
    pop_up_for_lesson_start_date_note,
    setPopupForLessonStartDateNote,
  ] = useState(false);
  const downloadAsPdf = async () => {
    if (fileNameDownload === null || fileNameDownload === "") {
      setFileNameError("Please enter filename");
    } else {
      const htmlData = document.querySelector(".editor-input").innerHTML;
      const payload = {
        html_text: htmlData,
      };
      const headers = {
        "Content-Type": "application/pdf",
        Authorization: `Token ${token}`,
      };
      try {
        const response = await api.post(
          `auth/convert-html-to-pdf/`,
          payload,
          headers
        );
        if (response.status === 200 || response.status === 201) {
          // Assuming the PDF data in `response.data` is the raw binary string
          const binaryString = response.data;

          // Convert binary string to a Blob
          const pdfBlob = new Blob([binaryStringToArrayBuffer(binaryString)], {
            type: "application/pdf",
          });

          // Create a download link and trigger the download
          const downloadUrl = window.URL.createObjectURL(pdfBlob);
          const link = document.createElement("a");
          link.href = downloadUrl;
          // link.download = "converted.pdf";
          link.download = `${fileNameDownload}.pdf`;
          document.body.appendChild(link);
          link.click();
          link.remove();
          toggledownlodefile();
          return response?.data?.user_id;
        } else {
          return null;
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    }
  };

  function getEditorContent() {
    // Replace this with the actual method to get content from your rich text editor
    return document.querySelector(".editor-input").innerHTML; // Example for plain text
  }

  const exportToWord = async () => {
    if (fileNameDownload === null || fileNameDownload === "") {
      setFileNameError("Please enter filename");
    } else {
      const preHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Word</title></head><body>";
      const postHtml = "</body></html>";
      const html = preHtml + getEditorContent() + postHtml;

      const blob = new Blob(["\ufeff", html], {
        type: "application/msword",
      });

      const url =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(html);
      const fileName = fileNameDownload + ".doc";

      const downloadLink = document.createElement("a");
      document.body.appendChild(downloadLink);

      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName);
      } else {
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.click();
      }
      document.body.removeChild(downloadLink);
      toggledownlodefile();
    }
  };

  const handleFileNameChange = (e) => {
    const enteredValue = e.target.value.trim();

    setFileNameDownload(enteredValue); // Always update the state

    if (!enteredValue) {
      setFileNameError("Please enter filename");
    } else {
      setFileNameError("");
    }
  };
  const calculateAssignmentDateRanges = (
    start_date,
    end_date,
    assignment_start_date,
    assignment_end_date
  ) => {
    const dayjsStart = start_date ? moment(start_date) : null;
    const dayjsEnd = end_date ? moment(end_date) : null;
    const dayjsAssignmentStart = assignment_start_date
      ? moment(assignment_start_date)
      : null;
    const dayjsAssignmentEnd = dayjsAssignmentStart
      ? moment(dayjsAssignmentStart)
      : null;
    let minStartDate = moment(dayjsStart);
    let maxStartDate = moment(dayjsEnd);
    let minEndDate = moment(dayjsStart);
    let maxEndDate = moment(dayjsEnd);

    return { minStartDate, maxStartDate, minEndDate, maxEndDate };
  };
  const {
    minStartDate,
    maxStartDate,
    minEndDate,
    maxEndDate,
  } = calculateAssignmentDateRanges(
    formState?.start_date,
    formState?.end_date,
    formAssignmentState?.start_date,
    formAssignmentState?.end_date
  );

  const defaultWorkboardContent = `<p class="editor-paragraph"><br></p>`;

  useEffect(() => {
    if (lesson_id !== "") {
      let errors = {};

      errors.end_date = checkifDateisAfterToday(
        format(new Date(formState?.end_date), "yyyy-MM-dd")
      );
      setFormLessonErrorState((prev) => ({
        ...prev,
        end_date: errors.end_date,
      }));

      // Use setTimeout to clear the error after 5 seconds
      const timeoutId = setTimeout(() => {
        let updatedErrors = {};
        updatedErrors.end_date = "";
        setFormLessonErrorState((prev) => ({
          ...prev,
          end_date: updatedErrors.end_date,
        }));
      }, 5000);

      // Cleanup the timeout when the component unmounts
      return () => clearTimeout(timeoutId);
    }
    // Add formLessonError as a dependency if needed
    speechSynthesis.speak(new SpeechSynthesisUtterance(""));
  }, [formState?.end_date]);
  const [assignmentDuration, setAssignmentDuration] = useState({
    minutes: 0,
  });
  const [assignmentDurationCount, setAssignmentDurationCount] = useState(
    Number(localStorage.getItem("assignmentDurationCount"))
  );

  useEffect(() => {
    localStorage.setItem("assignmentDurationCount", assignmentDurationCount);

    return () => {
      localStorage.setItem("assignmentDurationCount", 0);
    };
  }, [assignmentDurationCount]);

  const [
    assignmentDurationDropdownOpen,
    setAssignmentDurationDropdownOpen,
  ] = useState(false);
  const toggleAssignmentDurationDropdown = () =>
    setAssignmentDurationDropdownOpen(!assignmentDurationDropdownOpen);

  const assignmentDurationChangeTime = (value) => {
    setAssignmentDuration((prevTime) => {
      let { minutes } = prevTime;

      // Increment/Decrement minutes
      minutes += value;

      // Limit the minutes to the range 0-180
      minutes = Math.max(0, Math.min(180, minutes));

      // Update the input value for display
      const newInputValue = minutes;
      setFormAssignmentState((prev) => ({
        ...prev,
        duration_minutes: newInputValue,
      }));

      return { minutes };
    });
  };
  useEffect(() => {
    // Save the form state to local storage whenever it changes

    localStorage.setItem("lesson_formState", JSON.stringify(formState));
  }, [formState]);
  const [previewDtls, setpreviewDtls] = useState([]);
  const showstudentviewDtls = () => {
    setpreviewDtls(
      isAssignment
        ? workboards.ASSIGNMENT.data
        : isRubric
          ? workboards.RUBRIC.data
          : workboards.LESSON.data
    );

    setopenStudentView(!openStudentView);
  };

  useEffect(() => {

    const handleKeyDown = (event) => {
      if (isListening) {
        if (event.key) {
          startStopListening();
        }
      }
    };
    // Adding the event listener when the component mounts or stopListeningBTN changes
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isListening, transcript]);


  const startStopListening = () => {
    // console.log(transcript)
    if (isListening) {
      stopVoiceInput();
    } else {
      startListening();
    }
  };
  const stopVoiceInput = () => {
    stopListening();
    setFormState((prevState) => ({
      ...prevState,
      chat_query: prevState.chat_query
        ? prevState.chat_query + (transcript ? " " + transcript : "")
        : transcript,
    }));
  };

  const [chatAudioId, setchatAudioId] = useState();

  const generateAIVoice = (chat, id) => {
    setchatAudioId(id);

    let lessonData = parseKaTeX(chat);

    const req = {
      text: lessonData,
      generator_id: id,
    };
    // dispatch(getTextToSpeech(req, "generator", "Ruth", token));
    dispatch(getTextToSpeech(req, "generator", "Salli", "neural", token));
  };

  useEffect(() => {
    return () => {
      dispatch(resetTextToSpeech());
    };
  }, []);

  const preprocessLatex = (latexString) => {
    // Example: Replace common HTML entities with LaTeX-friendly characters
    return latexString.replace(/&amp;#39;/g, "'");
    // Add more replacements as needed
  };

  async function convertDrawingToText(imageData, setConvertedText) {
    try {
      const response = await fetch("https://api.mathpix.com/v3/text", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          app_id: "Webarcitech_621a57", // Replace with your Mathpix app ID
          app_key: "7d36ed55c7649574c24f", // Replace with your Mathpix app key
        },
        body: JSON.stringify({ src: imageData }),
      });
      const data = await response.json();
      setConvertedText(data.text || "No text recognized");
    } catch (error) {
      console.error("Error converting drawing:", error);
      setConvertedText("Error in conversion");
    }
  }

  return (
    <>
      {" "}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <LexicalComposer initialConfig={editorConfig}>
          <section className="lesson_generator">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="lesson_generator_inner">
                    <div className="view-course-Details-back-btn-folder">
                      <BackButton buttonName={"Lesson Generator"} />
                    </div>
                    <div className="assign_main_box">
                      <div className="assign_main_box_left">
                        <div className="assign_main_box_left_single">
                          <label>Lesson Name</label>
                          <CommonTextField
                            type="text"
                            placeholder="E.g. Neurons"
                            name="name"
                            label="Lesson name"
                            showLabel={false}
                            variant="outlined"
                            validate={(val) => isRequired(val, "Lesson name")}
                            size="sm"
                            className="custom-text-field"
                            value={formState.name || ""}
                            onChange={handleInputChange}
                            borderRadius={"6px"}
                            height={45}
                            border={"1px solid #ECECEC;"}
                            color="neutral"
                          />
                        </div>
                      </div>
                      {pop_up_for_lesson_start_date_note && (
                        <div className="pop_up_for_lesson_start_date_note">
                          <p>
                            Students can start accessing the course content from{" "}
                            {dayjs(formState.start_date).format("MMM DD, YYYY")}
                          </p>
                        </div>
                      )}

                      <div className="assign_main_box_right">
                        <div className="assign_main_box_right_single">
                          <label>Lesson Start Date</label>

                          <div className="assing_cal_sec_new">
                            <img src={Calimg} className="cal_img_sec" alt="" />
                            <Datetime
                              isValidDate={isValidDateLessonStartDate}
                              min={lessonMinDate}
                              max={
                                course?.end_date === ""
                                  ? null
                                  : dayjs(course?.end_date)
                              }
                              timeFormat={false}
                              inputProps={{
                                placeholder: "MM:DD:YYYY",
                                readOnly: true,
                              }}
                              closeOnSelect={true}
                              dateFormat="MMM DD, YYYY"
                              placeholder="E.g. Sep 10, 2023"
                              name="start_date"
                              value={
                                formState.start_date
                                  ? dayjs(formState.start_date).format(
                                    "MMM DD, YYYY"
                                  )
                                  : ""
                              }
                              onChange={(date) =>
                                handleInputChangedate("start_date", date)
                              }
                              className="custom-datetime"
                            />
                          </div>

                          <div className="warning-message">
                            {formLessonError?.start_date}
                          </div>
                        </div>
                        <div className="assign_main_box_right_single lesson-end-date">
                          <label>Lesson End Date (Optional)</label>
                          <div className="assing_cal_sec_new">
                            <img src={Calimg} className="cal_img_sec" alt="" />
                            <Datetime
                              isValidDate={isValidDateLessonEndDate}
                              max={dayjs(course?.end_date)}
                              timeFormat={false}
                              inputProps={{
                                placeholder: "MM:DD:YYYY",
                                readOnly: true,
                              }}
                              closeOnSelect={true}
                              dateFormat="MMM DD, YYYY"
                              placeholder="E.g. Sep 10, 2023"
                              name="end_date"
                              value={
                                formState.end_date
                                  ? dayjs(formState.end_date).format(
                                    "MMM DD, YYYY"
                                  )
                                  : ""
                              }
                              onChange={(date) =>
                                handleInputChangedate("end_date", date)
                              }
                              className="custom-datetime"
                            />
                          </div>

                          <div className="warning-message">
                            {formLessonError?.end_date}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="lesson_generator_body_sec"
                    style={{ flexDirection: isSmallScreen ? "column" : "row" }}
                  >
                    {isSmallScreen && (
                      <div className="view-lessons-btn-container">
                        <button
                          className={`tab-button ${activeTab === "lesson" ? "active" : ""
                            }`}
                          onClick={() => setActiveTab("lesson")}
                        >
                          Lessons
                        </button>
                        <button
                          className={`tab-button ${activeTab === "workboard" ? "active" : ""
                            }`}
                          onClick={() => setActiveTab("workboard")}
                        >
                          Workboard
                        </button>
                      </div>
                    )}
                    <Box
                      className="lesson_generator_body_sec_left"
                      width={isSmallScreen ? "100%" : "50%"}
                      sx={{
                        display: {
                          xs: activeTab === "lesson" ? "block" : "none",
                          lg: "block",
                        },
                      }}
                    >
                      <div className="lesson-generator-massege-folder">
                        <h6>Lesson Generator</h6>
                        <div
                          className="lesson-genrater-toggle-folder"
                          data-tooltip-id="chatbot-data-workboard"
                        >
                          <h6>ChatBot</h6>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isSwitchOnVirtualAssistant}
                              onClick={handleSwitchToggleVirtualAssistant}
                            />
                            <span className="slider round"></span>
                          </label>
                          <Tooltip
                            id="chatbot-data-workboard"
                            place="top"
                            content="Show/Hide chatbot during assignments"
                            type="light"
                            effect="float"
                          />
                        </div>
                        <div
                          className="lesson-genrater-toggle-folder"
                          data-tooltip-id="lessonpreview-data-workboard"
                        >
                          <h6>Lesson Preview</h6>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isSwitchOnLessonPreview}
                              onClick={handleSwitchToggleLessonPreview}
                            />
                            <span className="slider round"></span>
                          </label>
                          <Tooltip
                            id="lessonpreview-data-workboard"
                            place="top"
                            content="Show/Hide lesson preview during assignments"
                            type="light"
                            effect="float"
                          />
                        </div>
                        <div
                          className="lesson-genrater-downlode"
                          data-tooltip-id="filedownload-data-workboard"
                        >
                          <h6>Download Files</h6>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isSwitchOn2}
                              onClick={handleSwitchToggleDownload}
                            />
                            <span className="slider round"></span>
                          </label>
                          <Tooltip
                            id="filedownload-data-workboard"
                            place="top"
                            content="Allow download of uploaded files"
                            type="light"
                            effect="float"
                          />
                        </div>
                      </div>
                      <div className="lesson_generator_body_sec_left_body">
                        <div className="lesson_generator_body_sec_left_text_part">
                          <h5>
                            <img src={ass_img} alt="" /> Hello Polly, I will
                            assist you to create amazing lesson for your course.
                          </h5>
                        </div>

                        <div className="chat-with-ai-lession-container">
                          {chatHist.map((chat, index) => (
                            <div key={index}>
                              <div className="chat-with-ai-lession-user-folder">
                                <div className="chat-with-ai-lession-user-sned-massege-container">
                                  {chat?.file?.length > 0 ? (
                                    chat?.file.map((file, index) => {
                                      return (
                                        <ListItem
                                          key={index}
                                          sx={{
                                            backgroundColor: "white",
                                            borderRadius: "6px",
                                            marginBottom: "8px",
                                            height: "48px",
                                          }}
                                        >
                                          <ListItemIcon>
                                            <img
                                              src={getFileIcon(
                                                file?.original_name
                                              )}
                                              alt=""
                                            />
                                          </ListItemIcon>
                                          <ListItemText
                                            primary={file?.original_name}
                                            secondary={getFileType(
                                              file?.original_name
                                            )}
                                          />
                                        </ListItem>
                                      );
                                    })
                                  ) : (
                                    <></>
                                  )}
                                  <p className="text-massege-user">
                                    {chat.query}
                                  </p>
                                </div>
                                <div className="send-user-name">
                                  <h2>
                                    {getname(
                                      globalState.teacher_details?.data?.name
                                    )}
                                  </h2>
                                </div>
                              </div>
                              <div className="chat-with-ai-lession-user-folder chat-with-ai-lession-AI-message">
                                <div className="send-user-name">
                                  <h2>AI</h2>
                                </div>
                                {chat.answer === "" && (
                                  <>
                                    <div className="loading-chat-answer">
                                      <Waveform />
                                    </div>
                                  </>
                                )}
                                <div className="read-chat-container">
                                  <div className="chat-with-ai-lession-user-sned-massege-container">
                                    <PreprocessAndRenderLatex
                                      content={chat.answer}
                                      isBlockMath={true}
                                    // setShowCloseBTN={setShowCloseBTN}
                                    />
                                  </div>
                                  {!(chat.answer === "") && (
                                    <div
                                      key={index}
                                      className="copy-past-text-container"
                                    >
                                      <div className="play-pause-btn-lesson-generator">
                                        {chatAudioId === chat.uuid &&
                                          texttospeech.isLoading ? (
                                          <LoaderComponent />
                                        ) : chatAudioId === chat.uuid &&
                                          chatAudioId ===
                                          texttospeech.generatorId &&
                                          texttospeech.success ? (
                                          <audio
                                            controls
                                            src={texttospeech.voice.audio_url}
                                          />
                                        ) : (
                                          <i
                                            className="fa-solid fa-circle-play"
                                            onClick={() =>
                                              generateAIVoice(
                                                chat.answer,
                                                chat.uuid
                                              )
                                            }
                                          ></i>
                                        )}
                                      </div>
                                      <i
                                        data-tooltip-id="copy-data"
                                        onClick={() => onCopyClicked(chat, index)}
                                        className={"fa-regular fa-clipboard"}
                                      ></i>
                                      <UpdatePlugin index={index} chat={chat} />
                                      <Tooltip
                                        id="copy-data"
                                        place="bottom"
                                        content="Copy"
                                        type="light"
                                        effect="float"
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                          <div ref={messagesEndRef} />
                        </div>

                        <div className="ganrate-lesson-text-feild-wrapper">
                          <div className="pdf_box_sec">
                            <div className="pdf_box_sec_inner">
                              <AWSS3FileDelete
                                selectedFiles={selectedFiles}
                                setSelectedFiles={setSelectedFiles}
                                getFileIcon={getFileIcon}
                                uploadedFileLink={uploadedFileLink}
                                setuploadedFileLink={setuploadedFileLink}
                                progressTracker={progressTracker}
                                setprogressTracker={setprogressTracker}
                                fileInputRef={fileInputRef}
                              />
                            </div>
                            <form action="" onSubmit={sendGenerateLession}>
                              <div
                                className={`create-Assignment-container-popup ${checkAssignmentopen
                                  ? ""
                                  : isPopupVisible
                                    ? "show"
                                    : ""
                                  }`}
                                style={{
                                  padding: `${checkAssignmentopen
                                    ? ""
                                    : isPopupVisible
                                      ? "10px"
                                      : "0px"
                                    }`,
                                }}
                              >
                                <div className="create-Assignment-container-popup-folder1">
                                  <div className="assign_main_box_left_single create-Assignment-input-feild">
                                    <label>Assignment Name</label>
                                    <FormControl
                                      margin="0px"
                                      fullWidth
                                      error={!!formErrorAssignmentState.name}
                                    >
                                      <TextField
                                        name="name"
                                        size="small"
                                        defaultValue={""}
                                        placeholder="E.g. Neurons"
                                        className="custom-text-field"
                                        variant="outlined"
                                        value={formAssignmentState.name}
                                        error={!!formErrorAssignmentState.name}
                                        onChange={handleAssignmentInputChange}
                                        InputProps={{
                                          sx: {
                                            borderRadius: "6px",
                                            backgroundColor: "#FFFFFF",
                                            fontSize: "10px",
                                            height: "35px",
                                          },
                                        }}
                                      />
                                      <FormHelperText sx={{ color: "#d31332" }}>
                                        {formErrorAssignmentState.name}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                  <div className="Create-Course-inner-input Create-Assignment-dropdown">
                                    <label>Assignment timer</label>
                                    <FormControl margin="none">
                                      <div className="time-field">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            assignmentDurationChangeTime(-5);
                                            setAssignmentDurationCount(
                                              Number(assignmentDurationCount) >
                                                0
                                                ? Number(
                                                  assignmentDurationCount
                                                ) - 1
                                                : 0
                                            );
                                          }}
                                        >
                                          -
                                        </button>
                                        <span>
                                          {`${assignmentDuration.minutes
                                            .toString()
                                            .padStart(2, "0")} Minute${assignmentDuration.minutes?.toString() >
                                              1
                                              ? "s"
                                              : ""
                                            }`}
                                        </span>
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            assignmentDurationChangeTime(5);
                                            setAssignmentDurationCount(
                                              Number(assignmentDurationCount) +
                                              1
                                            );
                                          }}
                                        >
                                          +
                                        </button>
                                      </div>

                                      <FormHelperText sx={{ color: "#d31332" }}>
                                        {
                                          formErrorAssignmentState.duration_minutes
                                        }
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                </div>
                                <div className="create-Assignment-container-popup-folder2">
                                  <div className="assign_main_box_right_date">
                                    <label>Start Date</label>
                                    <FormControl
                                      margin="none"
                                      fullWidth
                                      error={
                                        !!formErrorAssignmentState.start_date
                                      }
                                    >
                                      <img
                                        src={Calimg}
                                        className="cal_img_sec_assignment"
                                        alt=""
                                      />
                                      <Datetime
                                        isValidDate={
                                          isValidDateAssignmentStartDate
                                        }
                                        min={minDate}
                                        timeFormat={false}
                                        inputProps={{
                                          placeholder: "MM:DD:YYYY",
                                          readOnly: true,
                                        }}
                                        closeOnSelect={true}
                                        dateFormat="MMM DD, YYYY"
                                        placeholder="E.g. Sep 10, 2023"
                                        name="start_date"
                                        value={
                                          formAssignmentState.start_date
                                            ? dayjs(
                                              formAssignmentState.start_date
                                            ).format("MMM DD, YYYY")
                                            : ""
                                        }
                                        onChange={(date) =>
                                          handleAssignmentInputChangedate(
                                            "start_date",
                                            date
                                          )
                                        }
                                        className="custom-datetime my-custom-picker"
                                      />
                                      <FormHelperText sx={{ color: "#d31332" }}>
                                        {formErrorAssignmentState.start_date}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                  <div className="assign_main_box_right_date">
                                    <label>Expiry Date</label>
                                    <FormControl
                                      margin="none"
                                      fullWidth
                                      error={
                                        !!formErrorAssignmentState.start_date
                                      }
                                    >
                                      <img
                                        src={Calimg}
                                        className="cal_img_sec_assignment"
                                        alt=""
                                      />

                                      <Datetime
                                        isValidDate={
                                          isValidDateAssignmentEndDate
                                        }
                                        min={dayjs(
                                          formAssignmentState?.start_date
                                            ? formState.start_date
                                            : formAssignmentState?.start_date
                                        )
                                          .add(1, "day")
                                          .format("MMM DD, YYYY")}
                                        timeFormat={false}
                                        inputProps={{
                                          placeholder: "MM:DD:YYYY",
                                          readOnly: true,
                                        }}
                                        closeOnSelect={true}
                                        dateFormat="MMM DD, YYYY"
                                        placeholder="E.g. Sep 10, 2023"
                                        name="end_date"
                                        value={
                                          formAssignmentState.end_date === null
                                            ? ""
                                            : dayjs(
                                              formAssignmentState.end_date
                                            ).format("MMM DD, YYYY")
                                        }
                                        onChange={(date) =>
                                          handleAssignmentInputChangedate(
                                            "end_date",
                                            date
                                          )
                                        }
                                        className="custom-datetime my-custom-picker"
                                        initialViewDate={dayjs(
                                          formAssignmentState?.start_date ??
                                          formState.start_date
                                        )
                                          .add(1, "day")
                                          .toDate()}
                                      />
                                      <FormHelperText sx={{ color: "#d31332" }}>
                                        {formErrorAssignmentState.end_date}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                  <div className="Create-Course-inner-input Create-Assignment-dropdown create-Assignment-popup-input-feild">
                                    <label>Choose Assignment type</label>
                                    <FormControl margin="none">
                                      <TextField
                                        name="type"
                                        defaultValue={"ddasas"}
                                        value={formAssignmentState.type}
                                        placeholder="Select Assignment type"
                                        variant="outlined"
                                        select
                                        size="small"
                                        error={!!formErrorAssignmentState.type}
                                        onChange={handleAssignmentInputChange}
                                        InputProps={{
                                          sx: {
                                            borderRadius: "6px",
                                            backgroundColor: "#FFFFFF",
                                            height: "35px",
                                            padding: "0px",
                                          },
                                        }}
                                      >
                                        {Object.values(AssignmentType).map(
                                          (type) => (
                                            <MenuItem
                                              key={type.name}
                                              value={type.value}
                                            >
                                              {type.name}
                                            </MenuItem>
                                          )
                                        )}
                                      </TextField>
                                      <FormHelperText sx={{ color: "#d31332" }}>
                                        {formErrorAssignmentState.type}
                                      </FormHelperText>
                                    </FormControl>
                                  </div>
                                </div>
                              </div>
                              <div className="promote_part_sec">
                                <FileUpload
                                  showUploadPop={showUploadPop}
                                  setShowUploadPop={setShowUploadPop}
                                  selectedFiles={selectedFiles}
                                  setSelectedFiles={setSelectedFiles}
                                  setuploadedFileLink={setuploadedFileLink}
                                  fileInputRef={fileInputRef}
                                />
                                <TextField
                                  ref={textBoxRef}
                                  id="lesson_promote_flexible"
                                  name="chat_query"
                                  label=""
                                  variant="standard"
                                  placeholder={`Write all your prompts here to create ${isAssignment
                                    ? "assignment"
                                    : isRubric
                                      ? "rubric"
                                      : "lesson"
                                    }`}
                                  fullWidth
                                  multiline
                                  InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                      <div>
                                        <AttachFileOutlined
                                          className="uplode-icon-generator"
                                          onClick={showUploadPopupcall}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                    ),

                                    endAdornment: (
                                      <>
                                        {showCloseBTN && (
                                          <>
                                            {" "}
                                            <button
                                              onClick={closeConnection}
                                              className="genrater-close-btn"
                                            >
                                              <i class="fa-regular fa-circle-stop"></i>
                                            </button>{" "}
                                          </>
                                        )}
                                        <button
                                          type="button"
                                          id="mic-btn"
                                          className={`mic ${isListening ? "listening" : ""
                                            }`}
                                          onClick={() => {
                                            startStopListening();
                                          }}
                                        >
                                          <i
                                            className={`fa-solid fa-microphone ${isListening ? "hidden" : ""
                                              }`}
                                          ></i>
                                          <div
                                            className={`boxContainer ${isListening ? "" : "hidden"
                                              }`}
                                          >
                                            <div className="box box1"></div>
                                            <div className="box box2"></div>
                                            <div className="box box3"></div>
                                          </div>
                                        </button>
                                        <Tooltip
                                          id="speack-mic"
                                          place="bottom"
                                          content="Press Alt to unmute Mic"
                                          type="light"
                                          effect="float"
                                        />

                                        <button
                                          type="submit"
                                          disabled={
                                            isListening ||
                                            !formState.chat_query ||
                                            formState.chat_query === null ||
                                            formState.chat_query === "" || // Condition 2
                                            selectedFiles.length !==
                                            progressTracker.length ||
                                            !progressTracker.every(
                                              (obj) =>
                                                obj.status === "Completed"
                                            ) // Condition 3
                                          }
                                          className="go_cls_btn"
                                        >
                                          <img src={go_btn} alt="" />
                                        </button>
                                      </>
                                    ),
                                  }}
                                  maxRows={5}
                                  value={
                                    isListening
                                      ? formState.chat_query
                                        ? `${formState.chat_query}${transcript ? ` ${transcript}` : ""
                                        }`
                                        : transcript
                                      : formState.chat_query
                                  }
                                  disabled={isListening}
                                  onChange={handleInputKeyPress}
                                  onKeyDown={handleInputKeyPress}
                                />
                                <Tooltip
                                  id="my-tooltip-click"
                                  content="Prompt here"
                                  events={["click"]}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                        <p className="AI-disclamer">
                          AI can make mistakes. Consider checking important
                          information
                        </p>
                      </div>
                    </Box>
                    <Box
                      className="lesson_generator_body_sec_right"
                      width={isSmallScreen ? "100%" : "50%"}
                      sx={{
                        display: {
                          xs: activeTab === "workboard" ? "block" : "none",
                          lg: "block",
                        },
                      }}
                    >
                      <div className="create-lesson-Personalize-contaiber">
                        <h6>Workboard</h6>
                        <h6>
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault(); // This prevents the default anchor link behavior
                              showstudentviewDtls();
                            }}
                          >
                            Student Preview
                          </a>
                        </h6>
                        <div className="Preview-lesson-Content">
                          <div className="Preview-lesson-text-editor-features-personalize">
                            {/* <h2>Personalize</h2>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={isSwitchOn}
                                onClick={handleSwitchToggle}
                              />
                              <span className="slider round"></span>
                            </label>
                            {showPopup && (
                              <p className="personlized_popup">
                                The content will be personalized to match the
                                student's specific interests
                              </p>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className="lesson_generator_body_sec_right_body">
                        <div className="lesson_generator_editor_toos-folder">
                          <UpdateActiveWorkbaordPlugin
                            assignmentsCount={assignmentsCount}
                            setAssignmentCounts={setAssignmentCounts}
                            showAssingment={showAssingment}
                            setShowAssingment={setShowAssingment}
                            selectedAssignment={selectedAssignment}
                            setSelectedAssignment={setSelectedAssignment}
                            isPopupVisible={isPopupVisible}
                            setPopupVisible={setPopupVisible}
                            setIsAssignment={setIsAssignment}
                            onChangeAssignmentData={onChangeAssignmentData}
                            setOnChangeAssignmentData={
                              setOnChangeAssignmentData
                            }
                            setAssignmentCountsTwo={setAssignmentCountsTwo}
                            editWorkboardStatues={editWorkboardStatues}
                            setEditWorkboardStatues={setEditWorkboardStatues}
                            assignmentsCountTwo={assignmentsCountTwo}
                            showDropdownRubric={showDropdownRubric}
                            setShowDropdownRubric={setShowDropdownRubric}
                            rubricCount={rubricCount}
                            setRubricCount={setRubricCount}
                            rubricCounttTwo={rubricCounttTwo}
                            setRubricCountTwo={setRubricCountTwo}
                            onChangeRubricData={onChangeRubricData}
                            setOnChangeRubricData={setOnChangeRubricData}
                            selectedRubric={selectedRubric}
                            setSelectedRubric={setSelectedRubric}
                            previesCountAssignment={previesAssignment}
                            setPreviesAssignment={setPreviesAssignment}
                            setPreviesRubric={setPreviesRubric}
                            setFormAssignmentState={setFormAssignmentState}
                            updateFuntion={updateFuntion}
                            setUpdateFuntion={setUpdateFuntion}
                            isRubricConfirmModalData={isRubricConfirmModalData}
                            setIsRubricConfirmModalData={
                              setIsRubricConfirmModalData
                            }
                            afterConfirmation={afterConfirmation}
                            setAfterConfirmation={setAfterConfirmation}
                            isAssingmentConfirmModalData={
                              isAssingmentConfirmModalData
                            }
                            setIsAssingmentConfirmModalData={
                              setIsAssingmentConfirmModalData
                            }
                            callAddAssignmentFun={callAddAssignmentFun}
                            setCallAddAssignmentFun={setCallAddAssignmentFun}
                          />
                          <div className="all-editer-tools-section">
                            <div>
                              <span>
                                {isCopied ? (
                                  <i className="fa-solid fa-check"></i>
                                ) : (
                                  <i
                                    data-tooltip-id="copy-data-workboard"
                                    onClick={() =>
                                      onCopyClicked({
                                        answer: document.querySelector(
                                          ".editor-input"
                                        ).innerHTML,
                                      })
                                    }
                                    className="fa-regular fa-clipboard"
                                  ></i>
                                )}
                                <Tooltip
                                  id="copy-data-workboard"
                                  place="top"
                                  content="Copy"
                                  type="light"
                                  effect="float"
                                />
                              </span>
                            </div>
                            <span onClick={openModal}>
                              <i
                                className="fa-solid fa-download"
                                data-tooltip-id="download-data-workboard"
                              ></i>
                              <Tooltip
                                id="download-data-workboard"
                                place="top"
                                content="Download"
                                type="light"
                                effect="float"
                              />
                            </span>

                            {showDropdown && (
                              <div className="view-course-details-share-form">
                                <div
                                  className="view-course-details-share-folder"
                                  data-aos="zoom-in"
                                >
                                  <h2>Share Lesson</h2>
                                  <div className="view-course-details-inner-wrapper">
                                    <p>Share this link via</p>
                                    <div className="view-course-social-links-folder">
                                      {showDropdown && (
                                        <GoogleDocFile
                                          nameValue={formState.name}
                                        />
                                      )}
                                    </div>

                                    <i
                                      id="create-lesson-share-canle-btn"
                                      className="fa-solid fa-xmark shocial-cross-btn"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}

                            {showDownloadFile && (
                              <div
                                ref={modalRef}
                                className="Download-File-popup-wrappper"
                                id="create-lesson-downloode-wrapper"
                              >
                                <div
                                  ref={modalContentRef}
                                  className="Download-File-popup-container"
                                  data-aos="zoom-in"
                                >
                                  <div className="download-popup">
                                    <h2>Download File as</h2>
                                    <button
                                      className="close-btn"
                                      onClick={toggledownlodefile}
                                    >
                                      ×
                                    </button>
                                    <div className="input-section">
                                      <label>
                                        File Name *
                                        <input
                                          type="text"
                                          name="namefiletodnld"
                                          placeholder="Enter File Name"
                                          value={fileNameDownload}
                                          onChange={handleFileNameChange}
                                        />
                                        {fileNameError && (
                                          <p style={{ color: "red" }}>
                                            {fileNameError}
                                          </p>
                                        )}
                                      </label>
                                    </div>
                                    <div className="button-section">
                                      <button
                                        className="spreadsheet-btn"
                                        onClick={exportToWord}
                                      >
                                        Word File(.doc){" "}
                                        <i
                                          className="fa-solid fa-file-lines"
                                          style={{
                                            color: "#ffffff",
                                            fontSize: "16px",
                                          }}
                                        ></i>
                                      </button>
                                      <span>Or</span>
                                      <button
                                        className="pdf-btn"
                                        onClick={downloadAsPdf}
                                      >
                                        PDF (.pdf){" "}
                                        <i
                                          className="fa-solid fa-file-lines"
                                          style={{
                                            color: "#ffffff",
                                            fontSize: "16px",
                                          }}
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="editor-container">
                          <ToolbarPlugin 
                            showInputTooltip={showInputTooltip}
                            setShowInputTooltip={setShowInputTooltip}
                            latextextvalue={latextextvalue}
                            setLatextextvalue={setLatextextvalue}
                            oldMathNode={oldMathNode}
                            oldnodeeditor={oldnodeeditor}
                          />
                          <div className="editor-inner">
                            <RichTextPlugin
                              contentEditable={
                                <ContentEditable className="editor-input" />
                              }
                              placeholder={<Placeholder />}
                            // ErrorBoundary={LexicalErrorBoundary}
                            />
                            {activeWorkboard.name === "Rubric" ? (
                              <OnChangeRubricPlugin
                                onChangeRubricData={onChangeRubricData}
                                rubricCounttTwo={rubricCounttTwo}
                              />
                            ) : (
                              <OnChangePlugin />
                            )}
                            <HistoryPlugin />
                            <AutoFocusPlugin />
                            <ClearEditorPlugin />
                            <HtmlPlugin
                              initialHtml={formState?.description || ""}
                              onHtmlChanged={(e) => { }}
                            />
                            <CodeHighlightPlugin />
                            <ListPlugin />
                            <LinkPlugin />
                            <AutoLinkPlugin />
                            <ListMaxIndentLevelPlugin maxDepth={7} />
                            <MarkdownShortcutPlugin
                              transformers={TRANSFORMERS}
                            />
                          </div>
                        </div>
                        {lesson_id !== "" ? (
                          <div className="create-all-btns-container">
                            <button
                              onClick={(e) => {
                                let schoologystatus = false;
                                setschoologystatus(schoologystatus);
                                handleAssinmentRubricSubmit(e, schoologystatus);
                              }}
                              className="proc_btn_new"
                              style={{
                                width:
                                  activeWorkboard.name == "Assignment"
                                    ? "52%"
                                    : "100%",
                              }}
                              disabled={
                                isSaveChecker ||
                                (isAssignment
                                  ? workboards.ASSIGNMENT.data ===
                                  defaultWorkboardContent ||
                                  workboards.ASSIGNMENT.data === "" ||
                                  defaultWorkboardData.ASSIGNMENT?.data ===
                                  workboards.ASSIGNMENT?.data ||
                                  workboards.ASSIGNMENT.data ===
                                  onChangeAssignmentData[
                                  assignmentsCountTwo
                                  ] ||
                                  workboardText === ""
                                  : isRubric
                                    ? workboards.RUBRIC.data ===
                                    defaultWorkboardContent ||
                                    workboards.RUBRIC.data === "" ||
                                    defaultWorkboardData.RUBRIC?.data ===
                                    workboards.RUBRIC?.data ||
                                    workboards.RUBRIC.data ===
                                    onChangeRubricData[rubricCounttTwo] ||
                                    disabledRubric ||
                                    workboardText === ""
                                    : workboards.LESSON.data ===
                                    defaultWorkboardContent ||
                                    workboards.LESSON.data === "" ||
                                    defaultWorkboardData.LESSON?.data ===
                                    workboards.LESSON?.data) ||
                                workboardText === ""
                              }
                            >
                              {isAssignment
                                ? // ? `${onChangeAssignmentData[assignmentsCountTwo] ===
                                `${defaultWorkboardData.ASSIGNMENT?.data !==
                                  workboards.ASSIGNMENT?.data
                                  ? "Save"
                                  : "Changes Saved"
                                }`
                                : isRubric
                                  ? `${defaultWorkboardData.RUBRIC?.data !==
                                    workboards.RUBRIC?.data
                                    ? "Save"
                                    : "Changes Saved"
                                  }`
                                  : `${defaultWorkboardData.LESSON?.data !==
                                    workboards.LESSON?.data
                                    ? "Save"
                                    : "Changes Saved"
                                  }`}
                            </button>
                            {activeWorkboard.name == "Assignment" && (
                              <button
                                type="button"
                                disabled={
                                  isSaveChecker ||
                                  (isAssignment
                                    ? workboards.ASSIGNMENT.data === ""
                                    : workboards.RUBRIC.data === "")
                                }
                                className="publish-schoology"
                                onClick={(e) => {
                                  let schoologystatus = true;
                                  if (lesson_id !== "") {
                                    handleAssinmentRubricSubmit(
                                      e,
                                      schoologystatus
                                    );
                                    setschoologystatus(schoologystatus);
                                  } else {
                                    handleForm1Submit(e, schoologystatus);
                                    setschoologystatus(schoologystatus);
                                  }
                                }}
                              >
                                Publish on Schoology
                              </button>
                            )}
                            {isAssignment
                              ? onChangeAssignmentData[assignmentsCountTwo] ===
                              workboards?.ASSIGNMENT?.data && (
                                <button
                                  onClick={(e) => {
                                    navigate(-1);
                                  }}
                                >
                                  Back to Course
                                </button>
                              )
                              : isRubric
                                ? onChangeRubricData[rubricCounttTwo] ===
                                workboards?.RUBRIC?.data && (
                                  <button
                                    onClick={(e) => {
                                      navigate(-1);
                                    }}
                                  >
                                    Back to Course
                                  </button>
                                )
                                : (lesson.description ===
                                  workboards?.LESSON?.data ||
                                  lesson.data === workboards?.LESSON?.data) && (
                                  <button
                                    onClick={(e) => {
                                      navigate(-1);
                                    }}
                                  >
                                    Back to Course
                                  </button>
                                )}
                          </div>
                        ) : (
                          <button
                            disabled={
                              isSaveChecker ||
                              workboards.LESSON.data ===
                              defaultWorkboardContent ||
                              workboards.LESSON.data === "" ||
                              workboardText === ""
                            }
                            onClick={(e) => {
                              let schoologystatus = false;
                              setschoologystatus(schoologystatus);
                              handleForm1Submit(e, schoologystatus);
                            }}
                            className="proc_btn_new"
                          >
                            Proceed
                          </button>
                        )}
                      </div>
                    </Box>
                  </div>
                </div>
              </div>
            </div>

            {showCourseSuccessfully && (
              <div className="preview-lession-course-created-successfully-wrapper">
                <div className="Course-created-successfully-container">
                  <div className="Course-created-successfully-folder">
                    <div className="Course-created-successfully-image">
                      <img
                        src={Course_created_successfully_image}
                        alt="Course Created Successfully"
                      />
                    </div>
                    {getschoologystatus ? (
                      <h2>
                        {isAssignment
                          ? "Assignment "
                          : isRubric
                            ? "Rubric "
                            : "Lesson "}
                        has been{" "}
                        {(isAssignment
                          ? previesAssignment
                          : isRubric
                            ? previesRubric
                            : lesson_id !== "")
                          ? "pushed to Schoology"
                          : "pushed to Schoology"}{" "}
                        successfully
                      </h2>
                    ) : (
                      <h2>
                        {isAssignment
                          ? "Assignment "
                          : isRubric
                            ? "Rubric "
                            : "Lesson "}
                        has been{" "}
                        {(isAssignment
                          ? (previesAssignment ? (eachAssignment_id === null ? false : true) : false)
                          : isRubric
                            ? (previesRubric ? (eachRubric_id === null ? false : true) : false)
                            : lesson_id !== "")
                          ? "updated"
                          : "created"}{" "}
                        successfully
                      </h2>
                    )}
                  </div>
                </div>
              </div>
            )}
          </section>
        </LexicalComposer>
      </LocalizationProvider>
      {openStudentView && (
        <div className="preview-student-wrapper">
          <div
            style={{ padding: "30px 20px" }}
            className="Course-created-successfully-container"
          >
            {/* dismiss modal */}
            <div
              className="close-modal-btn-student-view"
              onClick={() => setopenStudentView(false)}
            >
              <a>
                <i className="fa-solid fa-xmark dismis-close"></i>
              </a>
            </div>

            <div
              style={{ height: "482px", zIndex: 999, overflow: "auto" }}
              className="Course-created-successfully-folder"
            >
              <h4>
                {isAssignment ? "Assignment" : isRubric ? "Rubric" : "Lesson"}
              </h4>
              <PreprocessAndRenderLatex
                content={preprocessLatex(previewDtls)}
                isBlockMath={true}
              />
            </div>
          </div>
        </div>
      )}
      <div
        className="rubric-popup-wrapper"
        style={{
          display: showRubricPopUp && !hideRubricPopUp ? "flex" : "none",
        }}
      >
        <div className="rubric-popup-container">
          <h2>
            In the absence of a teacher-created rubric, the system will generate
            a default one.
          </h2>
          <button
            type="button"
            className=""
            onClick={() => setHideRubricPopUp(true)}
          >
            Continue
          </button>
        </div>
      </div>
      <UniversalChatbot />
    </>
  );
};

export default NewLessons;

function NotSavePopUp({ isOpen, onClose, onConfirm }) {
  if (!isOpen) return null;

  return (
    <div className="save-assignment-popup-wrapper">
      <div className="save-assignment-popup-cotainer">
        <h2>You want to Save your Changes</h2>
        <div className="save-assignment-popup-btn">
          <button onClick={onConfirm}>Yes</button>
          <button onClick={onClose}>No</button>
        </div>
      </div>
    </div>
  );
}
