import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setPublishtoSchoology, setloading, seterror, resetPublishtoSchoology
} from "../reducers/publishtoSchoologyReducer";

const setpublishtoSchoologydtls = (data: any, token: any) => async (dispatch: Dispatch) => {
   dispatch(setloading(true));
   try {
     const result = await api
       .post(
        `/courses/push-overall-course-data-to-schoology/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
       );
       
       if(result){
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setPublishtoSchoology(result.data.message));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
       }
   }
   catch (error: any) {
    dispatch(seterror(error.response.data.message ?? "Something Went Wrong!"));
   }
}


export { setpublishtoSchoologydtls, resetPublishtoSchoology };

