import { format } from "date-fns";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../../../../api/api";
import {
  calender_image,
  email_image,
  gender_image,
  location_image,
  profile_image,
  studentDashboard_AI_chatbot_image,
} from "../../../../../../assets/images/images";
import { useAuth } from "../../../../../../features/auth/AuthContext";

import imgs from "../../../../../../assets/images/imgs";
import {
  isRequired,
  isValidEmail,
  isValidMobileNumber,
} from "../../../../../../utils/validator";
import "../../../../styles/dateTime.css";
import BackButton from "../../../../../../components/BackButton/BackButton";
import StudentProfileDetails from "./StudentProfileDetails";
const StudentProfileSetting = ({
  course,
  teacher_id,
  setTotalLessons,
  setTotalStudent,
}) => {
  

  const [activeTab, setActiveTab] = useState("lessons"); // Default active tab is 'lessons'
  const { token } = useAuth();
  const navigate = useNavigate();
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "students") {
      studentdetailsget(`1,4,5`);
    }
    if (tabName === "studentsRequest") {
      studentdetailsget(3);
    }
  };
  const [studentsRequest, setstudentsRequest] = useState([]);
  const [students, setstudents] = useState([]);
  const [lessondetails, setlessondetails] = useState(null);
  const [requestProcessMssg, setrequestProcessMssg] = useState(0);

  const studentdetailsget = (status) => {
    api
      .get(
        `courses/teacher/enroll-course-requests/?course_id=${course?.course_id}&status=${status}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          if (status === 1) {
            setstudents(response.data.data);
          }
          if (status === 3) {
            setstudentsRequest(response.data.data);
          }
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    api
      .get(`courses/teacher//?course_id=${course?.course_id}&status='1,4,5'`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          setstudents(response.data.data);
          setTotalStudent(response?.data?.data?.length);
        }
      })
      .catch((error) => {});
    api
      .get(`lessons/get-all-lessons/course/${course?.course_id}/`, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`, // `Token ${token}`
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setlessondetails(response.data.data);
          setTotalLessons(response?.data?.data?.length);
        }
      })
      .catch((error) => {
        //
      });
  }, []);

  const [showDropdown2, setShowDropdown2] = useState(false); // State to control visibility
  const [showreuestsuccesfully, setshowreuestsuccesfully] = useState(false);
  const toggleDropdown2 = () => {
    setShowDropdown2(!showDropdown2); // Toggle the visibility state
  };
  const handleAcceptReject = (status, student_id) => {
    setrequestProcessMssg(status);
    const formData = new FormData();
    formData.append("status", status);
    formData.append("course_id", course.course_id);
    formData.append("student_id", student_id);
    // Perform the API call using Axios
    const url = "courses/teacher/enroll-course-requests/";
    const headers = {
      Authorization: `Token ${token}`,
    };
    api
      .post(url, formData, { headers })
      .then((response) => {
        if (response.data.status == 200) {
          const newStudentsRequest = studentsRequest.filter(
            (item) => item.student_id !== student_id
          );
          setstudentsRequest(newStudentsRequest);
          setshowreuestsuccesfully(true);
          setTimeout(() => {
            setshowreuestsuccesfully(false);
          }, 1000);
        }
      })
      .catch((error) => {});
  };
  function formatDate(isoDate) {
    const date = new Date(isoDate);

    // Get individual date components
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // 'Jan', 'Feb', 'Mar' etc.
    const year = date.getFullYear();

    // Get the suffix for the day
    let suffix = "th";
    if (day % 10 === 1 && day !== 11) {
      suffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      suffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      suffix = "rd";
    }

    return `${day}${suffix} ${month}, ${year}`;
  }
  const onStudentViewProfile = (student) => {
    navigate(`/student/${student?.student_id}`);
  };

  const [selectedCountry, setSelectedCountry] = useState({
    name: "United States",
    iso2: "us",
    dialCode: "1",
    format: "+...-...-....",
    hasAreaCodes: false,
  });

  const { course_id, student_id } = useParams();

  return (
    <>
      <div className="TeacherProfile" id="TeacherProfile-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="TeacherProfile-wrapper">
                <div className="TeacherProfile-btn-container">
                  <button
                    className={`tab-button ${
                      activeTab === "lessons" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("lessons")}
                  >
                    Student Profile
                  </button>
                  <button
                    className={`tab-button ${
                      activeTab === "students" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("students")}
                  >
                    Courses Enrolled
                  </button>
                </div>
                <div className="tab-content">
                 
                  {activeTab === "students" && (
                    <div className="list-of-students-wrappper">
                      <table>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Id</th>
                            <th>Grade</th>
                            <th>Enrolled On</th>
                            <th>Overall Progress</th>
                            <th></th>
                          </tr>
                          <div className="list-of-students-gap"></div>
                        </thead>
                        <tbody>
                          {students.length > 0 ? (
                            students.map((student, index) => (
                              <tr key={index}>
                                <td
                                  onClick={() => {
                                    onStudentViewProfile(student);
                                  }}
                                >
                                  <img
                                    src={
                                      student?.student_profile_pic ||
                                      studentDashboard_AI_chatbot_image
                                    }
                                    alt="Student"
                                  />

                                  {student?.student_name}
                                </td>
                                <td>{student?.student_id}</td>
                                <td>{student?.student_grade}</td>
                                <td>{formatDate(student?.enrolled_on)}</td>
                                <td>
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      style={{ width: `30` }}
                                    ></div>
                                  </div>
                                </td>
                                <td>
                                  <a
                                    href="#"
                                    onClick={(e) => {
                                      e.preventDefault(); // This prevents the default anchor link behavior
                                      onStudentViewProfile(student);
                                    }}
                                  >
                                    View Profile
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5">No students records found.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentProfileSetting;
