import { useState, useEffect } from 'react';

const useSubmitAssignment = (socket, onMessageCallback) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const submitAssignment = (assignmentData) => {
    if (!socket) {
      setError("WebSocket is not connected.");
      return;
    }

    setIsLoading(true);

    try {
      socket.send(JSON.stringify(assignmentData));
    } catch (error) {
      setError("Failed to send data: " + error.message);
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   if (socket) {
  //     socket.onmessage = (event) => {
  //       setIsLoading(false);
  //     };

  //     socket.onerror = (event) => {
  //       setError("WebSocket error observed: " + event.message);
  //       setIsLoading(false);
  //     };
  //   }
  // }, [socket]);

  useEffect(() => {
    if (socket) {
      // Handle incoming WebSocket messages
      socket.onmessage = (event) => {
        setIsLoading(false);
        if (onMessageCallback) {
          onMessageCallback(event); // Pass the event to the callback
        }
      };

      // Handle WebSocket errors
      socket.onerror = (event) => {
        setError("WebSocket error observed: " + event.message);
        setIsLoading(false);
      };
    }
  }, [socket, onMessageCallback]);

  return { isLoading, error, submitAssignment };
};

export default useSubmitAssignment;
