import React, { useEffect, useRef, useState } from 'react';
import AWS from 'aws-sdk';
import { getFileIdByName, resetFileIdByName } from "../../state/actions/getFileIdByNameAction";
import { updateFileUrl, resetupdateFileUrl } from "../../state/actions/updateFileUrlAction";
import { getFileStatus, resetgetfilestatus } from "../../state/actions/getUploadFileStatusAction";
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../features/auth/AuthContext';
import { toast } from "react-toastify";
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import * as XLSX from 'xlsx';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { uplode_file } from '../../assets/images/images';

GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist/build/pdf.worker.min.js';

AWS.config.update({
    region: process.env.REACT_APP_AWS_REGION,
    credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    }
})

const myBucket = new AWS.S3({
    useAccelerateEndpoint: true
})

function AWSS3FileUpload({ selectedFiles, setSelectedFiles, setuploadedFileLink,setShowUploadPop }) {

    const [filesToUpload, setfilesToUpload] = useState([]);

    const { token } = useAuth();
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();

    const { fileList } = useSelector((state) => state.getFileIdByName);

    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    const checkPasswordProtection = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = async (e) => {
                try {
                    const typedArray = new Uint8Array(e.target.result);
                    const loadingTask = getDocument({ data: typedArray });

                    await loadingTask.promise.then(
                        () => resolve({ isProtected: false, isCorrupted: false }), // PDF is fine and not password-protected
                        (error) => {
                            if (error.name === 'PasswordException') {
                                resolve({ isProtected: true, isCorrupted: false }); // PDF is password-protected
                            } else if (error.name === 'InvalidPDFException') {
                                resolve({ isProtected: false, isCorrupted: true }); // PDF is corrupted or invalid
                            } else {
                                reject(error); // Some other error occurred
                            }
                        }
                    );
                } catch (error) {
                    reject(error); // Rejects with the error object if loading the PDF fails
                }
            };

            fileReader.readAsArrayBuffer(file);
        });
    };


    function checkExcelFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    XLSX.read(data, { type: 'array' });

                    // Successfully read, so likely not password-protected or corrupted
                    resolve({ isProtected: false, isCorrupted: false });
                } catch (error) {
                    if (error.message.includes('password-protected') || error.message.includes('File is password-protected')) {
                        resolve({ isProtected: true, isCorrupted: false });
                    } else {
                        resolve({ isProtected: false, isCorrupted: true });
                    }
                }
            };

            reader.onerror = () => {
                resolve({ isProtected: false, isCorrupted: true });
            };

            reader.readAsArrayBuffer(file);
        });
    }


    const checkWordFile = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function (e) {
                try {
                    const content = e.target.result;
                    const zip = new PizZip(content);
                    new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });

                    // If no errors are thrown, the file is likely not password-protected or corrupted
                    resolve({ isProtected: false, isCorrupted: false });
                } catch (error) {
                    resolve({ isProtected: true, isCorrupted: true }); // This might not be accurate
                }
            };

            reader.onerror = function (e) {
                reject(e);
            };

            reader.readAsArrayBuffer(file);
        });
    };


    function checkImageCorruption(file) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(false); // false indicates the image is not corrupted
            img.onerror = () => resolve(true); // true indicates the image might be corrupted

            const reader = new FileReader();
            reader.onload = e => img.src = e.target.result;
            reader.onerror = e => reject(e);
            reader.readAsDataURL(file);
        });
    }

    const readFileContent = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject(new Error('Failed to read the file.'));
            reader.readAsText(file);
        });
    };

    const checkCorruption = (content) => {
        // Check for binary data (non-printable characters) in a text file
        // This is a very basic check and might not cover all cases
        return /[\x00-\x08\x0E-\x1F]/.test(content);
    };

    const checkForProtection = (content) => {
        // Simple check for a custom marker at the beginning of the file
        // Adjust the logic based on how your application signifies protection
        return content.startsWith("PROTECTED:");
    };

    const handleFileChange = async (e) => {

        let incomingFiles = e.target.files;
        let updatedFiles = Array.from(selectedFiles);
        let newFiles = [];
        setShowUploadPop(false);
        let totalSize = updatedFiles.reduce((acc, file) => acc + file.size, 0);
        const allowedExtensions = ['pdf', 'docx', 'doc', 'xls', 'xlsx', 'csv', 'png', 'jpg', 'jpeg', 'txt'];

        for (let i = 0; i < incomingFiles.length; i++) {
            const file = incomingFiles[i];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                toast.error(`File type not supported: ${file.name}`);
                continue; // Skip this file
            }

            if (file.size === 0) {
                toast.error(`${file.name} seems corrupted.`);
                continue; // Skip this file
            }

            if (fileExtension === 'pdf') {
                try {
                    const { isProtected, isCorrupted } = await checkPasswordProtection(file);

                    if (isProtected) {
                        toast.error(`${file.name} is password protected.`);
                        continue; // Skip this file
                    } else if (isCorrupted) {
                        toast.error(`${file.name} is corrupted or invalid.`);
                        continue; // Skip this file as well
                    }
                    // Proceed with your logic, as the file is neither password protected nor corrupted
                } catch (error) {
                    toast.error(`Error checking ${file.name}: ${error.message}`);
                    // Handle the error appropriately, maybe continue to the next file or show an error message
                }
            }

            const spreadsheetExtensions = ["xlsx", "xls", "xlsm", "xlsb", "csv", "ods"];

            if (spreadsheetExtensions.includes(fileExtension)) {
                try {
                    const { isProtected, isCorrupted } = await checkExcelFile(file);

                    if (isProtected) {
                        toast.error(`${file.name} is password protected.`);
                        continue; // Skip this file
                    } else if (isCorrupted) {
                        toast.error(`${file.name} is corrupted or invalid.`);
                        continue; // Skip this file as well
                    }
                    // Proceed with your logic for valid, unprotected files
                } catch (error) {
                    toast.error(`Error processing ${file.name}: ${error.message}`);
                    continue; // Consider skipping this file due to unexpected error
                }
            }

            const docExtensions = ["doc", "docx"];

            if (docExtensions.includes(fileExtension)) {
                try {
                    const { isProtected, isCorrupted } = await checkWordFile(file);

                    if (isProtected) {
                        toast.error(`${file.name} is password protected or corrupted.`);
                        continue; // Skip this file
                    }
                } catch (error) {
                    console.error("Error processing the file:", error);
                }
            }

            const imageExtensions = ["png", "jpg", "jpeg"];

            if (imageExtensions.includes(fileExtension)) {
                try {
                    const isCorrupted = await checkImageCorruption(file);

                    if (isCorrupted) {
                        toast.error(`${file.name} is corrupted or invalid.`);
                        continue; // Skip this file as it's corrupted
                    }
                    // Proceed with your logic, as the file is not corrupted
                } catch (error) {
                    toast.error(`Error checking ${file.name}: ${error.message}`);
                    // Handle the error appropriately, maybe continue to the next file or show an error message
                }
            }

            if (fileExtension === "txt") {
                try {
                    const fileContent = await readFileContent(file);

                    const isCorrupted = checkCorruption(fileContent);
                    if (isCorrupted) {
                        toast.error(`${file.name} appears to be corrupted.`);
                        continue; // Skip this file
                    }

                    const isProtected = checkForProtection(fileContent);
                    if (isProtected) {
                        toast.error(`${file.name} appears to be password protected.`);
                        continue; // Skip this file
                    }

                    // File is neither corrupted nor "protected", proceed with further logic
                } catch (error) {
                    toast.error(`Error processing ${file.name}: ${error.message}`);
                }
            }


            if (updatedFiles.length < 2 && totalSize + file.size <= 40 * 1024 * 1024) {
                updatedFiles.push(file);
                newFiles.push(file)
                totalSize += file.size;
            } else {
                toast.error("You can only upload 2 files with a total file size of 40MB.");
                break; // Stop checking files as the limit is reached
            }
        }
        // Update the state only if the conditions are met
        if (updatedFiles.length <= 2 && totalSize <= 40 * 1024 * 1024) {
            setSelectedFiles(updatedFiles);
            setfilesToUpload(newFiles);
        } else {
            e.target.value = null; // Reset the input value if the condition is not met
        }
    };

    const getFileIdByNameRequest = () => {
        const req = {
            file_name: filesToUpload.map((f) => f.name)
        }
        dispatch(getFileIdByName(req, token));
    }

    const handleFileStatus = (file_id) => {
        dispatch(getFileStatus(file_id, token))
    }
 
    const handleUpload = async () => {
        if (!filesToUpload) {
            alert('Please choose a file to upload first.');
            return;
        }

        //multiple files start

        const multipartUploadThreshold = 5 * 1024 * 1024; // 5 MB, adjust as needed

        const uploads = Array.from(filesToUpload).map(async (file) => {
            const dateTimePrefix = new Date().toISOString().replace(/[^0-9]/g, "");
            const fileNameParts = file.name.split(".");
            const newFilename = `${fileNameParts[0]}-${dateTimePrefix}.${fileNameParts.pop()}`;

            if (file.size <= multipartUploadThreshold) {
                const uploadParams = {
                    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
                    Key: newFilename,
                    Body: file,
                    ContentType: file.type, // Corrected to use the file's type
                };
                return myBucket.upload(uploadParams).promise();
            } else {
                // Multipart upload
                const multipartParams = {
                    Bucket: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
                    Key: newFilename,
                    ContentType: file.type,
                };
                const multipartUpload = await myBucket.createMultipartUpload(multipartParams).promise();
                const partSize = 5 * 1024 * 1024;
                const partsCount = Math.ceil(file.size / partSize);
                const uploadPartsPromises = [];

                for (let i = 1; i <= partsCount; i++) {
                    const start = (i - 1) * partSize;
                    const end = i * partSize;
                    const partParams = {
                        Body: file.slice(start, end),
                        Bucket: multipartParams.Bucket,
                        Key: multipartParams.Key,
                        PartNumber: i,
                        UploadId: multipartUpload.UploadId,
                    };
                    uploadPartsPromises.push(myBucket.uploadPart(partParams).promise());
                }

                const uploadedParts = await Promise.all(uploadPartsPromises);
                const completeParams = {
                    Bucket: multipartParams.Bucket,
                    Key: multipartParams.Key,
                    MultipartUpload: {
                        Parts: uploadedParts.map((part, index) => ({
                            ETag: part.ETag,
                            PartNumber: index + 1,
                        })),
                    },
                    UploadId: multipartUpload.UploadId,
                };

                return myBucket.completeMultipartUpload(completeParams).promise();
            }
        });

        try {
            const results = await Promise.all(uploads);
            let newArr = results.map(itemA => {
                // Extract the base file name from the key in array a
                let baseFileNameA = itemA.Key.split('-')[0];

                // Find the corresponding object in array b
                const itemB = fileList.find(itemB => itemB.original_name.startsWith(baseFileNameA));

                // Return a new object combining the information, or null if no match is found
                return itemB ? {
                    location: itemA.Location,
                    key: itemA.key,
                    name: itemB.original_name,
                    file_id: itemB.file_id,
                } : null;
            }).filter(item => item !== null);  // Filter out any null entries if no match was found

            if (newArr.length) {
                const req = {
                    file_upload: newArr.map((f) => ({
                        file_id: f.file_id,
                        link: f.location,
                    }))
                }

                dispatch(updateFileUrl(req, token))

                newArr.map((arr) => { handleFileStatus(arr.file_id); }
                )
            }

            setuploadedFileLink(prev => [
                ...prev,
                ...newArr,
            ]);
            setfilesToUpload([]);
            dispatch(resetFileIdByName());
        } catch (error) {
            console.error('Error uploading files:', error);
        }

        //multiple files end
    };


    useEffect(() => {
        if (fileList.length) {
            handleUpload();
        }
    }, [fileList])

    useEffect(() => {
        if (filesToUpload.length) {
            getFileIdByNameRequest();
        }
    }, [filesToUpload])

    useEffect(() => {
        dispatch(resetgetfilestatus());
    }, [])

    useEffect(() => {
        return () => {
            setSelectedFiles([]);
            dispatch(resetFileIdByName());
            dispatch(resetupdateFileUrl());
            dispatch(resetgetfilestatus());
        }
    }, [])

    return (
        <div className="google-drive-popup-folder"  onClick={handleIconClick} style={{ cursor: "pointer" }}>
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                accept=".pdf,.doc,.docx,.xlsx,.xls,.jpg,.jpeg,.png,.txt"
                onChange={handleFileChange}
            />

            <div className="google-drive-image" >
                <img src={uplode_file} />
            </div>
            <p>Upload from Computer</p>
        </div>
    )
}

export default AWSS3FileUpload