import { isAfter } from "date-fns";
import moment from "moment";
import validator from "validator";

const bullet = "•";
function isRequired(value, label = "field", isBullet = false) {
  return value
    ? ""
    : label === "field"
    ? "• This field is required"
      : `Enter ${label}.`;
}

function checkifDateisAfterToday(date) {
  const errors = [];
  const today = moment().format("YYYY-MM-DD");
  const dateToCheck = moment(date).format("YYYY-MM-DD");
  if (dateToCheck < today) {
    errors.push("• Expired date");
  }
  return errors.length > 0 ? errors.join(" ") : "";
}

function isMaxChar(value, char = 1000) {
  const errors = [];
  if (value.length >= char) {
    errors.push("Description should not exceed 1000 characters.");
  }

  return errors.length > 0 ? errors.join(" ") : "";
}

function isAfterDate(start_date, end_date) {
  const errors = [];
  if (start_date === null) {
    errors.push("• Please select start date.");
  }
  if (end_date === null) {
    errors.push("• Please select end date.");
  }
  if (!isAfter(start_date, end_date)) {
    errors.push("• End date must be after start date.");
  }
  return errors.length > 0 ? errors.join(" ") : "";
}
function isValidEmail(email, isBullet = false) {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email)
    ? ""
    : `${isBullet ? bullet : ""} Please enter valid email address.`;
}

const isValidDomain = (domain, isBullet = false) => {
  const re = /^https:\/\/([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
  return re.test(domain)
    ? ""
    : `${isBullet ? bullet : ""} Please enter valid Url address including https://`;
};

function isValidPassword(password, isBullet = false) {
  const errors = [];

  if (password?.length < 8) {
    errors.push(
      `${isBullet ? bullet : ""} Password must be at least 8 characters long.`
    );
  }

  if (!/[A-Z]/.test(password)) {
    errors.push(
      `${
        isBullet ? bullet : ""
      } Password must contain at least one uppercase letter.`
    );
  }

  if (!/[a-z]/.test(password)) {
    errors.push(
      `${
        isBullet ? bullet : ""
      } Password must contain at least one lowercase letter.`
    );
  }

  if (!/\d/.test(password)) {
    errors.push(
      `${isBullet ? bullet : ""} Password must contain at least one digit.`
    );
  }

  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    errors.push(
      `${
        isBullet ? bullet : ""
      } Password must contain at least one special character.`
    );
  }

  return errors.length > 0 ? errors.join(" ") : "";
}
function isSamePassword(password, confirm_password, isBullet = false) {
  const errors = [];
  if (password !== confirm_password) {
    errors.push(
      `${isBullet ? bullet : ""}Password and confirm password must be same.`
    );
  }
  return errors.length > 0 ? errors.join(" ") : "";
}
function isValidMobileNumber(mobile_number, country_code) {
  const errors = [];
  const number = `+${country_code}${mobile_number}`;
  if (!validator.isMobilePhone(number)) {
    errors.push("Please enter valid mobile number.");
  }
  return errors.length > 0 ? errors.join(" ") : "";
}
// Validation function to check if the new password is different from the current password
const isDifferentFromCurrentPassword = (currentPassword, newPassword) => {
  const errors = [];
  if (currentPassword === newPassword) {
    errors.push("New password must be different from current password.");
  }
  return errors.length > 0 ? errors.join(" ") : "";
};

const parseKaTeX = (katex) => {
  // Extending the basic parser to handle more symbols and expressions
  let processedText = katex.replace(/\$\$/g, '');

  // Then proceed with replacing mathematical symbols and expressions
  processedText = processedText.replace(/\$/g, '')
    // Basic arithmetic and algebra
    .replace(/f\(([^)]+)\)/g, 'f of $1')
    .replace(/\\frac{([^}]+)}{([^}]+)}/g, '($1) divided by ($2)')
    .replace(/\\sqrt{([^}]+)}/g, 'the square root of ($1)')
    .replace(/\\times/g, ' multiplied by ')
    .replace(/\\div/g, ' divided by ')
    .replace(/\^2/g, ' squared')
    .replace(/\^3/g, ' cubed')
    .replace(/\^{([^}]+)}/g, ' raised to the power of $1')
    .replace(/\\left\(/g, '(')
    .replace(/\\right\)/g, ')')
    .replace(/&#39;/g, "'")
    .replace(/\\pi/g, 'pi')
    .replace(/\\theta/g, 'theta')
    .replace(/\\sigma/g, 'sigma')
    .replace(/\\lambda/g, 'lambda')
    .replace(/\\sum/g, 'sum of ')
    .replace(/\\int/g, 'integral of ')
    .replace(/\\infty/g, 'infinity')
    .replace(/\\pm/g, ' plus or minus ')
    .replace(/\\mp/g, ' minus or plus ')
    .replace(/\\cdot/g, ' dot ')
    .replace(/\\leq/g, ' less than or equal to ')
    .replace(/\\geq/g, ' greater than or equal to ')
    .replace(/\\neq/g, ' not equal to ')
    .replace(/\\approx/g, ' approximately equal to ')
    .replace(/\\rightarrow/g, ' implies ')
    .replace(/\\sin/g, 'sine of ')
    .replace(/\\cos/g, 'cosine of ')
    .replace(/\\tan/g, 'tangent of ')
    .replace(/\\log/g, 'logarithm of ')
    .replace(/\\ln/g, 'natural logarithm of ')
    .replace(/\\exp/g, 'exponential of ')
    .replace(/\\rightarrow/g, 'tends to ')
    .replace(/\\lim/g, 'limit ')
    .replace(/\+/g, ' plus ')
    .replace(/-/g, ' minus ')
    .replace(/\\prime/g, ' prime ');

  return processedText;
};

export {
  checkifDateisAfterToday, isAfterDate,
  isDifferentFromCurrentPassword,
  isMaxChar,
  isRequired,
  isSamePassword, isValidDomain, isValidEmail, isValidMobileNumber, isValidPassword, parseKaTeX
};
