import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import StudentLessonChatbot from "../../../../components/studentLessonChatbot/StudentLessonChatbot";
import AnswerInput from "./AnswerInput";
import MCQs from "./MCQs";
import PreprocessAndRenderLatex from "../../../../components/ProcessRenderLatex";
import UplodeFile from "../../../../components/UplodeFile/UplodeFile";
import { assignment_view_icon } from "../../../../assets/images/images";
import PreviewPopup from "../../../../components/PreviewPopup/PreviewPopup";
import DrawingCanvas from "../../../DrawingCanvas";
import { useAuth } from "../../../../features/auth/AuthContext";
// import { marked } from "marked";

function Question({
  duration,
  assignment,
  index,
  currentStep,
  handleNext,
  handleBack,
  isLast,
  lesson,
  course_id,
  lesson_id,
  assignment_id,
  isTeacher,
  timers,
  setTimers,
  virtualAssistant,
  currentAssignment
}) {
  const { token } = useAuth();
  const [selectedOption, setSelectedOption] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [extractedText, setExtractedText] = useState(null);
  const [openWorkboard, setOpenWorkboard] = useState(false);
  const [convertedText, setConvertedText] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);





  useEffect(() => {
    if (!isTeacher) {
      const sAPDtls_AssignmentId = localStorage.getItem(`sAPDtls_AssignmentId${assignment_id}${token}`);

      if (sAPDtls_AssignmentId && sAPDtls_AssignmentId == assignment_id) {
        const sAProgressDtls = JSON.parse(localStorage.getItem(`studentAssignmentProgressOnSelect${assignment_id}${token}`)) || [];
        if (sAProgressDtls[currentStep]) {
          const currentAnswer = sAProgressDtls[currentStep].student_answer;
          if (!assignment.options || assignment.options.length === 0) {

            setSelectedOption(currentAnswer);
          } else {
            if (currentAssignment.type === 'MCQ' || currentAssignment.type === 'TF') {
              const matchingOptionIndex = assignment.options.findIndex((option) => option === currentAnswer);
              setSelectedOption(matchingOptionIndex);
            } else {
              const matchingOptionIndex = currentAnswer ? currentAnswer : null;
              setSelectedOption(matchingOptionIndex);
            }
          }
        } else {
          setSelectedOption(null);
        }
      }
    }

  }, [assignment, currentStep, assignment_id, token]);

  useEffect(() => {
    if (extractedText) {
      setSelectedOption(extractedText);
      if (!isTeacher) {
        updateLocalStorage(currentStep, extractedText);
      }
    }
  }, [extractedText]);

  const updateLocalStorage = (step, answer) => {
    const sAProgressDtls = JSON.parse(localStorage.getItem(`studentAssignmentProgressOnSelect${assignment_id}${token}`)) || [];

    while (sAProgressDtls.length <= step) {
      sAProgressDtls.push({});
    }

    sAProgressDtls[step] = { ...sAProgressDtls[step], student_answer: answer };
    localStorage.setItem(`studentAssignmentProgressOnSelect${assignment_id}${token}`, JSON.stringify(sAProgressDtls));
  };

  const handleOptionChange = (type, value, index = null) => {


    if (type === 'MCQ' || type === 'TF') {
      setSelectedOption(index);

      const answer = assignment.options ? assignment.options[index] : index;
      if (!isTeacher) {
        updateLocalStorage(currentStep, answer);
      }
    } else {
      setSelectedOption(value);
      if (!isTeacher) {
        updateLocalStorage(currentStep, value);
      }
    }
  };



  const handleNextClick = () => {
    if (assignment?.type === 'FITB') {
      if (selectedOption) {

        const count = (assignment.question.match(/_+/g) || []).length;
        if (count === 0) {
          const trimmedString = selectedOption.trim();
          const wordsLength = trimmedString.split(' ').filter(word => word !== '').join(' ').length;

          if (50 < wordsLength) {
            toast.error(`No more than 50 characters allowed`)
            return;
          }
        } else {
          const trimmedString = selectedOption.trim();
          const wordsLength = trimmedString.split(' ').filter(word => word !== '').join(' ').length;
          const maxCharacters = 50 * count

          if (maxCharacters < wordsLength) {
            toast.error(`No more than ${maxCharacters} characters allowed`)
            return;
          }
        }
      }
    }
    if (
      (selectedOption === -1 || selectedOption === null ||
        selectedOption === undefined ||
        (typeof selectedOption === "string" && selectedOption.trim() === "")) &&
      !isTeacher
    ) {
      if (assignment?.type === 'MCQ' || assignment?.type === 'TF') {
        toast.error("Please select an option before proceeding.");
      } else {
        toast.error("Before continuing, please ensure the question is answered.");
      }
      return;
    } else {
      if (!isTeacher) {
        if (!timers && !timers[index]) {
          setTimers((prevTimers) => {
            const newTimers = prevTimers ? [...prevTimers] : [];
            newTimers[index] = 0;
            return newTimers;
          });
        }
      }
      const responseData = {
        ...assignment,
        time_taken: timers ? timers[index] : 0,
        student_answer: assignment.options?.length > 0 ? assignment.options[selectedOption] : selectedOption,
      };

      handleNext(responseData);
    }
  };

  function switchQuestionView() {
    switch (assignment?.type) {
      case "FITB":
        return (
          <AnswerInput
            value={selectedOption || ""}
            onChange={(event) => handleOptionChange(assignment?.type, event.target.value)}
          />
        );
      case "MCQ":
      case "TF":
        return (
          <MCQs
            options={assignment.options}
            selectedOption={selectedOption}
            setSelectedOption={(index) => handleOptionChange(assignment?.type, null, index)}
          />
        );
      default:
        return (
          <AnswerInput
            value={selectedOption || ""}
            onChange={(event) => {
              handleOptionChange(assignment?.type, event.target.value);
              setExtractedText(event.target.value);
            }}
          />
        );
    }
  }

  const showWorkboard = () => setOpenWorkboard(true);
  const openPopup = () => setIsPopupVisible(true);
  const closePopup = () => setIsPopupVisible(false);
  const handleCanvaCancel = () => setOpenWorkboard(false);

  async function convertDrawingToText(imageData) {
    try {
      const response = await fetch("https://api.mathpix.com/v3/text", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          app_id: "Webarcitech_621a57", // Replace with your Mathpix app ID
          app_key: "7d36ed55c7649574c24f", // Replace with your Mathpix app key
        },
        body: JSON.stringify({
          src: imageData,
          formats: ["text"],
          math_inline_delimiters: ["$", "$"],
          rm_spaces: true,
        }),
      });
      const data = await response.json();
      setExtractedText(data.text);
      setSelectedOption(data.text || "");
    } catch (error) {
      console.error("Error converting drawing:", error);
      setExtractedText("Error in conversion");
    }
  }
  function escapeHtmlEntities(text) {
    if (text === "") {
      return text; // Return empty string as is
    }
    return text
      ?.replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/_/g, "&#95;"); // Escape underscores
  }
  return currentStep === index ? (
    <>
      <div className="AssingmentMCQs-mock-test-question-answer-container">
        <h2>
          <span className="AssingmentMCQs-question">Q. {index + 1}:</span>
          <span className="AssingmentMCQs-answers">
            <PreprocessAndRenderLatex
              content={escapeHtmlEntities(assignment?.question?.replace(
                /(\\\()(.*?)(\\\))/g,
                (match, p1, p2, p3) => `$$${p1}${p2}${p3}$$`
              ) || `" "`)}
            />
          </span>
        </h2>
        {switchQuestionView()}
        <div className="AssingmentMCQs-mock-test-answer-btn-folder">
          <button onClick={handleBack}>
            <span className="AssingmentMCQs-mock-test-answer-left-arrow-btn">
              <i className="fa-solid fa-arrow-left"></i>
            </span>
            Back
          </button>
          {isLast ? (
            !isTeacher ? (
              <button onClick={handleNextClick}>Submit
                <span className="AssingmentMCQs-mock-test-answer-right-arrow-btn">
                  <i className="fa-solid fa-arrow-right"></i>
                </span>
              </button>
            ) : (
              ''
            )

          ) : (
            <button onClick={handleNextClick}>Next
              <span className="AssingmentMCQs-mock-test-answer-right-arrow-btn">
                <i className="fa-solid fa-arrow-right"></i>
              </span>
            </button>
          )


          }
        </div>
      </div>

      {openWorkboard && (
        <DrawingCanvas
          onConvert={convertDrawingToText}
          oncancel={handleCanvaCancel}
        />
      )}

    </>
  ) : null;
}

export default Question;