import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Proschool_logo,
  book_logo3,
  half_circle_bg_image,
  half_circle_bg_image2,
  terms_condition_image,
  terms_condition_image_title
} from "../../assets/images/images";
import HomeNavbar from "../home/components/HomeNavbar";

const TermsandCondition = () => {
  const navigate = useNavigate();
  const globalState = useSelector((state) => state.globalState);
  const subadmin_details = globalState.subadmin_details;
  const handleClick = (e) => {
    e.preventDefault(); // Prevent default link behavior

    // Navigate to the home page
    navigate("/");

    // Use setTimeout to delay the scroll to the specific section
    setTimeout(() => {
      const element = document.getElementById("revolution_sec_new");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // Adjust the timeout as needed
  };

  return (
    <>
      <HomeNavbar />
      <div className="Terms-and-Conditions-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="Terms-and-Conditions-wrapper">
                <div className="Terms-and-Conditions-image-title">
                  <img src={terms_condition_image_title} />
                </div>
                <div className="Terms-and-Conditions-image">
                  <img src={terms_condition_image} />
                </div>
                <div className="Terms-and-Conditions-list-wrapper">
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>1. Acceptance of Terms</h2>
                    <p>
                      By accessing or using our Learning Management System (LMS)
                      platform, you agree to comply with and be bound by these
                      terms and conditions. If you do not agree to these terms,
                      please refrain from using our services.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>2. Description of Services</h2>
                    <p>
                      Our LMS platform serves as a marketplace connecting
                      educational organizations and individuals, providing a
                      range of tools and features integrated with advanced AI
                      technology to enhance teaching and learning experiences.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>3. Privacy Policy</h2>
                    <p>
                      We prioritize the privacy of our users. We collect basic
                      information such as names and email IDs for security
                      purposes and gather background data to tailor the learning
                      experience using AI. This information is used exclusively
                      for educational purposes.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>4. User Responsibilities</h2>
                    <p>
                      - School Administrators: Administrators have the sole
                      authority to input students' details, including names,
                      email addresses, and phone numbers. - Teachers: Teachers
                      can engage with students via the platform but do not have
                      access to add personal student details. - Independent
                      Students: Independent students can self-register by
                      providing their personal information on the registration
                      page.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>5. AI Integration for Personalized Learning</h2>
                    <p>
                      Our AI system customizes lessons for students, ensuring an
                      engaging and effective learning journey. Students can
                      interact with the AI chatbots of individual teachers,
                      enhancing the learning process's interactivity and
                      personalization.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>6. Assessment and Counseling Through AI</h2>
                    <p>
                      Upon completion of assignments, quizzes, or tests, our AI
                      system provides an objective assessment based on the
                      student’s performance and background. The AI acts as a
                      counselor, identifying areas for academic enhancement.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>7. Intellectual Property</h2>
                    <p>
                      All content provided on the platform, including courses,
                      lessons, and assessments, is the intellectual property of
                      the respective creators. Users are prohibited from
                      reproducing, distributing, or otherwise using this content
                      without explicit permission.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>8. User Conduct</h2>
                    <p>
                      Users are expected to adhere to ethical and respectful
                      conduct when using the platform. Any misuse, abuse, or
                      violation of these terms may result in the suspension or
                      termination of the user's account.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>9. Limitation of Liability</h2>
                    <p>
                      We are not liable for any direct, indirect, incidental,
                      special, or consequential damages arising out of the use
                      or inability to use our services.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>10. Modification of Terms</h2>
                    <p>
                      We reserve the right to modify these terms and conditions
                      at any time. Users will be notified of significant
                      changes, and continued use of the platform after such
                      modifications constitutes acceptance of the revised terms.
                    </p>
                  </div>
                  <div className="Terms-and-Conditions-list-foldre">
                    <h2>11. Contact Information.</h2>
                    <p>
                      For any queries or a detailed walkthrough of our platform,
                      please contact us at ______________
                    </p>
                  </div>
                </div>
                <div className="half-circle-bg-image">
                  <img src={half_circle_bg_image} />
                </div>
                <div className="half-circle-bg-image2">
                  <img src={half_circle_bg_image2} />
                </div>
                <div className="book-logo3">
                  <img src={book_logo3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-section" id="footer-section">
        <div className="footer-wraper">
          <div className="footer-link-folder">
            <div className="proschool-logo">
              <img src={subadmin_details ? subadmin_details?.school_image : Proschool_logo} /> <span>Beta</span>
            </div>
          </div>
          <div className="footer-Quick-Links-folder">
            <h2>Quick Links</h2>
            <ul className="footer-Quick-Links">
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <a href="revolution_sec_new" onClick={handleClick}>
                  Features
                </a>
              </li>
              <li>
                <NavLink to="/about">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/terms-and-conditions">Terms & Conditions</NavLink>
              </li>
              <li>
                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              </li>
            </ul>
          </div>
          <div className="footer-Quick-Links-folder">
            <h2>Contact</h2>
            <div className="footer-contact">
              <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-phone"></i>
                </a>
                <span>
                <a href="tel:+1 (646) 222-3901">{subadmin_details ? subadmin_details?.phone : '+1 (646) 222-3901'}</a>
                </span>
              </div>
              <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-envelope"></i>
                </a>
                {subadmin_details ? (
                    <a href={`mailto:${subadmin_details?.email}`}>{subadmin_details.email}</a>
                ) : (
                    <a href="mailto: info@proschool.ai">info@proschool.ai</a>
                )}
                
                
              </div>
              <div className="footer-contact-folder">
                <a>
                  <i class="fa-solid fa-location-dot"></i>
                </a>
                {subadmin_details ? (
                  subadmin_details?.location
                ) : (
                  <a
                    href="https://www.google.com/maps/place/Arcitech+-+AI+Software,+Web,+App+Development/@19.0020445,72.8229651,16z/data=!4m10!1m2!2m1!1sA-802,+2nd+floor,+Trade+Link,+Kamala+Mills,+Lower+Parel,+Mumbai,+Maharashtra+400013!3m6!1s0x3be7ce9336955555:0x743645ef3c0b1a05!8m2!3d19.0033511!4d72.8274615!15sClNBLTgwMiwgMm5kIGZsb29yLCBUcmFkZSBMaW5rLCBLYW1hbGEgTWlsbHMsIExvd2VyIFBhcmVsLCBNdW1iYWksIE1haGFyYXNodHJhIDQwMDAxM1pPIk1hIDgwMiAybmQgZmxvb3IgdHJhZGUgbGluayBrYW1hbGEgbWlsbHMgbG93ZXIgcGFyZWwgbXVtYmFpIG1haGFyYXNodHJhIDQwMDAxM5IBEHNvZnR3YXJlX2NvbXBhbnmaASNDaFpEU1VoTk1HOW5TMFZKUTBGblNVTkxiMHBEVm1ObkVBReABAA!16s%2Fg%2F11lt56gqrs?entry=ttu"
                    target="_blank"
                  >
                    A-802, 2nd floor, Trade Link, Kamala Mills, Lower Parel,
                    Mumbai, Maharashtra 400013
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="footer-get-in-touch-folder">
            <span>Get in touch</span>
            <div className="footer-social-link-folder">
              <a href="https://www.facebook.com/proschoolai/" target="_blank">
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a href="https://twitter.com/Proschoolai" target="_blank">
                <i class="fa-brands fa-x-twitter"></i>
              </a>
              <a href="https://www.instagram.com/proschoolai/" target="_blank">
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a
                href="https://www.linkedin.com/company/proschool-ai"
                target="_blank"
              >
                <i class="fa-brands fa-linkedin"></i>
              </a>
              <a href="https://www.youtube.com/@ProSchoolAI" target="_blank">
                <i class="fa-brands fa-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsandCondition;
