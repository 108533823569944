import { Dispatch } from "redux";
import api from "../../api/api";
import {
  setSchoolList,
  setloading,
  seterror,
  setPagination,
  resetSchoolList,
} from "../reducers/getAllSchoolsReducer";

const getAllSchoolList = (page: any, token: any) => async (
  dispatch: Dispatch
) => {
  dispatch(setloading(true));
  try {
    const result = await api.get(`/super-admin/get-all-school/`, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`,
      },
    });

    if (result) {
      dispatch(setloading(false));
      if (result.data?.status === 200) {
        dispatch(setSchoolList(result.data.data));
        dispatch(setPagination(result.data));
      } else {
        dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
      }
    }
  } catch (error: any) {
  }
};

export { getAllSchoolList, resetSchoolList}; 

