import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import api from "../../../../../../api/api";
import {
    alert_image,
    graduation,
    Teacher_Dashboard_Default,
    dashborad_Courses_book_image,
    Asset_teacher,
    Asset_teacher2,
  } from "../../../../../../assets/images/images";
  import { updateGlobalState } from "../../../../../../state/global/slice";
  import AOS from "aos";
import "aos/dist/aos.css";
import CreateCourse from "../course/CreateCourse";
import Card from "../course/Card";
import { toast } from "react-toastify";
import MyCourses from "./MyCourses";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Grid } from "@mui/material";
import { getAllCoursesByTeacherId } from "../../../../../../state/actions/getAllCoursesByTeacherIdAction";

const TeacherHome = ({
    handlePageChange,
    totalPages,
    currentPage,
    nextUrl,
    prevUrl,
    showCourse,
    setShowCourse,
    toggleCourse,
    getDashboardCount,
    courseLoading,
  setgetDashboardCount,
  dashboardCountLoading,
  setdashboardCountLoading,
  }) => {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [deleteCourse, setdeleteCourse] = useState(false);
    const globalState = useSelector((state) => state.globalState);
    const dispatch = useDispatch();
    const updateState = (key, value) => {
      dispatch(updateGlobalState({ key: key, value: value }));
    };
    const [loading, setLoading] = useState(false);
  
    const toggleDeleteCourse = () => {
      setdeleteCourse(!deleteCourse);
      setLoading(false);
      document.body.style.overflow = "auto";
    };
    const teacher_id = globalState?.teacher_details?.data?.teacher_id;

    const [modalIsOpen, setIsOpen] = React.useState(false);
  
    function openModal() {
      setIsOpen(true);
    }
  
    function closeModal() {
      setIsOpen(false);
    }
    const [selectedCourse, setSelectedCourse] = useState(null);
  
    // Handle file input
    const deleteCourseId = async () => {
      setLoading(true);
      document.body.style.overflow = "auto";
      try {
  
        const response = await api.delete(
          `/courses/delete-course/${selectedCourse}/`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );
        if (response.data.status === 200) {
          setdashboardCountLoading(true)
          api
            .get("/auth/teacher-dashboard-count/", {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
              },
            })
            .then((response) => {
              if (response.status === 200) {
                if (response?.data) {
                  setdashboardCountLoading(false)
                  setgetDashboardCount(response?.data);
                }
              }
            })
            .catch((error) => { setdashboardCountLoading(false) });

          setLoading(false);
          
          const course_list = globalState.course_list?.filter(
            (course) => course.course_id !== selectedCourse
          );
          updateState("course_list", course_list);
          dispatch(getAllCoursesByTeacherId("1", teacher_id, token));
       
          setTimeout(() => {
            setdeleteCourse(false);
            toast.success("Course deleted successfully.", {
              position: toast.POSITION.TOP_CENTER,
            });
          }, 700);
        } else {
          setLoading(false);
          setdeleteCourse(false);
          toast.error("Something went wrong.", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        setLoading(false);
        setdeleteCourse(false);
      }
    };
  
    useEffect(() => {
      AOS.init({
        duration: 500,
      });
    }, []);
  
    return (
      <>
        <div className="dashboard-main-section">
          <h2 className="dashboard-name-title">
            Hello {globalState?.teacher_details?.data?.name}!
          </h2>
          <div className="dashborad-Courses-container">
            <div className="dashborad-Courses-folder"
              style={{ cursor: dashboardCountLoading ? "progress" : "pointer" }}
              onClick={
                dashboardCountLoading ? () => { } :
                  () => getDashboardCount?.data?.total_courses > 0 ? navigate(`/course-list`, { state: { totalPages: totalPages } }) : toggleCourse("hidden")}>
              <div className="dashborad-Courses-folder-image">
                <img src={dashborad_Courses_book_image} alt="" />
              </div>
              <div className="dashborad-Courses-count">
                <h2>{getDashboardCount?.data?.total_courses}</h2>
                <p>
                  {getDashboardCount?.data?.total_courses > 1
                    ? " Total Courses"
                    : " Total Course"}{" "}
                </p>
              </div>
            </div>
            <div className="dashborad-Courses-folder"
              style={{ cursor: dashboardCountLoading ? "progress" : "pointer" }}
              onClick={dashboardCountLoading ? () => { } : () => getDashboardCount?.data?.total_courses > 0 ? navigate(`/lesson-list`) : toggleCourse("hidden")}>
              <div className="dashborad-Courses-folder-image">
                <img src={Asset_teacher2} className="asset_book_image" alt="" />
              </div>
              <div className="dashborad-Courses-count">
                <h2>{getDashboardCount?.data?.total_lessons}</h2>
                <p>
                  {" "}
                  {getDashboardCount?.data?.total_lessons > 1
                    ? "Total Lessons"
                    : "Total Lesson"}{" "}
                </p>
              </div>
            </div>
            <div className="dashborad-Courses-folder"
              style={{ cursor: dashboardCountLoading ? "progress" : "pointer" }}
              onClick={dashboardCountLoading ? () => { } : () => getDashboardCount?.data?.total_courses > 0 ? navigate(`/assignment-list`) : toggleCourse("hidden")} >
              <div className="dashborad-Courses-folder-image">
                <img src={Asset_teacher} className="asset_book_image" alt="" />
              </div>
              <div className="dashborad-Courses-count">
                <h2>{getDashboardCount?.data?.total_assignments}</h2>
                <p>
                  {" "}
                  {getDashboardCount?.data?.total_assignments > 1
                    ? "Total Assignments"
                    : "Total Assignment"}
                </p>
              </div>
            </div>
            <div className="dashborad-Courses-folder"
              style={{ cursor: dashboardCountLoading ? "progress" : "pointer" }}
              onClick={dashboardCountLoading ? () => { } : () => getDashboardCount?.data?.total_courses > 0 ? navigate(`/studnet-list`) : toggleCourse("hidden")}>
              <div className="dashborad-Courses-folder-image">
                <img src={graduation} alt="" />
              </div>
              <div className="dashborad-Courses-count">
                <h2>{getDashboardCount?.data?.enrolled_students_count}</h2>
                <p>
                  {getDashboardCount?.data?.enrolled_students_count > 1
                    ? "Total Students"
                    : "Total Student"}
                </p>
              </div>
            </div>
          </div>
          <div className="Start-by-creating-your-course-container">
            <p>Teaching Starts with You</p>
            <button type="button" onClick={() => toggleCourse("hidden")}>
              Create Course
            </button>
          </div>
          <div className="created-my-courses-wrapper">
            {totalPages > 1 && <h2 className="my-course-title">My Courses</h2>}
  
            {globalState?.course_list === null ||
            globalState?.course_list?.length === 0 ? (
              <>
                <Card
                  image={Teacher_Dashboard_Default}
                  title="Craft an Interactive Course"
                  description="Crafting an interactive course involves designing engaging lessons with quizzes and activities to actively involve students in the learning process"
                />
                <Card
                  image={Teacher_Dashboard_Default}
                  title="Build an Exciting Learning Journey"
                  description="Crafting an interactive course involves designing engaging lessons with quizzes and activities to actively involve students in the learning process"
                />
              </>
            ) : ( courseLoading ? 
                  <Grid
                  height={"50vh"}
                  xs={12}
                  container
                  justifyContent={"center"}
                  alignItems={"center"}
              >
                  <CircularProgress size={80} />
              </Grid> :
                <MyCourses
                  totalPages={totalPages}
                  currentPage={currentPage}
                  nextUrl={nextUrl}
                  prevUrl={prevUrl}
                  handlePageChange={handlePageChange}
                  setSelectedCourse={setSelectedCourse}
                  closeModal={closeModal}
                  modalIsOpen={modalIsOpen}
                  openModal={openModal}
                  toggleDeleteCourse={toggleDeleteCourse}
                />
            )}
          </div>
  
          {deleteCourse && (
            <form action="" className="delete-course-form">
              <div className="delete-course-form-container" data-aos="zoom-in">
                <div className="delete-course-alert-image">
                  <img src={alert_image} alt="" />
                </div>
                <h2>Are you sure you want to delete this course?</h2>
                <div className="delete-course-form-btn-folder">
                  <button
                    type="button"
                    onClick={toggleDeleteCourse}
                    className="not-delete-btn"
                  >
                    No
                  </button>
                  {loading ? (
                    <div className="loading-spinner" />
                  ) : (
                    <button
                      type="button"
                      onClick={deleteCourseId}
                      disabled={loading}
                      className="delete-btn"
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
  
          {/* Course created form start */}
          {showCourse && (
            <CreateCourse
              setShowCourse={setShowCourse}
              toggleCourse={toggleCourse}
            />
          )}
        </div>
      </>
    );
  };

  export default TeacherHome;