import { TextNode } from 'lexical';
import katex from 'katex';
import 'katex/dist/katex.min.css';

export class MathNode extends TextNode {
  static getType() {
    return 'math';
  }

  static clone(node) {
    return new MathNode(node.__text, node.__key);
  }

  createDOM(config) {
    const dom = document.createElement('span');
    dom.setAttribute('data-lexical-key', this.__key);
    const latex = this.getTextContent();
    dom.className = `math-node $$${latex}$$`;
    katex.render(latex, dom, {
        throwOnError: false
    });
    return dom;
  }

  updateDOM(prevNode, dom) {
    // const currentLatex = this.getTextContent();
    // const previousLatex = prevNode.getTextContent();
  
    // // If the LaTeX content has changed, update the DOM
    // if (currentLatex !== previousLatex) {
    //   katex.render(currentLatex, dom, {
    //     throwOnError: false
    //   });
    //   return true; // Return true to indicate DOM update
    // }
  
    return false; // No update necessary
  }

  static importJSON(serializedNode) {
    const node = new MathNode(serializedNode.text, serializedNode.key);
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: 'math',
      text: this.getTextContent()
    };
  }

  exportDOM(editor) {
    const element = document.createElement('span');
    element.className = 'math-node';
    element.innerHTML = `$$${this.getTextContent()}$$`; 
    return { element };
  }

  static importDOM() {
    return {
      span: (domNode) => {
        if (domNode.classList.contains('math-node') || containsLatex(domNode.textContent)) {
          return {
            conversion: convertMathAndTextNodes,
            priority: 1,
          };
        }
        return null;
      },
    };
  }
}

function convertMathAndTextNodes(domNode) {
  const nodes = [];
  let textContent = domNode.textContent;
  
  // Split text based on LaTeX math expressions
  const parts = textContent.split(/(\$\$.*?\$\$)/);

  parts.forEach((part) => {
    if (containsLatex(part)) {
      // If part is a LaTeX expression
      const latex = extractLatex(part);
      nodes.push(new MathNode(latex)); // Convert LaTeX to MathNode
    } else if (part.trim().length > 0) {
      // Handle regular text
      nodes.push(new TextNode(part.trim())); // Convert text to TextNode
    }
  });

  return {
    node: nodes.length === 1 ? nodes[0] : nodes, // Return single or multiple nodes
  };
}

// Helper function to detect if the content contains $$LaTeX$$
function containsLatex(text) {
  return /\$\$(.*?)\$\$/.test(text);
}

// Helper function to extract LaTeX content from $$...$$
function extractLatex(text) {
  const match = text.match(/\$\$(.*?)\$\$/);
  return match ? match[1] : '';
}
