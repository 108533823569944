import React from 'react';
import { course_demo_image, search, srot } from "../../assets/images/images"; 
import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import MarketPlaceCourseCard from './MarketPlaceCourseCard';
import BackButton from '../BackButton/BackButton';

function MarketPlaceCourse() {
    const navigate= useNavigate();

    return (
        <>  
           <div className='MarketPlaceCourse-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <BackButton buttonName="Marketplace" />
                            <div className="search_header-container">
                                <div className="search-list">
                                <ReactSVG className="search-icon" src={search} />
                                <input type="text" className="search-input" placeholder="Search" />
                                </div>
                                <div className="filter-wrapper">
                                <div className="lessonviewprogress-filder-tab">
                                    <span>
                                    <i className="fa-solid fa-sliders"></i>
                                    </span>
                                    <p>Filter</p>
                                </div>
                                <div className="lessonviewprogress-filder-tab">
                                    <span>
                                        <i class="fa-solid fa-arrow-down-wide-short"></i>
                                    </span>
                                    <p>Sort</p>
                                </div>
                                </div>
                            </div>
                            <div className='ratingCard-wrapper'>
                                <MarketPlaceCourseCard
                                    course_demo_image = {course_demo_image}
                                    title= "Rehan blackman"
                                    rate= "4.1"
                                    number= "(322)"
                                    Course_name= "Science"
                                    course_title= "Fundamental of Neuroscience"
                                    price = "$20"
                                    button_name1= "View Course"
                                    button_name2= "Enroll"
                                />
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </>
    )
}

export default MarketPlaceCourse;