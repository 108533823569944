import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import api from "../../../../../api/api";
import imgs from "../../../../../assets/images/imgs";
import MutipleFloatingChatBot from "../../../../../components/MutipleFloatingChatBot";
import Welcome from "../../../../../components/popup/student/Welcome";
import { useAuth } from "../../../../../features/auth/AuthContext";
import { updateGlobalState } from "../../../../../state/global/slice";
import "../../../styles/dashboard.css";
import "../../../styles/r-style.css";
import StudentPopupSection from "../student/StudentDashboard_popup";
import StudentDashboardChatbot from "./chatbot/StudentChatbot";
import MyCourses from "./tabs/MyCourse";
import MyTeacher from "./tabs/MyTeacher";
import StudentHome from "./tabs/StudentHome";
import { changeScrollPopup } from "../../../../../utils/utlis";

const StudentDashboard = () => {
  const { token } = useAuth();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.globalState);
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const profileName =
    globalState?.role === "Student"
      ? globalState?.student_details?.data?.name ?? globalState?.role
      : globalState?.teacher_details?.data?.name ?? globalState?.role;
  const tabList = [
    { key: "Dashboard", route: "home", value: imgs.svg.dashboard_sidebar },
    { key: "My Courses", route: "my-courses", value: imgs.svg.book },
    { key: "My Teacher", route: "my-teacher", value: imgs.svg.sms_sidebar },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const pathParts = location.pathname.split("/");
  const lastPathPart = pathParts[2];
  const getActiveTabState = lastPathPart.length ? tabList.find((tab) => tab.route == lastPathPart).key : tabList[0].key;
  const [activeTab, setActiveTab] = useState(getActiveTabState);
  const [teacherChat, setTeacherChat] = useState(null);
  const [getTeacherActiveChat, setTeacherActiveChat] = useState([]);
  const [chatbotTeacherStatus, setTeacherchatbotStatus] = useState(false);

  const redirectPath = localStorage.getItem("redirectAfterSignup");
  const redirectToCourse = localStorage.getItem("redirectToCourse");

  const [activeTeacher, setActiveTeacher] = useState([]);

  const handleTeacherClick = (teacher) => {
    if (teacher !== activeTeacher) {
      setActiveTeacher(teacher);
    }
  };

  const updateTeacherActiveChat = (teacher) => {
    // Check if the teacher is already in the array
    const teacherExists = getTeacherActiveChat.some(
      (t) => t.name === teacher.name
    );

    if (!teacherExists) {
      // If the teacher is not in the array, add them
      const newTeacherActiveChat = [...getTeacherActiveChat, teacher];

      // Limit the array to a maximum length of 3
      if (newTeacherActiveChat.length > 3) {
        newTeacherActiveChat.shift();
      }

      // Update the state with the new array to trigger re-render
      setTeacherActiveChat(newTeacherActiveChat);
    }
  };

  const removeTeacherActiveChat = (teacher) => {
    const newTeacherActiveChat = [...getTeacherActiveChat];
    const teacherIndex = newTeacherActiveChat.indexOf(teacher);

    if (teacherIndex !== -1) {
      newTeacherActiveChat.splice(teacherIndex, 1);
    }
    if (getTeacherActiveChat.length === 1) {
      setTeacherchatbotStatus(false);
    }

    setTeacherActiveChat(newTeacherActiveChat);
  };

  const onClickTeacher = (teacher) => {
    // Check if the teacher is already active

    const isTeacherActive = getTeacherActiveChat.some(
      (activeTeacher) => activeTeacher.name === teacher.name
    );

    if (!isTeacherActive) {
      updateTeacherActiveChat({ ...teacher, isMinimized: false });
    }
    handleTeacherClick(teacher);
    setTeacherChat(teacher);
    setTeacherchatbotStatus(true);

    // Rest of your logic here
    const course_id = teacher.first_course?.[0]?.course_id || null;
    if (course_id !== null) {
      toast.error("This teacher never created any course.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
  };
  const onClickCourse = (teacher) => {
    updateTeacherActiveChat(teacher);
    const course_id =
      teacherChat?.first_course[0]?.course_id !== null
        ? teacherChat?.first_course[0]?.course_id ?? null
        : null;
    if (course_id !== null) {
      toast.error("This teacher never created any course.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    setTeacherchatbotStatus(false);
    setTeacherChat(teacher);
  };
  const [showTooltip, setShowTooltip] = useState(false);
  const [WelcomePopupStatus, setWelcomePopupStatus] = useState(
    globalState?.has_profile || false
  );
  const [ProfilePopupStatus, setProfilePopupStatus] = useState(false);
  const getProfileDetails = async () => {
    api
      .get("/students/get-student/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.data.status === 200) {
          updateState("student_details", response?.data ?? {});
          setWelcomePopupStatus(true);
          setTimeout(() => {
            setWelcomePopupStatus(false);
            updateState('has_profile', true);
          }, 5000);
          setProfilePopupStatus(false);
          api
            .get("/auth/get-teacher-list/", {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
              },
            })
            .then((response) => {
              if (response.data.status === 200) {
                updateState("get_teacher_list", response?.data ?? {});
              }
            })
            .catch((error) => {});
          if (redirectPath !== null && redirectPath !== "") {
            navigate(redirectPath);
            localStorage.removeItem("redirectAfterSignup");
          }
          if (redirectToCourse !== null && redirectToCourse !== "") {
            navigate(redirectToCourse);
            localStorage.removeItem("redirectToCourse");
          }
        } else {
          setWelcomePopupStatus(false);
          setProfilePopupStatus(true);
        }
      })
      .catch((error) => {
        setWelcomePopupStatus(false);
        setProfilePopupStatus(true);
      });
  };

  useEffect(() => {
    getProfileDetails();
    api
      .get("/courses/student/my-courses-dashboard/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          updateState("student_courses", response?.data?.data ?? []);
        }
      })
      .catch((error) => {
        //
      });
  }, [token]);

  useEffect(() => {
    AOS.init({
      duration: 500,
    });
  }, []);

  console.warn('student1');
  

  const myCoursesCount = globalState?.my_courses_length;
  useEffect(() => {
    if (WelcomePopupStatus === true) {
      changeScrollPopup("hidden", "welcome-polly-popup-wrapper");
    } else {
      changeScrollPopup("auto", "welcome-polly-popup-wrapper");
    }
  }, [WelcomePopupStatus]);
  return (
    <>
      {WelcomePopupStatus === true && !(globalState?.has_profile === true) && (
        <Welcome
          setWelcomePopupStatus={setWelcomePopupStatus}
          setShowTooltip={setShowTooltip}
        />
      )}
      {ProfilePopupStatus === true && (
        <StudentPopupSection
          ProfilePopupStatus={ProfilePopupStatus}
          setProfilePopupStatus={setProfilePopupStatus}
          setWelcomePopupStatus={setWelcomePopupStatus}
          setShowTooltip={setShowTooltip}
        />
      )}
      <div className="dashboard">
        <div className="studentDashboardbody">
          <div className="studentDashboardSidePannel">
            {tabList.map((tab, index) => {
              return (
                <Link
                  key={index}
                  to={`/dashboard/${tab.route.toLowerCase()}`}
                  onClick={() => {
                    setActiveTab(tab.key);
                  }}
                  className={(activeTab === tab.key ? "active" : "") + " dashboardlink"} 
                >
                    <ReactSVG
                      src={
                        (activeTab === tab.key || activeTab === tab.route) &&
                        tab.route === "my-courses"
                          ? imgs.svg.book_active
                          : tab.value
                      }
                      className={
                        activeTab === tab.key || activeTab === tab.route
                          ? "svg-active"
                          : "svg-inactive"
                      }
                    />
                    {tab.key}
                    {tab.key === "My Courses"}
                </Link>
              );
            })}
          </div>
          <div className="studentDashboardMainBody">
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard/home" />} />
              <Route
                path="home"
                element={
                  <StudentHome
                    showTooltip={showTooltip}
                    teacherChat={teacherChat}
                    setTeacherChat={setTeacherChat}
                    setShowTooltip={setShowTooltip}
                    profileFirstName={profileName}
                    chatbotTeacherStatus={chatbotTeacherStatus}
                    setTeacherchatbotStatus={setTeacherchatbotStatus}
                    getTeacherActiveChat={getTeacherActiveChat}
                    setTeacherActiveChat={setTeacherActiveChat}
                    onClickTeacher={onClickTeacher}
                    activeTeacher={activeTeacher}
                    handleTeacherClick={handleTeacherClick}
                    removeTeacherActiveChat={removeTeacherActiveChat}
                    setActiveTab={setActiveTab}
                  />
                }
              />
              <Route
                path="my-courses/*"
                element={
                  <MyCourses
                    setShowTooltip={setShowTooltip}
                    profileFirstName={profileName}
                    chatbotTeacherStatus={chatbotTeacherStatus}
                    setTeacherchatbotStatus={setTeacherchatbotStatus}
                    getTeacherActiveChat={getTeacherActiveChat}
                    onClickCourse={onClickCourse}
                    setTeacherActiveChat={setTeacherActiveChat}
                    onClickTeacher={onClickTeacher}
                    removeTeacherActiveChat={removeTeacherActiveChat}
                  />
                }
              />
              <Route path="my-teacher" element={<MyTeacher />} />
            </Routes>
          </div>
        </div>

        <div className="StudentChatbot-section">
          <div
            className={
              getTeacherActiveChat.length > 0
                ? "studentDashboard-chatbot-wrapper"
                : ""
            }
          >
            {getTeacherActiveChat.map((teacher, index) => (
              <div key={index}>
                {chatbotTeacherStatus && (
                  <StudentDashboardChatbot
                    key={index}
                    removeTeacherActiveChat={() =>
                      removeTeacherActiveChat(teacher)
                    }
                    right={index * 30}
                    ai_name={`${teacher.ai_name}`}
                    teacher_id={teacher?.user}
                    courseId={
                      teacher?.first_course
                        ? teacher?.first_course?.course_id ?? ""
                        : ""
                    }
                    isMinimize={teacher.isMinimized}
                    chatbotStatus={chatbotTeacherStatus}
                    lesson_name={
                      teacher?.first_lesson
                        ? teacher?.first_lesson?.lesson_name ?? ""
                        : ""
                    }
                    ai_tone={teacher?.ai_tone}
                    ai_avatar={teacher?.ai_avatar}
                    ai_welcome_msg={teacher?.ai_welcome_msg}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <MutipleFloatingChatBot
        teacherChat={teacherChat}
        onClickTeacher={onClickTeacher}
        getTeacherActiveChat={getTeacherActiveChat}
      />
    </>
  );
};

export default StudentDashboard;
