import { Dispatch } from "redux";
import api from "../../api/api";

const updateSchoolId = (data: any,token:any) => async (dispatch: Dispatch) => {
   try {
     const result = await api
       .put(
        `auth/update-school-id/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`
          },
        }
       )
       
       if(result){
        if (result.data?.status === 200) {
          }
          else {
          }
       }
   }
   catch (error: any) {
    // dispatch(seterror(error.response.data.message));
   }
}

export {updateSchoolId};
