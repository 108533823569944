import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  course_bg_image,
  course_info_image,
} from "../../../assets/images/images";
import CourseCard from "../../../components/CourseCard/CourseCard";
import PaginationView from "../../../components/Pagination/PaginationView";
import { useAuth } from "../../../features/auth/AuthContext";
import {
  getCourseBySchoolId,
  resetCourseListBySchoolId,
} from "../../../state/actions/getCourseListBySchoolIDAction";
import "./SchoolCourseCard.css";

const SchoolCourseCard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { token } = useAuth();
  const dispatch = useDispatch();
  const { courses, count, next, previous, totalPages, error } = useSelector(
    (state) => state.getCoursesbySchoolId
  );
  const { role } = useSelector((state) => state.globalState);

  const param = useParams();

  const getCourseBySchoolIdRequest = () => {
    dispatch(getCourseBySchoolId(role, param.school_id, token));
  };

  useEffect(() => {
    getCourseBySchoolIdRequest();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetCourseListBySchoolId());
    };
  }, []);

  const courseData = [
    {
      courseimage: course_bg_image,
      coursename: "Fundamental of Neuroscience",
      enrolled_students: "0 Student",
      lesson_count: "1 Lesson",
      start_date: "2023-12-06",
      end_date: "Dec 14, 2023",
      course_info_image: course_info_image,
      Teacher_name: "Rehan blackman",
      Teacher_title: "Teacher",
      Sales_Courses_title: "Sales Courses",
      checkbox: "checkbox",
      location: <i class="fa-solid fa-location-dot"></i>,
      location_name: "New Jersey, United States",
      description:
        "Embark on a fascinating journey through time with our course on the History of Maharashtra, designed specifically for 5th and 6th grade students. This course will transport you to the vibrant past of Maharashtra, exploring its rich heritage, influential leaders, and significant events. Through engaging lessons, interactive activities, and captivating stories, students will gain a comprehensive understanding of Maharashtra's historical timeline. This exploration of the past will not only enhance their knowledge but also instill a deep appreciation for their cultural roots.Embark on a fascinating journey through time with our course on the History of Maharashtra, designed specifically",
    },
    {
      courseimage: course_bg_image,
      coursename: "Fundamental of Neuroscience",
      enrolled_students: "0 Student",
      lesson_count: "1 Lesson",
      start_date: "2023-12-06",
      end_date: "Dec 14, 2023",
      course_info_image: course_info_image,
      Teacher_name: "Rehan blackman",
      Teacher_title: "Teacher",
      Sales_Courses_title: "Sales Courses",
      checkbox: "checkbox",
      location: <i class="fa-solid fa-location-dot"></i>,
      location_name: "New Jersey, United States",
      description:
        "Embark on a fascinating journey through time with our course on the History of Maharashtra, designed specifically for 5th and 6th grade students. This course will transport you to the vibrant past of Maharashtra, exploring its rich heritage, influential leaders, and significant events. Through engaging lessons, interactive activities, and captivating stories, students will gain a comprehensive understanding of Maharashtra's historical timeline. This exploration of the past will not only enhance their knowledge but also instill a deep appreciation for their cultural roots.Embark on a fascinating journey through time with our course on the History of Maharashtra, designed specifically",
    },
  ];

  return (
    <>
      <div className="School_Course_Card">
        <div className="School_Course_Card_wrapper">
          <div className="container-fluid">
            <div className="col-lg-12 col-md-12">
              <div className="School_Course_Card_container">
                {courses?.map((data, index) => (
                  <CourseCard
                    key={index}
                    courseimage={data.courseimage}
                    coursename={data.coursename}
                    enrolled_students={data.enrolled_students}
                    lesson_count={data.lesson_count}
                    start_date={data.start_date}
                    end_date={data.end_date}
                    description={data.description}
                    course_info_image={data.course_info_image}
                    Teacher_title={data.Teacher_title}
                    Teacher_name={data.Teacher_name}
                    location={data.location}
                    location_name={data.location_name}
                  />
                ))}
                {!courses.length && (
                  <p style={{ textAlign: "center" }}>No Courses Found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {totalPages > 1 && (
        <PaginationView
          totalPages={totalPages}
          next={next}
          previous={previous}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
export default SchoolCourseCard;
