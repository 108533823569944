// AuthContext.js
import Cookies from 'js-cookie';
import React, { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetGlobalState, updateGlobalState } from '../../state/global/slice.tsx';
import { QueryClient } from 'react-query';
import { persistor } from '../../state/global/store.tsx';

const AuthContext = createContext();

export function AuthProvider({ children }) {
    const dispatch = useDispatch();
    const updateState = (key, value) => {
        dispatch(updateGlobalState({ key: key, value: value }));
    };
    const [token, setToken] = useState(Cookies.get('auth_token') || null);
    const login = (newToken, role, email, school_id = null) => {
        setToken(newToken);
        updateState("role", role);
        updateState("email", email);
        updateState("school_id", school_id);
        Cookies.set('auth_token', newToken, { expires: 7 }); // Store token in a cookie for 7 days
    };

    const logout = () => {
        setToken(null);
        dispatch(resetGlobalState());
        localStorage.removeItem("student_formState");
        localStorage.removeItem("teacher_formState");

        sessionStorage.clear()

        Cookies.remove('auth_token');

        persistor.purge();

        if ('caches' in window) {
            caches.keys().then(function (cacheNames) {
                // Iterate over all cache names
                cacheNames.forEach(function (cacheName) {
                    // Delete each cache
                    caches.delete(cacheName).then(function (success) {
                        if (success) {
                            console.log(`Cache ${cacheName} deleted successfully.`);
                        } else {
                            console.log(`Cache ${cacheName} could not be deleted.`);
                        }
                    });
                });
            }).catch(function (error) {
                console.error('Error fetching cache names:', error);
            });
        }

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {

                for (let registration of registrations) {
                    registration.unregister();
                }
            });
        }
        indexedDB.databases().then((databases) => {

            databases.forEach((db) => {
                indexedDB.deleteDatabase(db.name);
            });
        });

    };

    return (
        <AuthContext.Provider value={{ token, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
}

export function useAuth() {
    return useContext(AuthContext);
}
