// import { Waveform } from "@uiball/loaders";
// import { marked } from "marked";
// import React, { useEffect, useRef, useState } from "react";
// import {
//   getTextToSpeech,
//   resetTextToSpeech,
// } from "../../state/actions/getTextToSpeechAction";
// import Dictaphone from "../../../src/pages/speechtotext/SpeechtoText";
// import {
//   go_btn,
//   studentDashboard_AI_chatbot_clodse_image,
//   studentDashboard_Small_AI_chatbot_image
// } from "../../assets/images/images";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   checkifDateisAfterToday,
//   isRequired,
//   parseKaTeX,
// } from "../../utils/validator";
// import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
// import { useAuth } from "../../features/auth/AuthContext";
// import "./CustomChatbot.css";

// function CustomChatbot(props) {
//   const {
//     chatHistory,
//     ai_welcome_msg,
//     ai_avatar,
//     ai_name,
//     school_name,
//     onClose,
//     onMinimize,
//     socketUrl,
//     socketPayload,
//     roomId
//   } = props;
//   const { token } = useAuth();
//   const {
//     isListening,
//     transcript,
//     startListening,
//     stopListening,
//   } = Dictaphone({ continuous: true, interimResults: true, lang: "en-US" });
//   const [getSocket, setSocket] = useState(null);
//   const [isReconnecting, setIsReconnecting] = useState(false);
//   const [reconnectAttempts, setReconnectAttempts] = useState(0);


//   const texttospeech = useSelector((state) => state.texttospeech);
//   const [chatAudioId, setchatAudioId] = useState();
//   const dispatch = useDispatch();


//   const [chatHist, setChatHist] = useState(
//     chatHistory !== null && chatHistory?.length > 0
//       ? chatHistory
//       : [
//         {
//           query: "",
//           answer:
//             ai_welcome_msg !== undefined
//               ? ai_welcome_msg
//               : "Hi, I am your Ai Assistant, How may I help you?",
//           uuid: null
//         },
//       ]
//   );

//   const messagesEndRef = useRef(null);
//   const [aichatbotquery, setAichatbotquery] = useState("");


//   const generateAIVoice = (chat, id, wholeChat) => {
//     setchatAudioId(id);



//     let lessonData = parseKaTeX(chat);

//     const req = {
//       text: lessonData,
//       generator_id: id,
//     };
//     dispatch(getTextToSpeech(req, "generator", "Ruth", token));
//   };

//   useEffect(() => {
//     return () => {
//       dispatch(resetTextToSpeech());
//     };
//   }, []);


//   const startStopListening = () => {
//     // console.log(transcript)
//     if (isListening) {
//       stopVoiceInput();
//     } else {
//       startListening();
//     }
//   };

//   const stopVoiceInput = () => {
//     stopListening();
//     setAichatbotquery(aichatbotquery ? aichatbotquery + (transcript ? " " + transcript : "") : transcript)
//   };

//   const handleTextareaChange = (e) => {
//     setAichatbotquery(e.target.value);
//   };
//   const renderer = new marked.Renderer();
//   const originalLinkRenderer = renderer.link;
//   renderer.link = (href, title, text) => {
//     const html = originalLinkRenderer.call(renderer, href, title, text);
//     return html.replace(/^<a /, '<a target="_blank" rel="noopener noreferrer" ');
//   };

//   const handlechatbotSubmit = async (e) => {
//     const history = chatHist.map((chat) => {
//       return {
//         query: chat.query,
//         answer: Array(chat.answer)
//           ?.map((item) => item)
//           .join(""),
//       };
//     });
//     try {
//       if (aichatbotquery !== "") {
//         if (getSocket) {
//           const newPayload = {
//             ...socketPayload,
//             query: aichatbotquery,
//             messages: history ?? [],
//             school_name: school_name ?? "SRDS",
//           };
//           const payloadJSON = JSON.stringify(newPayload);
//           setChatHist((prevChatHist) => {
//             if (Array.isArray(prevChatHist)) {
//               return [...prevChatHist, { query: newPayload.query, answer: "" }];
//             } else {
//               return [{ query: newPayload.query, answer: "" }];
//             }
//           });

//           getSocket.send(payloadJSON);
//         }
//       }
//     } catch (error) {
//       console.error("Error sending message:", error);
//     }
//     setAichatbotquery("");
//   };

//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   };

//   let combinedMessage = [];

//   // useEffect(() => {
//   //   if (!roomId) return;

//   //   const maxReconnectInterval = 30000;
//   //   const reconnectDecay = 1.5;
//   //   const maxReconnectAttempts = 3;
//   //   let reconnectInterval = 1000;
//   //   let reconnectAttempts = 0;
//   //   let combinedMessage = [];

//   //   // Ensure only one socket connection is established at a time
//   //   if (getSocket && getSocket.readyState === WebSocket.OPEN) {
//   //     return;
//   //   }

//   //   let socket = new WebSocket(socketUrl);
//   //   setSocket(socket);

//   //   socket.onopen = () => {
//   //     reconnectAttempts = 0;
//   //     reconnectInterval = 1000;
//   //   };

//   //   socket.onmessage = (event) => {
//   //     try {
//   //       const data = JSON.parse(event.data);

//   //       if (data && data.message) {
//   //         const message = data.message;
//   //         combinedMessage.push(message);
//   //         let status = false;
//   //         let chatdatares = combinedMessage.join("");

//   //         if (
//   //           chatdatares.includes("<STARTOFTURN>") ||
//   //           chatdatares.includes("<ENDOFTURN>")
//   //         ) {
//   //           chatdatares = chatdatares
//   //             .replace(/<STARTOFTURN>/g, "")
//   //             .replace(/<ENDOFTURN>/g, "");

//   //           if (combinedMessage.join("").includes("<ENDOFTURN>")) {
//   //             status = true;
//   //             combinedMessage.length = 0;
//   //             combinedMessage.push(chatdatares);
//   //           }
//   //         }
//   //         setChatHist((prevChatHist) => {
//   //           const updatedChatHist = Array.isArray(prevChatHist)
//   //             ? [...prevChatHist]
//   //             : [];
//   //           if (updatedChatHist.length > 0) {
//   //             const lastQuery = updatedChatHist[updatedChatHist.length - 1];
//   //             if (lastQuery) {
//   //               lastQuery.answer = chatdatares;
//   //             }
//   //           }
//   //           if (status) {
//   //             combinedMessage.length = 0;
//   //           }
//   //           return updatedChatHist;
//   //         });
//   //         scrollToBottom();
//   //       }
//   //     } catch (error) {
//   //       console.error("Error parsing message:", error);
//   //     }
//   //   };

//   //   socket.onerror = (error) => {
//   //     console.error("WebSocket error:", error);
//   //   };

//   //   socket.onclose = () => {
//   //     if (reconnectAttempts < maxReconnectAttempts) {
//   //       setTimeout(() => {
//   //         reconnectAttempts++;
//   //         if (socket.readyState !== 3) {
//   //           socket.close(); // Ensure old socket is closed before creating a new one
//   //         }
//   //         socket = new WebSocket(socketUrl);
//   //         reconnectInterval = Math.min(reconnectInterval * reconnectDecay, maxReconnectInterval);
//   //         setSocket(socket);
//   //       }, reconnectInterval);
//   //     }
//   //   };

//   //   return () => {
//   //     if (socket.readyState === WebSocket.OPEN) {
//   //       socket.close();
//   //     }
//   //   };
//   // }, [roomId, socketUrl]);

//   useEffect(() => {
//     if (!roomId) return;

//     const maxReconnectAttempts = 3;
//     const maxReconnectInterval = 30000;
//     const reconnectDecay = 1.5;
//     let reconnectInterval = 1000;

//     const initializeWebSocket = () => {
//       let combinedMessage = [];
//       // Ensure only one socket connection is established at a time
//       if (getSocket && getSocket.readyState === WebSocket.OPEN) {
//         return;
//       }
//       let socket = new WebSocket(socketUrl);
//       setSocket(socket);

//       socket.onopen = () => {
//         console.log("WebSocket connection opened.");
//         setIsReconnecting(false);
//         setReconnectAttempts(0); // Reset attempt flag on successful connection
//         reconnectInterval = 1000; // Reset interval on successful connection
//       };

//       socket.onmessage = (event) => {
//         try {
//           const data = JSON.parse(event.data);
//           if (data && data.message) {
//             const message = data.message;
//             combinedMessage.push(message);
//             let status = false;
//             let uuid = Number(data.uuid);
//             let chatdatares = combinedMessage.join("");

//             if (
//               chatdatares.includes("<STARTOFTURN>") ||
//               chatdatares.includes("<ENDOFTURN>")
//             ) {
//               chatdatares = chatdatares
//                 .replace(/<STARTOFTURN>/g, "")
//                 .replace(/<ENDOFTURN>/g, "");
//               if (combinedMessage.join("").includes("<ENDOFTURN>")) {
//                 status = true;
//                 combinedMessage.length = 0;
//                 combinedMessage.push(chatdatares);
//               }
//             }

//             setChatHist((prevChatHist) => {
//               const updatedChatHist = Array.isArray(prevChatHist)
//                 ? [...prevChatHist]
//                 : [];
//               if (updatedChatHist.length > 0) {
//                 const lastQuery = updatedChatHist[updatedChatHist.length - 1];
//                 if (lastQuery) {
//                   lastQuery.answer = chatdatares;
//                   lastQuery.uuid = uuid;
//                 }
//               }
//               if (status) {
//                 combinedMessage.length = 0;
//               }
//               return updatedChatHist;
//             });
//             scrollToBottom();
//           }
//         } catch (error) {
//           console.error("Error parsing message:", error);
//         }
//       };

//       socket.onerror = (error) => {
//         console.error("WebSocket error:", error);
//       };

//       // socket.onclose = () => {
//       //   console.log("WebSocket connection closed.");
//       //   if (!isReconnecting && !reconnectAttempt) { // Check the reconnect attempt flag
//       //     console.log("Reconnecting...");
//       //     setIsReconnecting(true);
//       //     setReconnectAttempts(true); // Set attempt flag to true to prevent multiple calls
//       //     setTimeout(() => {
//       //       reconnectInterval = Math.min(reconnectInterval * reconnectDecay, maxReconnectInterval);
//       //       initializeWebSocket();
//       //     }, reconnectInterval);
//       //   }
//       // };

//       socket.onclose = () => {
//         console.log("WebSocket connection closed.");
//         if (reconnectAttempts < maxReconnectAttempts && !isReconnecting) {
//           // console.log("Reconnecting attempt:", reconnectAttempts + 1);
//           console.log("Reconnecting attempt");
//           setIsReconnecting(true);
//           setReconnectAttempts((prev) => prev + 1);
//           setTimeout(() => {
//             reconnectInterval = Math.min(
//               reconnectInterval * reconnectDecay,
//               maxReconnectInterval
//             );
//             initializeWebSocket();
//           }, reconnectInterval);
//         } else {
//           console.log("Max reconnection attempts reached. Stopping reconnection.");
//         }
//       };

//     };

//     initializeWebSocket();

//     return () => {
//       if (getSocket && getSocket.readyState === WebSocket.OPEN) {
//         getSocket.close();
//       }
//     };
//   }, [roomId, socketUrl]);


//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       e.preventDefault(); // Prevents the default action to stop from happening (e.g., navigation)
//       handlechatbotSubmit();
//     }
//   };
//   const renderMarkdown = (markdown) => {
//     return { __html: marked(markdown, { renderer }) };
//   };

//   return (
//     <div className="studentDashboard-chatbot">
//       <div className="studentDashboard-chatbot-title-folder">
//         <div className="studentDashboard-chatbot-Small-AI-image">
//           <img
//             src={ai_avatar ?? studentDashboard_Small_AI_chatbot_image}
//             alt=""
//           />
//         </div>
//         <h2>{ai_name}</h2>
//       </div>
//       <div className="studentDashboard-universal-chatbot-inner-chat">
//         {chatHist?.map((chat, index) => (
//           <div key={index}>
//             {chat.query !== null && chat.query !== "" ? (
//               <div className="studentDashboard-universal-chatbot-AI-des studentDashboard-universal-chatbot-send-massege">
//                 {chat.query}
//               </div>
//             ) : (
//               <></>
//             )}
//             <div className="studentDashboard-universal-chatbot-AI-des">
//               {chat.answer === "" && (
//                 <div className="loading-chat-answer waveform">
//                   <Waveform size={25} />
//                 </div>
//               )}
//               <div
//                 className="loading-chat-answer"
//                 dangerouslySetInnerHTML={renderMarkdown(chat.answer)}
//               ></div>
//               <div className="chatbot-play-pause-folder">
//                 {
//                   chat.answer &&  <div className="play-pause-btn-lesson-generator">
//                   {chatAudioId === chat.uuid &&
//                     texttospeech.isLoading ? (
//                     <>
//                       <LoaderComponent />
//                     </>
//                   ) : chatAudioId === chat.uuid &&
//                     chatAudioId ===
//                     texttospeech.generatorId &&
//                     texttospeech.success ? (
//                     <audio
//                       controls
//                       src={texttospeech.voice.audio_url}
//                     />
//                   ) : (
//                     <i
//                       className="fa-solid fa-circle-play"
//                       onClick={() =>
//                         generateAIVoice(
//                           chat.answer,
//                           chat.uuid,
//                           chat
//                         )
//                       }
//                     ></i>
//                   )}
//                 </div> 
//                 }
//               </div>
//             </div>
//           </div>
//         ))}
//         <div ref={messagesEndRef}></div>
//       </div>
//       <div className="studentDashboard-universal-chatbot-send-text-folder">
//         <textarea
//           name="aichatbotquery"
//           disabled={isListening}
//           value={
//             isListening
//               ? aichatbotquery
//                 ? `${aichatbotquery}${transcript ? ` ${transcript}` : ""
//                 }`
//                 : transcript
//               : aichatbotquery
//           }
//           rows="30"
//           color="8"
//           onKeyPress={handleKeyPress}
//           onChange={handleTextareaChange}
//           placeholder="Type your query here"
//         />
//         <div className="chatbot-mic-folder">
//           <button
//             type="button"
//             id="mic-btn"
//             className={`mic ${isListening ? "listening" : ""
//               }`}
//             onClick={() => {
//               startStopListening();
//             }}
//           >
//             <i
//               className={`fa-solid fa-microphone ${isListening ? "hidden" : ""
//                 }`}
//             ></i>
//             <div
//               className={`boxContainer ${isListening ? "" : "hidden"
//                 }`}
//             >
//               <div className="box box1"></div>
//               <div className="box box2"></div>
//               <div className="box box3"></div>
//             </div>
//           </button>
//           <button
//             type="button"
//             onClick={handlechatbotSubmit}
//             className="go_cls_btn"
//             id="studentDashboard-universal-chatbot-send-btn"
//             disabled={
//               isListening ||
//               !aichatbotquery ||
//               aichatbotquery === null ||
//               aichatbotquery === ""
//             }
//           >
//             <img src={go_btn} alt="" />
//           </button>
//         </div>

//       </div>
//       <div
//         className="studentDashboard-chatbot-container-close-image"
//         onClick={onClose}
//       >
//         <img src={studentDashboard_AI_chatbot_clodse_image} alt="" />
//       </div>
//     </div>
//   );
// }

// export default CustomChatbot;










// CustomChatbot.jsx

import React, { useEffect, useRef, useState } from "react";
import { Waveform } from "@uiball/loaders";
import { marked } from "marked";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import {
  getTextToSpeech,
  resetTextToSpeech,
} from "../../state/actions/getTextToSpeechAction";
import {
  go_btn,
  studentDashboard_AI_chatbot_clodse_image,
  studentDashboard_Small_AI_chatbot_image,
} from "../../assets/images/images";
import { useDispatch, useSelector } from "react-redux";
import { parseKaTeX } from "../../utils/validator";
import LoaderComponent from "../../components/LoaderComponent/LoaderComponent";
import { useAuth } from "../../features/auth/AuthContext";
import "./CustomChatbot.css";
import { Tooltip } from "react-tooltip";

function CustomChatbot(props) {
  const {
    chatHistory,
    ai_welcome_msg,
    ai_avatar,
    ai_name,
    school_name,
    onClose,
    onMinimize,
    socketUrl,
    socketPayload,
    roomId,
    closeChatWindow
  } = props;
  const { token } = useAuth();
  const scrollDiv = useRef(null);

  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);

  // Speech recognition setup
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const [socket, setSocket] = useState(null);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);

  const texttospeech = useSelector((state) => state.texttospeech);
  const [chatAudioId, setchatAudioId] = useState();
  const [ready, setReady] = useState(false);


  const [chatHist, setChatHist] = useState(
    chatHistory !== null && chatHistory?.length > 0
      ? chatHistory
      : [
        {
          query: "",
          answer:
            ai_welcome_msg !== undefined
              ? ai_welcome_msg
              : "Hi, I am your Ai Assistant, How may I help you?",
          uuid: null,
        },
      ]
  );

  useEffect(() => {
    setChatHist( chatHistory !== null && chatHistory?.length > 0
      ? chatHistory
      : [
        {
          query: "",
          answer:
            ai_welcome_msg !== undefined
              ? ai_welcome_msg
              : "Hi, I am your Ai Assistant, How may I help you?",
          uuid: null,
        },
      ])
  }, [chatHistory])



  useEffect(() => {
    if (scrollDiv.current) {
      scrollDiv.current.scrollTop = scrollDiv.current.scrollHeight;
    }
  }, [chatHist]); // Add dynamic content as a dependency


  useEffect(() => {
    if (closeChatWindow) {
      handleClick();
    }
  }, [closeChatWindow]);
  const [aichatbotquery, setAichatbotquery] = useState("");

  // New state variables
  const [playingAudio, setPlayingAudio] = useState(null); // To store the Audio object
  const [isAudioPlaying, setIsAudioPlaying] = useState(false); // To track if the audio is playing
  const [currentAudioChatId, setCurrentAudioChatId] = useState(null); // To track which chat's audio is playing
  const [audioUrls, setAudioUrls] = useState({}); // To store audio URLs for each chat

  const cleanText = (text) => {

    // Remove HTML tags (e.g., <br>, <p>, etc.)
    text = text.replace(/<[^>]*>/g, ''); // This will remove all HTML tags

    // Remove "minus" and surrounding spaces (case insensitive)
    text = text.replace(/\s*minus\s*/gi, '');

    // Remove numbered list markers and leading hyphens (if any)
    text = text.replace(/^(\d+\.|\-)\s*/gm, '');

    // Reduce multiple consecutive newlines to two (preserves paragraph breaks)
    text = text.replace(/\n{3,}/g, '\n\n');

    // Replace single newlines with a space (for better flow in text-to-speech)
    text = text.replace(/\n/g, ' ');

    return text.trim();

  };

  const generateAIVoice = (chat, id, wholeChat, texttospeech) => {
    setchatAudioId(id);
    setCurrentAudioChatId(id);

    // let lessonData = parseKaTeX(chat);
    // Clean the text to prepare for text-to-speech
    const lessonData = cleanText(parseKaTeX(chat));

    const req = {
      text: lessonData,
      generator_id: id,
    };
    // dispatch(getTextToSpeech(req, "generator", "Ruth", "neural", token));
    dispatch(getTextToSpeech(req, "generator", "Salli", "neural", token));
  };

  useEffect(() => {
    if (texttospeech.success) {
      // Store the audio URL
      setAudioUrls((prevAudioUrls) => ({
        ...prevAudioUrls,
        [texttospeech.generatorId]: texttospeech.voice.audio_url,
      }));

      // If the user requested to play this audio, play it
      if (currentAudioChatId === texttospeech.generatorId) {
        // If an audio is already playing, stop it
        if (playingAudio) {
          playingAudio.pause();
        }

        const audio = new Audio(texttospeech.voice.audio_url);
        audio.play().catch((error) => console.error("Audio playback failed:", error));
        setPlayingAudio(audio);
        setIsAudioPlaying(true);

        // When the audio ends, reset the state
        audio.onended = () => {
          setIsAudioPlaying(false);
          setPlayingAudio(null);
          setCurrentAudioChatId(null);
        };
      }
    }
  }, [texttospeech.success, texttospeech.generatorId]);

  useEffect(() => {
    return () => {
      if (playingAudio) {
        playingAudio.pause();
      }
      dispatch(resetTextToSpeech());
    };
  }, [dispatch, playingAudio]);

  const startListening = () => {
    resetTranscript(); // Clear the previous transcript
    // setAichatbotquery(""); // Clear the chatbot query input
    SpeechRecognition.startListening({
      continuous: true,
      language: "en-US",
    });
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
    setAichatbotquery((prevQuery) =>
      prevQuery ? `${prevQuery} ${transcript}` : transcript
    );
    resetTranscript();
  };

  const handleMicButtonClick = () => {
    if (listening) {
      stopListening();
    } else {
      startListening(); // This will reset the transcript and start listening
    }
  };

  const handleTextareaChange = (e) => {
    setAichatbotquery(e.target.value);
  };

  const renderer = new marked.Renderer();
  const originalLinkRenderer = renderer.link;
  renderer.link = (href, title, text) => {
    const html = originalLinkRenderer.call(renderer, href, title, text);
    return html.replace(
      /^<a /,
      '<a target="_blank" rel="noopener noreferrer" '
    );
  };

  const handlechatbotSubmit = async (e) => {
    if (e) e.preventDefault();

    if (aichatbotquery.trim() === "") return;

    const history = chatHist.map((chat) => {
      return {
        query: chat.query,
        answer: Array(chat.answer)
          ?.map((item) => item)
          .join(""),
      };
    });
    try {
      if (aichatbotquery !== "") {
        if (socket && socket.readyState === WebSocket.OPEN) {
          const newPayload = {
            ...socketPayload,
            query: aichatbotquery,
            messages: history ?? [],
            school_name: school_name ?? "SRDS",
          };
          const payloadJSON = JSON.stringify(newPayload);
          setChatHist((prevChatHist) => {
            if (Array.isArray(prevChatHist)) {
              return [
                ...prevChatHist,
                { query: newPayload.query, answer: "" },
              ];
            } else {
              return [{ query: newPayload.query, answer: "" }];
            }
          });

          socket.send(payloadJSON);
        } else {
          console.error("WebSocket is not open.");
        }
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
    setAichatbotquery("");
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (!roomId || !socketUrl) return;

    let socketInstance;
    let combinedMessage = "";

    const maxReconnectAttempts = 5;
    let reconnectAttempts = 0;
    let reconnectTimeout;

    const initializeWebSocket = () => {
      // Close any existing socket before creating a new one
      if (socketInstance) {
        socketInstance.close();
      }

      socketInstance = new WebSocket(socketUrl);
      setSocket(socketInstance);

      socketInstance.onopen = () => {
        console.log("WebSocket connection opened.");
        reconnectAttempts = 0; // Reset reconnection attempts on successful connection
      };


      socketInstance.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data && data.message) {
            const message = data.message;
            // let message = cleanText(data.message);
            // console.log("Received message:", message);

            const uuid = data.uuid;

            combinedMessage += message;

            if (combinedMessage.includes("<ENDOFTURN>")) {
              const cleanMessage = combinedMessage
                .replace(/<STARTOFTURN>/g, "")
                .replace(/<ENDOFTURN>/g, "");

              setChatHist((prevChatHist) => {
                const updatedChatHist = [...prevChatHist];
                const lastChat = updatedChatHist[updatedChatHist.length - 1];
                if (lastChat) {
                  lastChat.answer = cleanMessage;
                  lastChat.uuid = uuid;
                }
                return updatedChatHist;
              });

              combinedMessage = "";
              scrollToBottom();
            }
          }
        } catch (error) {
          console.error("Error parsing message:", error);
        }
      };

      socketInstance.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      socketInstance.onclose = () => {
        console.log("WebSocket connection closed.");
        if (reconnectAttempts < maxReconnectAttempts) {
          const reconnectDelay = Math.min(
            1000 * 2 ** reconnectAttempts,
            30000
          );
          console.log(`Reconnecting in ${reconnectDelay / 1000} seconds...`);
          reconnectAttempts += 1;
          reconnectTimeout = setTimeout(() => {
            initializeWebSocket();
          }, reconnectDelay);
        } else {
          console.error("Max reconnection attempts reached.");
        }
      };
    };

    initializeWebSocket();

    // Cleanup function
    return () => {
      if (socketInstance) {
        socketInstance.onopen = null;
        socketInstance.onmessage = null;
        socketInstance.onerror = null;
        socketInstance.onclose = null;
        socketInstance.close();
      }
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
    };
  }, [roomId, socketUrl]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevents the default action to stop from happening (e.g., navigation)
      handlechatbotSubmit();
    }
  };
  const renderMarkdown = (markdown) => {
    return { __html: marked(markdown, { renderer }) };
  };

  const handlePlayPause = (chat) => {
    const audioUrl = audioUrls[chat.uuid];
    if (audioUrl) {
      // Audio URL already exists, play the audio
      if (playingAudio) {
        playingAudio.pause();
      }

      const audio = new Audio(audioUrl);
      audio.play().catch((error) => console.error("Audio playback failed:", error));
      setPlayingAudio(audio);
      setIsAudioPlaying(true);
      setCurrentAudioChatId(chat.uuid);

      audio.onended = () => {
        setIsAudioPlaying(false);
        setPlayingAudio(null);
        setCurrentAudioChatId(null);
      };
    } else {
      // Need to generate the audio
      generateAIVoice(chat.answer, chat.uuid, chat, texttospeech);
    }
  };

  const stopAudio = () => {
    if (playingAudio) {
      playingAudio.pause();
      playingAudio.currentTime = 0;
      setIsAudioPlaying(false);
      setPlayingAudio(null);
      setCurrentAudioChatId(null);
    }
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Your browser doesn't support speech recognition.</span>;
  }
const handleClick = () => {
  SpeechRecognition.stopListening();
  setAichatbotquery((prevQuery) =>
    prevQuery ? `${prevQuery} ${transcript}` : transcript
  );
  resetTranscript();
 
  };


  return (
    <div className="studentDashboard-chatbot"   >
      <div className="studentDashboard-chatbot-title-folder">
        <div className="studentDashboard-chatbot-Small-AI-image">
          <img
            src={ai_avatar ?? studentDashboard_Small_AI_chatbot_image}
            alt=""
          />
        </div>
        <h2>{ai_name}</h2>
      </div>
      <div className="studentDashboard-universal-chatbot-inner-chat" ref={scrollDiv}>
        {chatHist?.map((chat, index) => (
          <div key={index}>
            {chat.query !== null && chat.query !== "" ? (
              <div className="studentDashboard-universal-chatbot-AI-des studentDashboard-universal-chatbot-send-massege">
                {chat.query}
              </div>
            ) : null}
            <div className="studentDashboard-universal-chatbot-AI-des">
              {chat.answer === "" && (
                <div className="custom-chat-bot-responce">
                  <div className="loading-chat-answer waveform">
                    <Waveform size={25} />
                  </div>
                </div>
              )}
              <div
                className="loading-chat-answer"
                dangerouslySetInnerHTML={renderMarkdown(chat.answer)}
              ></div>
              {/* <div className="chatbot-play-pause-folder">
                {(chat.answer && chat.uuid) && (
                  <div className="play-pause-btn-lesson-generator">
                    {chatAudioId === chat.uuid && texttospeech.isLoading ? (
                      <LoaderComponent />
                    ) : isAudioPlaying && currentAudioChatId === chat.uuid ? (
                      <i
                        className="fa-solid fa-circle-stop"
                        onClick={() => stopAudio()}
                      ></i>
                    ) : (
                      <>
                        <i
                          className="fa-solid fa-volume-high"
                          data-tooltip-id="chatbot-data"
                          onClick={() => handlePlayPause(chat)}
                        ></i>
                        <Tooltip
                          id="chatbot-data"
                          place="bottom"
                          content="Read aloud"
                          type="light"
                          effect="float"
                        />
                      </>
                    )}
                  </div>
                )}
              </div> */}
            </div>
          </div>
        ))}
        <div ref={messagesEndRef}></div>
      </div>
      <div className="studentDashboard-universal-chatbot-send-text-folder">
        <div className="customchatbot-text-feild">
          <textarea
            name="aichatbotquery"
            disabled={listening}
            value={
              listening
                ? aichatbotquery
                  ? `${aichatbotquery}${transcript ? ` ${transcript}` : ""}`
                  : transcript
                : aichatbotquery
            }
            rows="3"
            onKeyPress={handleKeyPress}
            onChange={handleTextareaChange}
            placeholder="Type your query here"
          />
        </div>
        <div className="chatbot-mic-folder">
          <button
            type="button"
            id="mic-btn"
            className={`mic ${listening ? "listening" : ""}`}
            onClick={handleMicButtonClick}
          >
            <i
              className={`fa-solid fa-microphone ${listening ? "hidden" : ""}`}
            ></i>
            {listening && (
              <div className="boxContainer">
                <div className="box box1"></div>
                <div className="box box2"></div>
                <div className="box box3"></div>
              </div>
            )}
          </button>
          <button
            type="button"
            onClick={handlechatbotSubmit}
            className="go_cls_btn"
            id="studentDashboard-universal-chatbot-send-btn"
            disabled={
              listening || !aichatbotquery || aichatbotquery.trim() === ""
            }
          >
            <img src={go_btn} alt="" />
          </button>
        </div>
      </div>
      <div
        className="studentDashboard-chatbot-container-close-image"
        // onClick={onClose}
        onClick={() => { stopListening(); onClose(); }}
      >
        <img src={studentDashboard_AI_chatbot_clodse_image} alt="" />
      </div>
    </div>
  );
}

export default CustomChatbot;
