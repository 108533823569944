import React, { useEffect, useState } from "react";
import "./TeachersList.css";
import { useNavigate } from "react-router-dom";
import { Switch } from "@mui/material";
import api from "../../api/api";
import { useAuth } from "../../features/auth/AuthContext";
import { toast } from "react-toastify";
import { upload_image } from "../../assets/images/images";

function TeachersList({
  teacher_reviews_profile,
  teacher_reviews_profile_aword,
  teacher_reviews_star_number,
  teacher_reviews_subject,
  teacher_id,
  teacher_grade,
  teacher_reviews_title,
  tid,
  isactive_status,
  user_id,
  getAllteacherDetails
}) {
  const navigate = useNavigate();
  const { token } = useAuth();
  const onteacherViewProfile = (tid) => {
    navigate(`/TeacherProfile/${tid}`);
  };
  const [openDialog, setOpenDialog] = useState(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [isActive, setIsActive] = useState(isactive_status);
  const handleSwitchToggle = () => {
    setOpenDialog(true);
  }
  const handleActiveinactive = () => {   
    const payload = {
      user_id: user_id,
      active: !isActive
    };
    
    api
      .post(`super-admin/school/active-deactive/`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`
        }
      })
      .then((response) => {
        if (response.status === 200) {
          setOpenDialog(false);
          getAllteacherDetails();
          !isActive ? (
            toast.success(
                "The teacher account has been enabled.",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            )
          ):(
            toast.success(
                "The teacher account has been disabled",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            )
          );
          setIsActive(!isActive);
        }
      })
      .catch((error) => {
      });
    

  };
  const cancelRegistration = () => {
    setOpenDialog(false);
    document.body.style.overflow = "auto";
    // Reset the selected role or handle cancellation
  };
  return (
    <>
      <div className="Dashboard-Review-Card-foler">
        <div className="Dashboard-Review-Card-teacher-id-folder">
          <div className="Dashboard-Review-Card-toggle-wrapper">
            <p className="Teacher_ID"  onClick={() => {
            onteacherViewProfile(tid);
          }}>
              Teacher ID: <span>{teacher_id}</span>
            </p>
            <Switch
              {...label}
              checked={isActive}
              onChange={handleSwitchToggle}
            />
          </div>
        </div>
        <div className="Dashboard-Review-Card-image-folder"  onClick={() => {
            onteacherViewProfile(tid);
          }}>
          <div className="Dashboard-Review-Card-image">
            <img src={teacher_reviews_profile ?? upload_image} alt="" />
          </div>
        </div>
        <h2  onClick={() => {
            onteacherViewProfile(tid);
          }}>{teacher_reviews_title}</h2>
        <div className="Dashboard-Review-subject-folder">
          {Object.entries(teacher_reviews_subject)
            .slice(0, 5)
            .map(([id, name]) => (
              <p id={id}>{name}</p>
            ))}
        </div>
        <button
          className="Dashboard-Review-Card-button"
          onClick={() => {
            onteacherViewProfile(tid);
          }}
        >
          View
        </button>
      </div>
      {openDialog && (
        <div className="Course-created-successfully-main">
          <div className="Course-created-successfully-container">
            <div className="Course-created-successfully-folder">
              <h2>Are you sure you want to {isActive == true ? 'disable' : 'enable' } the teacher account?</h2>
              <div className="Course-close-btn">
                <button type="submit" onClick={cancelRegistration}>
                  No
                </button>
                <button
                  type="submit"
                  onClick={() => handleActiveinactive(isActive)}
                  className="Course-close"
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TeachersList;
