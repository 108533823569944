import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { useAuth } from "../../../features/auth/AuthContext";
import { useCourseContext } from '../../../context/CourseContext';
import Pagination from "@mui/material/Pagination";
import { CircularProgress, Grid } from "@mui/material";
import Helper from "../../../utils/Helper";
import {
    massege,
    search_icon,
    student_grade_flage,
    srot
} from "../../../assets/images/images";
import AOS from 'aos';
import 'aos/dist/aos.css';
import api from "../../../api/api";
import SortPopup from "../../../pages/customSort/SortPopup";
import FilterPopup from "../../../pages/customFilter/FilterPopup";
import BackButton from "../../BackButton/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { getLessonStats, resetLessonStats } from "../../../state/actions/getLessonStatsAction";
import PaginationView from "../../PaginationView/PaginationView";

const StudentAssignmentStats = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { student_id, course_id } = useParams();
    const { student_details } = useSelector((state) => state.globalState);
    const course = location.state?.course || {};
    const [search, setSearch] = useState("");
    const [TempglobalState, setTempglobalState] = useState("");
    const [nextUrl, setNextUrl] = useState(null);
    const [prevUrl, setPrevUrl] = useState(null);
    const { token } = useAuth();
    const fillterstatus = [
        { id: "Active", name: "Active" },
        { id: "Completed", name: "Completed" },
    ];
    const fillterScore = [];
    const itemsPerPage = 12;
    const [isVisible, setIsVisible] = useState(false);
    const [isVisiblefillter, setIsVisiblefillter] = useState(false);
    const [assignmentStatus, setAssignmentStatus] = useState("");
    const [score, setScore] = useState("");
    const [student_assignment_details, setStudent_assignment_details] = useState(
        []
    );
    const [sortOption, setSortOption] = useState("");

    const [showdownlodefile, setShowdownlodefile] = useState(false); // State to control visibility
    const modalRef = useRef();
    const modalContentRef = useRef(); // Reference to the modal content

    const { lessonStats, next, previous, count, totalPages, isLoading } = useSelector((state) => state.lessonStats);
    // Function to open the modal
    const openModal = () => {
        setShowdownlodefile(true);
    };

    const toggledownlodefile = () => {
        // setShowdownlodefile(!showdownlodefile); // Toggle the visibility state
        setShowdownlodefile(false);
    };

    // Event listener to close modal if clicked outside the modal content
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalContentRef.current && !modalContentRef.current.contains(event.target)) {
                toggledownlodefile();
            }
        };

        // Attach the event listener
        if (showdownlodefile) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showdownlodefile]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setSearch(value);
    };
    const handleAssignmentStatusChange = (event) => {
        setAssignmentStatus(event.target.value);
    };
    const handleScoreChange = (event) => {
        setScore(event.target.value);
    };
    const [currentPage, setCurrentPage] = useState(1);

    const selectGrade = [
        {
            filtergrade: "A+"
        },
        {
            filtergrade: "A"
        },
        {
            filtergrade: "B"
        },
        {
            filtergrade: "C"
        }
    ];

    const handlePageChange = (event, value) => {
        window.scrollTo(0, 0);
        setCurrentPage(value);
    };

    const handleSortOptionChange = (newSortOption) => {
        setSortOption(newSortOption);
    };

    const handleApplyClick = () => {
        setIsVisiblefillter(false);
        setIsVisible(false);
    };

    useEffect(() => {
        getLessonStatsReq();
    }, [assignmentStatus, search, sortOption])

    useEffect(() => {
        return () => {
            dispatch(resetLessonStats());
        };
    }, []);

    const getLessonStatsReq = () => {
        dispatch(getLessonStats(course_id, student_id, token, search, assignmentStatus, sortOption));
    }
    const handleViewAnalytics = (lesson, lesson_id, id) => {
        navigate(
            `/course/${course_id}/lesson/${lesson_id}/analytics/${student_id}`, { state: { lesson: lesson, analytic_id: id, teacher_id: location.state.teacher_id } }
        );
    }
    const assignmentStatsHandler = (id) => {
        navigate(`/student-detail/${student_id}/course/${id}/assignment-stats`)
    }

    return (
        <>
            <div id="assignment-stats-section">
                <div className="view-course-Details-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div style={{ float: "right" }}>
                                    <button type="button" className="Preview-lesson-btn" onClick={() => assignmentStatsHandler(course_id)}>
                                        Assignment Stats
                                    </button>
                                </div>
                                <div className="LessonViewProgress-header-section">
                                    <div className="view-course-Details-back-btn-folder LessonViewProgress-back-btn-folder">
                                        <BackButton buttonName={"Lesson Stats"} />
                                    </div>
                                    <div style={{ paddingBottom: "10px" }}>
                                        Student Name: {student_details?.data?.name}
                                    </div>
                                    <h6 style={{ fontWeight: "600" }}>
                                        Course Name : {course?.name}
                                    </h6>

                                    <div className="date-inner-wrapper">

                                    </div>
                                </div>

                                <div className="LessonViewProgress-tab-folder-container">
                                    <div className="LessonViewProgress-serach-filter">
                                        <ReactSVG className="search-icon" src={search_icon} />
                                        <input
                                            type="text"
                                            name="student_name"
                                            value={search}
                                            onChange={handleInputChange}
                                            placeholder="Search by student name"
                                        />
                                    </div>
                                    <div className="LessonViewProgress-tab-filter-wrapper">
                                        <div className="LessonViewProgress-filder-tab-wrapper">
                                            <div
                                                className="lessonviewprogress-filder-tab"
                                                onClick={() => {
                                                    if (!isVisiblefillter) {
                                                        setIsVisiblefillter(true);
                                                        setIsVisible(false);
                                                    } else {
                                                        setIsVisiblefillter(false);
                                                    }
                                                }
                                                }
                                            >
                                                <span>
                                                    <i className="fa-solid fa-sliders"></i>
                                                </span>
                                                <p>Filter</p>
                                            </div>
                                            <FilterPopup
                                                assignmentStatus={assignmentStatus}
                                                score={score}
                                                isVisiblefillter={isVisiblefillter}
                                                handleApplyClick={handleApplyClick}
                                                handleAssignmentStatusChange={handleAssignmentStatusChange}
                                                fillterstatus={fillterstatus}
                                                fillterScore={fillterScore}
                                                handleScoreChange={handleScoreChange}
                                            />
                                        </div>
                                        <div className="LessonViewProgress-filder-tab-wrapper">
                                            <div
                                                className="lessonviewprogress-filder-tab"
                                                onClick={() => {
                                                    if (!isVisible) {
                                                        setIsVisible(true);
                                                        setIsVisiblefillter(false);
                                                    } else {
                                                        setIsVisible(false);
                                                    }
                                                }}
                                            >
                                                <span>
                                                    <i class="fa-solid fa-arrow-down-wide-short"></i>
                                                </span>
                                                <p>Sort</p>
                                            </div>
                                            <SortPopup
                                                isVisible={isVisible}
                                                handleApplyClick={handleApplyClick}
                                                sortOption={sortOption}
                                                onSortOptionChange={handleSortOptionChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="LessonViewProgress-table-container">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Lessons</th>
                                                <th>Started At</th>
                                                <th>Completed At</th>
                                                <th>Lesson Status</th>
                                            </tr>
                                        </thead>



                                        {isLoading ?
                                            <div style={{ display: "table-caption" }}>
                                                <Grid
                                                    height={"50vh"}
                                                    xs={12}
                                                    container
                                                    justifyContent={"center"}
                                                    alignItems={"center"}
                                                >
                                                    <CircularProgress size={80} />
                                                </Grid>
                                            </div>
                                            : lessonStats && lessonStats.length > 0 ?
                                                lessonStats?.map((lesson, index) => (
                                                    <tbody>
                                                        <tr key={lesson.id}>
                                                            <td className="Lesson_Stats_name-wrapper">
                                                                <div className="Lesson_Stats_name">
                                                                    <span>{index + 1 + ": "}</span>
                                                                    <span className="lesson_name">{lesson.lesson_name}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="startedAt-time">
                                                                    <span>{Helper.formatDateTime(lesson.started_at).date}</span>
                                                                    <span>{Helper.formatDateTime(lesson.started_at).time}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="completedat-time">
                                                                    <span>{lesson.completed_at ? (Helper.formatDateTime(lesson.completed_at).date) : '-'}</span>
                                                                    <span>{lesson.completed_at ? (Helper.formatDateTime(lesson.completed_at).time) : ''}</span>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className={`LessonViewProgress-student-status ${Helper.getClassNameByStatus(lesson.status)}`}>
                                                                    {lesson.status}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )) :
                                                <tr>
                                                    <td colSpan="8" className="tex-center">
                                                        No data found
                                                    </td>
                                                </tr>
                                        }
                                    </table>
                                    <br />
                                </div>
                            </div>
                        </div>
                        {totalPages > 1 && (
                            <PaginationView
                                totalPages={totalPages}
                                next={next}
                                previous={previous}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                            />
                        )}
                    </div>
                    {showdownlodefile && (
                        <div ref={modalRef} className="Download-File-popup-wrappper">
                            <div ref={modalContentRef} className="Download-File-popup-container" data-aos="zoom-in">
                                <div className="download-popup">
                                    <h2>Download File as</h2>
                                    <button className="close-btn" onClick={toggledownlodefile}>
                                        ×
                                    </button>
                                    <div className="input-section">
                                        <label>
                                            File Name *{" "}
                                            <input type="text" placeholder="Enter File Name" />
                                        </label>
                                    </div>
                                    <div className="button-section">
                                        <button className="pdf-btn">PDF (.pdf)</button>
                                        <span>Or</span>
                                        <button className="spreadsheet-btn">
                                            Spreadsheet (.xlsx)
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default StudentAssignmentStats;
