import React, { useEffect, useState } from "react";
import "./CourseCard.css";
import StyledMenu from "../StyleMenu";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  alert_image,
} from "../../assets/images/images";
import { useDispatch, useSelector } from "react-redux";
import { deleteCourseById, resetdeleteCourse } from "../../state/actions/deleteCourseAction";
import { useAuth } from "../../features/auth/AuthContext";

function CourseCard({
  courseimage,
  coursename,
  schooljoined,
  totalstudents,
  totalteachers,
  createdcourse,
  description,
  course_info_image,
  Teacher_name,
  Teacher_title,
  Sales_Courses_title,
  checkbox,
  location,
  location_name,
  course_details,
}) {

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [deletePopUp, setdeletePopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeMenuCourseId, setActiveMenuCourseId] = useState(null);
  const { token } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deletedCourseById = useSelector((state) => state.deleteCourseById);

  const onViewCourseClick = async (course_details) => {
    navigate(`/course/${course_details.course_id}/view`, {
      state: { course: course_details, teacher_id: course_details.teacher_id },
    });
  };

  const onEditCourseClick = async (course) => {
    navigate(`/course/${course.course_id}/edit`, {
      state: { course: course_details, teacher_id: course_details.teacher_id },
    });
  };

  const handleClick = (event, courseId) => {
    setAnchorEl(event.currentTarget);
    setActiveMenuCourseId(courseId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveMenuCourseId(null);
  };

  const deleteCourse = async (course_id) => {
    setSelectedCourse(course_id);
    setdeletePopUp(!deletePopUp);
    handleClose();
  };

  const handleDeleteCourse = () => {
    setdeletePopUp(!deletePopUp);
    dispatch(deleteCourseById(selectedCourse, token))
  }

  useEffect(() => {
    if (deletedCourseById?.message?.length) {
      dispatch(resetdeleteCourse());
    }
  }, [deletedCourseById])

  useEffect(() => {
    return () => {
      dispatch(resetdeleteCourse())
    }
  }, []);
  const onteacherViewProfile = (tid) => {
    navigate(`/TeacherProfile/${tid}`);
  };
  return (
    <>
      <div className="created-my-courses-folder">
        <div className="created-my-courses-folder-image-left-folder">
          <div className="created-my-courses-folder-image" onClick={() => onViewCourseClick(course_details)}>
            <img src={courseimage} alt="" />
          </div>
        </div>
        <div className="created-my-courses-des-wrapper">
          <div className="created-my-courses-title-folder">
            <div className="course-inner-title-wrapper">
              <div className="created-my-courses-title-wrapper" onClick={() => onViewCourseClick(course_details)}>
                <h2 className="school_title">
                  {coursename}{" "}
                </h2>
              </div>
            </div>
            <div className="edit-course-dropdown-menu-container" style={{ cursor: "pointer" }}>
              <div className="edit-course-or-delete-course" onClick={(e) => handleClick(e, course_details.course_id)}>
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </div>
              <StyledMenu
                id="demo-customized-menu"
                anchorEl={anchorEl}
                open={Boolean(
                  anchorEl && activeMenuCourseId === course_details.course_id
                )}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    if (activeMenuCourseId === course_details.course_id) {
                      onEditCourseClick(course_details);
                    }
                    handleClose();
                  }}
                  disableRipple
                >
                  <button
                    type="button"
                    className="course-edit-or-delete-folder"
                  >
                    <a href="#">
                      <i className="fa-solid fa-pencil"></i>
                    </a>
                    <p>Edit</p>
                  </button>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    if (activeMenuCourseId === course_details.course_id) {
                      deleteCourse(course_details.course_id);
                    }
                    handleClose();
                  }}
                  disableRipple
                >
                  <button
                    type="button"
                    className="course-edit-or-delete-folder"
                  >
                    <a href="#">
                      <i className="fa-solid fa-trash"></i>
                    </a>
                    <p>Delete</p>
                  </button>
                </MenuItem>
              </StyledMenu>
            </div>
          </div>
          <div className="Students-courses-details-container">
            <div className="Students-courses-details-folder course_inner_tab_details">
              <span>Total Students</span>
              <div className="Students-courses-details-counts">
                <p>{totalstudents}</p>
              </div>
            </div>
            <div className="Students-courses-details-folder course_inner_tab_details">
              <span>Total Teachers</span>
              <div className="Students-courses-details-counts">
                <p>{totalteachers}</p>
              </div>
            </div>
            <div className="Students-courses-details-folder course_inner_tab_details">
              <span>Created Course</span>
              <div className="Students-courses-details-counts">
                <p>{createdcourse}</p>
              </div>
            </div>
          </div>
          <p className="Students-des">{description}</p>
          <div className="course-main-container" >
            <div
              className={
                Teacher_name?.length > 0
                  ? "course-inner-info"
                  : "course-inner-without-info"
              }
            >
              <div
                className={
                  course_info_image?.length > 0
                    ? "course-inner-info-with-image"
                    : "course-inner-info-without-image"
                }
              >
                <img src={course_info_image} alt="" />
              </div>
              <div className="course-inner-info-title">
                <h2 onClick={() => {
            onteacherViewProfile(course_details.teacher_id);
          }}>{Teacher_name}</h2>
                <p onClick={() => {
            onteacherViewProfile(course_details.teacher_id);
          }}>{Teacher_title}</p>
              </div>
            </div>
            <button
              type="button"
              className="View-Course"
              onClick={() => onViewCourseClick(course_details)}
            >
              View Course
            </button>
          </div>
        </div>
      </div>

      {
        deletePopUp && (
          <form action="" className="delete-course-form">
            <div className="delete-course-form-container">
              <div className="delete-course-alert-image">
                <img src={alert_image} alt="" />
              </div>
              <h2>Are you sure you want to delete this course?</h2>
              <div className="delete-course-form-btn-folder">
                <button
                  type="button"
                  onClick={() => setdeletePopUp(!deletePopUp)}
                  className="not-delete-btn"
                >
                  No
                </button>
                {loading ? (
                  <div className="loading-spinner" />
                ) : (
                  <button
                    type="button"
                    onClick={handleDeleteCourse}
                    disabled={loading}
                    className="delete-btn"
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          </form>
        )
      }
    </>
  );
}

export default CourseCard;
