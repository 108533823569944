import React from 'react';
import { topratingreview_profile , send_message_icon, course_demo_image, course_info_image} from "../../../assets/images/images";
import TopRatingReview from '../TopRatingReview/TopRatingReview';
import Course from '../Rating/CourseRating/Course';
import BackButton from "../../../components/BackButton/BackButton";

function RatingDetails() {

    return (

        <>  
        <div className='ratingDetails-section'>
            <div className='ratingDetails-wrapper'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <BackButton buttonName="Ratings"/>
                                <Course
                                course_demo_image= {course_demo_image}
                                Course_title= "Maths"
                                Course_des= "Welcome to the fascinating world of Mathematics! This course is designed for students from Grade 1 to Grade 8. We'll embark on an exciting journey, exploring numbers, shapes, patterns, and more. From basic arithmetic to complex problem-solving, students will engage in interactive lessons, making math fun and accessible. The course also emphasizes critical thinking and logical reasoning, essential skills for future mathematicians. As we progress, students will gain confidence in their mathematical abilities, ready to tackle more advanced concepts."
                                Lesson_number= "3"
                                Lessons_name= "Lessons"
                                student_number= "3"
                                Students_name= "Students"
                                Course_Start_Date= "Feb 16, 2024"
                                Course_Expiry_Date= "- -"
                                course_info_image={course_info_image}
                                Teacher_title= "Rehan blackman"
                                Teacher_name= "Teacher"
                            />
                            <TopRatingReview
                                topratingreview_profile= {topratingreview_profile}
                                send_message_icon={send_message_icon}
                                topratingreview_title= "Students reviews & ratings"
                                rating_numbers="(322 Ratings)"
                                rating= "4.1"
                                profile_title= "Charles Shelby"
                                Reply= "Reply"
                                des= "Enjoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend! Enjoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend! njoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend! njoyed the engaging lectures and practical assignments. Valuable insights gained. Instructor was knowledgeable and responsive. Highly recommend!"
                                Edit_Reply= "Edit Reply"
                                Delete_Reply= "Delete Reply"
                                date= "12:00 PM"
                                time="12 Oct 2023"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default RatingDetails;