import React, { useEffect, useState } from "react";
import MultipleAssignmentviewResult from "./MultipleAssignmentviewResult";
import "./MultipleAssignment.css";
import MultipleAssignmentviewPopup from "./MultipleAssignmentviewPopup";
import { getAssignmentsByLesson, resetAssignmentsList } from "../../state/actions/getAssignmentsByLessonAction";
import { useAuth } from "../../features/auth/AuthContext";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PaginationView from "../PaginationView/PaginationView";
import { useDispatch, useSelector } from "react-redux";
import { createAssignmentStatus } from "../../state/actions/createAssignmentStatusAction";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { wsURL } from "../../api/api";
import { getPracticeAssignments } from "../../state/actions/getPracticeAssignmentsAction";

function MultipleAssignment({ lesson, course, rubrics, setCurrentPage, currentPage }) {
    const navigate = useNavigate();
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { lesson_id } = useParams();
    const [selectedAssignment, setSelectedAssignment] = useState(null);
    const [callWebSocket, setcallWebSocket] = useState(false);
    const [websocketPayload, setwebsocketPayload] = useState({
        practice_id: null,
        submission_id: null,
        assignment_id: null
    });
    const [selectedAssignmentIndex, setSelectedAssignmentIndex] = useState(null);
    const [assignmentPopUp, setAssignmentPopUp] = useState(false);
    const [assignmentRubric, setAssignmentRubric] = useState(null);
    const { count, next, previous, totalPages, error, assignments, isLoading } = useSelector((state) => state.getassignmentsbyLesson);
    const globalState = useSelector((state) => state.globalState);
    const studentId = globalState.student_details?.data?.student_id;
    const [ws, setWs] = useState(null);
    const location = useLocation();

    useEffect(() => {
        if (location.state.practice_id) {
            setwebsocketPayload({
                practice_id: location.state.practice_id,
                submission_id: location.state.submission_id,
                assignment_id: location.state.assignment_id
            })
        }

    }, [location])


    const startAssignmentPopupHandler = (assignment, index) => {

        setSelectedAssignment(assignment);
        setSelectedAssignmentIndex(index);
        let rubricId = null
        if (assignment.rubric_id !== null) {
            for (let i = 0; i < rubrics.length; i++) {
                if (assignment.rubric_id === rubrics[i].rubric_id) {
                    rubricId = rubrics[i]
                    break;
                }
            }
        }
        // setAssignmentRubric(rubrics[index] ? rubrics[index] : null);
        setAssignmentRubric(rubricId);
        setAssignmentPopUp(!assignmentPopUp);
    };
    const assignmentResultHandler = (assignment) => {

        setwebsocketPayload((prev) => {
            return {
                ...prev,
                submission_id: assignment?.submission?.submission_id,
                assignment_id: assignment.assignment_id
            };
        })

    }

    const startAssignmentHandler = (status = 3) => {
        const course_id = course?.course_id;
        const lesson_id = lesson?.lesson_id;
        const id = selectedAssignment?.assignment_id;

        const payload = {
            student_id: globalState.student_details.data.student_id,
            assignment_id: id,
            status: 3,
            completed_name: selectedAssignment.name,
            assignment_type: 1,
        };

        dispatch(createAssignmentStatus(payload, token));

        try {
            const state = {
                course: JSON.parse(JSON.stringify(course)),
                lesson: JSON.parse(JSON.stringify(lesson)),
                assignment: selectedAssignment,
                rubric: assignmentRubric ? JSON.parse(JSON.stringify(assignmentRubric)) : null,
                assignmentDtls: assignments?.length > 0 ? JSON.parse(JSON.stringify(assignments)) : null,
                type: 1,
            };

            navigate(`/course/${course_id}/lesson/${lesson_id}/assignment/${id}/preview`, { state });
        } catch (error) {
            console.error("Serialization error:", error);
        }
    };



    useEffect(() => {

        dispatch(getAssignmentsByLesson(lesson_id, currentPage, token));
    }, [currentPage])

    useEffect(() => {



        if (assignments?.length > 0) {
            for (let i = 0; i < assignments.length; i++) {
                if (assignments[i].submission) {
                    dispatch(getPracticeAssignments(assignments[i].assignment_id, token));
                }
            }
        }
    }, [assignments]);




    useEffect(() => {

        const newWebSocketURL = `${wsURL}ws/practice_assignment/${studentId}/?token=${token}`;
        let reconnectInterval = 1000; // Initial reconnect interval in ms
        const maxReconnectInterval = 30000; // Max reconnect interval
        let reconnectAttempts = 0; // Number of reconnect attempts
        const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
        const maxReconnectAttempts = 3; // Maximum number of reconnect attempts




        if (websocketPayload.practice_id && websocketPayload.submission_id) {
            let socket = new WebSocket(newWebSocketURL);
            setWs(socket);





            socket.onopen = () => {
                console.log("WebSocket connected");
                reconnectAttempts = 0; // Reset reconnect attempts on successful connection
                reconnectInterval = 1000; // Reset reconnect interval
                socket.send(JSON.stringify(
                    {
                        practice_id: websocketPayload.practice_id,
                        submission_id: websocketPayload.submission_id
                    }
                ));
                dispatch(getPracticeAssignments(websocketPayload.assignment_id, token));
            };

            socket.onmessage = (event) => {
                // console.log("WebSocket message received:", event.data);
                const data = JSON.parse(event.data);
                if (data.status === 200) {
                    if (data.data.practice_assignment_status === "Completed") {
                        dispatch(getPracticeAssignments(data.data.assignment_id, token));
                    }
                    setwebsocketPayload({
                        practice_id: null,
                        submission_id: null,
                        assignment_id: null
                    })
                }
            };

            socket.onclose = () => {
                console.log("WebSocket disconnected");
                if (reconnectAttempts < maxReconnectAttempts) {
                    setTimeout(() => {
                        reconnectAttempts++;
                        socket.close(); // Close old sockets if not yet closed
                        socket = new WebSocket(newWebSocketURL); // Create a new socket and attempt to connect
                        reconnectInterval = Math.min(reconnectInterval * reconnectDecay, maxReconnectInterval);
                    }, reconnectInterval);
                } else {
                    console.log("Max reconnect attempts reached, will not attempt further reconnects.");
                }
            };

            socket.onerror = (error) => {
                console.error("WebSocket error:", error);
                socket.close();
            };

            return () => {
                if (socket.readyState === 1) {
                    socket.close();
                }
            };
        }
        }, [websocketPayload.practice_id, websocketPayload.submission_id, callWebSocket]);





    // useEffect(() => {
    //     if (practice_id && submission_id) {
    //         const newWebSocketURL = `${wsURL}ws/practice_assignment/${studentId}/?token=${token}`;
    //         let reconnectInterval = 1000; // Initial reconnect interval in ms
    //         const maxReconnectInterval = 30000; // Max reconnect interval
    //         let reconnectAttempts = 0; // Number of reconnect attempts
    //         const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
    //         const maxReconnectAttempts = 3; // Maximum number of reconnect attempts

    //         const socket = new WebSocket(newWebSocketURL);
    //         setWs(socket);

    //         const handleOpen = () => {
    //             console.log("WebSocket connected");
    //             reconnectAttempts = 0; // Reset reconnect attempts on successful connection
    //             reconnectInterval = 1000; // Reset reconnect interval
    //             socket.send(JSON.stringify({ practice_id, submission_id }));
    //         };

    //         const handleMessage = (event) => {
    //             const data = JSON.parse(event.data);
    //             if (data.status === 200 && data.data.practice_assignment_status === "Completed") {
    //                 console.log("WebSocket message received:", data);
    //                 dispatch(getPracticeAssignments(data.data.assignment_id, token));
    //             }
    //         };

    //         const handleClose = () => {
    //             console.log("WebSocket disconnected");
    //             if (reconnectAttempts < maxReconnectAttempts) {
    //                 reconnectAttempts++;
    //                 setTimeout(() => {
    //                     reconnectInterval = Math.min(reconnectInterval * reconnectDecay, maxReconnectInterval);
    //                     const newSocket = new WebSocket(newWebSocketURL);
    //                     setWs(newSocket); // Update the WebSocket reference
    //                 }, reconnectInterval);
    //             } else {
    //                 console.log("Max reconnect attempts reached, will not attempt further reconnects.");
    //             }
    //         };

    //         const handleError = (error) => {
    //             console.error("WebSocket error:", error);
    //             socket.close();
    //         };

    //         socket.addEventListener("open", handleOpen);
    //         socket.addEventListener("message", handleMessage);
    //         socket.addEventListener("close", handleClose);
    //         socket.addEventListener("error", handleError);

    //         return () => {
    //             console.log("Cleaning up WebSocket...");
    //             socket.removeEventListener("open", handleOpen);
    //             socket.removeEventListener("message", handleMessage);
    //             socket.removeEventListener("close", handleClose);
    //             socket.removeEventListener("error", handleError);
    //             if (socket.readyState === WebSocket.OPEN) {
    //                 socket.close();
    //             }
    //         };
    //     }
    // }, [practice_id, submission_id, token, studentId, wsURL, dispatch]);




    return (
        <>
            <div className="maltiple-assignment-section">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="maltiple-assignment-wrapper">
                                <div className="maltiple-assignment-card-container">

                                    {isLoading ? 
                                    <div className="spinner-folder">
                                        <LoadingComponent
                                            loading_text="Educating the Future, Loading Now."
                                        />
                                    </div>
                                        : assignments?.length > 0 ?
                                            assignments?.map((assignment, index) => (
                                                <div className="maltiple-assignment-card-folder">
                                                    <MultipleAssignmentviewResult key={index} index={index} assignment={assignment} startAssignmentPopupHandler={startAssignmentPopupHandler} lesson={lesson} course={course} rubrics={rubrics} setcallWebSocket={setcallWebSocket}
                                                        callWebSocket={callWebSocket} setwebsocketPayload={setwebsocketPayload} assignmentResultHandler={assignmentResultHandler} />
                                                </div>
                                            ))
                                            : (
                                                <p style={{ textAlign: "center", marginTop: "20px" }}>No assignments found.</p>
                                            )
                                    }
                                </div>
                                {assignmentPopUp && <MultipleAssignmentviewPopup type={"Assignment"} startAssignmentPopupHandler={startAssignmentPopupHandler} startAssignmentHandler={startAssignmentHandler} rubric={assignmentRubric} assignment={selectedAssignment} index={selectedAssignmentIndex} />}
                            </div>
                        </div>
                    </div>
                    {totalPages > 1 && (
                        <PaginationView
                            totalPages={totalPages}
                            next={next}
                            previous={previous}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default MultipleAssignment;
