import { useEffect } from "react";
import { genders, titles } from "./consts";

function getKeyByValue(dictionary, value) {
  const key = Object.keys(dictionary).find((key) => dictionary[key] === value);
  return key || null; // Return null if the value is not found in the dictionary
}
function getKeyByDictionary(dictionary, selectedDictionary) {
  let selectedKey;
  for (const [key] of Object.entries(dictionary)) {
    if (key === Object.keys(selectedDictionary)[0]) {
      selectedKey = key;
    } else {
      selectedKey = Object.keys(dictionary)[0];
    }
  }
  return selectedKey;
}
const getTitle = (titleValue, name) => {
  const titleObj = titles.find((title) => `${title.value}` === `${titleValue}`);
  if (titleObj) {
    return `${titleObj.name} ${name}`;
  }
  return name || "";
};
const truncate = (str, num) => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};
function getIdsForLanguages(languageArray, languageObject) {
  const languageIds = languageArray.map((lang) => {
    const id = Object.keys(languageObject).find(
      (key) => languageObject[key] === lang
    );
    return id || null; // Return null if the language is not found in the object
  });
  return languageIds.join(","); // Join the IDs with a comma
}

function isLinkOrText(input) {
  if (input.includes("http")) {
    return true;
  } else {
    return false;
  }
}

function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function capitalizeWordsInList(wordList) {
  return wordList.map(function(sentence) {
    return sentence
      .split(" ")
      .map(function(word) {
        return capitalizeFirstLetter(word);
      })
      .join(" ");
  });
}

function binaryStringToArrayBuffer(binary) {
  const len = binary.length;
  const buffer = new ArrayBuffer(len);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < len; i++) {
    view[i] = binary.charCodeAt(i);
  }
  return buffer;
}
function changeScrollPopup(value, className) {
  const popupContainer = document.querySelector(`.${className}`);

  if (popupContainer) {
    popupContainer.style.overflow = value;
    if (value === "hidden") {
      document.body.style.position = "fixed";
      document.body.style.width = "100%";
    } else {
      document.body.style.position = "";
      document.body.style.width = "";
    }
  }
}

const convertSecondsToMinutes = (seconds) => {
  if (!seconds) {
    return "0 min 1 sec";
  }
  if (seconds === "0") {
    return "0 min 1 sec";
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes} min ${remainingSeconds} sec`;
};

function timeDifferenceInMinutes(timestamp1, timestamp2) {
  var date1 = new Date(timestamp1);
  var date2 = new Date(timestamp2);

  var difference = date2 - date1;
  var minutes = Math.abs(Math.floor(difference / 60000)); // Use Math.abs for absolute value

  // Handle pluralization
  var minuteText = minutes === 1 ? "min" : "mins";

  return `${minutes} ${minuteText}`; // Returns the formatted string
}

function isOthersGender(selGender) {
  return (
    genders.find((gender) => `${gender.value}` === `${selGender}`) !==
      undefined &&
    (`${selGender}` === "0" || `${selGender}` === "3")
  );
}
function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

const addIdsToParagraphs = (description, lessonJson) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(description, 'text/html');
  const paragraphs = doc.querySelectorAll('h1, h2, h3, h4, h5, h6');

  // Function to normalize text for accurate matching
  const normalizeText = (text) => text?.trim().toLowerCase();

  // Function to generate a valid HTML ID from a section header
  const generateIdFromHeader = (header) => {
    return header.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/^-|-$/g, '');
  };

  paragraphs.forEach((p) => {
    const pText = normalizeText(p.textContent);

    lessonJson.forEach((section) => {
      const headerText = normalizeText(section.section_header);

      if (pText.includes(headerText)) {
        // Use the section_header value as an ID, transforming it into a valid HTML ID format
        p.id = generateIdFromHeader(section.section_header);
      }
    });
  });

  // Return the modified HTML as a string
  return doc.body.innerHTML;
};


function formatTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = '';

  if (hours > 0) {
      // Format for hours, minutes, and seconds
      formattedTime += `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}hrs`;
  } else if (minutes > 0) {
      // Format for minutes and seconds
      formattedTime += `${minutes}:${remainingSeconds.toString().padStart(2, '0')}min`;
  } else {
      // Format for seconds only
      formattedTime += `${remainingSeconds}s`;
  }

  return formattedTime;
}
function formatTimeAssignment(seconds) {
  const hours = seconds ? Math.floor(seconds / 3600) : '00';
  const minutes = seconds ? Math.floor((seconds % 3600) / 60) : '00';
  const remainingSeconds = seconds ? seconds % 60 : '00';

  let formattedTime = '';

      formattedTime += `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;

  return formattedTime;
}
function addFormattedTime(startTime, formattedTime) {
  // Parse the start time
  let [hours, minutes, seconds] = startTime.split(/[:\s]/);
  hours = hours % 12 + (startTime.includes('PM') ? 12 : 0);

  // Create a date object from the start time
  let startDate = new Date();
  startDate.setHours(hours, minutes, seconds, 0);

  // Calculate the additional seconds from formattedTime
  let additionalSeconds = 0;
  if (formattedTime.endsWith('min')) {
      let [addMinutes, addSeconds] = formattedTime.replace('min', '').split(':').map(Number);
      additionalSeconds = addMinutes * 60 + addSeconds;
  } else if (formattedTime.endsWith('s')) {
      additionalSeconds = parseInt(formattedTime);
  }

  // Add the duration to the start date
  startDate.setSeconds(startDate.getSeconds() + additionalSeconds);

  // Format the new time
  let newHours = startDate.getHours();
  let newMinutes = startDate.getMinutes().toString().padStart(2, '0');
  let newSeconds = startDate.getSeconds().toString().padStart(2, '0');
  let newTime = `${newHours % 12 || 12}:${newMinutes}:${newSeconds} ${newHours >= 12 ? 'PM' : 'AM'}`;

  return newTime;
}
export {
  useOutsideClick,
  getKeyByValue,
  getKeyByDictionary,
  getIdsForLanguages,
  isLinkOrText,
  capitalizeFirstLetter,
  capitalizeWordsInList,
  binaryStringToArrayBuffer,
  changeScrollPopup,
  convertSecondsToMinutes,
  truncate,
  getTitle,
  isOthersGender,
  timeDifferenceInMinutes,
  addIdsToParagraphs,
  formatTime,
  addFormattedTime,
  formatTimeAssignment
};
