import React, { useState } from "react";
// import "./CourseCard.css";
import { MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import StyledMenu from "../../../components/StyleMenu";

function SchoolCard({ school }) {
  const navigate = useNavigate();

  const viewSchoolDetail = (id) => {
    navigate(`/school-details/${id}`);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className="created-my-courses-folder">
        <div className="created-my-courses-folder-image-left-folder">
          <div className="created-my-courses-folder-image">
            <img src={school.image} alt="" />
          </div>
        </div>
        <div className="created-my-courses-des-wrapper">
          <div className="created-my-courses-title-folder">
            <div className="course-inner-title-wrapper">
              <div className="created-my-courses-title-wrapper">
                <h2 className="school_title">{school.name} <span className="course-location"><i class="fa-solid fa-location-dot"></i> <p>{school.location}</p></span></h2>
              </div>
            </div>
            <div className="edit-course-dropdown-menu-container">
              <div className="edit-course-or-delete-course" onClick={handleClick}>
                <i className="fa-solid fa-ellipsis-vertical"></i>
              </div>
              <StyledMenu
                id="demo-customized-menu"
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onClick={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();
                  }}
                  disableRipple
                >
                  <button
                    type="button"
                    className="course-edit-or-delete-folder"
                  >
                    <a href="#">
                      <i className="fa-solid fa-pencil"></i>
                    </a>
                    <p>Edit</p>
                  </button>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleClose();
                  }}
                >
                  <button
                    type="button"
                    className="course-edit-or-delete-folder"
                  >
                    <a href="#">
                      <i className="fa-solid fa-trash"></i>
                    </a>
                    <p>Delete</p>
                  </button>
                </MenuItem>
              </StyledMenu>
            </div>
          </div>
          <div className="Students-courses-details-container">
            <div className="Students-courses-details-folder course_inner_tab_details">
              <span>Total Students</span>
              <div className="Students-courses-details-counts">
                <p>{school.lesson_count}</p>
                <p>{school.lesson_count} </p>
              </div>
            </div>
            <div className="Students-courses-details-folder course_inner_tab_details">
              <span>Total Teachers</span>
              <div className="Students-courses-details-counts">
                <p>{school.start_date}</p>
              </div>
            </div>
            <div className="Students-courses-details-folder course_inner_tab_details">
              <span>Created Courses</span>
              <div className="Students-courses-details-counts">
                <p>{school.end_date}</p>
              </div>
            </div>
          </div>
          <p className="Students-des">{school.description}</p>
          <div className="course-main-container">
            <div
              className={
                school.Teacher_name?.length > 0
                  ? "course-inner-info"
                  : "course-inner-without-info"
              }
            >
              <div
                className={
                  school.course_info_image?.length > 0
                    ? "course-inner-info-with-image"
                    : "course-inner-info-without-image"
                }
              >
                <img src={school.course_info_image} alt="" />
              </div>
              <div className="course-inner-info-title">
                <h2>{school.Teacher_name}</h2>
                <p>{school.Teacher_title}</p>
              </div>
            </div>
            <button
              type="button"
              className="View-Course"
              onClick={() => viewSchoolDetail(school.school_id)}
            >
              View Details
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default SchoolCard;