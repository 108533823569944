import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PracticeAssignments {
    id: any;
    practiceassignments: any;
    next: string | null;
    previous: string | null;
    count: number | null;
    totalPages: number | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: PracticeAssignments = {
    id: null,
    practiceassignments: [],
    next: null,
    previous: null,
    count: 0,
    totalPages: 0,
    isLoading: false,
    error: null,
};

const practiceassignmentsSlice = createSlice({
    name: "practiceassignments",
    initialState,
    reducers: {
        setPracticeAssignmentsList: (state: PracticeAssignments, action: PayloadAction<PracticeAssignments[]>) => {
            action.payload.forEach(newAssignment => {
                const index = state.practiceassignments.findIndex((assignment: { id: any; }) => assignment.id === newAssignment.id);
                if (index !== -1) {
                    state.practiceassignments[index] = newAssignment;
                } else {
                    state.practiceassignments.push(newAssignment);
                }
            });
            state.isLoading = false;
            state.error = null;
        },
        setPagination: (state: PracticeAssignments, action: PayloadAction<PracticeAssignments>) => {
            state.next = action.payload.next;
            state.previous = action.payload.previous;
            state.count = action.payload.count;
            state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: PracticeAssignments, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: PracticeAssignments, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetPracticeAssignmentsList: () => {
            return initialState;
        },
    },
});

export const {
    setPracticeAssignmentsList,
    setloading,
    seterror,
    setPagination,
    resetPracticeAssignmentsList,
} = practiceassignmentsSlice.actions;

export default practiceassignmentsSlice.reducer;