import React, { useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"; // Import the CSS for cropper.js
import { Link } from "react-router-dom";
import { FaTimesCircle, FaCheckCircle } from "react-icons/fa";

const CropperComponent = (props) => {
  const cropperRef = useRef(null);
  const onCropSave = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    if (cropper) {
      const croppedImageUrl = cropper
        .getCroppedCanvas()
        .toDataURL("image/jpeg");

      props.setSrc(croppedImageUrl);
      props.setSelectedImage(croppedImageUrl);
      props.setUplodedImage(croppedImageUrl)

      cropper.getCroppedCanvas().toBlob((blob) => {

        if (blob) {
          const file = new File([blob], "cropped_profile_image.jpeg", {
            type: "image/jpeg",
          });
          props.setFormState((prevFormState) => ({
            ...prevFormState,
            [props.fieldName]: file,
          }));
        }
      }, "image/jpeg");
      props.setImageUploaded(true);
      props.modalClose(true);  // Pass true to confirm chan// Use onSave to indicate saving the image
    }
  };

  const onCancel = () => {
    props.modalClose(false);  // Pass false to discard changes

  };


  return (
    <>
      <Cropper
        src={props.src}
        ref={cropperRef}
        style={{
          height: props.isSmallScreen ? 200 : 500,
          width: props.isSmallScreen ? "80%" : "100%",
        }}
        aspectRatio={props.aspectRatio ? props.aspectRatio : 1}
        guides={false}
        background={false} // Set to false to disable default background (if possible)
        checkCrossOrigin={false}
      />
      <div className="crop_btn_sec">
        <Link className="cancel_btn" onClick={onCancel}>
          <FaTimesCircle />
          <span>Cancel</span>
        </Link>

        <Link className="save_btn" onClick={onCropSave}>
          <FaCheckCircle />
          <span>Save</span>
        </Link>
      </div>
    </>
  );
};

export default CropperComponent;