import dayjs from "dayjs";
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CourseCard from '../../../components/CourseCard/CourseCard';
import CustomTabs from '../../../components/CustomTabs/CustomTabs';
import { useAuth } from "../../../features/auth/AuthContext";
import SchoolCourseCard from '../../../modules/superadmin/SchoolCourseCard/SchoolCourseCard';
import TeacherStudentTab from '../../../modules/superadmin/TeacherStudentTable/TeacherStudentTab';
import { resetschoolbyid, schoolById } from "../../../state/actions/getSchoolByIDAction";
import './SchoolViewDetils.css';

const SchoolViewDetils = () => {
    const { token } = useAuth();
    const dispatch = useDispatch();
    const schooldetils = useSelector((state) => state.schoolById);
    const teacherdetils = useSelector((state) => state.allTeacherBySchool);
    let { school_id } = useParams();



    const tabList = [
        {
            title: "Total Teachers ("+schooldetils?.total_teachers+")",
            displayItem: <TeacherStudentTab roletype="Teacher"/>,
            key: "Total Teachers", // Add the title property
        },
        {
            title: "Total Students ("+schooldetils?.total_students+")",
            displayItem: <TeacherStudentTab roletype="Student"/>,
            key: "Total Students", // Add the title property
        },
        {
            title: "Total Courses ("+schooldetils?.total_courses+")",
            displayItem: <SchoolCourseCard />,
            key: "Total Courses", // Add the title property
        },
      ];
      
    const getAllSchoolDetails = () => {
        dispatch(schoolById(school_id,token));
      };
    
      useEffect(() => {
        getAllSchoolDetails();
      }, []);
      useEffect(() => {
        return () => {
          dispatch(resetschoolbyid());
        };
      }, []);
    return (
        <>
            <div className="School-View-Detils-section">
                <div className="School-View-Detils-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12 col-md-12">
                                <div className='School-View-Detils_card_wrapper'>
                                    <CourseCard
                                        courseimage={schooldetils.image}
                                        coursename= {schooldetils.name}
                                        schooljoined= {dayjs(schooldetils.joined_at).format("MMM DD, YYYY")}
                                        totalstudents= {schooldetils.total_students}
                                        totalteachers= {schooldetils.total_teachers}
                                        createdcourse= {schooldetils.total_courses}
                                        description= {schooldetils.description}
                                        View_Course= {schooldetils.View_Course}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <CustomTabs tabs={tabList} />
                </div>
            </div>
        </>
    )
}

export default SchoolViewDetils;