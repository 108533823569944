import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { getFileIdByName, resetFileIdByName } from "../../state/actions/getFileIdByNameAction";
import { updateFileUrl, resetupdateFileUrl } from "../../state/actions/updateFileUrlAction";
import { getFileStatus, resetgetfilestatus } from "../../state/actions/getUploadFileStatusAction";
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../features/auth/AuthContext';
import { toast } from "react-toastify";
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import * as XLSX from 'xlsx';
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";

GlobalWorkerOptions.workerSrc = 'https://cdn.jsdelivr.net/npm/pdfjs-dist/build/pdf.worker.min.js';

export function useAWSS3FileUpload({
    selectedFiles,
    setSelectedFiles,
    setuploadedFileLink,
    setShowUploadPop
}) {

    const [filesToUpload, setfilesToUpload] = useState([]);

    const { token } = useAuth();
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();

    const { fileList } = useSelector((state) => state.getFileIdByName);

    const handleIconClick = () => {
        fileInputRef.current.click();
    };

    const checkPasswordProtection = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onload = async (e) => {
                try {
                    const typedArray = new Uint8Array(e.target.result);
                    const loadingTask = getDocument({ data: typedArray });

                    await loadingTask.promise.then(
                        () => resolve({ isProtected: false, isCorrupted: false }),
                        (error) => {
                            if (error.name === 'PasswordException') {
                                resolve({ isProtected: true, isCorrupted: false });
                            } else if (error.name === 'InvalidPDFException') {
                                resolve({ isProtected: false, isCorrupted: true });
                            } else {
                                reject(error);
                            }
                        }
                    );
                } catch (error) {
                    reject(error);
                }
            };

            fileReader.readAsArrayBuffer(file);
        });
    };


    function checkExcelFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                try {
                    const data = new Uint8Array(e.target.result);
                    XLSX.read(data, { type: 'array' });
                    resolve({ isProtected: false, isCorrupted: false });
                } catch (error) {
                    if (error.message.includes('password-protected') || error.message.includes('File is password-protected')) {
                        resolve({ isProtected: true, isCorrupted: false });
                    } else {
                        resolve({ isProtected: false, isCorrupted: true });
                    }
                }
            };

            reader.onerror = () => {
                resolve({ isProtected: false, isCorrupted: true });
            };

            reader.readAsArrayBuffer(file);
        });
    }


    const checkWordFile = async (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = function (e) {
                try {
                    const content = e.target.result;
                    const zip = new PizZip(content);
                    new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });
                    resolve({ isProtected: false, isCorrupted: false });
                } catch (error) {
                    console.error("Error processing the file:", error);
                    resolve({ isProtected: true, isCorrupted: true });
                }
            };

            reader.onerror = function (e) {
                reject(e);
            };

            reader.readAsArrayBuffer(file);
        });
    };


    function checkImageCorruption(file) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(false);
            img.onerror = () => resolve(true);

            const reader = new FileReader();
            reader.onload = e => img.src = e.target.result;
            reader.onerror = e => reject(e);
            reader.readAsDataURL(file);
        });
    }

    const readFileContent = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject(new Error('Failed to read the file.'));
            reader.readAsText(file);
        });
    };

    const checkCorruption = (content) => {
        return /[\x00-\x08\x0E-\x1F]/.test(content);
    };

    const checkForProtection = (content) => {
        return content.startsWith("PROTECTED:");
    };

    const handleFileChange = async (e) => {

        let incomingFiles = e.target.files;
        let updatedFiles = Array.from(selectedFiles);
        let incomingNewFiles = Array.from(e.target.files);

        const duplicateFile = incomingNewFiles.find(incomingFile =>
            updatedFiles.some(selectedFile => selectedFile.name === incomingFile.name)
        );

        if (duplicateFile) {
            toast.error(`File with name "${duplicateFile.name}" already exists.`);
            return; // Stop further processing if a duplicate is found
        }

        let newFiles = [];
        setShowUploadPop(false);
        let totalSize = updatedFiles.reduce((acc, file) => acc + file.size, 0);
        const allowedExtensions = ['pdf', 'docx', 'doc', 'xls', 'xlsx', 'csv', 'png', 'jpg', 'jpeg', 'txt'];

        for (let i = 0; i < incomingFiles.length; i++) {
            const file = incomingFiles[i];
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (!allowedExtensions.includes(fileExtension)) {
                toast.error(`File type not supported: ${file.name}`);
                continue;
            }

            if (file.size === 0) {
                toast.error(`${file.name} seems corrupted.`);
                continue;
            }

            if (fileExtension === 'pdf') {
                try {
                    const { isProtected, isCorrupted } = await checkPasswordProtection(file);

                    if (isProtected) {
                        toast.error(`${file.name} is password protected.`);
                        continue;
                    } else if (isCorrupted) {
                        toast.error(`${file.name} is corrupted or invalid.`);
                        continue;
                    }
                } catch (error) {
                    toast.error(`Error checking ${file.name}: ${error.message}`);
                }
            }

            const spreadsheetExtensions = ["xlsx", "xls", "xlsm", "xlsb", "csv", "ods"];

            if (spreadsheetExtensions.includes(fileExtension)) {
                try {
                    const { isProtected, isCorrupted } = await checkExcelFile(file);

                    if (isProtected) {
                        toast.error(`${file.name} is password protected.`);
                        continue;
                    } else if (isCorrupted) {
                        toast.error(`${file.name} is corrupted or invalid.`);
                        continue;
                    }
                } catch (error) {
                    toast.error(`Error processing ${file.name}: ${error.message}`);
                    continue;
                }
            }

            const docExtensions = ["doc", "docx"];

            if (docExtensions.includes(fileExtension)) {
                try {
                    const { isProtected, isCorrupted } = await checkWordFile(file);

                    if (isProtected) {
                        toast.error(`${file.name} is password protected or corrupted.`);
                        continue;
                    }
                } catch (error) {
                    console.error("Error processing the file:", error);
                }
            }

            const imageExtensions = ["png", "jpg", "jpeg"];

            if (imageExtensions.includes(fileExtension)) {
                try {
                    const isCorrupted = await checkImageCorruption(file);

                    if (isCorrupted) {
                        toast.error(`${file.name} is corrupted or invalid.`);
                        continue;
                    }
                } catch (error) {
                    toast.error(`Error checking ${file.name}: ${error.message}`);
                }
            }

            if (fileExtension === "txt") {
                try {
                    const fileContent = await readFileContent(file);

                    const isCorrupted = checkCorruption(fileContent);
                    if (isCorrupted) {
                        toast.error(`${file.name} appears to be corrupted.`);
                        continue;
                    }

                    const isProtected = checkForProtection(fileContent);
                    if (isProtected) {
                        toast.error(`${file.name} appears to be password protected.`);
                        continue;
                    }

                } catch (error) {
                    toast.error(`Error processing ${file.name}: ${error.message}`);
                }
            }


            if (updatedFiles.length < 2 && totalSize + file.size <= 40 * 1024 * 1024) {
                updatedFiles.push(file);
                newFiles.push(file)
                totalSize += file.size;
            } else {
                toast.error("You can only upload 2 files with a total file size of 40MB.");
                break;
            }
        }
        if (updatedFiles.length <= 2 && totalSize <= 40 * 1024 * 1024) {
            setSelectedFiles(updatedFiles);
            setfilesToUpload(newFiles);
        } else {
            e.target.value = null;
        }
    };

    const handleFileStatus = (file_id) => {
        dispatch(getFileStatus(file_id, token))
    }

    const handleUpload = async () => {
        if (!filesToUpload.length) {
            alert('Please choose a file to upload first.');
            return;
        }
    
        try {
            const newArr = [];
    
            for (const file of filesToUpload) {
                const matchingFile = fileList.find(f => f.original_name === file.name);
                if (matchingFile) {
                    const { presigned_url, file_id } = matchingFile;
                    await uploadFileToS3(file, presigned_url);
    
                    const fileLink = {
                        file_id: file_id, // Ensure file_id is retrieved here
                        name: file.name,
                        url: presigned_url.split('?')[0],
                    };
    
                    newArr.push(fileLink);
    
                    const req = {
                        file_upload: [{
                            file_id: file_id,
                            link: fileLink.url,
                        }]
                    };
    
                    dispatch(updateFileUrl(req, token));
                    handleFileStatus(file_id);
                } else {
                    console.error(`No matching file found for ${file.name}`);
                }
            }
    
            setuploadedFileLink(prev => [
                ...prev,
                ...newArr,
            ]);
    
            setfilesToUpload([]);
            dispatch(resetFileIdByName());
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };
    
    const uploadFileToS3 = async (file, url) => {
        try {
            await axios.put(url, file, {
                headers: {
                    'Content-Type': file.type,
                },
            });
        } catch (error) {
            console.error('Error uploading file:', error);
            toast.error(`Error uploading ${file.name}`);
            throw error;
        }
    };

    useEffect(() => {
        if (fileList.length) {
            handleUpload();
        }
    }, [fileList]);

    useEffect(() => {
        if (filesToUpload.length) {
            dispatch(getFileIdByName({ file_name: filesToUpload.map(f => f.name) }, token));
        }
    }, [filesToUpload]);

    useEffect(() => {
        dispatch(resetgetfilestatus());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            setSelectedFiles([]);
            dispatch(resetFileIdByName());
            dispatch(resetupdateFileUrl());
            dispatch(resetgetfilestatus());
        }
    }, [dispatch, setSelectedFiles]);

    return {
        handleIconClick,
        handleFileChange,
        fileInputRef
    };

}
