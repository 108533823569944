import { Dispatch } from "redux";
import api from "../../api/api";
import {
    getfilestatus,
    setFileUploadProgress,
    setloading,
    seterror,
    resetgetfilestatus,
} from "../reducers/getUploadFileStatusReducer";

const getFileStatus = (id: any, token: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));

    const completedIds = new Set();

    const checkStatus = async () => {
        try {

            if (completedIds.has(id)) {
                // If ID is already completed or failed, do not call API
                return;
            }

            const result = await api.get(`/ai/api/get-file-status/${id}/`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Token ${token}`,
                    },
                });

            if (result) {

                if (result.data.data.status.toLowerCase() === "completed") {
                    completedIds.add(id);
                    dispatch(setFileUploadProgress("100"));
                    dispatch(setloading(false));
                    dispatch(getfilestatus(result.data.data));
                }
                else if (result.data.data.status.toLowerCase() === "failed") {
                    completedIds.add(id);
                    dispatch(setFileUploadProgress("0"));
                    dispatch(setloading(false));
                    dispatch(getfilestatus(result.data.data));
                }
                else {
                    switch (result.data.data.status.toLowerCase()) {
                        case "started": dispatch(setFileUploadProgress("5"));
                            break;
                        case "uploaded": dispatch(setFileUploadProgress("20"));
                            break;
                        case "processing": dispatch(setFileUploadProgress("40"));
                            break;
                        case "chunking": dispatch(setFileUploadProgress("60"));
                            break;
                        case "parsed": dispatch(setFileUploadProgress("80"));
                            break;
                        case "vector": dispatch(setFileUploadProgress("90"));
                            break;
                        default: dispatch(setFileUploadProgress("0"));
                    }
                    dispatch(setloading(false));
                    dispatch(getfilestatus(result.data.data));
                    setTimeout(checkStatus, 5000);
                }

            }
        } catch (error: any) {
            // dispatch(seterror(error.response.data.message));
        }
    }

    checkStatus();

};

export { getFileStatus, resetgetfilestatus };

