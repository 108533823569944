import React, { useEffect, useRef, useState } from "react";
import "./GoogleUploadPopup.css";
import {
  material_symbols_light_upload,
  google_drive,
  downlode_pdf_image
} from "../../assets/images/images";
import GoogleDriveUpload from "../FileUploadToS3/GoogleDriveUpload";
import DeleteAWSFiles from "./DeleteAWSFiles";
import jpg from "../../assets/images/jpg.png";
import pdf_img from "../../assets/images/pdf.png";
import png_img from "../../assets/images/png.png";
import xlsx_img from "../../assets/images/xls.png";
import csv_img from "../../assets/images/csv-file.png";
import doc_img from "../../assets/images/docx-file.png";
import file_img_storage from "../../assets/images/file-storage.png";
import DraggableFileUpload from "./DraggableFileUpload";
import { AWSCourseFilesUpload } from "./AWSFilesUpload";
import { getUploadedCourseFiles, resetUploadedCourseFiles } from "../../state/actions/getCourseFileUploadsAction";
import { useDispatch, useSelector } from "react-redux";
import { useAuth } from "../../features/auth/AuthContext";
import { useParams } from "react-router-dom";

function GoogleUploadPopup({
  setIsShowGoogleUploadPopup,
  isShowGoogleUploadPopup,
  selectedFiles,
  setSelectedFiles
}) {

  const [showUploadPop, setShowUploadPop] = useState(false);
  const [uploadedFileLink, setuploadedFileLink] = useState([]);
  const [progressTracker, setprogressTracker] = useState([]);
  const [courseFileList, setcourseFileList] = useState([]);
  const { token } = useAuth();
  const dispatch = useDispatch();
  const { id } = useParams();
  const fileInputRef = useRef(null);

  const getCourseFiles = useSelector((state) => state.getCourseFileUploads);
  const { message } = useSelector((state) => state.deleteUploadedCourseFile);

  const { handleFileChange } = AWSCourseFilesUpload({
    selectedFiles,
    setSelectedFiles,
    setuploadedFileLink,
    setShowUploadPop,
    courseFileList,
    setcourseFileList,
  });

  const getFileIcon = (fileName) => {

    const extension = fileName
      .split(".")
      .pop()
      .toLowerCase();

    switch (extension) {
      case "pdf":
        return pdf_img;
      case "doc":
        return doc_img;
      case "docx":
        return doc_img;
      case "xls":
        return xlsx_img;
      case "xlsx":
        return xlsx_img;
      case "csv":
        return csv_img;
      case "jpg":
        return jpg;
      case "jpeg":
        return jpg;
      case "png":
        return png_img;
      default:
        return file_img_storage; // Default icon if the file type is not recognized
    }
  };

  useEffect(() => {
    dispatch(getUploadedCourseFiles(id, token));
  }, [message]);

  useEffect(() => {
    setcourseFileList(getCourseFiles.courseFiles);
  }, [getCourseFiles])

  return (
    <>
      {isShowGoogleUploadPopup && (
        <div className="google-uplode-popup-section">
          <div className="google-uplode-popup-wrapper">
            <div className="Select-files-popup-wrapper">
              <div className="select-Files-allow-download-popup-container">
                <i
                  class="fa-solid fa-x select-Files-allow-download-popup-close-btn"
                  onClick={() => {
                    setIsShowGoogleUploadPopup(false);
                  }}
                ></i>
                <div className="select-Files-allow-download-inner-popup-folder">
                  {courseFileList.length > 0 && <div className="select-Files-allow-download-popup-scroller">
                    <DeleteAWSFiles
                      selectedFiles={selectedFiles}
                      setSelectedFiles={setSelectedFiles}
                      uploadedFileLink={uploadedFileLink}
                      setuploadedFileLink={setuploadedFileLink}
                      progressTracker={progressTracker}
                      setprogressTracker={setprogressTracker}
                      getFileIcon={getFileIcon}
                      courseFileList={courseFileList}
                      fileInputRef={fileInputRef}
                    />
                  </div>}

                  <DraggableFileUpload
                    handleFileChange={handleFileChange}
                    fileInputRef={fileInputRef}
                  />
                  <p>or</p>
                  <div className="connect-google-drive-btn">

                    <GoogleDriveUpload
                      setShowUploadPop={setShowUploadPop}
                      handleFileChange={handleFileChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GoogleUploadPopup;
