import React from "react";
import "./CourseCard.css";
import { useNavigate } from "react-router-dom";

function CourseCard({ teacher_id,course,courseimage, coursename, enrolled_students, lesson_count, start_date, end_date, description , course_info_image, Teacher_name, Teacher_title, Sales_Courses_title, checkbox, location, location_name}) {
    const navigate = useNavigate();
        const viewCourseHandler = () => {
            navigate(`/course/${course.course_id}/view`, {
                state: { course, teacher_id },
            });
    }
 
  return <>
    <div className="created-my-courses-folder">
        <div className="created-my-courses-folder-image-left-folder">
            <div className="created-my-courses-folder-image" onClick={viewCourseHandler}>
                <img src={courseimage} alt="" />
            </div>
        </div>
        <div className="created-my-courses-des-wrapper">
            <div className="created-my-courses-title-folder">
                <div className="course-inner-title-wrapper">
                    <div className="created-my-courses-title-wrapper" onClick={viewCourseHandler}>
                        <h2 className="school_title">{coursename}</h2>
                    </div>
                    <div className="course-location">
                        <span>{location}</span>
                        <p>{location_name}</p>
                    </div>  
                </div>
                <div className="edit-course-dropdown-menu-container">
                    <div className="Sales-Courses-toggle-btn">
                        <p>{Sales_Courses_title}</p>
                        <label className="switch">
                            <input type={checkbox}/>
                        </label>
                    </div>
                </div>
            </div>
            <div className="Students-courses-details-container">
                <div className="Students-courses-details-folder">
                    <span>Students Enrolled</span>
                    <div className="Students-courses-details-counts">
                        <p>{enrolled_students}</p>
                    </div>
                </div>
                <div className="Students-courses-details-folder">
                    <span>Total Lessons</span>
                    <div className="Students-courses-details-counts">
                        <p>{lesson_count}</p>
                    </div>
                </div>
                <div className="Students-courses-details-folder">
                    <span>Course Start Date</span>
                    <div className="Students-courses-details-counts">
                        <p>{start_date}</p>
                    </div>
                </div>
                <div className="Students-courses-details-folder">
                    <span>Course Expiry Date</span>
                    <div className="Students-courses-details-counts">
                        <p>
                            {end_date ? end_date : '- -'}
                        </p>
                    </div>
                </div>
            </div>
            <p className="Students-des">{description}</p>
            <div className="course-inner-info-wrapper">
                <div className="course-inner-info">
                    <div className="course-inner-info-image">
                        <img src={course_info_image} alt="" />
                    </div>
                    <div className="course-inner-info-title" >
                        <h2>{Teacher_name}</h2>
                        <p>{Teacher_title}</p>
                    </div>
                </div>
                <button type="button" className="View-Course" onClick={viewCourseHandler}>View Course</button>
            </div>
        </div>
    </div>
  </> 
}

export default CourseCard;