import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api, { wsURL } from "../../../../../../api/api";
import AssignmentTimer from "../../../../../../components/assignmentTimer/AssignmentTimer";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import useSubmitAssignment from "../../../../../../hooks/useAssignmentSubmission";
import useWebSocketConnection from "../../../../../../hooks/useWebSOcketConnection";
import Question from "../../../questions/Question";
import StudentAssignmentScore from "./StudentAssignmentScore";
import BackButton from "../../../../../../components/BackButton/BackButton";
import CongratulationsPopup from '../../../../../../../src/components/CongratulationsPopup/CongratulationsPopup';
import QuestionNew from "../../../questions/Question_new";
import { CircularProgress, Grid } from "@mui/material";
import StudentLessonPreview from "./StudentLessonPreview";
import "./StudentAssignmentPreview.css";
import {
  check_image
} from "../../../../../../assets/images/images";
import { getPracticeAssignments } from "../../../../../../state/actions/getPracticeAssignmentsAction";

const StudentAssignmentPreview = () => {
  const globalState = useSelector((state) => state.globalState);
  const studentId = globalState.student_details?.data?.student_id ?? "";
  const navigate = useNavigate();
  const location = useLocation();

  const { assignment_id, course_id, lesson_id } = useParams();

  const [showCongratulations, setShowCongratulations] = useState(false);


  useEffect(() => {
    if (showCongratulations) {
      setTimeout(() => {
        setShowCongratulations(false)
      }, 2000)
    }

  }, [showCongratulations])

  const { token } = useAuth();
  const [assignment, setAssignment] = useState(
    location?.state?.assignment || {}
  );




  const lesson = location?.state.lesson;

  const [assignmentIndex, setAssignmentIndex] = useState(0);
  const [detailedResult, setdetailedResult] = useState(false);
  // const [showScorePopup, setshowScorePopup] = useState(false);
  const [answeredList, setAnsweredList] = useState([]);
  const [getSubmissiondtls, setSubmissiondtls] = useState(localStorage.getItem(`getSubmissiondtlsData${assignment_id}${token}`) ? JSON.parse(localStorage.getItem(`getSubmissiondtlsData${assignment_id}${token}`)) : []);
  const [showScorePopup, setshowScorePopup] = useState(JSON.parse(localStorage.getItem(`congratulationMessgaePopUp${assignment_id}${token}`)) || false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignmentLoading, setisAssignmentLoading] = useState(false);
  const [showSubmit, setShowPopup] = useState(false);
  const [assignmentClockTimer, setassignmentClockTimer] = useState(true);
  const dispatch = useDispatch();
  const [isLessonPreviewEnabled, setIsLessonPreviewEnabled] = useState(false);
  const socketRef = useRef(null);
  const [
    isVirtualAssistantPreviewEnabled,
    setIsVirtualAssistantPreviewEnabled,
  ] = useState(false);
  const [isTimerPaused, setIsTimerPaused] = useState(false);
  const [timers, setTimers] = useState(
    Array(assignment?.assignment_json?.length).fill(0)
  );
  const [currentStep, setCurrentStep] = useState(0);
  const assignmentJSON = assignment?.assignment_json?.map((item) => {
    return { ...item, student_answer: null, time_taken: "0" };
  });

  const practiceAssignments = useSelector(
    (state) => state.getPracticeAssignments
  );

  useEffect(() => {
    localStorage.setItem(`congratulationMessgaePopUp${assignment_id}${token}`, showScorePopup);
    console.log(getSubmissiondtls)
    localStorage.setItem(`getSubmissiondtlsData${assignment_id}${token}`, JSON.stringify(getSubmissiondtls));
  }, [showScorePopup, getSubmissiondtls, assignment_id, token])


  const [startIndex, setStartIndex] = useState(1);
  const [lastIndex, setLastIndex] = useState(assignmentJSON?.length);
  const [rangIndex, setRangIndex] = useState(4);
  useEffect(() => {
    const sAPDtls_AssignmentId = localStorage.getItem(
      `sAPDtls_AssignmentId${assignment_id}${token}`
    );
    if (sAPDtls_AssignmentId &&
      sAPDtls_AssignmentId == assignment_id
    ) {
      const sAProgressDtls = localStorage.getItem(
        `studentAssignmentProgressDtls${assignment_id}${token}`
      );
      if (sAProgressDtls) {
        setAnsweredList(JSON.parse(sAProgressDtls));
        const sAPCurrentStep = localStorage.getItem(
          `studentAssignmentProgressCurrentStep${assignment_id}${token}`
        );
        setCurrentStep(Number(sAPCurrentStep));
        const sAPCurrentStepForQuestion = localStorage.getItem(
          `studentAssignmentProgressCurrentStepForQuestion${assignment_id}${token}`
        );
        setStartIndex(sAPCurrentStepForQuestion);
      }
    }
  }, []);

  useEffect(() => {
    // console.log("assignment_id", assignment_id);
    if (location?.state?.type === 1) {
      if (assignment_id) {
        getAssignment(assignment_id);
      }
    }
    if (location?.state?.type === 2) {
      dispatch(
        getPracticeAssignments(
          location?.state?.assignment?.assignment_id,
          token
        )
      );
    }
    // countCompletedLessons(assignmentDtls);
  }, [lesson_id, assignment_id]);


  useEffect(() => {
    if (practiceAssignments.practiceassignments.length > 0) {
      setisAssignmentLoading(false);


      const matchingPracAssigns = practiceAssignments.practiceassignments.filter(
        (pracAssign) => pracAssign.id === location?.state?.assignment?.id
      );
      const totallengthAssignment = (matchingPracAssigns.length - 1);
      let pracAssign = matchingPracAssigns[totallengthAssignment];
      setAssignment(pracAssign);

      setIsLessonPreviewEnabled(pracAssign?.lesson_preview);
      setIsVirtualAssistantPreviewEnabled(pracAssign?.virtual_assistant);
      // if (
      //   !pracAssign ||
      //   !pracAssign.assignment_status ||
      //   !pracAssign.assignment_status.status
      // ) {
      //   // console.log("Triggering createAssignmentStatus");
      //   createAssignmentStatus(location?.state?.assignment?.id);
      // }
    }
  }, [practiceAssignments]);

  const getAssignment = async () => {
    setisAssignmentLoading(true);
    await api
      .get(`assignments/get-assignment/${assignment_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setisAssignmentLoading(false);
          setAssignment(response?.data?.data);
          setIsLessonPreviewEnabled(response?.data?.data?.lesson_preview);
          setIsVirtualAssistantPreviewEnabled(true);
          //   response?.data?.data?.virtual_assistant
          // );

          const assignmentData = response?.data?.data;

          // if (
          //   !assignmentData &&
          //   !assignmentData.assignment_status &&
          //   !assignmentData.assignment_status.status
          // ) {
          //   createAssignmentStatus(assignment_id);
          // }
        }
      })
      .catch(() => {
        setisAssignmentLoading(false);
      });
  };

  const [submission_id, setSubmission_id] = useState(0);
  const totalSteps = assignmentJSON?.length;
  const [getSocket, setSocket] = useState(null);

  const handleNext = async (answeredQuestion) => {
    const existingIndex = answeredList.findIndex(
      (question) => question.question_id === answeredQuestion.question_id
    );

    let updatedList;
    if (existingIndex !== -1) {
      updatedList = answeredList.map((question, index) =>
        index === existingIndex ? answeredQuestion : question
      );
    } else {
      updatedList = [...answeredList, answeredQuestion];
    }

    setAnsweredList(updatedList);
    localStorage.setItem(`sAPDtls_AssignmentId${assignment_id}${token}`, assignment_id);
    localStorage.setItem(
      `studentAssignmentProgressDtls${assignment_id}${token}`,
      JSON.stringify(updatedList)
    );
    if (currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
      localStorage.setItem(
        `studentAssignmentProgressCurrentStep${assignment_id}${token}`,
        currentStep + 1
      );
      setStartIndex(startIndex + 1);
      localStorage.setItem(
        `studentAssignmentProgressCurrentStepForQuestion${assignment_id}${token}`,
        startIndex
      );
    } else {
      setShowPopup(true);
      setassignmentClockTimer((prevIsRunning) => !prevIsRunning);
    }
  };

  // const socket = useWebSocketConnection(
  //   `${wsURL}//ws/v2/assignment_submission/${parseInt(studentId)}/`
  // );
  // useEffect(() => {
  //   const initializeWebSocket = () => {
  //     if (socket) {
  //       let reconnectInterval = 1000; // Initial reconnect interval in ms
  //       const maxReconnectInterval = 30000; // Max reconnect interval
  //       let reconnectAttempts = 0; // Number of reconnect attempts
  //       const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
  //       const maxReconnectAttempts = 10; // Maximum number of reconnect attempts

  //       //  Event handler for when the WebSocket connection is established
  //       socket.onopen = () => {
  //         // console.log("WebSocket connection opened");
  //         reconnectAttempts = 0; // Reset reconnect attempts on successful connection
  //         reconnectInterval = 1000; // Reset reconnect interval
  //       };

  //       socket.onmessage = handleWebSocketMessage;

  //       socket.onclose = () => {
  //         if (reconnectAttempts < maxReconnectAttempts) {
  //           setTimeout(() => {
  //             reconnectAttempts++;
  //             socket.close(); // Make sure to close old sockets if not yet closed
  //             initializeWebSocket();
  //             reconnectInterval = Math.min(
  //               reconnectInterval * reconnectDecay,
  //               maxReconnectInterval
  //             );
  //           }, reconnectInterval);
  //         } else {
  //         }
  //       };

  //       socket.onerror = (event) => {
  //         setIsLoading(false);
  //         // Handle WebSocket errors
  //         console.error("WebSocket error observed:", event.message);
  //       };
  //     }
  //   };
  //   initializeWebSocket();
  // }, [socket]);


  useEffect(() => {
    if (!assignment_id || !studentId) return;

    let reconnectAttempts = 0;
    let reconnectInterval = 1000; // Initial reconnect interval in ms
    const maxReconnectInterval = 30000; // Max reconnect interval
    const reconnectDecay = 1.5; // Rate of increase of the reconnect delay
    const maxReconnectAttempts = 5; // Maximum number of reconnect attempts

    const connectWebSocket = () => {

      const newWebSocketURL = `${wsURL}//ws/v2/assignment_submission/${parseInt(studentId)}/`;

      // console.log(`Attempting to connect to WebSocket at ${newWebSocketURL}`);
      socketRef.current = new WebSocket(newWebSocketURL); // Assign to ref

      socketRef.current.onopen = () => {
        console.log("WebSocket connection opened");
        reconnectAttempts = 0; // Reset reconnect attempts
        reconnectInterval = 1000; // Reset reconnect interval
      };

      socketRef.current.onmessage = (event) => {
        // console.log("Message received from WebSocket:", event.data);
        handleWebSocketMessage(event);
      };


      // Reconnection logic on close
      socketRef.current.onclose = (event) => {
        console.warn("WebSocket connection closed:", event.reason);
        if (reconnectAttempts < maxReconnectAttempts) {
          reconnectAttempts++;
          setTimeout(() => {
            reconnectInterval = Math.min(
              reconnectInterval * reconnectDecay,
              maxReconnectInterval
            );
            console.log(`Reconnecting...`);
            connectWebSocket(); // Attempt to reconnect
          }, reconnectInterval);
        } else {
          console.error("Maximum reconnection attempts reached. Cannot reconnect.");
        }
      };

      // WebSocket error handling
      socketRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
        socketRef.current.close(); // Ensure the socket is closed properly
      };
    };

    // Connect WebSocket initially
    connectWebSocket();

    // Cleanup WebSocket when component unmounts
    return () => {
      if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
        console.log("Closing WebSocket connection.");
        socketRef.current.close();
      }
    };
  }, [assignment_id, studentId, wsURL]);

  const handleWebSocketMessage = async (event) => {

    try {
      const data = JSON.parse(event.data);
      // console.log("WebSocket message received:", data);


      setSubmissiondtls(data);
      // setIsLoading(false); // Stop loading when last question is processed
      // console.log("Stopping loader...");
      setIsLoading(false);
      // console.log("Loader stopped.");
      setShowPopup(false);
      setshowScorePopup(true);
      // await getAssignment(lesson_id);
      if (location?.state?.type === 1) {
        console.log(location?.state?.assignment?.assignment_id);
        await getAssignment(location?.state?.assignment?.assignment_id);
      }
      if (location?.state?.type === 2) {
        dispatch(
          getPracticeAssignments(
            location?.state?.assignment?.assignment_id,
            token
          )
        );

      }
    } catch (error) {
      setIsLoading(false);
      console.error("WebSocket message error:", error);
    }
  };


  // const handleWebSocketMessage = async (event) => {
  //   try {
  //     // Parse the incoming JSON message
  //     const data = JSON.parse(event.data);
  //     // const assignmentStatusId = localStorage.getItem("assignmentStatusId");
  //     // Check if the message represents a successful submission
  //     setIsLoading(false);
  //     setShowPopup(false);
  //     setSubmissiondtls(data);
  //     // const grade = Object.keys(data.grade)[0];
  //     if (location?.state?.type === 1) {
  //       console.log(location?.state?.assignment?.assignment_id);
  //       await getAssignment(location?.state?.assignment?.assignment_id);
  //     }
  //     if (location?.state?.type === 2) {
  //       dispatch(
  //         getPracticeAssignments(
  //           location?.state?.assignment?.assignment_id,
  //           token
  //         )
  //       );
  //       // let pracAssign = practiceAssignments.practiceassignments.find(
  //       //   (pracAssign) => pracAssign.id === location?.state?.assignment?.id
  //       // );
  //       // console.log('pracAssign',pracAssign);
  //       // console.log('location?.state?.assignment?.id',location?.state?.assignment?.id);
  //       // setAssignment(pracAssign);
  //     }
  //     // await updateAssignmentStatus(assignmentStatusId, grade);
  //     // You can call your putAssignmentStatus function here if needed
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.error("WebSocket message error", error);
  //   }
  // };


  const { isLoadingAssignment, error, submitAssignment } = useSubmitAssignment(
    socketRef.current,
    handleWebSocketMessage
  );

  const typeValue = location?.state?.type;


  const handleSubmitAssignment = async (questionAnswers) => {
    setIsTimerPaused(true);
    setIsLoading(true); // Start loading when submitting
    const payload = {
      // assignment_type: 'assignment',
      assignment_type: typeValue === 1
        ? 'assignment'
        : typeValue === 2
          ? 'practice_assignment'
          : undefined, // Default or error handling if type is neither 1 nor 2
      student_id: parseInt(studentId),
      // assignment_id: assignment?.assignment_id,
      assignment_id:
        location?.state?.type === 1
          ? assignment?.assignment_id
          : assignment?.id,
      rubric_id: assignment?.rubric_id ?? null,
      questions_answers: questionAnswers ?? answeredList,
    };

    try {
      submitAssignment(payload);
    } catch (error) {
      setIsLoading(false);
      console.error("WebSocket error", error);
    }
  };

  const createAssignmentStatus = async (assignment_id) => {
    const payload = {
      student_id: globalState.student_details.data.student_id,
      assignment_id: assignment_id,
      status: 3,
      completed_name: assignment.name,
      assignment_type: location?.state?.type
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    await api
      .post(`assignments/create-assignment-status/`, payload, {
        headers,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          localStorage.setItem("assignmentStatusId", response?.data?.data?.id);
        }
      })
      .catch(() => { });
  };

  const updateAssignmentStatus = async (status_id, score) => {
    const payload = {
      status: 1,
      score: score,
      completed_at: new Date().toISOString(),
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    };
    await api
      .put(`assignments/update-assignment-status/${status_id}/`, payload, {
        headers,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          localStorage.removeItem("assignmentStatusId");
        }
      })
      .catch(() => { });
  };


  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      setStartIndex(startIndex - 1);
      setIsTimerPaused(false);
    }
  };

  const handleTimeUp = () => {
  };

  const isLastQuestion = assignmentJSON?.length === currentStep + 1;

  const showScore = () => {
    setshowScorePopup(false);
  };

  const showDetailedResult = () => {
    setAssignmentIndex(0);
    setdetailedResult(!detailedResult);
  };

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [showStudentLessonPreview, setShowStudentLessonPreview] = useState(
    isSwitchOn
  );

  useEffect(() => {
    setShowStudentLessonPreview(isSwitchOn);
  }, [isSwitchOn]);

  const handleSwitchToggle = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const getStepNumbers = () => {
    const steps = [];
    const currentIndexVal = currentStep + 1;
    const middleRange = Math.floor(rangIndex / 2);
    let start = Math.max(1, currentIndexVal - middleRange);
    let end = Math.min(lastIndex, currentIndexVal + middleRange);

    if (currentIndexVal <= middleRange) {
      end = Math.min(rangIndex, lastIndex);
    } else if (currentIndexVal >= lastIndex - middleRange) {
      start = Math.max(1, lastIndex - rangIndex + 1);
    }

    if (start > 1) steps.push(1);
    if (start > 2) steps.push("...");

    for (let i = start; i <= end; i++) {
      steps.push(i);
    }

    if (end < lastIndex - 1) steps.push("...");
    if (end < lastIndex) steps.push(lastIndex);

    return steps;
  };

  return (
    <>
      <div id="AssingmentMCQs-section">
        <div className="AssingmentMCQs-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="AssingmentMCQs-header-main-contaiiner">
                  <div
                    className="view-course-Details-back-btn-folder"
                    id="AssingmentMCQs-back-btn"
                  >
                    <BackButton buttonName={assignment?.name} />
                  </div>
                  {isLessonPreviewEnabled && (
                    <div className="AssingmentMCQs-show-lesson-preview">
                      <h2>Show Lesson Preview</h2>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isSwitchOn}
                          onChange={handleSwitchToggle} // Use onChange event 
                        />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  )}
                </div>
                {isAssignmentLoading ? (
                  <>
                    <p style={{ textAlign: "center" }}>
                      Loading Assignment... Please wait!
                    </p>
                    <div className="spinner-folder">
                      <Grid
                        height={"50vh"}
                        container
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Grid item xs={12}>
                          <CircularProgress size={80} />
                        </Grid>
                      </Grid>
                    </div>
                  </>
                ) : (
                  <div className="AssingmentMCQs-container">
                    {showStudentLessonPreview && (
                      <StudentLessonPreview lesson={lesson} />
                    )}
                    <div
                      className={`AssingmentMCQs-right-section ${showStudentLessonPreview ? "" : "full-width"
                        }`}
                    >
                      <AssignmentTimer
                        duration={(assignment?.duration_minutes ?? 0) * 60} 
                        allotted_time={assignment?.duration_minutes}
                        onTimeUp={handleTimeUp}
                        isTimerPaused={isTimerPaused}
                        timers={timers}
                        setTimers={setTimers}
                        currentStep={currentStep}
                        isAssignmentLoading={isAssignmentLoading}
                        assignmentClockTimer={assignmentClockTimer}
                      />
                      <div className="AssingmentMCQs-main-wrapper">
                        <div className="step-container">
                          {getStepNumbers().map((step, index) => {
                            const isAnswered = answeredList.some(
                              (question) => question.question_id === step
                            );
                            const stepchecker = currentStep + 1 === step ? true : false;
                            return (
                              <div
                                key={index}
                                className={`step ${stepchecker ? "active" : ""
                                  } ${isAnswered && !stepchecker ? "answered" : ""}`}
                              >
                                {step}
                              </div>
                            );
                          })}
                        </div>
                        {assignmentJSON?.map((assign, index) => (
                          <div key={index}>
                            <Question
                              duration={
                                (assignment?.duration_minutes ?? 0) * 60
                              }
                              assignment={assign}
                              currentAssignment={assignmentJSON[currentStep]}
                              index={index}
                              currentStep={currentStep}
                              handleBack={handleBack}
                              handleNext={handleNext}
                              isLast={isLastQuestion}
                              lesson={lesson}
                              course_id={course_id}
                              lesson_id={lesson_id}
                              assignment_id={assignment_id}
                              timers={timers}
                              virtualAssistant={
                                isVirtualAssistantPreviewEnabled
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {showScorePopup && (
          <StudentAssignmentScore
            showScore={showScore}
            getSubmissiondtls={getSubmissiondtls}
            onclick={() => {
              // navigate(-2)
              navigate(`/course/${course_id}/`)
              localStorage.setItem(`congratulationMessgaePopUp${assignment_id}${token}`, false);
              localStorage.setItem(`getSubmissiondtlsData${assignment_id}${token}`, []);
            }}
            title={"Go to Course"}
            isCurrentAssignment={true}
            onclick2={showDetailedResult}
            title2={"View Detailed Result"}
            title3={"Review Assignment"}
            assignment={assignment}
            setshowScorePopup={setshowScorePopup}
            setShowCongratulations={setShowCongratulations}
            isNaviget={true}
          />
        )}

        {showCongratulations && <CongratulationsPopup
          check_image={check_image}
          des="Review Assignments has been generated successfully."
        />}
        {showSubmit && (
          <form
            action=""
            className="delete-course-form"
            id="delete-AssingmentMCQs-form"
          >
            <div className="delete-course-form-container">
              {isLoading ? (
                <>
                  <p>
                    Submission is in progress. Please keep this browser tab open
                    to ensure your submission completes successfully.
                  </p>
                  <Grid
                    height={"50vh"}
                    xs={12}
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CircularProgress size={80} />
                  </Grid>
                </>
              ) : (
                <>
                  <h2>Are you sure you want to submit the assignment?</h2>
                  <div className="delete-course-form-btn-folder">
                    <button
                      type="button"
                      disabled={isLoading}
                      onClick={() => {
                        setIsTimerPaused(false);
                        setShowPopup(false);
                        setassignmentClockTimer(true);
                      }}
                      className="not-delete-btn"
                    >
                      Back
                    </button>
                    {isLoading ? (
                      <div className="loading-spinner-course" />
                    ) : (
                      <button
                        onClick={() => {
                          handleSubmitAssignment();
                        }}
                        type="button"
                        className="delete-btn"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </form>
        )}
      </div>

      {detailedResult && (
        <div className="AssignmentViewProgress-mock-test-wrapper">
          <div className="AssignmentViewProgress-mock-test-container">
            {
              <QuestionNew
                assignment={
                  assignment?.submission?.student_answers_json[assignmentIndex]
                }
                ai_evaluation_json={assignment?.submission?.ai_evaluation_json}
                index={assignmentIndex}
                currentStep={assignmentIndex + 1}
                totalSteps={assignment?.submission?.student_answers_json.length}
                handleNext={() =>
                  assignmentIndex <
                    assignment?.submission?.student_answers_json.length - 1
                    ? setAssignmentIndex((p) => p + 1)
                    : null
                }
                handleBack={() => setAssignmentIndex((p) => p - 1)}
                isLast={
                  assignmentIndex ===
                  assignment?.submission?.student_answers_json.length - 1
                }
                isFirst={assignmentIndex === 0}
                close={
                  <span
                    onClick={showDetailedResult}
                    className="AssignmentViewProgress-mock-test-close-btn"
                  >
                    <i className="fa-solid fa-x"></i>
                  </span>
                }
                questions={getSubmissiondtls?.questions}
              />
            }
          </div>
        </div>
      )}
    </>
  );
};

export default StudentAssignmentPreview;







