// import React from 'react';
import React, { useEffect } from "react";
import '../home/styles/r-style.css'
import HomeNavbar from './components/HomeNavbar';


import {NavLink, NavLink as RouterNavLink, useNavigate } from "react-router-dom"; // Renamed to RouterNavLink
import { ReactSVG } from "react-svg";
import {
    blue_line_image, blue_line_image2,
    choose_your_role_bg_star, choose_your_role_book_image, choose_your_role_doted3_image, choose_your_role_doted4_image, choose_your_role_half_circle_image,
    choose_your_role_image1, choose_your_role_image2, choose_your_role_image3, customize_learing_journey_arrow,
    customize_learing_journey_image,
    half_circle_image,
    orange_line_image2,
    transforming_learning_experiences_circle_bg_icon,
    transforming_learning_experiences_doted2_image,
    transforming_learning_experiences_doted_image,
    transforming_learning_experiences_line_image,
    transforming_learning_experiences_list_icon,
    transforming_learning_experiences_right_image,
    circle_background,
    school_girl_image,
} from "../../assets/images/images";
import image8 from '../../assets/images/bulb-upp.png';
import image4 from '../../assets/images/edu-inn-1.png';
import image3 from '../../assets/images/edu-inn.png';
import image2 from '../../assets/images/edu-rtg.svg';
import image7 from '../../assets/images/line-text-orn.png';
import image6 from '../../assets/images/line-text.png';
import image5 from '../../assets/images/rota-icn.png';
import image9 from '../../assets/images/rv-1.png';
import image10 from '../../assets/images/rv-2.png';
import image11 from '../../assets/images/rv-3.png';
import image12 from '../../assets/images/rv-4.png';


// import { useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import 'animate.css';
import WOW from "wowjs";
import "wowjs/css/libs/animate.css";
import ChooseyourroleData from "../../components/ChooseyourroleData";
import CustomizedLearningCard from "../../components/CustomizedLearningCard";
const Home = () => {
    const navigate = useNavigate();

    const customizedLearningObj = [
        {
            data_wow_duration:"1.8s",
            title: "Teacher’s AI Assistants:",
            des: "Our platform features AI assistants to help teachers with lesson planning, grading, and educational content creation, significantly enhancing teaching efficiency."
        }, {
            data_wow_duration:"2s",
            title: "Interactive Learning Tools:",
            des: "Engage students with a range of interactive tools, from multimedia content to collaborative projects, encouraging active participation and deeper comprehension."
        }, {
            data_wow_duration:"2.8s",
            title: "Parental Involvement and Monitoring:",
            des: "Features for parental involvement allow monitoring of progress, fostering a comprehensive educational experience that bridges home and school learning."
        }
    ]

    const chooseyourroleObj = [
        {
            data_wow_duration:"1.8s",
            image: choose_your_role_image1,
            des: "Sign Up as Student",
            onClick: () => navigate("/signup", { state: { role: "Student" } }),
        },
        {
            data_wow_duration:"2s",
            image: choose_your_role_image2,
            des: "Sign Up as Teacher",
            onClick: () => navigate("/signup", { state: { role: "Teacher" } }),
        },
        {
            data_wow_duration:"2.8s",
            image: choose_your_role_image3,
            des: "Sign Up as a Parent",
            // onClick: () => navigate("/signup", { state: { role: "Parent" } }),
        }
    ]

    AOS.init({
        duration: 1200,
    });

    useEffect(() => {
        {
            const wow = new WOW.WOW();
            wow.init();
        }
    }, []);

    // const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault(); // Prevent default link behavior
    
        // Navigate to the home page
        navigate("/");
    
        // Use setTimeout to delay the scroll to the specific section
        setTimeout(() => {
        const element = document.getElementById('revolution_sec_new');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
        }, 100); // Adjust the timeout as needed
    };


    return (
        <>
            <HomeNavbar />

            <section className='empower_sec_new'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <div className='empower_sec_new_inner'>
                                <div className='empower_sec_new_inner_left'>
                                    <h3 className="wow fadeInLeftBig" data-wow-duration="1.2s">Empowering Education through Innovation <img className='line_text_img' src={image6} alt='' /></h3>
                                    <p className="wow fadeInLeftBig" data-wow-duration="1.8s">AI at the Heart of Learning: Saddle River Day School's Futuristic Approach</p>
                                    <div className='pre_stu_sec wow fadeInLeftBig' data-wow-duration="2s">
                                        <div className='stu_img_pro'><img src={image3} alt='' /></div>
                                        <p>Preparing <span>students</span> not just for college, but for life beyond.</p>
                                    </div>
                                    <div className='indi_cls_main wow fadeInLeftBig' data-wow-duration="2s">
                                        <img src={image5} alt='' />
                                    </div>
                                    <div className='pre_stu_sec_main wow fadeInLeftBig' data-wow-duration="2.3s">
                                        <div className='stu_img_pro'><img src={image4} alt='' /></div>
                                        <p><span>AI-powered</span> interactive tools create engaging and Dynamic learning environments.</p>
                                    </div>
                                </div>
                                <div className='empower_sec_new_inner_right'>
                                    <div className='empower_sec_new_inner_right_circle_image_background wow animate__zoomIn' data-wow-duration="1.2s">
                                        <img src={school_girl_image} alt='' />
                                    </div>
                                </div>
                            </div>
                            <div className='lets_explore_btn'>
                                <a href='#' onClick={() => navigate("/signup")}>Lets Explore<i className="fas fa-arrow-circle-up"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='revolution_sec_new' id="revolution_sec_new">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <div className='revolution_sec_new_inner'>
                                <div className='revolution_sec_new_head'>
                                    <span className='img_revo'><img src={image8} alt='' /></span>
                                    <h5 className="wow animate__zoomIn">Revolutionizing learning experiences with innovative features<img className='line_text_img' src={image7} alt='' /></h5>
                                </div>
                                <div className='revolution_sec_new_body_sec'>
                                    <div className='revolution_sec_new_single wow animate__fadeInUpBig' data-wow-duration="1.2s">
                                        <img className='revo_img' src={image9} alt='' />
                                        <h6>Accurate Student Assessments:</h6>
                                        <p>Leveraging AI for in-depth student assessments, our system identifies individual interests and learning gaps, tailoring lessons to each student's unique needs and interests.</p>
                                    </div>
                                    <div className='revolution_sec_new_single wow animate__fadeInUpBig' data-wow-duration="1.5s">
                                        <img className='revo_img' src={image10} alt='' />
                                        <h6>AI-Powered Personalization:</h6>
                                        <p>SRDS.ai customizes learning experiences by adapting to each student’s learning pace and style, ensuring a highly individualized educational journey.
</p>
                                    </div>
                                    <div className='revolution_sec_new_single wow animate__fadeInUpBig' data-wow-duration="2s">
                                        <img className='revo_img' src={image11} alt='' />
                                        <h6>Differentiated Learning Support:</h6>
                                        <p>SRDS.ai recognizes the diverse learning speeds and styles of students, providing content that aligns with each student's specific trajectory.</p>
                                    </div>
                                    <div className='revolution_sec_new_single wow animate__fadeInUpBig' data-wow-duration="2.5s">
                                        <img className='revo_img' src={image12} alt='' />
                                        <h6>Instant Assignments and Grading:</h6>
                                        <p>Streamline the evaluation process with AI-driven instant assignment creation and grading, allowing for more timely feedback and continuous learning.
</p>
                                    </div>
                                </div>
                                <div className='lets_explore_btn'>
                                    <a href='#' onClick={() => navigate("/signup")}>Get Started<i className="fas fa-arrow-circle-up"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='customize_learing_journey'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <div className='customize_learing_journey_inner'>
                                <div className='customize_learing_journey_inner_head'>
                                    <div className='revolution_sec_new_head customize_learing_journey_inner_title'>
                                        <span className='customize_learing_journey_light_image'><img src={image8} alt='' /></span>
                                        <h5 className="wow animate__zoomIn">Customized Learning Journeys with Interactive Environments and AI Insights<img className='line_text_image2' src={blue_line_image} alt='' /></h5>
                                    </div>
                                    <div className='customize_learing_journey-folder'>
                                        <div className='customize_learing_journey_left_section'>
                                            <div className='customize_learing_journey_image wow animate__zoomIn'>
                                                <img src={customize_learing_journey_image} alt='' />
                                            </div>
                                            <ReactSVG className="customize_learing_journey_arrow" src={customize_learing_journey_arrow} />
                                        </div>
                                        <div className='customize_learing_journey_right_section'>
                                            <div className='customize_learing_journey_right_inner_container'>
                                                {customizedLearningObj.map((card, index) => (
                                                        <CustomizedLearningCard
                                                            key={index}
                                                            title={card.title}
                                                            des={card.des}
                                                            data_wow_duration={card.data_wow_duration}
                                                            icon={card.customize_learing_journey_right_icon}
                                                        />
                                                ))}
                                            </div>
                                        </div>
                                        <ReactSVG className="customize_learing_journey_half_circle" src={half_circle_image} />
                                    </div>
                                    <div className='lets_explore_btn'>
                                        <a href='#' onClick={() => navigate("/signup")}>Get Started<i className="fas fa-arrow-circle-up"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='choose-your-role-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <div className='choose-your-role-wraper'>
                                <h2 data-aos="zoom-in">Choose your role and unlock a world of learning</h2>
                                <img className='blue-line-image2' src={blue_line_image2} alt='' />
                                <div className='choose-your-role-container'>
                                    {chooseyourroleObj.map((card, index) => (
                                        < ChooseyourroleData
                                            key={index}
                                            des={card.des}
                                            title={card.title}
                                            image={card.image}
                                            data_wow_duration={card.data_wow_duration}
                                            onClick={card.onClick}
                                        />
                                    ))}
                                </div>
                                <ReactSVG className="choose-your-role-bg-star" src={choose_your_role_bg_star} />
                                <ReactSVG className="choose-your-role-book-image" src={choose_your_role_book_image} />
                                <ReactSVG className="choose-your-role-doted3-image" src={choose_your_role_doted3_image} />
                                <ReactSVG className="choose-your-role-doted4-image" src={choose_your_role_doted4_image} />
                                <ReactSVG className="choose-your-role-half-circle-image" src={choose_your_role_half_circle_image} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='transforming-learning-experiences-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-12 col-md-12'>
                            <div className='transforming-learning-experiences-wrapper'>
                                <div className='transforming-learning-experiences-left-section wow animate__fadeInLeft'>
                                    <h2>Transforming Learning Experiences</h2>
                                    <img className='orange-line-image2' src={orange_line_image2} alt='' />
                                    <div className='transforming-learning-experiences-list-container'>
                                        <div className='transforming-learning-experiences-list-folder wow animate__fadeInLeft'>
                                            <ReactSVG className="transforming-learning-experiences-list-icon" src={transforming_learning_experiences_list_icon} />
                                            <p>Redefining Education Paradigms</p>
                                        </div>
                                        <div className='transforming-learning-experiences-list-folder wow animate__fadeInRight'>
                                            <ReactSVG className="transforming-learning-experiences-list-icon" src={transforming_learning_experiences_list_icon} />
                                            <p>Remote Learning and Resilience</p>
                                        </div>
                                        <div className='transforming-learning-experiences-list-folder wow animate__fadeInLeft'>
                                            <ReactSVG className="transforming-learning-experiences-list-icon" src={transforming_learning_experiences_list_icon} />
                                            <p>Digital Literacy and Future-Ready Skills</p>
                                        </div>
                                        <div className='transforming-learning-experiences-list-folder wow animate__fadeInRight'>
                                            <ReactSVG className="transforming-learning-experiences-list-icon" src={transforming_learning_experiences_list_icon} />
                                            <p>Gamified Learning</p>
                                        </div>
                                    </div>
                                    <div className='lets_explore_btn'>
                                        <a href='#' onClick={() => navigate("/signup")}>Get Started<i className="fas fa-arrow-circle-up"></i></a>
                                    </div>
                                </div>
                                <div className='transforming-learning-experiences-right-section wow animate__zoomIn'>
                                    <div className='transforming-learning-experiences-right-image'>
                                        <img src={transforming_learning_experiences_right_image} alt='' />
                                    </div>
                                    <ReactSVG className="transforming-learning-experiences-circle-bg-icon" src={transforming_learning_experiences_circle_bg_icon} />
                                    <ReactSVG className="transforming-learning-experiences-line-image" src={transforming_learning_experiences_line_image} />
                                    <ReactSVG className="transforming-learning-experiences-doted-image" src={transforming_learning_experiences_doted_image} />
                                    <ReactSVG className="transforming-learning-experiences-doted2-image" src={transforming_learning_experiences_doted2_image} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div className='footer-section' id="footer-section">
                <div className='footer-wraper'>
                    <div className='footer-link-folder'>
                        <p>Saddle River Day School</p>
                    </div>
                    <div className="footer-Quick-Links-folder">
                        <h2>Quick Links</h2>
                        <ul className="footer-Quick-Links">
                            <li>
                                <a href="revolution_sec_new" onClick={handleClick}>Features</a>
                            </li>
                            <li>
                                <RouterNavLink to="/about">About Us</RouterNavLink>
                            </li>
                            <li>
                                <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                            </li>
                        </ul>
                    </div>
                    <div className="footer-Quick-Links-folder">
                        <h2>Contact</h2>
                        <div className="footer-contact">
                            <div className="footer-contact-folder">
                                <a><i class="fa-solid fa-phone"></i></a>
                                <span><a href="tel:+1 201-327-4050">+1 201-327-4050</a></span>
                            </div>
                            <div className="footer-contact-folder">
                                <a><i class="fa-solid fa-envelope"></i></a>
                                <a href = "mailto: noreply@saddleriverday.org">kmerges@saddleriverday.org</a>
                            </div>
                            <div className="footer-contact-folder">
                                <a><i class="fa-solid fa-location-dot"></i></a>
                                <a href="https://www.google.com/maps/place/Saddle+River+Day+School/@41.0307394,-74.0880125,17z/data=!3m1!4b1!4m6!3m5!1s0x89c2e436c9e7ef3b:0xf060c34a65b01898!8m2!3d41.0307394!4d-74.0854376!16zL20vMGRkYzZ5?entry=ttu" target="_blank">Saddle River Day School, 147 Chestnut Ridge Road, Saddle River, NJ 07458​​.</a>
                            </div>
                        </div>
                    </div>
                    <div className='footer-get-in-touch-folder'>
                        <span>Get in touch</span>
                        <div className='footer-social-link-folder'>
                            <a href='https://www.facebook.com/saddleriverdayschool' target="_blank"><i className="fa-brands fa-facebook"></i></a>
                            <a href='https://twitter.com/srDSrebels' target="_blank"><i class="fa-brands fa-x-twitter"></i></a>
                            <a href='https://www.instagram.com/saddleriverday/' target="_blank"><i className="fa-brands fa-instagram"></i></a>
                            <a href='https://vimeo.com/srds' target="_blank"><i class="fa-brands fa-vimeo-v"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;