import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Course_created_successfully_image,
  studentDashboard_AI_chatbot_image,
} from "../../../../../../assets/images/images";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import api from "../../../../../../api/api";
import Pagination from "@mui/material/Pagination";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import { truncate } from "../../../../../../utils/utlis";
import PaginationView from "../../../../../../components/PaginationView/PaginationView";
import { CircularProgress } from "@mui/material";
import { deleteLessonById, resetdeleteLesson } from "../../../../../../state/actions/deleteLessonAction"
import { deleteAssignmentById, resetdeleteAssignment } from "../../../../../../state/actions/deleteAssignmentAction"
import { toast } from "react-toastify";
import {
  alert_image,
} from "../../../../../../assets/images/images";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import MenuItem from "@mui/material/MenuItem";
import StyledMenu from "../../../../../../components/StyleMenu";
import LessonCard from "./LessonCard";
import RemovePopup from "../../../../../../components/RemovePopup/RemovePopup";
import { removeStudentsFromCourse, resetremovestudents } from "../../../../../../state/actions/removeStudentsFromCourseAction";

const CourseTab = ({
  course,
  teacher_id,
  setTotalLessons,
  setTotalStudent,
  addlessonBtn,
  activeTabCourse,
  dropDown,
  invitationSuccessfull,
}) => {
  const [activeTab, setActiveTab] = useState("lessons"); // Default active tab is 'lessons'
  const { token } = useAuth();
  const navigate = useNavigate();
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "students") {
      studentdetailsget(`1,4,5`);
    }
    if (tabName === "studentsRequest") {
      studentdetailsget(3);
    }
  };

  const [studentsRequest, setstudentsRequest] = useState([]);
  const [studentcount, setstudentcount] = useState(0);
  const [lessonCount, setlessonCount] = useState(0);
  const [studentCurrentPage, setstudentCurrentPage] = useState(1);
  const [studentTotalPages, setstudentTotalPages] = useState(0);
  const [studentNext, setstudentNext] = useState(null);
  const [studentPrevious, setstudentPrevious] = useState(null);
  const [studentReqCurrentPage, setstudentReqCurrentPage] = useState(1);
  const [studentReqTotalPages, setstudentReqTotalPages] = useState(0);
  const [studentReqNext, setstudentReqNext] = useState(null);
  const [studentReqPrevious, setstudentReqPrevious] = useState(null);
  const [students, setstudents] = useState([]);
  const [loadStudent, setloadStudent] = useState(false);
  const [lessondetails, setlessondetails] = useState(null);
  const [requestProcessMssg, setrequestProcessMssg] = useState(0);
  const [page, setPage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [isLoding, setisLoding] = useState(false);
  const [removeStudentsArray, setremoveStudentsArray] = useState([]);
  const handlePageChange = (event, value) => {
    setPage(value);
    let dynamic_url = ""; // setPage(value);
    if (value == 1) {
      dynamic_url = `lessons/get-all-lessons/course/${course?.course_id}/`;
    } else {
      dynamic_url = `lessons/get-all-lessons/course/${course?.course_id}/?page=${value}`;
    }

    getLessondetails(dynamic_url);
  };

  useEffect(() => {
    if (activeTabCourse.length) {
      setActiveTab(activeTabCourse);
      if (activeTabCourse === "students") {
        studentdetailsget(`1,4,5`);
      }
      if (activeTabCourse === "studentsRequest") {
        studentdetailsget(3);
      }
    }
  }, [activeTabCourse]);
  const studentdetailsget = (status) => {
    setisLoding(true);
    api
      .get(
        `courses/teacher/enroll-course-requests/?course_id=${course?.course_id}&status=${status}&teacher_id=${teacher_id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setisLoding(false);
          if (status === 1) {
            setstudents(response.data.data);
          }
          if (status === 3) {
            setstudentsRequest(response.data.data);
            setstudentReqNext(response?.data?.next);
            setstudentReqPrevious(response?.data?.previous);
            setstudentReqTotalPages(
              Math.ceil((response?.data?.count ?? 0) / 12)
            );
          }
        }
      })
      .catch((error) => { });
  };
  const onPreviewLesson = (lesson) => {
    navigate("/NewLessons", { state: { course, teacher_id, lesson } });
  };

  const deleteLesson = useSelector((state) => state.deleteLesson);
  const deleteAssignment = useSelector((state) => state.deleteAssignment);
  const removestudentfromcourse = useSelector((state) => state.removestudentfromcourse);

  useEffect(() => {
    const first_url = `lessons/get-all-lessons/course/${course?.course_id}/`;
    getLessondetails(first_url);
    api
      .get(
        `courses/teacher/enroll-course-requests/?course_id=${course?.course_id}&status=1,4,5&page=${studentCurrentPage}&teacher_id=${teacher_id}`,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status == 200) {
          setstudents(response.data.data);
          setstudentcount(response?.data?.count);
          setTotalStudent(response?.data?.count);
          setstudentNext(response?.data?.next);
          setstudentPrevious(response?.data?.previous);
          setstudentTotalPages(Math.ceil((response?.data?.count ?? 0) / 12));
        }
      })
      .catch((error) => { });
    studentdetailsget(3);
  }, [course?.course_id, studentCurrentPage, deleteLesson, deleteAssignment, removestudentfromcourse, invitationSuccessfull, loadStudent]);


  useEffect(() => {
    if (removestudentfromcourse?.message?.length) {
      toast.success(removestudentfromcourse.message);
      setremoveStudentsArray([]);
      handleClose();
    }
    else if (removestudentfromcourse?.error?.length) {
      toast.error(removestudentfromcourse.error);
      handleClose();
    }

    return () => {
      dispatch(resetremovestudents());
    }
  }, [removestudentfromcourse])

  function getLessondetails(url) {
    api
      .get(url, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`, // `Token ${token}`
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setlessondetails(response.data.data);
          setlessonCount(response?.data?.count);
          setTotalLessons(response?.data?.count);
          const totalItems = response?.data?.count;
          const totalPages_d = Math.ceil(totalItems / 12);
          settotalPages(totalPages_d);
        }
      })
      .catch((error) => {
        setTotalLessons(0);
        setlessondetails(null);
        setlessonCount(0);
      });
  }
  const [showreuestsuccesfully, setshowreuestsuccesfully] = useState(false);

  const handleAcceptReject = (status, student_id) => {
    setisLoding(true);
    setrequestProcessMssg(status);
    // Perform the API call using Axios
    const currentDate = new Date();

    // Convert to ISO string (YYYY-MM-DDTHH:MM:SS.sssZ) and slice to get YYYY-MM-DD format
    const formattedDate = currentDate.toISOString().slice(0, 10);

    const url = `courses/teacher/enroll-course-requests/?teacher_id=${teacher_id}`;
    const headers = {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    };
    api
      .post(
        url,
        {
          course_id: course.course_id,
          student_id: student_id,
          status: status,
          enrolled_on: formattedDate
        },
        { headers }
      )
      .then((response) => {
        if (response.data.status === 200) {
          setloadStudent(true)
          setisLoding(false);
          const newStudentsRequest = studentsRequest.filter(
            (item) => item.student_id !== student_id
          );
          setstudentsRequest(newStudentsRequest);
          setshowreuestsuccesfully(true);

          setOpenDialog(false);
          setTimeout(() => {
            setshowreuestsuccesfully(false);
          }, 1000);
        }
      })
      .catch((error) => { });
    setloadStudent(false);
  };

  function formatDate(isoDate) {
    const date = new Date(isoDate);

    // Get individual date components
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // 'Jan', 'Feb', 'Mar' etc.
    const year = date.getFullYear();

    // Get the suffix for the day
    let suffix = "th";
    if (day % 10 === 1 && day !== 11) {
      suffix = "st";
    } else if (day % 10 === 2 && day !== 12) {
      suffix = "nd";
    } else if (day % 10 === 3 && day !== 13) {
      suffix = "rd";
    }

    return `${day}${suffix} ${month}, ${year}`;
  }
  const onStudentViewProfile = (student) => {
    navigate(`/student/${student?.student_id}`, {
      state: { course, student },
    });
  };



  const AssignmentCard = ({ lesson, assignment }) => {
    const [deleteAssignment, setdeleteAssignment] = useState(false);

    const dispatch = useDispatch();
    const deleteAssignmentState = useSelector((state) => state.deleteAssignment);

    const deleteAssignmentReq = (id) => {
      dispatch(deleteAssignmentById(id, token))
    }

    return (
      <div className="preview-assignments-inner-folder">
        <ol>
          <li>{truncate(assignment.name, 15)}</li>
        </ol>
        <div className="Created-assignments-date">
          <p className="Created">Created:</p>
          <p>{dayjs(assignment.start_date).format("MMM DD, YYYY")}</p>
        </div>
        <div className="Created-assignments-date Created-assignments-due-date">
          <p className="due-date">Due date:</p>
          <p>{dayjs(assignment.end_date).format("MMM DD, YYYY")}</p>
        </div>
        <div
          className="View-Progress-btn"
          onClick={(e) => {
            e.preventDefault();
            const course_id = course?.course_id;
            const lesson_id = lesson?.lesson_id;
            const assignment_id = assignment?.assignment_id;
            navigate(
              `/course/${course_id}/lesson/${lesson_id}/assignment/${assignment_id}/progress`,
              { state: { course, teacher_id, lesson, assignment } }
            );
          }}
        >
          Assignment Progress
        </div>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
            const course_id = course?.course_id;
            const lesson_id = lesson?.lesson_id;
            const assignment_id = assignment?.assignment_id;
            navigate(
              `/course/${course_id}/lesson/${lesson_id}/assignment/${assignment_id}/preview`,
              {
                state: {
                  course,
                  teacher_id,
                  lesson,
                  assignment,
                  isTeacher: true,
                },
              }
            );
          }}
        >
          Preview Assignment
        </button>
        <i class="fa-solid fa-trash" data-tooltip-id="delete-assignment" onClick={() => setdeleteAssignment(true)}></i>
        <Tooltip
          id="delete-assignment"
          place="bottom"
          content="Delete Assignment"
          type="light"
          effect="float"
        />
        {deleteAssignment && (
          <form action="" className="delete-course-form">
            <div className="delete-course-form-container" data-aos="zoom-in">
              <div className="delete-course-alert-image">
                <img src={alert_image} alt="" />
              </div>
              <h2>Are you sure you want to delete this assignment?</h2>
              <div className="delete-course-form-btn-folder">
                <button
                  type="button"
                  onClick={() => setdeleteAssignment(false)}
                  className="not-delete-btn"
                >
                  No
                </button>
                {deleteAssignmentState?.isLoading ? (
                  <div className="loading-spinner" />
                ) : (
                  <button
                    type="button"
                    onClick={() => deleteAssignmentReq(assignment?.assignment_id)}
                    disabled={deleteAssignmentState?.isLoading}
                    className="delete-btn"
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          </form>
        )
        }
      </div>
    );
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [getstudentid, setstudentid] = useState();
  const dispatch = useDispatch();

  const handleCancelClick = (student_Id) => {
    setOpenDialog(true);
    setstudentid(student_Id);
  };
  const cancelRegistration = () => {
    setOpenDialog(false);
    document.body.style.overflow = "auto";
  };

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  // Function to close the popup
  const handleClose = () => {
    setIsDeletePopupOpen(false);
  };

  // Function to handle the action after confirmation (optional extra functionality)
  const handleRemoveStudent = () => {

    // const studentArr = removeStudentsArray.map(Number);

    const req = {
      student_emails: removeStudentsArray,
      course_id: course?.course_id
    }
    dispatch(removeStudentsFromCourse(req, token))
  }

  const handleDeleteCheck = (e) => {
    if (removeStudentsArray.some(element => element === e.target.value)) {
      setremoveStudentsArray(removeStudentsArray.filter(element => element !== e.target.value))
    }
    else {
      setremoveStudentsArray([...removeStudentsArray, e.target.value]);
    }
  }

  const handleDeleteAll = (e) => {
    if (e.target.checked) {
      setremoveStudentsArray(students.map(student => student.student_email));
    }
    else {
      setremoveStudentsArray([]);
    }
  }

  return (
    <>
      <div className="Create-Lesson-section" id="CreateLesson-section">
        <div className="Create-Lesson-section-wrapper">
          <div className="view-lessons-section">
            <div className="view-lessons-wrapper">
              {
                <div className="view-lessons-btn-container teacehr-tab-setion">
                  <button
                    className={`tab-button ${activeTab === "lessons" ? "active" : ""
                      }`}
                    onClick={() => handleTabClick("lessons")}
                  >
                    Lessons {`(${lessonCount ?? 0})`}
                  </button>
                  <button
                    className={`tab-button ${activeTab === "students" ? "active" : ""
                      }`}
                    onClick={() => handleTabClick("students")}
                  >
                    Students {`(${studentcount ?? 0})`}
                  </button>
                  <button
                    className={`tab-button ${activeTab === "studentsRequest" ? "active" : ""
                      }`}
                    onClick={() => handleTabClick("studentsRequest")}
                  >
                    Student Request {`(${studentsRequest?.length ?? 0})`}
                  </button>
                  {addlessonBtn}
                </div>
              }
              <div className="tab-content">
                {
                  lessondetails && lessondetails.length > 0 &&
                  activeTab === "lessons" &&
                  (lessondetails && Array.isArray(lessondetails)
                    ? lessondetails.map((value, index) => (
                      <LessonCard lesson={value} course={course} teacher_id={teacher_id} />
                    ))
                    : null)}
                {lessondetails &&
                  lessondetails.length > 0 &&
                  activeTab === "lessons" && (
                    <Grid container justifyContent="center" alignItems="center">
                      <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                      />
                    </Grid>
                  )}

                {activeTab === "students" && (
                  <div className="list-of-students-wrappper" id="list-of-students">
                    <table>
                      <thead>
                        <tr>
                          <th data-label="Name"><input type="checkbox" value={"all"} onChange={handleDeleteAll} checked={removeStudentsArray.length > 0 && removeStudentsArray.length === students.length} /></th>
                          <th data-label="Name">Name</th>
                          <th data-label="Id">Id</th>
                          <th data-label="Grade">Grade</th>
                          <th data-label="Enrolled On">Enrolled On</th>
                          <th data-label="">
                            <button type="button" className="student-remove" onClick={() => setIsDeletePopupOpen(true)} disabled={removeStudentsArray.length === 0}><i class="fa-solid fa-trash"></i> Remove</button>
                          </th>
                        </tr>
                        <div className="list-of-students-gap"></div>
                      </thead>
                      <tbody>
                        {isLoding ?
                          <Grid
                            height={"50vh"}
                            xs={12}
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <CircularProgress size={80} />
                          </Grid> :
                          students.length > 0 ? (
                            students.map((student, index) => (
                              <>
                                <tr key={index}>
                                  <td>
                                    <input value={student?.student_email} type="checkbox" onChange={handleDeleteCheck} checked={removeStudentsArray.some(element => element === student?.student_email)} />
                                  </td>
                                  <td
                                    onClick={() => {
                                      onStudentViewProfile(student);
                                    }}
                                    data-label="Name"
                                  >
                                    <div className="studentrequest-profile">
                                      <div className="studentrequest-profile-image">
                                        <img
                                          src={
                                            student?.student_profile_pic ||
                                            studentDashboard_AI_chatbot_image
                                          }
                                          alt="Student"
                                        />
                                      </div>
                                      <div className="studentrequest-profile-name">
                                        {student?.student_name}
                                      </div>
                                    </div>
                                  </td>
                                  <td data-label="Id">{student?.student_id}</td>
                                  <td data-label="Grade">
                                    {student?.student_grade}
                                  </td>
                                  <td data-label="Enrolled On">
                                    {formatDate(student?.enrolled_on)}
                                  </td>
                                  <td data-label="">
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault(); // This prevents the default anchor link behavior
                                        onStudentViewProfile(student);
                                      }}
                                    >
                                      View Profile
                                    </a>
                                  </td>
                                </tr>
                                <div className="blank-div"></div>

                              </>
                            ))
                          ) : (
                            <tr>
                              <td
                                  colSpan="6"
                                data-label="No students records found."
                              >
                                No students records found.
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                    {studentTotalPages > 1 && (
                      <PaginationView
                        totalPages={studentTotalPages}
                        next={studentNext}
                        previous={studentPrevious}
                        currentPage={studentCurrentPage}
                        setCurrentPage={setstudentCurrentPage}
                      />
                    )}
                    {isDeletePopupOpen && (
                      <RemovePopup
                        title="Are you sure you want to remove this student?"
                        cancel="No"
                        submit="Remove"
                        onclick={handleRemoveStudent}
                        onClose={handleClose}
                      />
                    )}
                  </div>
                )}
                {activeTab === "studentsRequest" && (
                  <div className="list-of-students-wrappper student-request-wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Id</th>
                          <th>Grade</th>
                          <th>Requested On</th>
                          <th>Request</th>
                        </tr>
                        <div className="list-of-students-gap"></div>
                      </thead>
                      <tbody>
                        {isLoding ?
                          <div style={{ display: "table-caption" }}>
                            <Grid
                              height={"50vh"}
                              xs={12}
                              container
                              justifyContent={"center"}
                              alignItems={"center"}
                            >
                              <CircularProgress size={80} />
                            </Grid>
                          </div>
                          :
                          studentsRequest.length > 0 ? (
                            studentsRequest.map((student, index) => (
                              <>
                                <tr key={index}>
                                  <td
                                    onClick={() => {
                                      onStudentViewProfile(student);
                                    }}
                                  >
                                    <div className="studentrequest-profile">
                                      <div className="studentrequest-profile-image">
                                        <img
                                          src={
                                            student?.student_profile_pic ||
                                            studentDashboard_AI_chatbot_image
                                          }
                                          alt="Student"
                                        />
                                      </div>
                                      <div className="studentrequest-profile-name">
                                        {student?.student_name}
                                      </div>
                                    </div>
                                  </td>
                                  <td>{student?.student_id}</td>
                                  <td>{student?.student_grade}</td>
                                  <td>{formatDate(student?.enrolled_on)}</td>
                                  <td style={{ width: "30%" }}>
                                    <div className="student-request-btn-folder">
                                      <button
                                        onClick={() =>
                                          handleCancelClick(student?.student_id)
                                        }
                                      >
                                        Reject
                                      </button>
                                      <button
                                        onClick={() =>
                                          handleAcceptReject(
                                            4,
                                            student?.student_id
                                          )
                                        }
                                      >
                                        Accept
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <div className="blank-div"></div>
                              </>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5">No students request found.</td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                    {openDialog && (
                      <div className="Course-created-successfully-main">
                        <div className="Course-created-successfully-container">
                          <div className="Course-created-successfully-folder">
                            <h2>
                              Are you sure you want to reject the request?
                            </h2>
                            <div className="Course-close-btn">
                              <button
                                type="submit"
                                onClick={cancelRegistration}
                              >
                                No
                              </button>
                              <button
                                type="submit"
                                onClick={() =>
                                  handleAcceptReject(2, getstudentid)
                                }
                                className="Course-close"
                              >
                                Yes
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {studentReqTotalPages > 1 && (
                      <PaginationView
                        totalPages={studentReqTotalPages}
                        next={studentReqNext}
                        previous={studentReqPrevious}
                        currentPage={studentReqCurrentPage}
                        setCurrentPage={setstudentReqCurrentPage}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showreuestsuccesfully && (
          <div className="Course-created-successfully-main">
            <div className="Course-created-successfully-container">
              <div className="Course-created-successfully-folder">
                <div className="Course-created-successfully-image">
                  <img src={Course_created_successfully_image} alt="" />
                </div>
                <h2>
                  {requestProcessMssg === 4
                    ? "Request Accepted."
                    : requestProcessMssg === 2
                      ? "Request Rejected."
                      : ""}
                </h2>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CourseTab;
