import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../../../../../api/api";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import Question from "../../../questions/Question";
import BackButton from "../../../../../../components/BackButton/BackButton";

const TeacherAssignmentPreview = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { assignment_id, course_id, lesson_id } = useParams();
  const { token } = useAuth();
  const [assignment, setAssignment] = useState(
    location?.state?.assignment || {}
  );
  const lesson = location?.state.lesson;
  const [answeredList, setAnsweredList] = useState([]);
  const [showSubmit, setShowPopup] = useState(false);
  const assignmentJSON = assignment?.assignment_json?.map((item) => {
    return { ...item, student_answer: null };
  });
  const [startIndex, setStartIndex] = useState(1);
  const [lastIndex, setLastIndex] = useState(assignmentJSON?.length);
  const [rangIndex, setRangIndex] = useState(4);
  useEffect(() => {
    getAssignment(lesson_id, assignment_id);
  }, [lesson_id, assignment_id]);
  const getAssignment = async (lesson_id) => {
    await api
      .get(`assignments/get-assignment/${assignment_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAssignment(response?.data?.data);
        }
      })
      .catch(() => {});
  };

  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = assignmentJSON?.length;

  const handleNext = async (answeredQuestion) => {
    const existingIndex = answeredList.findIndex(
      (question) => question.question_id === answeredQuestion.question_id
    );

    let updatedList;
    if (existingIndex !== -1) {
      updatedList = answeredList.map((question, index) =>
        index === existingIndex ? answeredQuestion : question
      );
    } else {
      updatedList = [...answeredList, answeredQuestion];
    }

    setAnsweredList(updatedList);

    if (currentStep < totalSteps - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      setShowPopup(true);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const isLastQuestion = assignmentJSON?.length === currentStep + 1;
  const getStepNumbers = () => {
    const steps = [];
    const currentIndexVal = currentStep + 1;
    const middleRange = Math.floor(rangIndex / 2);
    let start = Math.max(1, currentIndexVal - middleRange);
    let end = Math.min(lastIndex, currentIndexVal + middleRange);

    if (currentIndexVal <= middleRange) {
      end = Math.min(rangIndex, lastIndex);
    } else if (currentIndexVal >= lastIndex - middleRange) {
      start = Math.max(1, lastIndex - rangIndex + 1);
    }

    if (start > 1) steps.push(1);
    if (start > 2) steps.push("...");

    for (let i = start; i <= end; i++) {
      steps.push(i);
    }

    if (end < lastIndex - 1) steps.push("...");
    if (end < lastIndex) steps.push(lastIndex);

    return steps;
  };
  return (
    <>
      <div id="AssingmentMCQs-section">
        <div className="AssingmentMCQs-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="AssingmentMCQs-header-main-contaiiner">
                  <div
                    className="view-course-Details-back-btn-folder"
                    id="AssingmentMCQs-back-btn"
                  >
                    <BackButton buttonName={"Back"} />
                  </div>
                  <h2>Assignment: {assignment.name}</h2>
                  <div></div>
                </div>
                <div className="AssingmentMCQs-main-wrapper">
                  <div className="step-container">
                  {getStepNumbers().map((step, index) => {
                            const isAnswered = answeredList.some(
                              (question) => question.question_id === step
                            );
                            const stepchecker = currentStep + 1 === step ? true : false;
                            return (
                              <div
                                key={index}
                                className={`step ${
                                  stepchecker ? "active" : ""
                                } ${isAnswered && !stepchecker ? "answered" : ""}`}
                              >
                                {step}
                              </div>
                            );
                          })}
                  </div>
                  {assignmentJSON?.map((assignment, index) => (
                    <div key={index}>
                      <Question
                        assignment={assignment}
                        index={index}
                        currentStep={currentStep}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        isLast={isLastQuestion}
                        lesson={lesson}
                        course_id={course_id}
                        lesson_id={lesson_id}
                        assignment_id={assignment_id}
                        isTeacher={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherAssignmentPreview;
