import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Ring } from "@uiball/loaders";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../api/api";
import {
  image1,
  srds_admin
} from "../../../assets/images/images";
import EmailVerification from "../../../components/EmailVerification"; // import the ForgotPassword component
import { useAuth } from "../../../features/auth/AuthContext";
import "../../../index.css";
import {
  resetuserRegistration, userRegistration
} from "../../../state/actions/userRegistrationAction";
import {
  isRequired,
  isSamePassword,
  isValidEmail,
  isValidPassword,
} from "../../../utils/validator";

const AdminLogin = () => {
  const { login } = useAuth();
  const location = useLocation();
  const roleFromState = location.state?.role || "";
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isverified, setIsverified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmpassword, setShowconfirmpassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("Admin");
  const navigate = useNavigate();
  const redirectPath = localStorage.getItem("redirectAfterSignup");
  const userregistration = useSelector((state) => state.userregistration);
  const globalState = useSelector((state) => state.globalState);
  const subadmin_details = globalState.subadmin_details;
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmpassword: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
    confirmpassword: "",
  });
  const dispatch = useDispatch();

  const handleSignUpClick = (event) => {
    event.preventDefault();
    navigate("/admin", { state: { role: activeTab } });
  };

  const handleChange = (e) => {
    // Check if Enter key is pressed and ignore tab switch
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior (e.g., form submission)
      handleSubmit(e); // Call the submit function
      return;
    }
    const { name, value } = e.target;
    let error = "";
    if (name === "email") {
      error = isRequired(value, "email") || isValidEmail(value);
    } else if (name === "password") {
      error = isRequired(value, "password") || isValidPassword(value);
    } else if (name === "confirmpassword") {
      error =
        isRequired(value, "password") ||
        isSamePassword(formData.password, value);
    }

    setFormErrors({
      ...formErrors,
      [name]: error,
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isverified) {
      checkEmailVerfied()
    }
    else if (!formData.email || !formData.password || !formData.confirmpassword) {
      let errors = {};

      errors.email =
        isRequired(formData.email, "email") || isValidEmail(formData.email);
      errors.password =
        isRequired(formData.password, "password") ||
        isValidPassword(formData.password);
      errors.confirmpassword =
        isRequired(formData.confirmpassword, "password") ||
        isSamePassword(formData.password, formData.confirmpassword);

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ...errors,
      }));
    }
    else {
      let errors = {};

      errors.email =
        isRequired(formData.email, "email") || isValidEmail(formData.email);
      errors.password =
        isRequired(formData.password, "password") ||
        isValidPassword(formData.password);
      errors.confirmpassword =
        isRequired(formData.confirmpassword, "password") ||
        isSamePassword(formData.password, formData.confirmpassword);

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        ...errors,
      }));

      if (!Object.values(errors).some((error) => error)) {
        await loginUser(formData.email.toLocaleLowerCase(), formData.password);
      }
    }
  };

  const loginUser = () => {
    const req = {
      email: formData.email,
      password: formData.password,
      role: 5,
    };

    dispatch(userRegistration(req));
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [completeModal, setcompleteModal] = useState(false);
  const customModalStyleNew = {
    modal: {
      background: "#F5F9FF",
      width: "650px",
      borderRadius: "10px",
    },
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default Enter key behavior

      handleSubmit(e);
    }
  };

  const checkEmailVerfied = (e) => {
    onSendEmailForgotPass(formData.email).then((res) => {
      if (res.status === 200) {
        toast.success("Verification code sent successfully");
        setIsModalOpen(true);
      } else if(res.status === 400){
        toast.success(res?.data?.message || "Please Enter valid email");
      } else if(res.status === 406){
        let error = res?.data?.message || "Please Enter valid email";
        setFormErrors({
          ...formErrors,
          ["email"]: error?.trim(),
        });
        toast.error(res?.data?.message || "Please Enter valid email");
      } else {
        toast.error('Something went wrong!');
      }
    });
  };

   const onSendEmailForgotPass = async (email) => {
    if (email) {
      return await api
        .post("auth/email-verification/", {
          email: email,
        })
        .then((res) => {
          return res;
        })
        .catch((err) => {
          return err?.response;
        });
    } else {
      return "Please enter valid email";
    }
  };

  useEffect(() => {
    const failedErrorMessage =
      "Oops, something went wrong. Please try again later.";
    if (userregistration?.token?.length) {
      login(userregistration?.token, activeTab, userregistration?.email || "");
      toast.success("Registration successful.", {
        position: toast.POSITION.TOP_CENTER,
      });
      if (redirectPath !== null && redirectPath !== "") {
        navigate(redirectPath);
        localStorage.removeItem("redirectAfterSignup");
        return;
      }
    } else if (userregistration?.error?.length) {
      toast.error(userregistration?.error || failedErrorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
      dispatch(resetuserRegistration());
    }
  }, [userregistration]);

  useEffect(() => {
    return () => {
      dispatch(resetuserRegistration());
    };
  }, []);

  return (
    <div className="login" onKeyPress={handleKeyPress}>
      <div className="login-wrapper">
        <form
          action=""
          className="signup-right-section"
          onSubmit={handleSubmit}
        >
          <div className="Register-container admin-login">
            <span className="welcome-text">Welcome to</span>
            <img src={image1} width={200} alt="" />
            <div className="signup-form">
              <p className="email-or-phoneNumber-title">Email</p>
              <div className="signup-input edit-email">
                <FormControl
                  margin="normal"
                  fullWidth
                  error={!!formErrors.email}
                >
                  <TextField
                    name="email"
                    size="small"
                    value={formData.email}
                    error={!!formErrors.email}
                    onChange={handleChange}
                    InputProps={{
                      sx: {
                        borderRadius: "35px",
                        backgroundColor: "#F5F5F5",
                        padding: "0px",
                        fontSize: "15px",
                      },
                    }}
                  />
                  {isModalOpen && (
                    <EmailVerification
                      isModalOpen={isModalOpen}
                      onClose={() => setIsModalOpen(false)}
                      email={formData.email}
                      setIsverified={setIsverified}
                      signupUser={() => { }}
                      //signupUser={loginUser}
                    />
                  )}
                  <FormHelperText>{formErrors.email}</FormHelperText>
                </FormControl>
              </div>
              <p className="email-or-phoneNumber-title">Password</p>
              <div className="signup-input hide-show-password">
                <FormControl
                  margin="normal"
                  fullWidth
                  error={!!formErrors.password}
                >
                  <TextField
                    size="small"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    error={!!formErrors.password}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowPassword(
                                (prevShowPassword) => !prevShowPassword
                              )
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: {
                        borderRadius: "35px",
                        backgroundColor: "#F5F5F5",
                      },
                    }}
                  />
                  <FormHelperText>
                    <ul>
                      {formErrors.password.split(".").map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  </FormHelperText>
                </FormControl>
              </div>
              <p className="email-or-phoneNumber-title">Confirm Password</p>
              <div className="signup-input hide-show-password">
                <FormControl
                  margin="normal"
                  fullWidth
                  error={!!formErrors.confirmpassword}
                >
                  <TextField
                    size="small"
                    type={showconfirmpassword ? "text" : "password"}
                    name="confirmpassword"
                    value={formData.confirmpassword}
                    error={!!formErrors.confirmpassword}
                    onChange={handleChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              setShowconfirmpassword(
                                (prevShowPassword) => !prevShowPassword
                              )
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showconfirmpassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: {
                        borderRadius: "35px",
                        backgroundColor: "#F5F5F5",
                      },
                    }}
                  />
                  <FormHelperText>
                    <ul>
                      {formErrors.confirmpassword.split(".").map((error) => (
                        <li key={error}>{error}</li>
                      ))}
                    </ul>
                  </FormHelperText>
                </FormControl>
              </div>
            </div>

            <div className="signup-btn">
              {loading ? (
                <Ring />
              ) : (
                <button type="submit" disabled={loading}>
                  Register
                </button>
              )}
            </div>

            <div className="Already-have-an-accout-folder">
              <span>
                Already have an account?{" "}
                <a href="#" onClick={handleSignUpClick}>
                  {" "}
                  Login
                </a>
              </span>
            </div>
          </div>
        </form>
        <div className="signup-left-section">
          <div className="signup-image">
            <img
              src={
                srds_admin
              }
              alt="Background"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;