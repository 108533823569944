import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Course {
    course_id: number | null;
    name: string | null;
    start_date: string | null;
    end_date: string | null;
    description: string | null;
    teacher: string | null;
    teacher_name: string | null;
    ai_avatar: string | null;
    subject: string | null;
    image_url: string | null;
    status: string | null;
}

interface Courses {
    enrolledCourses: Course[],
    next: string | null;
    previous: string | null;
    count: number | null;
    totalPages: number | null;
    isLoading: boolean;
    error: string | null;
}

const initialState: Courses = {
    enrolledCourses: [],
    next: null,
    previous: null,
    count: 0,
    totalPages: 0,
    isLoading: false,
    error: null,
};

const enrolledCoursesSlice = createSlice({
    name: "enrolledcourses",
    initialState,
    reducers: {
        setenrolledcourses: (state: Courses, action: PayloadAction<Course[]>) => {
            state.enrolledCourses = action.payload;
            state.isLoading = false;
            state.error = null;
        },
        setPagination: (state: Courses, action: PayloadAction<Courses>) => {
            state.next = action.payload.next;
            state.previous = action.payload.previous;
            state.count = action.payload.count;
            state.totalPages = Math.ceil((action.payload.count ?? 0) / 12);
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: Courses, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: Courses, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetenrolledcourses: () => {
            return initialState;
        },
    },
});

export const {
    setenrolledcourses,
    setPagination,
    setloading,
    seterror,
    resetenrolledcourses,
} = enrolledCoursesSlice.actions;

export default enrolledCoursesSlice.reducer;
