import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { course_demo_image, monetize_course_upload_document } from "../../../../../../assets/images/images";
import DescriptionCoursePopup from "./DescriptionCoursePopup";
import { useDispatch, useSelector } from "react-redux";
import {
  setpublishtoSchoologydtls,
  resetPublishtoSchoology,
} from "../../../../../../state/actions/publishtoSchoologyAction";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import { toast } from "react-toastify";
import GoogleUploadPopup from "../../../../../../components/GoogleUploadPopup/GoogleUploadPopup";
import { syncCourseToGoogleDrive, resetgoogleDriveCourseSync } from "../../../../../../state/actions/googleDriveCourseSyncAction";
import { Ring } from "@uiball/loaders";
import CancelPopup from "../../../../../../components/CancelPopup/CancelPopup";

const CourseDetailsHeader = ({
  course,
  onEditCourseClick,
  toggleinvite,
  totalLessons,
  totalStudent,
  Sales_Courses_title,
  checkbox,
  setactiveTabCourse,
  onInvitedStudents,
}) => {
  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const paragraphRef = useRef(null);
  const { token } = useAuth();
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.globalState);
  const { message, isLoading, error } = useSelector((state) => state.publishtoSchoology);
  const googleDriveCourseSync = useSelector((state) => state.googleDriveCourseSync);
  const [isSaveChecker, setisSaveChecker] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingPopUp, setLoadingPopUp] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [isShowGoogleUploadPopup, setIsShowGoogleUploadPopup] = useState(false);
  const openFileuploadPopup = () => {
    setIsShowGoogleUploadPopup(true);
  };

  const descriptionToggle = () => {
    setDescriptionOpen(!descriptionOpen);
  };
  // const onCoursePublishToSchoology = () => {
  //   setisSaveChecker(true);
  //   const req = {
  //     course_id: course?.course_id,
  //   };
  //   dispatch(setpublishtoSchoologydtls(req, token));
  // };

  const handleCreateCourseInSchoology = async () => {

    setLoadingPopUp(true); // Start loader
    setisSaveChecker(true); // Disable the button
    try {
      const req = {
        course_id: course?.course_id,
        create_new_course: true
      };


      // Dispatch the Redux action and wait for its completion
      await dispatch(setpublishtoSchoologydtls(req, token));
    } catch (error) {
      console.error("API Error:", error);
    } finally {

      setLoadingPopUp(false); // Stop loader
      setisSaveChecker(false); // Re-enable the button
    }
  }

  const onCoursePublishToSchoology = async () => {

    setLoading(true); // Start loader
    setisSaveChecker(true); // Disable the button
    try {
      const req = {
        course_id: course?.course_id,
        // create_new_course:true
      };
      // Dispatch the Redux action and wait for its completion
      await dispatch(setpublishtoSchoologydtls(req, token));
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false); // Stop loader
      setisSaveChecker(false); // Re-enable the button
    }
  };

  useEffect(() => {
    const descriptionLength = course?.description?.length || 0;

    if (paragraphRef.current) {
      const lineHeight = parseFloat(
        window.getComputedStyle(paragraphRef.current).lineHeight
      );
      const height = paragraphRef.current.clientHeight;
      const lineCount = height / lineHeight;

      setShowViewMore(lineCount > 5 || descriptionLength > 1000);
    }
  }, [course?.description]);


  useEffect(() => {

    const failedErrorMessage =
      "Oops, something went wrong. Please try again later.";

    if (message) {
      toast.success(message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setisSaveChecker(false);

      setShowPopUp(false)
    } else if (error) {

      // toast.error(error || failedErrorMessage, {
      //   position: toast.POSITION.BOTTOM_RIGHT,
      // });
      if (error === 'Unable to Sync Course: Course Not Found on Schoology') {

        setShowPopUp(true);
      } else {
        dispatch(resetPublishtoSchoology());
        setisSaveChecker(false);
      }
    } else {
      setisSaveChecker(false);
    }
    return () => {
      dispatch(resetPublishtoSchoology());
      setisSaveChecker(false);
    };
  }, [message, error]);

  useEffect(() => {
    return () => {
      dispatch(resetPublishtoSchoology());
    };
  }, [])

  const syncCoursetoDrive = () => {
    const req = {
      course_id: course?.course_id,
    };
    dispatch(syncCourseToGoogleDrive(req, token));
  }

  useEffect(() => {
    if (googleDriveCourseSync?.message) {
      toast.success("Uploading is in progress; We will notify you once it is completed.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    if (googleDriveCourseSync?.error) {
      toast.error("Please connect to your Google Drive", {
        position: toast.POSITION.TOP_CENTER,
      });
    }

    return () => {
      dispatch(resetgoogleDriveCourseSync());
    }
  }, [googleDriveCourseSync])

  return (
    <div className="view-course-details-container">
      <div className="view-course-details-folder">
        <div className="view-course-details-left-section">
          {course?.image ? (
            <div className="view-course-details-image">
              <img src={course?.image} alt="" />
            </div>
          ) : (
            <img src={course_demo_image} alt="" />
          )}
        </div>
        <div className="view-course-details-right-section">
          <div className="view-course-details-edit-and-share-container">
            {onEditCourseClick && globalState.role !== "SuperAdmin" && (
              <button
                onClick={onEditCourseClick}
                type="button"
                className="view-course-details-edit-and-share-folder"
              >
                <span>
                  <i className="fa-solid fa-pencil"></i>
                </span>
                <p>Edit</p>
              </button>
            )}

            <button
              onClick={onInvitedStudents}
              type="button"
              className="Invited_students_btn"
            >
              <p>Invited students</p>
            </button>

            {loading ? (
              <Ring />
            ) : (
              <button
                type="button"
                className="Invited_students_btn"
                disabled={isSaveChecker}
                onClick={onCoursePublishToSchoology}
              >
                <p>Sync with Schoology</p>
              </button>
            )
            }

            {toggleinvite && (
              <div
                className="view-course-details-edit-and-share-folder view-course-details-share-btn"
                onClick={toggleinvite}
              >
                <span>
                  <i className="fa-solid fa-user-plus"></i>
                </span>
                <p>Invite</p>
              </div>
            )}
          </div>

          <h2>{course?.name}</h2>
          <div className="teacherviewcoursedetails-des-wrapper">
            <p className="teacherviewcoursedetails-des" ref={paragraphRef}>
              {course?.description}
              {showViewMore && (
                <span className="view-more" onClick={descriptionToggle}>
                  ...View More
                </span>
              )}
            </p>
            {descriptionOpen && (
              <DescriptionCoursePopup
                title="My Course Description"
                data={course?.description}
                onClick={descriptionToggle}
              />
            )}
          </div>
          <div className="view-course-details-container">
            <div className="view-Students-course-details-folder">
              <span>Total Lessons</span>
              <div
                className="view-Students-course-details-counts"
                onClick={() => setactiveTabCourse("lessons")}
              >
                <p>{totalLessons}</p>
                <p>{totalLessons > 1 ? "Lessons" : "Lesson"} </p>
              </div>
            </div>
            <div className="view-Students-course-details-folder">
              <span> {course?.total_assignments > 1 ? "Total Assignments" : "Total Assignment"}{" "}</span>
              <div
                   className="view-Students-course-details-counts"
                onClick={() => setactiveTabCourse("lessons")}
              >
                <p>{course?.total_assignments}</p>
                <p>
                  {course?.total_assignments > 1 ? "Assignments" : "Assignment"}{" "}
                </p>
              </div>
            </div>
            <div className="view-Students-course-details-folder">
              <span>Students Enrolled</span>
              <div
                className="view-Students-course-details-counts"
                onClick={() => setactiveTabCourse("students")}
              >
                <p>{totalStudent}</p>
                <p> {totalStudent > 1 ? "Students" : "Student"}</p>
              </div>
            </div>
            <div className="view-Students-course-details-folder">
              <span>Course Start Date</span>
              <div className="view-Students-course-details-counts">
                <p>{dayjs(course?.start_date).format("MMM DD, YYYY")} </p>
              </div>
            </div>
            <div className="view-Students-course-details-folder">
              <span>Course Expiry Date:</span>
              <div className="view-Students-course-details-counts">
                <p>
                  {course.end_date !== null && course.end_date !== ""
                    ? dayjs(course?.end_date).format("MMM DD, YYYY")
                    : "- -"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {
        showPopUp && <CancelPopup title={'course not found in schoology, do you you want to create course in schoology'} No={'No'} Yes={'Yes'} show={handleCreateCourseInSchoology} close={() => { setShowPopUp(false) }} loading={loading} />
      } */}
      {
        showPopUp && <>
          <form action="" className="Course-created-successfully-form">
            <div className="schoology-Course-created-successfully-container" data-aos="zoom-in">
              <h2>Course unavailable on Schoology. Would you like to create it?</h2>
              {/* { loadingPopUp ? <h2> Please wait...</h2> : <h2>Course unavailable on Schoology. Would you like to create it?</h2> } */}
              <div className="schoology-Course-created-successfully-container-btn-folder">

                {loadingPopUp ? (
                  // <div className="loading-spinner" />
                  <Ring />
                ) : (
                  <>
                  <button
                    type="button"
                    onClick={handleCreateCourseInSchoology}
                    className="create-btn"
                    >
                    Yes
                  </button>
              
                <button
                  type="button"
                  onClick={() => setShowPopUp(false)}
                  disabled={loadingPopUp}
                >
                  No
                </button> 
                  </>
                  )}
              </div>
            </div>
          </form>
        </>
      }


    </div>
  );
};



export default CourseDetailsHeader;
