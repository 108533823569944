import { Tooltip } from "react-tooltip";
import "./SpeechtoText.css";
import react, { useEffect, useRef, useState } from 'react';

const useSpeechToText = (options) => {
    const [isListening, setIsListening] = useState(false);
    const [transcript, setTranscript] = useState("");
    const recgonitionRef = useRef(null);

    useEffect(() => {
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (!SpeechRecognition) {
            return;
        }

        recgonitionRef.current = new SpeechRecognition();
        const recogonition = recgonitionRef.current;
        recogonition.interimResults = options.interimResults || true;
        recogonition.lang = options.lang || "en-US";
        recogonition.continuous = options.continuous || true;

        if ('webitSpeechGrammerList' in window) {
            const grammar = `#JSGF V1.0; grammer punctuation;
      public <action> = prompt | generate | create | lesson | give | solution;
      public <punc> = . | , | ? | ! | ; | : ;`;
            const speechRecognitionList = new window.webitSpeechGrammerList();
            speechRecognitionList.addFromString(grammar, 1);
            recogonition.grammars = speechRecognitionList;
        }

        recogonition.onresult = (event) => {
            let text = "";
            for (let i = 0; i < event.results.length; i++) {
                text += event.results[i][0].transcript;
            }
            if (text) {
                setTranscript(text);
            }

        }

        recogonition.onerror = (event) => {
            console.error("Speech recongnition error: ", event.error);
        }

        recogonition.onend = () => {
            setIsListening(false);
            setTranscript("");
        }

        return () => {
            recogonition.stop();
        }
    }, []);

    const startListening = () => {
        if (recgonitionRef.current && !isListening) {
            recgonitionRef.current.start();
            recgonitionRef.current.onstart = () => {
            };
            recgonitionRef.current.onerror = (error) => {
            };
            setTimeout(() => {
                setIsListening(true);
            }, 300);

        }
    };


    const stopListening = () => {
        if (recgonitionRef.current && isListening) {
            recgonitionRef.current.stop();
            setIsListening(false);
        }
    }
    return {
        isListening,
        transcript,
        startListening,
        stopListening,
        setIsListening
    };
};
export default useSpeechToText