import { Dispatch } from "redux";
import api from "../../api/api";
import {
    setCourseList,
    setloading,
    seterror,
    setPagination,
    resetCourseList,
} from "../reducers/getAllCoursesByTeacherIdReducer";

const getAllCoursesByTeacherId = (page: any, id: any, token: any, search: any, sort: any) => async (
    dispatch: Dispatch
) => {
    dispatch(setloading(true));
    try {
        const result = await api.get(`/courses/get-all-courses/teacher/${id}/`, {
            params: {
                page: page,
                search: search,
                sort: sort,
            },
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
            },
        });

        if (result) {
            dispatch(setloading(false));
            if (result.data?.status === 200) {
                dispatch(setCourseList(result.data.data));
                dispatch(setPagination(result.data));
            } else {
                dispatch(seterror(result.data?.message ?? "Something Went Wrong!"));
            }
        }
    } catch (error: any) {
        dispatch(setloading(false));
    }
};

export { getAllCoursesByTeacherId, resetCourseList };

