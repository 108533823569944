import React, { useState } from "react";
import { useSelector } from "react-redux";
import PracticeAssignmentPopup from "./PracticeAssignmentPopup/PracticeAssignmentPopup";
import { Modal } from "react-responsive-modal";
import CancelPopup from "../../../../../../components/CancelPopup/CancelPopup";
import { Tooltip } from "react-tooltip";

const StudentAssignmentScore = ({
  showScore,
  getSubmissiondtls,
  onclick,
  title,
  isCurrentAssignment,
  onclick2,
  title2,
  title3,
  assignment, 
  setshowScorePopup,
  setShowCongratulations,
  setcallWebSocket,
  callWebSocket,
  setwebsocketPayload,
  isNaviget,
  filteredAssignments
}) => {

  const [showPopup, setShowPopup] = useState(false);
  const globalState = useSelector((state) => state.globalState);
  const practice_assignment_status = globalState.userCurrentPlan?.currentSubscription && globalState.userCurrentPlan.currentSubscription.plan_data.practice_assignment

  const practice_assignment_remaining_count = assignment?.practice_assignment_remaining_count



  const handlePracticeAssignmentClick = () => {
    setShowPopup(true);

  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };


  return (
    <div className="AssingmentMCQs-Congratulations-popup-wrapper">
      <div className="AssingmentMCQs-Congratulations-popup-container">
        {!isCurrentAssignment ? (
          <i
            class="fa-solid fa-x"
            id="AssingmentMCQs-close-btn"
            onClick={showScore}
          ></i>
        ) : (
          <></>
        )}
        <span className="your-score-title">Your score</span>
        <div className="your-score-folder">
          <span className="your-score-check">
            <i class="fa-solid fa-circle-check"></i>
          </span>
          <div className="your-score">
            <span>{getSubmissiondtls?.marks_obtained ?? 0}</span>
            <span>/{getSubmissiondtls?.total_marks ?? 0}</span>
          </div>
        </div>
        <p>{getSubmissiondtls?.feedback?.replace(/(\\\()(.*?)(\\\))/g, (match, p1, p2, p3) => `$$${p1}${p2}${p3}$$`) ?? 0}</p>
        <div className="AssingmentMCQs-Congratulations-popup-btn">
          <button
            onClick={onclick}
            type="button"
            className="View-Course"
          >
            {title}
          </button>
          {isCurrentAssignment && <button
            onClick={onclick2}
            type="button"
            className="View-Course"
          >
            {title2}
          </button>}
          {/* <div className="practice-assignment" data-tooltip-id={isNaviget ? practice_assignment_remaining_count === 0 && "practice-assignment" : filteredAssignments.length === 2 && "practice-assignment"}>
            <button
              onClick={handlePracticeAssignmentClick}
              type="button"
              className="practice-assignment-btn"
              disabled={isNaviget ? practice_assignment_remaining_count === 0 : filteredAssignments.length === 2}
              style={{
                backgroundColor: isNaviget ? practice_assignment_remaining_count === 0 ? '#d3d3d3' : '' : filteredAssignments.length === 2 ? '#d3d3d3' : '', // Inline CSS to gray out the button
                cursor: isNaviget ? practice_assignment_remaining_count === 0 ? 'not-allowed' : 'pointer' : filteredAssignments.length === 2 ? 'not-allowed' : 'pointer' // Disable cursor pointer when disabled
              }}
            >
              {title3}
            </button>
          </div> */}
          { practice_assignment_remaining_count === 0 &&  <Tooltip
          id="practice-assignment"
          place="bottom"
          content="No more than 2 review assignments can be generated."
          type="light"
          effect="float"
          /> }
        </div>
        <Tooltip
          id="practice-assignment"
          place="bottom"
          content="No more than 2 review assignments can be generated."
          type="light"
          effect="float"
        />

      </div>
      {showPopup && <PracticeAssignmentPopup assignment={assignment} onClose={handleClosePopup} setShowPopup={setShowPopup} setshowScorePopup={setshowScorePopup}
        setShowCongratulations={setShowCongratulations} setcallWebSocket={setcallWebSocket} callWebSocket={callWebSocket} setwebsocketPayload={setwebsocketPayload} isNaviget={isNaviget} />}
    </div>
  );
};

export default StudentAssignmentScore;