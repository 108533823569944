import React from "react";
import "./MultipleAssignmentviewPopup.css";
import BackButton from "../BackButton/BackButton";
import MultipleAssignmentStaticContent from "./MultipleAssignmentStaticContent";
import PreprocessAndRenderLatex from "../ProcessRenderLatex";
import { AssignmentType } from "../../utils/consts";

function MultipleAssignmentviewPopup({ type, startAssignmentPopupHandler, startAssignmentHandler, rubric, assignment, index }) {

    const getAssignmentTypeName = (type) => {
        if (assignment?.assignment_type === 'Multifaceted') {
            return AssignmentType['MULTIFACETED'].name
        } else {
            return AssignmentType[type]?.name;
        }
    };


    return (
        <>
            <div className="maltiple-assignment-view-popup-wrapper">
                <div className="maltiple-assignment-view-popup-container">
                    <div className="maltiple-assignment-view-popup-folder">
                        <div className="Back_button" onClick={startAssignmentPopupHandler}>
                            <i class="fa-solid fa-chevron-left"></i>
                            <span>Back to Assignments</span>
                        </div>
                        <div className="maltiple-assignment-view-popup-header">
                            <div className="maltiple-assignment-view-popup-title">
                                <h2>{type} {index + 1}: <span>{assignment?.name}</span></h2>
                                <p>{getAssignmentTypeName(assignment?.assignment_json?.[0]?.type)}</p>
                            </div>
                            <div className="maltiple-assignment-view-popup-time-folder">
                                <button onClick={startAssignmentHandler}>Start Assignment</button>
                            </div>
                        </div>
                        <div className="maltiple-assignment-view-popup-details">
                            <MultipleAssignmentStaticContent />
                            <PreprocessAndRenderLatex
                                ref={null}
                                content={rubric?.rubric}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MultipleAssignmentviewPopup;
