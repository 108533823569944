import React, { useEffect, useState } from "react";
import "./MultipleAssignment.css";
import { useNavigate } from "react-router-dom";
import QuestionNew from "../../pages/home/components/questions/Question_new";
import MultipleAssignmentviewPopup from "./MultipleAssignmentviewPopup";
import StudentAssignmentScore from "../../pages/home/components/dashboard/student/lessons/StudentAssignmentScore";
import { createAssignmentStatus, resetcreateAssignStatus } from "../../state/actions/createAssignmentStatusAction"
import { useAuth } from "../../features/auth/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import Helper from "../../utils/Helper";
import { Tooltip } from "react-tooltip";
import CongratulationsPopup from '../../components/CongratulationsPopup/CongratulationsPopup';
import {
    check_image
} from "../../assets/images/images";

function MultipleAssignmentStartAssignment({ index, practiceassignment, lesson, course, rubrics, setcallWebSocket, callWebSocket, setwebsocketPayload, assignmentResultHandler, filteredAssignments }) {

    const navigate = useNavigate();
    const { token } = useAuth();
    const dispatch = useDispatch();
    const [detailedResult, setdetailedResult] = useState(false);
    const [assignmentIndex, setAssignmentIndex] = useState(0);
    const [arrayIndex, setArrayIndex] = useState(0);
    const [pracassignmentPopUp, setpracassignmentPopUp] = useState(false);
    const [showScorePopup, setshowScorePopup] = useState(false);


    const [showCongratulations, setShowCongratulations] = useState(false);

    useEffect(() => {
        if (showCongratulations) {
            setTimeout(() => {
                setShowCongratulations(false)
            }, 2000)
        }

    }, [showCongratulations])


    const globalState = useSelector((state) => state.globalState);
    // const practice_assignment_status = globalState.userCurrentPlan?.currentSubscription && globalState.userCurrentPlan.currentSubscription.plan_data.practice_assignment

    const startPractiseAssignmentHandler = (status = 3) => {

        const course_id = course?.course_id;
        const lesson_id = lesson?.lesson_id;
        const id = practiceassignment?.id;


        const payload = {
            student_id: globalState.student_details.data.student_id,
            assignment_id: id,
            status: 3,
            completed_name: practiceassignment.name,
            assignment_type: 2,
        };

        dispatch(createAssignmentStatus(payload, token))

        try {
            const state = {
                course: JSON.parse(JSON.stringify(course)),
                lesson: JSON.parse(JSON.stringify(lesson)),
                assignment: practiceassignment,
                rubric: null,
                assignmentDtls: practiceassignment?.length > 0 ? JSON.parse(JSON.stringify(practiceassignment))
                    : null,
                type: 2,
            };
            navigate(
                // `/course/${course_id}/lesson/${lesson_id}/assignment/${practiceassignment.assignment_id}/preview`,
                `/course/${course_id}/lesson/${lesson_id}/assignment/${id}/preview`,
                { state }
            );
        } catch (error) {
            console.error("Serialization error:", error);
        }
    }

    return (
        <>
            <div className="maltiple-assignment-card">
                <p className="maltiple-assignment-title practice-assignment-title"><span>{index + 1}.</span>Review Assignment </p>
                <div className="maltiple-assignment-card-date-container">
                    <div className="maltiple-assignment-card-date">
                        <p>Created On: </p>
                        <p>{Helper.formatDateTime(practiceassignment?.created_at).date}</p>
                    </div>
                    <div className="maltiple-assignment-card-date">
                        {practiceassignment?.submission === null ? (
                            <>
                            </>
                        ) : (
                            <>
                                <p>Submitted On:</p>
                                <p>{Helper.formatDateTime(practiceassignment?.assignment_completed_at).date}</p>
                            </>
                        )}
                    </div>
                </div>
                {practiceassignment?.submission === null ?
                    <button
                        className="maltiple-assignment-btn maltiple-assignment-start-assignment-btn"
                        disabled={practiceassignment?.assignment_json === null || practiceassignment?.ai_feedback_json === null}
                        title={practiceassignment?.assignment_json === null || practiceassignment?.ai_feedback_json === null ? "Generating assignment...Please wait!" : ""}
                        onClick={() => setpracassignmentPopUp(!pracassignmentPopUp)}
                    // data-tooltip-id={!practice_assignment_status ? "show-message" : ""}
                    // style={{
                    //     backgroundColor: !practice_assignment_status ? "gray" : "",
                    //     color: !practice_assignment_status ? "white" : "",
                    //     border: !practice_assignment_status ? "none" : "",
                    //     cursor: !practice_assignment_status ? "not-allowed" : "",
                    // }}
                    >
                        {practiceassignment?.assignment_json === null || practiceassignment?.ai_feedback_json === null ? 'Generating assignment...Please wait!' : 'Start Assignment'}
                    </button>
                    : <button className="maltiple-assignment-btn maltiple-assignment-start-assignment-btn maltiple-assignment-view-result-assignment-btn" disabled={practiceassignment?.submission === null} onClick={() => { assignmentResultHandler(practiceassignment); setshowScorePopup(!showScorePopup) }}>View Result</button>}
                {/* <Tooltip
                    id="show-message"
                    place="bottom"
                    content="Upgrade Subscription Plan"
                    type="light"
                    effect="float"
                /> */}
            </div>

            {pracassignmentPopUp && <MultipleAssignmentviewPopup type={"Review Assignment"} startAssignmentPopupHandler={() => setpracassignmentPopUp(!pracassignmentPopUp)} startAssignmentHandler={startPractiseAssignmentHandler} rubrics={null} assignment={practiceassignment} index={index} />}

            {showScorePopup && (
                <StudentAssignmentScore
                    showScore={() => { }}
                    getSubmissiondtls={practiceassignment?.submission?.ai_evaluation_json}
                    onclick={() => {
                        setshowScorePopup(!showScorePopup)
                    }}
                    title={"Close"}
                    isCurrentAssignment={true}
                    onclick2={() => {
                        setdetailedResult(!detailedResult)
                        setshowScorePopup(!showScorePopup)
                    }}
                    title2={"View Result"}
                    // title3={"Review Assignment"}
                    assignment={practiceassignment}
                    setshowScorePopup={setshowScorePopup}
                    setShowCongratulations={setShowCongratulations}
                    setcallWebSocket={setcallWebSocket}
                    callWebSocket={callWebSocket}
                    setwebsocketPayload={setwebsocketPayload}
                    isNaviget={false}
                    filteredAssignments={filteredAssignments}
                />
            )}

            {showCongratulations && <CongratulationsPopup
                check_image={check_image}
                des="Review Assignments has been generated successfully."
            />}

            {detailedResult && (
                <div className="AssignmentViewProgress-mock-test-wrapper">
                    <div
                        className="AssignmentViewProgress-mock-test-container"
                    >
                        {
                            <QuestionNew
                                assignment={
                                    practiceassignment?.submission?.student_answers_json[
                                    assignmentIndex
                                    ]
                                }
                                ai_evaluation_json={
                                    practiceassignment?.submission?.ai_evaluation_json
                                }
                                index={assignmentIndex}
                                arrayIndex={arrayIndex}
                                currentStep={assignmentIndex + 1}
                                totalSteps={
                                    practiceassignment?.submission?.student_answers_json.length
                                }
                                handleNext={() =>
                                    assignmentIndex <
                                        practiceassignment?.submission?.student_answers_json.length - 1
                                        ? setAssignmentIndex((p) => p + 1)
                                        : null
                                }
                                handleBack={() => setAssignmentIndex((p) => p - 1)}
                                isLast={
                                    assignmentIndex ===
                                    practiceassignment?.submission?.student_answers_json.length - 1
                                }
                                isFirst={assignmentIndex === 0}
                                close={
                                    <span
                                        onClick={() => {
                                            setdetailedResult(!detailedResult)
                                            setAssignmentIndex(0)
                                        }}
                                        className="AssignmentViewProgress-mock-test-close-btn"
                                    >
                                        <i className="fa-solid fa-x"></i>
                                    </span>
                                }
                                questions={practiceassignment?.submission?.ai_evaluation_json?.questions}
                            />
                        }
                    </div>
                </div>
            )}

        </>
    );
}

export default MultipleAssignmentStartAssignment;
