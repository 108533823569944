import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import api from "../../../../../../api/api";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import "../../../../styles/dashboard.css";
import "../../../../styles/r-style.css";
import imgs from "../../../../../../assets/images/imgs";
import { updateGlobalState } from "../../../../../../state/global/slice";
import { useDispatch } from "react-redux";
import { devicon_linkedin } from "../../../../../../assets/images/images";
import { CircularProgress, Grid } from "@mui/material";

const TeacherProfileSetting = () => {
  const navigate = useNavigate();
  const { token } = useAuth();
  const { teacher_id } = useParams();
  const [getinfotecher, setinfoteacher] = useState({});
  const [gettechercoursedtls, settechercoursedtls] = useState({});
  const [loading, setLoading]= useState("");

  const dispatch = useDispatch();

  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };

  useEffect(() => {
    document.body.style.overflow = "auto";
    api
      .get(`/auth/get-teacher-by-teacher-id/${teacher_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          updateState("teacher_details", response?.data ?? {});
          setinfoteacher(response?.data?.data ?? []);
        }
      })
      .catch((error) => {
        //
      });

    api
      .get(`/courses/get-all-courses/teacher/${teacher_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          settechercoursedtls(response?.data?.data ?? []);
        }
      })
      .catch((error) => {
        //
      });
  }, [teacher_id]);

  return (
    <>
      <section className="teacher_profile_set_sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="teacher_profile_set_sec_inner">
                <Link to={`/dashboard/home`} className="back_btn_st">
                  <i className="fa-solid fa-chevron-left"></i>Back
                </Link>
                <div className="teacher_profile_body">
                  <h6>Teacher Profile</h6>
                  <div className="about_teach_main_profile">
                  <div className="teacher_profile_about_teacher">
                    <div className="about_teacher_img">
                      <div className="about_teach_sec_new_left">
                        <div className="about_left_pic">
                          <img
                            src={getinfotecher?.ai_avatar || imgs.png.ab_img}
                            alt=""
                          />
                        </div>
                        <h5>{getinfotecher?.name}</h5>
                      </div>
                    </div>
                    <div className="about_teacher_cont">
                      <h5>About Teacher</h5>
                      <p>{getinfotecher?.introduction}</p>
                    </div>
                    </div>
                    <div className="profile_linkedin">
                      <div className="profile_linkedin_image">
                        <img src={devicon_linkedin} />
                      </div>
                      <a href={getinfotecher.linkedin_url} target="_blank">{getinfotecher?.linkedin_url ?? "-"}</a>
                    </div>
                  </div>
                  <div className="subject_special_sec">
                    <h5>Subject Specialization</h5>
                    <ul>
                      {getinfotecher?.subjects &&
                      Object.values(getinfotecher?.subjects).length > 0 ? (
                        Object.values(
                          getinfotecher?.subjects
                        ).map((subject, index) => <li>{subject}</li>)
                      ) : (
                        <h6>No Specialization are selected</h6>
                      )}
                    </ul>
                  </div>
                  <div className="gray_house_sec">
                    <h5>Teacher Courses</h5>
                    <div className="gray_house_sec_inner">

                      {
                        loading  ? 
                          <Grid
                            height={"50vh"}
                            xs={12}
                            container
                            justifyContent={"center"}
                            alignItems={"center"}
                          >
                            <CircularProgress size={80} />
                          </Grid>
                            :      
                      gettechercoursedtls && gettechercoursedtls.length > 0 ? (
                        gettechercoursedtls.map((value, index) => (
                          <div className="gray_house_sec_single">
                            <div className="gray_house_sec_single_img">
                              <img src={value?.image} alt="" />
                            </div>
                            <div className="gray_house_sec_single_cont">
                              <div className="gray_house_teacher">
                                <img
                                  src={
                                    getinfotecher?.ai_avatar || imgs.png.ab_img
                                  }
                                  alt=""
                                />
                                <span>
                                  <h6>{getinfotecher?.name}</h6>
                                  <p>Teacher</p>
                                </span>
                              </div>
                              <div className="gray_house_teacher_cont_sec_new">
                                <h4>{value?.subject}</h4>
                                <h3>{value?.name}</h3>
                                <div className="view_cose_det_sec">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      if (value?.status === null || value?.status === "Pending") {  
                                        navigate(`/course/${value?.course_id}/view`,
                                        {
                                          state: {
                                            course: getinfotecher,
                                            isFromInvite: false,
                                          },
                                        });
                                      } else {
                                        navigate(`/course/${value?.course_id}/`);
                                      }
                                    }}
                                    className="view_course_sec"
                                  >
                                    View Course
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <h6>Course not uploaded yet</h6>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TeacherProfileSetting;
