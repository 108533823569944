import React, { useState } from 'react';
import "./AddToCartLesson.css";

function Lesson({course_info_image, Teacher_name, Teacher_title}) {

    const [isContainerVisible, setContainerVisible] = useState(false);

    const toggleContainer = () => {
        setContainerVisible(!isContainerVisible);
    };

    return (
        <> 
            <div className='lesson-main-wrapper'>
                <div className="lesson-container">
                    <div className="lesson-folder">
                        <div className="lesson-dropdown-tab" onClick={toggleContainer}>
                            <i className={`fa-solid fa-angle-${isContainerVisible ? 'down' : 'right'}`}></i>
                            <h2>Lesson 01</h2>
                        </div>
                        <p className="Lesson-des">Chemical Reaction</p>
                        <p className='lesson-duration'>Duration: <i className="fa-solid fa-clock"></i>Approx <span className="StudentViewCourseDetails-lesson-min">10</span> min</p>
                        <p className='assignments-count'><i className="fa-solid fa-clock"></i><span>1</span>assignments</p>
                    </div>
                    {isContainerVisible && (
                        <div className="lesson-inner-assignments-container">
                            <h2>Chemical Reaction</h2>
                            <div className="lesson-inner-assignments-wrapper">
                                <div className="lesson-inner-assignments-folder">
                                <div className="lesson-inner-assignments-text-feild">
                                    <h2>Assignment <span>01</span>: <span>chemical reaction</span></h2>
                                    <p>Multiple Choice Questions</p>
                                </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div> 
        </>
    )
}

export default Lesson;
