import React from 'react';

function Course({course_demo_image,
    Course_title , 
    Course_des,
    Lesson_number, 
    Lessons_name, 
    student_number, 
    Students_name, 
    Course_Start_Date, 
    Course_Expiry_Date,
    course_info_image,
    Teacher_name,
    Teacher_title,
}) {

    return (

        <>  
            <div className="view-course-details-container">
                <div className="view-course-details-folder">
                    <div className="view-course-deatails-left-section">
                        <div className="view-course-details-image">
                            <img src={course_demo_image} alt="" />
                        </div>
                    </div>
                    <div className="view-course-details-right-section">
                        <h2>{Course_title}</h2>
                        <div className="teacherviewcoursedetails-des-wrapper">
                            <p className="teacherviewcoursedetails-des">{Course_des}</p>
                        </div>
                        <div className="view-course-details-container">
                            <div className="view-Students-course-details-folder">
                                <span>Total Lessons</span>
                                <div className="view-Students-course-details-counts">
                                    <p>{Lesson_number}</p>
                                    <p>{Lessons_name}</p>
                                </div>
                            </div>
                            <div className="view-Students-course-details-folder">
                                <span>Students Enrolled</span>
                                <div className="view-Students-course-details-counts">
                                    <p>{student_number}</p>
                                    <p>{Students_name}</p>
                                </div>
                            </div>
                            <div className="view-Students-course-details-folder">
                                <span>Course Start Date</span>
                                <div className="view-Students-course-details-counts">
                                    <p>{Course_Start_Date}</p>
                                </div>
                            </div>
                            <div className="view-Students-course-details-folder">
                                <span>Course Expiry Date:</span>
                                <div className="view-Students-course-details-counts">
                                    <p>{Course_Expiry_Date}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Course;