import React from 'react'

function TeacherFilter({
    gradeList,
    grade,
    setgrade,
    subjectList,
    subject,
    setsubject,
    isVisiblefillter,
    handleApplyClick,
}) {
    return (
        <>
            {isVisiblefillter && (
                <div className="sort-popup-assignprocess" style={{ right: "125px", top: "175px" }}>
                    <h3>Filter By:</h3>

                    {Object.entries(subjectList).length > 0 && (
                        <div>
                            <label>Subject:</label>
                            <select id="subject" name="subject"
                                value={subject || ""}
                                onChange={(e) => setsubject(e.target.value)}
                            >
                                <option value="">All</option>
                                {Object.keys(subjectList).map((key) => (
                                    <option
                                        key={`subject${key}`}
                                        value={key}
                                    >
                                        {subjectList[key]}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}

export default TeacherFilter