import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { Ring } from "@uiball/loaders";
import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../api/api";
import { student_login_bg, teacher_login_bg } from "../../assets/images/images";
import EmailVerification from "../../components/EmailVerification"; // import the ForgotPassword component
import SwitchTab from "../../components/SwitchTab";
import { useAuth } from "../../features/auth/AuthContext";
import { formatApiErrors } from "../../utils/formatApiErrors";
import {
  isRequired,
  isValidEmail,
  isValidPassword,
} from "../../utils/validator";
import GoogleInUp from "../GoogleInUp";

const Signup = (selectedTab) => {
  const [checkvalidationregisteris, setcheckvalidationregisteris] = useState(
    false
  );
  const globalState = useSelector((state) => state.globalState);
  const subadmin_details = globalState.subadmin_details;
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [tempFormData, setTempFormData] = useState(null);
  const { login } = useAuth();
  const location = useLocation();
  const roleFromState = location.state?.role || "";
  // const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(roleFromState);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    email: "",
    // password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    // password: "",
  });
  const [searchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const redirectPath = searchParams.get("redirect");
  if (redirectPath) {
    localStorage.setItem("redirectAfterSignup", redirectPath);
  }
  const redirectLink = localStorage.getItem("redirectAfterSignup");

  const redirectCourse = searchParams.get("redirectToCourse");
  if (redirectCourse) {
    localStorage.setItem("redirectToCourse", redirectCourse);
  }

  const redirectToCoursePath = localStorage.getItem("redirectToCourse");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (activeTab !== "" || activeTab !== null) {
      setcheckvalidationregisteris(false);
    }
  };

  const handleSignUpClick = (event) => {
    event.preventDefault(); // Prevent the default behavior of the anchor link
    // Pass the activeTab as state when navigating to /signup
    navigate("/login", { state: { role: activeTab } });
  };

  const handleChange = (e) => {
    // Check if Enter key is pressed and ignore tab switch
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default behavior (e.g., form submission)
      handleSubmit(e); // Call the submit function
      return;
    }
    const { name, value } = e.target;
    let error = "";
    if (name === "email") {
      error = isRequired(value, "email") || isValidEmail(value);
    } 
    // else if (name === "password") {
    //   error = isRequired(value, "password") || isValidPassword(value);
    // }

    setFormErrors({
      ...formErrors,
      [name]: error,
    });

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (activeTab === "" || activeTab === null) {
      setcheckvalidationregisteris(true);
      return;
    }

    let errors = {};

    errors.email =
      isRequired(formData.email, "email") || isValidEmail(formData.email);
    // errors.password =
    //   isRequired(formData.password, "password") ||
    //   isValidPassword(formData.password);

    setFormErrors(errors);
    if (!Object.values(errors).some((error) => error)) {
      handleFormSubmit(activeTab, formData);
    }
  };

  const [registerCredentials, setRegisterCredentials] = useState({
    email: "",
    otp: "",
    role: "",
  }); // To store register credentials

  const [isverified, setIsverified] = useState(false);
  const signupUser = async (email, role , otp = null ) => {
    const email_id = formData.email 
    if (Object.values(formErrors).length && activeTab.length) {
      const failedErrorMessage =
        "Oops, something went wrong. Please try again later.";
      let role = 2;
      if (activeTab === "Student") {
        role = 1;
      } else if (activeTab === "Teacher") {
        role = 2;
      } else if (activeTab === "Parent") {
        role = 3;
      }

      try {

        // const response = await api.post(`/auth/register-user/`, {
        //   email,
        //   // password,
        //   role,
        // });

        const payload = otp ? { email , role ,  otp } : { email, role };
        
        const response = await api.post('/auth/register-user/', payload);

        

        if (response?.status === 400) {
          toast.dismiss();
          const error = formatApiErrors(response?.data);
          toast.error(error || response?.data?.message || failedErrorMessage, {
            position: toast.POSITION.TOP_CENTER,
          });

          setLoading(false);
          return;
        }
        if (
          response?.data?.status === 200 &&
          response?.data?.message ===
          "Verification code has been sent to your email."
        ) {
          setRegisterCredentials({ email, role }); // Save the email and password to pass to the verification component
          setLoading(false);
          setIsModalOpen(true);
          toast.success("Verification code sent successfully", {
            position: toast.POSITION.TOP_CENTER,
          });
          return;
        } 
        if (response?.data?.data?.token === undefined) {
          toast.dismiss();
          const error = formatApiErrors(response?.data);

          toast.error(error || response?.data?.message || failedErrorMessage, {
            // toast.error('watng', {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
          return;
        }
        login(response?.data?.data?.token, activeTab, email || "");
        setIsverified(true);
        toast.dismiss();
        toast.success("Signup successful.", {
          position: toast.POSITION.TOP_CENTER,
        });
        if (activeTab === "Student") {

          const { pathname } = location.state || { from: "/dashboard/home" };
          navigate(pathname || "/dashboard/home", {
            state: { role: activeTab },
          });

        } else if (activeTab === "Teacher") {

          navigate("/dashboard/home", { state: { role: activeTab } });

        } else if (activeTab === "Parent") {
          role = "parent";
        }
        setLoading(false);
      } catch (error) {
        // if (error?.response?.data?.message === "Email not verified.") {
        //   checkEmailVerfied();
        // } else {
          toast.dismiss();
          const errorMessage = formatApiErrors(error?.response?.data);
          toast.error(errorMessage || failedErrorMessage, {
            position: toast.POSITION.TOP_CENTER,
          });
          setLoading(false);
        // }
      }
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleFormSubmit = async (role, formData) => {
    setSelectedRole(role);
    setTempFormData(formData); // Store form data temporarily
    setOpenDialog(true);
  };

  // Function to confirm the registration
  const confirmRegistration = async () => {
    setOpenDialog(false);
    if (tempFormData) {
      await signupUser(
        tempFormData.email.toLocaleLowerCase(),
        // tempFormData.password
      );
      // Clear the temporary form data after use
      setTempFormData(null);
    }
  };

  // Function to cancel the registration
  const cancelRegistration = () => {
    setOpenDialog(false);
    setTempFormData(null);
    // Reset the selected role or handle cancellation
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default Enter key behavior

      handleSubmit(e);
    }
  };

  // const checkEmailVerfied = (e) => {
  //   if (!formErrors.email.length) {
  //     if (activeTab === "" || activeTab === null) {
  //       setcheckvalidationregisteris(true);
  //       return;
  //     }
  //     onSendEmailForgotPass(formData.email).then((res) => {
  //       if (res.status === 200) {
  //         toast.success("Verification code sent successfully");
  //         setIsModalOpen(true);
  //       } else if (res.status === 400) {
  //         // let error = res?.data?.message || "Please Enter valid email";
  //         // setFormErrors({
  //         //   ...formErrors,
  //         //   ["email"]: error?.trim(),
  //         // });
  //         // setIsverified(true);
  //         toast.error(res?.data?.message || "Please Enter valid email");
  //       } else if (res.status === 406) {
  //         let error = res?.data?.message || "Please Enter valid email";
  //         setFormErrors({
  //           ...formErrors,
  //           ["email"]: error?.trim(),
  //         });
  //         // setIsverified(true);
  //         toast.error(res?.data?.message || "Please Enter valid email");
  //       } else {
  //         let error = res?.data?.message || "Please Enter valid email";
  //         setFormErrors({
  //           ...formErrors,
  //           ["email"]: error?.trim(),
  //         });
  //         // toast.error("Please Enter valid email");
  //       }
  //     });
  //   }
  // };
  // const onSendEmailForgotPass = async (email) => {
  //   if (email) {
  //     let role = 0;
  //     if (activeTab === "Student") {
  //       role = 1;
  //     } else if (activeTab === "Teacher") {
  //       role = 2;
  //     } else if (activeTab === "Parent") {
  //       role = 3;
  //     }

  //     return await api
  //       .post("auth/email-verification/", {
  //         email: email,
  //         role: role,
  //       })
  //       .then((res) => {
  //         return res;
  //       })
  //       .catch((err) => {
  //         return err?.response;
  //       });
  //   }
  //    else {
  //     return "Please enter valid email";
  //   }
  // };

  // Callback function to handle OTP verification and complete registration
  const handleVerificationSubmit = async (role, otp) => {
    await signupUser(formData.email, role, otp);
  };

  return (
    <>
      <div className="signup-section" onKeyPress={handleKeyPress}>
        <div className="signup-wrapper">
          <form
            action=""
            className="signup-right-section"
            onSubmit={handleSubmit}
          >
            <div className="Register-container">
              <span>Welcome to</span>
              <h2 onClick={() => {
                navigate("/");
              }}>Saddle River Day School</h2>
              <div className="Register-tab-section">
                <p className="Register-title">Register as</p>
                <div className="signup-tab-section">
                  <SwitchTab
                    activeTab={activeTab}
                    onTabChange={handleTabChange}
                  />
                </div>
              </div>
              {checkvalidationregisteris && (
                <p className="regiterAsNote">
                  Please select register as Student or Teacher.
                </p>
              )}
              <div className="signup-form">
                <p className="email-or-phoneNumber-title">Email</p>
                <div className="signup-input edit-email">
                  <FormControl
                    margin="normal"
                    fullWidth
                    error={!!formErrors.email}
                  >
                    <TextField
                      name="email"
                      size="small"
                      value={formData.email}
                      error={!!formErrors.email}
                      onChange={handleChange}
                      disabled={activeTab !== "Teacher" && activeTab !== "Student"}
                      InputProps={{
                        sx: {
                          borderRadius: "35px",
                          backgroundColor: "#F5F5F5",
                        },
                      }}
                    />
                    <div className="forgot-password-folder">
                      {isverified && (
                        <div className="emaild-verifed-btn">Verified</div>
                      )}
                    </div>
                    <FormHelperText>{formErrors.email}</FormHelperText>
                  </FormControl>
                </div>
                {/* <p className="email-or-phoneNumber-title">Create Password</p>
                <div className="signup-input hide-show-password">
                  <FormControl
                    margin="normal"
                    fullWidth
                    error={!!formErrors.password}
                  >
                    <TextField
                      size="small"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      error={!!formErrors.password}
                      onChange={handleChange}
                      disabled={activeTab !== "Teacher" && activeTab !== "Student"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowPassword(
                                  (prevShowPassword) => !prevShowPassword
                                )
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                        sx: {
                          borderRadius: "35px",
                          backgroundColor: "#F5F5F5",
                        },
                      }}
                    />
                    <FormHelperText>
                      <ul>
                        {formErrors.password?.split(".").map((error) => (
                          <li key={error}>{error}</li>
                        ))}
                      </ul>
                    </FormHelperText>
                  </FormControl>
                </div> */}
              </div>
              <div className="signup-btn">
                {loading ? (
                  <Ring />
                ) : (
                  <button disabled={loading} type="submit">
                    Register
                  </button>
                )}
              </div>
              <div className="login-with-page-section">
                <div className="login-with-page-folder">
                  <GoogleInUp
                    forContent="Sign Up with Google"
                    loading={loading}
                    setRoleValid={setcheckvalidationregisteris}
                    activeTab={activeTab}
                    setLoading={setLoading}
                    login={login}
                  />
                  <div>
                  </div>
                </div>
              </div>
              <div className="Already-have-an-accout-folder">
                <span>
                  Already have an account?{" "}
                  <a href="#" onClick={handleSignUpClick}>
                    {" "}
                    Login
                  </a>
                </span>
              </div>
              <p className="terms-and-Privacy-policy">
                By Logging In Saddle River day school, you agree to our terms of
                use & Privacy policy
              </p>
              <div className="back-home-btn" onClick={() => {
                navigate("/");
              }}>
                <span><i class="fa-solid fa-chevron-left"></i></span>
                <span>Home</span>
              </div>
            </div>
          </form>
          <div className="signup-left-section">
            <div className="signup-image">
              <img
                src={
                  activeTab === "Student" ? student_login_bg : teacher_login_bg
                }
                alt="Background"
              />
            </div>
          </div>
        </div>
        <Dialog open={openDialog} onClose={cancelRegistration}>
          <DialogTitle>Confirm Registration</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to register as a {selectedRole}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelRegistration}>Cancel</Button>
            <Button onClick={confirmRegistration} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>

      {isModalOpen && (
        <EmailVerification
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          email={formData.email}
          // password={formData.password}
          // otp={registerCredentials.otp}
          setRegisterCredentials={setRegisterCredentials}
          role={registerCredentials.role}
          // setIsverified={setIsverified}
          signupUser={signupUser}
          // onVerificationSubmit={handleVerificationSubmit} // Pass the callback
        />
      )}
    </>
  );
};

export default Signup;