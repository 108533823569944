import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, Radio, TextField } from "@mui/material";
import "./PracticeAssignmentPopup.css";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { toast } from "react-toastify";
import Checkbox from '@mui/material/Checkbox';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    benefits_practice_assignments_image_1,
    benefits_practice_assignments_image_2,
    benefits_practice_assignments_image_3,
    benefits_practice_assignments_image_4,
    benefits_practice_assignments_image_5,
    benefits_practice_assignments_image_6
} from "../../../../../../../assets/images/images";
import { useDispatch, useSelector } from 'react-redux';
import { generatePracticeAssignment, resetGeneratePracticeAssignment } from '../../../../../../../state/actions/generatePracticeAssignmentAction'
import { AssignmentType } from "../../../../../../../utils/consts";
import { useAuth } from '../../../../../../../features/auth/AuthContext';
import { getPracticeAssignments } from "../../../../../../../state/actions/getPracticeAssignmentsAction";
import Modal from 'react-responsive-modal';

const getAssignmentTypeName = (type) => {
    return Object.values(AssignmentType).find((item) => {
        return item.value === type;
    })?.name;
};


const assignmentTypes = [
    'Multiple Choice Questions',
    'True/False',
    'Fill in the Blanks',
    'Short Answer Questions',
    'Essay Questions',
    'Mixed',
    'Other',
];


const difficultyLevels = [
    { label: 'Easy', value: 1 },
    { label: 'Moderate', value: 2 },
    { label: 'Difficult', value: 3 },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function PracticeAssignmentPopup({ assignment, onClose, setShowPopup, setshowScorePopup, setShowCongratulations, setcallWebSocket, callWebSocket, setwebsocketPayload, isNaviget }) {


    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const course = location?.state?.course;
    const lesson = location?.state?.lesson;
    const { token } = useAuth();
    const lessonName = location?.state?.lesson.name;
    const courseName = location?.state?.course.name;
    const courseId = location?.state?.course.course_id;
    const lessonId = location?.state?.lesson.lesson_id;
    const [personName, setPersonName] = React.useState([]);
    const [selectedAssignmentType, setSelectedAssignmentType] = useState('');
    const [selectedDifficultyLevel, setSelectedDifficultyLevel] = useState('');
    const [submissionId, setSubmissionId] = useState(assignment?.submission?.submission_id || null);
    const [practiceId, setPracticeId] = useState(null);
    const generatepracticeassignment = useSelector((state) => state.generatePracticeAssignment);
    const [openDialog, setOpenDialog] = useState(false);
    const [item_id, setitem_id] = useState();
    const [paymentSuccessPopup, setpaymentSuccessPopup] = useState(false);

    const handleAssignmentTypeChange = (event) => {
        setSelectedAssignmentType(event.target.value);
    };

    const handleDifficultyLevelChange = (event) => {
        setSelectedDifficultyLevel(event.target.value);
    };

    const [formErrorAssignmentState, setFormErrorAssignmentState] = useState({});
    // const [showCongratulations, setShowCongratulations] = useState(false);


    const handleProceedToPay = async (assignment) => {

        const payload = {
            assignment_id: assignment?.assignment_id,
            type: selectedAssignmentType,
            level: selectedDifficultyLevel,
            name: assignment?.name,
        };
        dispatch(generatePracticeAssignment(payload, token))

        // dispatch(generatePracticeAssignment(payload, token));
        // try {
        //     const response = dispatch(generatePracticeAssignment(payload, token));

        //     console.log("response", response);



        // console.log("response", response?.data);

        //     if (response?.status === 200) {
        //         // console.log("response", response?.data?.data?.practice_assignment_id);()
        //         if (isNaviget == true) {
        //             setshowScorePopup(false);
        //             setShowCongratulations(true);
        //             setTimeout(() => {
        //                 navigate(`/course/${courseId}/lesson/${lessonId}/overview`, {
        //                     state: {
        //                         courseId: courseId,
        //                         lessonId: lessonId,
        //                         course: course,
        //                         lesson: lesson,
        //                         activeTab: "Assignments",
        //                         submission_id: assignment.submission.submission_id,
        //                         practice_id: response?.data?.data.practice_assignment_id,
        //                         assignment_id: assignment.assignment_id,
        //                     }
        //                 });
        //             }, 2150)

        //         }
        //         setcallWebSocket(!callWebSocket)


        //         setShowPopup(false);
        //         setshowScorePopup(false);
        //         const { practice_assignment_id } = response?.data?.data || {};
        //         setwebsocketPayload((prev) => {
        //             return {
        //                 ...prev,
        //                 practice_id: practice_assignment_id,
        //             };
        //         })
        //         setPracticeId(practice_assignment_id); // Set the practice_id
        //         setShowCongratulations(true);


        //     } else {
        //         console.error("Failed to generate practice assignment:", response);
        //     }
        // } catch (error) {
        //     console.log("error", error);

        //     console.warn("Error occurred while generating practice assignment:", error);
        // }

    };


    // useEffect(() => {
    //     if (showCongratulations) {
    //         setTimeout(() => {
    //             dispatch(resetGeneratePracticeAssignment());
    //             setShowCongratulations(false);
    //             navigate(`/course/${courseId}/lesson/${lessonId}/overview`, {
    //                 state: {
    //                     courseId: courseId,
    //                     lessonId: lessonId,
    //                     course: course,
    //                     lesson: lesson,
    //                     activeTab: "Assignments",
    //                     submission_id: submissionId,
    //                     practice_id: practiceId,
    //                 }
    //             });
    //         }, 3000)
    //     }
    // }, [showCongratulations])





    const isButtonDisabled = !selectedAssignmentType || !selectedDifficultyLevel;

    useEffect(() => {
        if (generatepracticeassignment.error === 'You cannot generate more than 2 practice assignments for this assignment.') {
            toast.error(generatepracticeassignment.error)
            return;
        }
        if (generatepracticeassignment.practice_assignment_id) {
            if (isNaviget == true) {
                setshowScorePopup(false);
                setShowCongratulations(true);
                setTimeout(() => {
                    navigate(`/course/${courseId}/lesson/${lessonId}/overview`, {
                        state: {
                            courseId: courseId,
                            lessonId: lessonId,
                            course: course,
                            lesson: lesson,
                            activeTab: "Assignments",
                            submission_id: assignment.submission.submission_id,
                            practice_id: generatepracticeassignment.practice_assignment_id,
                            assignment_id: assignment.assignment_id,
                        }
                    });
                }, 2150)
                return;
            }
            setcallWebSocket(!callWebSocket)


            setShowPopup(false);
            setshowScorePopup(false);
            setwebsocketPayload((prev) => {
                return {
                    ...prev,
                    practice_id: generatepracticeassignment.practice_assignment_id,
                };
            })
            setPracticeId(generatepracticeassignment.practice_assignment_id); // Set the practice_id
            setShowCongratulations(true);

        }

        return () => dispatch(resetGeneratePracticeAssignment());

        // const assignJson = {
        //     submission_id: assignment?.submission?.submission_id,
        //     level: selectedDifficultyLevel,
        //     type: selectedAssignmentType,
        // }

    }, [generatepracticeassignment])

    const close = (id) => {
        setitem_id(id)
        setOpenDialog(!openDialog); // This will close the popup
    };


    return (
        <>
            <div className="popup-wrapper">
                <div className="popup-container">
                    <div className="practice-assignment-popup">
                        <h2>Review Assignment : <span>{assignment?.name}</span></h2>
                        <p><span>Lesson:</span> {lessonName}</p>
                        <p><span>Course:</span> {courseName}</p>
                        <div className="practice-assignment-input-folder">
                            <div className="practice-assignment-inner-input">
                                <label>Assignment type</label>
                                <FormControl margin="none">
                                    <Select
                                        value={selectedAssignmentType}
                                        onChange={handleAssignmentTypeChange}
                                        displayEmpty
                                        renderValue={(selected) => {
                                            if (!selected) {
                                                return <p className='select-assignment-type'>Select assignment type</p>;
                                            }
                                            return Object.values(AssignmentType).find(type => type.value === selected)?.name;
                                        }}
                                        MenuProps={MenuProps}
                                        sx={{
                                            height: 42,
                                            "& .MuiInputBase-input": {
                                                height: "30px",
                                                padding: "6px 14px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "4px",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                fontFamily: "inherit",
                                                background: "rgba(248, 248, 248, 1)",
                                            },
                                        }}
                                    >
                                        {Object.values(AssignmentType).map((type) => (
                                            <MenuItem key={type.name} value={type.value}>
                                                <ListItemText primary={type.name} />
                                                <Radio checked={selectedAssignmentType === type.value} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="practice-assignment-inner-input">
                                <label>Difficulty Level</label>
                                <FormControl>
                                    <Select
                                        value={selectedDifficultyLevel}
                                        onChange={handleDifficultyLevelChange}
                                        displayEmpty
                                        renderValue={(selected) => {
                                            if (!selected) {
                                                return <p className='select-difficulty-level'>Select difficulty level</p>;
                                            }
                                            return difficultyLevels.find(level => level.value === selected)?.label;
                                        }}
                                        MenuProps={MenuProps}
                                        sx={{
                                            height: 42,
                                            "& .MuiInputBase-input": {
                                                height: "30px",
                                                padding: "6px 14px",
                                            },
                                            "& .MuiOutlinedInput-root": {
                                                borderRadius: "4px",
                                                fontSize: "14px",
                                                fontWeight: 400,
                                                fontFamily: "inherit",
                                                background: "rgba(248, 248, 248, 1)",
                                            },
                                        }}
                                    >
                                        {difficultyLevels.map((level) => (
                                            <MenuItem key={level.value} value={level.value}>
                                                <ListItemText primary={level.label} />
                                                <Radio checked={selectedDifficultyLevel === level.value} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className='benefits-practice-assignments-feature-container'>
                            <h2 className='benefits-practice-assignments-feature-title'>Benefits of the "Review Assignments" Feature:</h2>
                            <div className='benefits-practice-assignments-feature-folder'>
                                <div className='benefits-practice-assignments-inner-feature-folder'>
                                    <div className='benefits-practice-assignments-image'>
                                        <img src={benefits_practice_assignments_image_1} />
                                    </div>
                                    <h2>Personalized Learning: <span>Tailor assignments based on past performance.</span></h2>
                                </div>
                                <div className='benefits-practice-assignments-inner-feature-folder'>
                                    <div className='benefits-practice-assignments-image'>
                                        <img src={benefits_practice_assignments_image_2} />
                                    </div>
                                    <h2>Skill Improvement: <span>Focus on areas needing more practice.</span></h2>
                                </div>
                                <div className='benefits-practice-assignments-inner-feature-folder'>
                                    <div className='benefits-practice-assignments-image'>
                                        <img src={benefits_practice_assignments_image_3} />
                                    </div>
                                    <h2>Flexible Difficulty Levels: <span>Choose difficulty to match your skills</span></h2>
                                </div>
                                <div className='benefits-practice-assignments-inner-feature-folder'>
                                    <div className='benefits-practice-assignments-image'>
                                        <img src={benefits_practice_assignments_image_4} />
                                    </div>
                                    <h2>Self-Paced Progress: <span>Complete assignments at your own pace.</span></h2>
                                </div>
                                <div className='benefits-practice-assignments-inner-feature-folder'>
                                    <div className='benefits-practice-assignments-image'>
                                        <img src={benefits_practice_assignments_image_5} />
                                    </div>
                                    <h2>Enhanced Subject Mastery: <span>Target specific subjects for better mastery</span></h2>
                                </div>
                                <div className='benefits-practice-assignments-inner-feature-folder'>
                                    <div className='benefits-practice-assignments-image'>
                                        <img src={benefits_practice_assignments_image_6} />
                                    </div>
                                    <h2>Performance Tracking: <span>Monitor progress and improvement over time</span></h2>
                                </div>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="practice-assignment-button"
                            onClick={() => handleProceedToPay(assignment)}
                            disabled={isButtonDisabled}
                            style={{
                                backgroundColor: isButtonDisabled ? 'gray' : '#0068FF'
                            }}>
                            Proceed
                        </button>
                    </div>
                    <span className="practice-assignment-close" onClick={onClose}><i class="fa-solid fa-x"></i></span>




                    {/* {openDialog && (
                        <Modal
                            open={openDialog}
                            onClose={() => setOpenDialog(false)}
                            center
                            showCloseIcon={false}
                            classNames={{
                                modal: 'Cancel-Popup',
                            }}
                        >
                            <CancelPopup
                                title="Are you sure you want to remove this course from the cart?"
                                No="No"
                                Yes="Yes"
                                // show={show} // Assuming you have a state or value to pass 
                                close={close} // Pass the reference, not the invocation
                            />
                        </Modal>
                    )} */}
                </div>
            </div>
        </>
    )
}

export default PracticeAssignmentPopup;