import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { truncateFileName } from '../../utils/truncate';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import { deleteUploadedFile, resetdeleteUploadedFile } from "../../state/actions/deleteUploadedFileAction";
import { useAuth } from '../../features/auth/AuthContext';
import { Tooltip } from "react-tooltip";

function AWSS3FileDelete({ selectedFiles, setSelectedFiles, getFileIcon, uploadedFileLink, setuploadedFileLink, progressTracker, setprogressTracker, fileInputRef }) {
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { fileStatus, uploadProgress, isLoading, error } = useSelector((state) => state.getFileUploadStatus);
    const handleRemoveFile = async (index) => {
        const updatedFiles = [...selectedFiles];
        const deletedFiles = [...uploadedFileLink];
        const splicedArray = deletedFiles.splice(index, 1);

        const fileToDelete = splicedArray[0];
        if (fileToDelete) {
            await handleS3FileDelete(fileToDelete);  // Delete from S3 using presigned URL
        }

        updatedFiles.splice(index, 1);
        setSelectedFiles(updatedFiles);
        setuploadedFileLink(deletedFiles);
        const del = [...progressTracker];
        del.splice(index, 1);
        setprogressTracker(del);

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleS3FileDelete = async (file) => {
        try {

            // Dispatch the delete action to update the backend
            dispatch(deleteUploadedFile([file.file_id], token));
            // toast.success(`${file.name} deleted successfully`);
        } catch (error) {
            console.error('Error deleting file:', error);
            // toast.error(`Error deleting ${file.name}`);
        }
    };

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    useEffect(() => {
        if (progressTracker.length) {
            if (Object.entries(fileStatus).length && uploadProgress != null) {
                let updated = false;
                const newProgressTracker = progressTracker.map(p => {
                    if (p.uuid === fileStatus.uuid) {
                        updated = true;
                        return { ...p, ...fileStatus, uploadProgress };
                    }
                    return p;
                });

                if (!updated) {
                    newProgressTracker.push({ ...fileStatus, uploadProgress });
                }

                setprogressTracker(newProgressTracker);
            }
        } else {
            if (Object.entries(fileStatus).length && uploadProgress != null) {
                setprogressTracker([{ ...fileStatus, uploadProgress }]);
            }
        }
    }, [fileStatus]);

    useEffect(() => {
        return () => {
            setprogressTracker([]);
            dispatch(resetdeleteUploadedFile());
        }
    }, []);

    return (
        <>
            {
                selectedFiles.length > 0 &&
                selectedFiles.map((file, index) => {
                    const fileURL = URL.createObjectURL(file);
                    return (
                        <React.Fragment key={index}>
                            <div
                                data-tooltip-id={file.name}
                                className="pdf_box_sec_inner_single"
                                style={{ position: 'relative' }}
                            >
                                <a href={fileURL} target="_blank" rel="noopener noreferrer">
                                    <span>
                                        <h5><img src={getFileIcon(file.name)} />{truncateFileName(file.name) + " (" + formatBytes(file.size) + ")"}</h5>
                                    </span>
                                </a>
                                <i
                                    className="fas fa-times"
                                    onClick={() => handleRemoveFile(index)}
                                ></i>

                                {uploadedFileLink.length < selectedFiles.length &&
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            right: 0,
                                            bottom: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: 'rgba(255, 255, 255, 0.5)'
                                        }}
                                    >
                                        <CircularProgress variant="determinate" value={0} />
                                    </div>
                                }
                                {progressTracker.length > 0 && progressTracker.map((tracker, idx) => {

                                    const uniqueKey = `${tracker.original_name}-${idx}`;

                                    return (
                                        <React.Fragment key={`progress-${uniqueKey}`}>
                                            {
                                                tracker.status !== "Completed" && (file?.name === tracker?.original_name) && (
                                                    <div
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                                            width: '100%',
                                                            height: '100%',
                                                            margin: '0px auto',
                                                        }}
                                                    >
                                                        <CircularProgress variant="determinate" value={parseInt(tracker.uploadProgress)} />
                                                    </div>
                                                )
                                            }

                                            {tracker.status === "Failed" && (file?.name === tracker?.original_name) && (
                                                <button className='s3_file_uploade_failed'
                                                    key={`button-${uniqueKey}`}
                                                    onClick={() => handleRemoveFile(index)}
                                                >
                                                    Failed to Upload
                                                    <i className="fas fa-times"></i>
                                                </button>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                            <Tooltip
                                id={file.name}
                                place="top"
                                content={`${file.name} (${formatBytes(file.size)})`}
                                type="light"
                                effect="float"
                            />
                        </React.Fragment>
                    );
                })
            }
        </>
    );
}

export default AWSS3FileDelete;
