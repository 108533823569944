import React, { useEffect } from 'react';
import { submitGoogleToken, resetsubmitgoogleToken } from "../../state/actions/submitGoogleTokenAction";
import { getGoogleTokenAction, resetGoogleToken } from "../../state/actions/getGoogleTokenAction";
import { unlinkGDrive, resetunlinkGoogleDrive } from "../../state/actions/unlinkGoogleDriveAction";
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '../../features/auth/AuthContext';
import { google_drive } from "../../assets/images/images";
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';

const GoogleLoginComponent = () => {

    const dispatch = useDispatch();
    const { token } = useAuth();

    const googleToken = useSelector((state) => state.submitGoogleToken);
    const googleAccessToken = useSelector(state => state.getGoogleAccessToken);
    const unLinkgdrive = useSelector(state => state.unlinkgdrive);

    const onSuccess = async (tokenResponse) => {


        const response = await fetch('https://oauth2.googleapis.com/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams({
                code: tokenResponse.code,
                client_id: process.env.REACT_APP_GOOGLEDRIVE_CLIENTID,
                client_secret: process.env.REACT_APP_GOOGLEDRIVE_SECRET_KEY,
                redirect_uri: process.env.REACT_APP_SHARE_URL,  // Ensure this matches with your console setup
                grant_type: 'authorization_code',
                access_type: 'offline'
            })
        });

        const tokenData = await response.json();

        if (tokenData) {

            const creds = {
                google_token: {
                    token: tokenData.access_token,
                    refresh_token: tokenData.refresh_token || null,
                    token_uri: "https://oauth2.googleapis.com/token",
                    client_id: process.env.REACT_APP_GOOGLEDRIVE_CLIENTID,
                    client_secret: process.env.REACT_APP_GOOGLEDRIVE_SECRET_KEY,
                    scopes: tokenResponse.scope ? tokenData.scope.split(' ') : [],
                    expiry: new Date(Date.now() + tokenData.expires_in * 1000).toISOString()
                }
            };

            handleLogin(creds);
        }
    };

    const onFailure = (error) => {
        console.error("Login failed:", error);
    };

    const loginWithGoogle = useGoogleLogin({
        onSuccess,
        onError: onFailure, // Changed from onFailure to onError as per the GIS library
        scope: [
            "https://www.googleapis.com/auth/drive.file",
            "https://www.googleapis.com/auth/drive",
            "https://www.googleapis.com/auth/drive.metadata.readonly",
            "https://www.googleapis.com/auth/drive.metadata",
        ].join(" "),
        access_type: 'offline',
        approval_prompt: 'force',
        prompt: 'consent',
        flow: 'auth-code'
    });

    const handleLogin = (req) => {
        dispatch(submitGoogleToken(req, token))
    }

    const unLinkGDrive = () => {
        dispatch(unlinkGDrive(token))
    }

    useEffect(() => {
        if (googleToken?.google_token !== null || unLinkgdrive.message !== null) {
            dispatch(getGoogleTokenAction(token));
        }
        if (unLinkgdrive.message !== null) {
            toast.success("Drive Unlinked Successfully");
        }
        return () => {
            dispatch(resetsubmitgoogleToken());
            dispatch(resetunlinkGoogleDrive());
        }
    }, [googleToken, unLinkgdrive])

    return (
        <>
            {googleAccessToken?.googleToken === null ?
                <div className="Marketplace-btn">
                    <img src={google_drive} alt="Google Drive" />
                    <button type="button" onClick={loginWithGoogle}>Connect To Drive</button>
                </div>
                : <div className="Marketplace-btn">
                    <img src={google_drive} alt="Google Drive" />
                    <button type="button" onClick={unLinkGDrive}>Disconnect From Drive</button>
                </div>
            }
        </>
    );
};

export default GoogleLoginComponent;
