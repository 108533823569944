import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface generatePracticeAssignment {
    isLoading: boolean;
    error: string | null;
    order_id: string | null;
    practice_assignment_id: string | null;
}

const initialState: generatePracticeAssignment = {
    order_id: null,
    practice_assignment_id: null,
    isLoading: false,
    error: null,
};

const generatePracticeAssignment = createSlice({
    name: 'GeneratePracticeAssignment',
    initialState,
    reducers: {
        setGeneratePracticeAssignment: (state: generatePracticeAssignment, action: PayloadAction<generatePracticeAssignment>) => {
            state.order_id = action.payload.order_id;
            state.practice_assignment_id = action.payload.practice_assignment_id;
            state.isLoading = false;
            state.error = null;
        },
        setloading: (state: generatePracticeAssignment, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
            state.error = null;
        },
        seterror: (state: generatePracticeAssignment, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.isLoading = false;
        },
        resetGeneratePracticeAssignment: () => {
            return initialState;
        },
    },
});

export const { setGeneratePracticeAssignment, setloading, seterror, resetGeneratePracticeAssignment } = generatePracticeAssignment.actions;

export default generatePracticeAssignment.reducer;