// import React, { useState } from 'react';
// import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
// import { MathNode } from './MathNode'; // Assuming you've implemented the custom MathNode
// import { $createTextNode, $insertNodes } from 'lexical';

// const InsertMathButton = ({setShowInputTooltip}) => {
//   const [editor] = useLexicalComposerContext();
//   const [latex, setLatex] = useState('');

//   // Function to insert the formula as a MathNode
//   const insertMath = () => {
//     if (latex) {
//       editor.update(() => {
//         const mathNode = new MathNode(latex);
//         console.log('MathNode created:', mathNode); // Log the node before inserting it
//         $insertNodes([mathNode]); // Inserts the MathNode with the LaTeX formula
//         // Create and insert a TextNode right after the MathNode
//         const textNode = $createTextNode(' ');  // Space to allow typing after
//         $insertNodes([textNode]);
//         setShowInputTooltip(false);
//       });
//     }
//   };

//   return (
//     <div>
//       <input
//         type="text"
//         value={latex}
//         className='m-1'
//         onChange={(e) => setLatex(e.target.value)}
//         placeholder="Enter LaTeX formula"
//       />
//       <button className='ml-1' onClick={insertMath}>Insert Formula</button>
//     </div>
//   );
// };

// export default InsertMathButton;
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { MathNode } from './MathNode'; // Assuming you've implemented the custom MathNode
import { $createTextNode, $insertNodes,$getNodeByKey } from 'lexical';

const InsertMathButton = ({setShowInputTooltip,value,setLatextextvalue,setInputValue,oldMathNode,oldnodeeditor}) => {
  const [editor] = useLexicalComposerContext();
  // console.log('value:', value);
  const [latex, setLatex] = useState(value ? value : '');

  useEffect(() => {
    if (value) {
      setLatex(value);
    }
  }, [value]);
  // Function to insert the formula as a MathNode
  const insertMath = () => {
    if (latex) {
      console.log('oldMathNode:', oldMathNode);
      if (oldMathNode) {
        // Remove the node from the Lexical editor
        
          editor.update(() => {
            const nodeToDelete = $getNodeByKey(oldMathNode); // Get the node by its key
            if (nodeToDelete) {
             
              nodeToDelete.remove(); // Remove the node from the editor
              // console.log(`Removed MathNode with key: ${nodeKey}`);
            }
          });
       
      }
      editor.update(() => {
        console.log('latex:', latex);
        const mathNode = new MathNode(latex);
        console.log('MathNode created:', mathNode); // Log the node before inserting it
        $insertNodes([mathNode]); // Inserts the MathNode with the LaTeX formula
        // Create and insert a TextNode right after the MathNode
        const textNode = $createTextNode(' ');  // Space to allow typing after
        $insertNodes([textNode]);
        setShowInputTooltip(false);
        setLatextextvalue('');
        setInputValue('');
   
      });
    }
  };

  return (
    <div>
      <input
        type="text"
        value={latex}
        className='m-1'
        onChange={(e) => setLatex(e.target.value)}
        placeholder="Enter LaTeX formula"
      />
      <button className='ml-1' onClick={insertMath}>Insert Formula</button>
    </div>
  );
};

export default InsertMathButton;