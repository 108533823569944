import React from "react";
import { ReactSVG } from "react-svg";

function SearchComponent({ search, searchPlaceholder, searchValue, onchange, name, value }) {

  return <>
    <div className="search-container dashboard-header-search">
        <ReactSVG className="search-icon" src={search} />
      <input type={searchValue} className="search-input" placeholder={searchPlaceholder} onChange={onchange} name={name} value={value} />
    </div>
  </> 
}

export default SearchComponent;