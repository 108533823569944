import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import globalStateReducer from "./slice";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import assignmentAnalyticsReducer from "../reducers/assignmentAnalyticsReducer";
import adminRegistrationReducer from "../reducers/adminRegistrationReducer";
import adminLoginReducer from "../reducers/adminLoginReducer";
import superadminLoginReducer from "../reducers/superadminLoginReducer";
import addSchoolReducer from "../reducers/addSchoolReducer";
import getSchoolByIDReducer from "../reducers/getSchoolByIDReducer";
import getAllSchoolsReducer from "../reducers/getAllSchoolsReducer";
import getAllTeacherBySchoolReducer from "../reducers/getAllTeacherBySchoolReducer";
import getCourseListBySchoolIDReducer from "../reducers/getCourseListBySchoolIDReducer";
import profileLoginReducer from "../reducers/profileLoginReducer";
import addIndividualProfileReducer from "../reducers/addIndividualProfileReducer";
import userRegistrationReducer from "../reducers/userRegistrationReducer";
import addBulkProfileReducer from "../reducers/addBulkProfileReducer";
import firstLoginReducer from "../reducers/firstLoginReducer";
import getLetestTeacherReducer from "../reducers/getLetestTeacherReducer";
import getAllCoursesByTeacherIdReducer from "../reducers/getAllCoursesByTeacherIdReducer";
import getTeacherByTeacherIdReducer from "../reducers/getTeacherByTeacherIdReducer";
import getdataListReducer from "../reducers/getdataListReducer";
import getStudentByStudentIdReducer from "../reducers/getStudentByStudentIdReducer";
import getStudentEnrolledCoursesReducer from "../reducers/getStudentEnrolledCoursesReducer";
import historyReducer from "../reducers/historyReducer";
import getProfileDetailsReducer from "../reducers/getProfileDetailsReducer";
import * as Sentry from "@sentry/react";
import notificationListReducer from "../reducers/notificationListReducer";
import sentInvitationListReducer from "../reducers/sentInvitationListReducer";
import updateTeacherProfileReducer from "../reducers/updateTeacherProfileReducer"
import getAssignmentStatsReducer from "../reducers/getAssignmentStatsReducer";
import getLessonStatsReducer from "../reducers/getLessonStatsReducer";
import getAllCoursesReducer from "../reducers/getAllCoursesReducer";
import adminDashboardCountReducer from "../reducers/adminDashboardCountReducer";
import getSchoolDetailsReducer from "../reducers/getSchoolDetailsReducer";
import updateSchoolReducer from "../reducers/updateSchoolReducer";
import adminDashboardGraphReducer from "../reducers/adminDashboardGraphReducer";
import deleteCourseReducer from "../reducers/deleteCourseReducer";
import AddSchoolAdminReducer from "../reducers/addSchoolAdminReducer";
import getFileIdByNameReducer from "../reducers/getFileIdByNameReducer";
import getUploadFileStatusReducer from "../reducers/getUploadFileStatusReducer";
import deleteLessonReducer from "../reducers/deleteLessonReducer";
import deleteAssignmentReducer from "../reducers/deleteAssignmentReducer";
import removeStudentsFromCourseReducer from "../reducers/removeStudentsFromCourseReducer";
import getTextToSpeechReducer from "../reducers/getTextToSpeechReducer";
import courseImageGenerateReducer from "../reducers/courseImageGenerateReducer";
import getAssignmentsByLessonReducer from "../reducers/getAssignmentsByLessonReducer";
import couseFileUploadReducer from "../reducers/couseFileUploadReducer";
import courseFileUploadStatusReducer from "../reducers/courseFileUploadStatusReducer";
import getCourseFileUploadsReducer from "../reducers/getCourseFileUploadsReducer";
import deleteUploadedCourseFileReducer from "../reducers/deleteUploadedCourseFileReducer";
import publishtoSchoologyReducer from "../reducers/publishtoSchoologyReducer";
import syncSchoologyReducer from "../reducers/setSyncSchoologyReducer";
import submitGoogleTokenReducer from "../reducers/submitGoogleTokenReducer";
import getGoogleTokenReducer from "../reducers/getGoogleTokenReducer";
import googleDriveSyncReducer from "../reducers/googleDriveSyncReducer";
import googleDriveCourseSyncReducer from "../reducers/googleDriveCourseSyncReducer";
import unlinkGoogleDriveReducer from "../reducers/unlinkGoogleDriveReducer";
import getPracticeAssignmentsReducer from "../reducers/getPracticeAssignmentsReducer";
import generatePracticeAssignmentReducer from "../reducers/generatePracticeAssignmentReducer";


const globalStatePersistConfig = {
  key: "globalState",
  storage,
  whitelist: [
    "token",
    "role",
    "email",
    "teacher_details",
    "student_details",
    "has_profile",
    "datalist",
    "school_id",
    "grade_details",
    "school_details",
    "subadmin_details"
  ],
};

const persistedReducer = persistReducer(
  globalStatePersistConfig,
  globalStateReducer
);
const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: {
    ignoredActions: ["persist/PERSIST"],
    ignoredActionPaths: ["meta.arg", "payload.timestamp"],
    ignoredPaths: ["items.dates"],
  },
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = configureStore({
  reducer: {
    globalState: persistedReducer,
    profilelogin: profileLoginReducer,
    userregistration: userRegistrationReducer,
    firstlogin: firstLoginReducer,
    adminregistration: adminRegistrationReducer,
    adminlogin: adminLoginReducer,
    superadminLogin: superadminLoginReducer,
    addSchool: addSchoolReducer,
    schoolById: getSchoolByIDReducer,
    schoolList: getAllSchoolsReducer,
    fetchSchoolData: getAllTeacherBySchoolReducer,
    getCoursesbySchoolId: getCourseListBySchoolIDReducer,
    addIndividualProfile: addIndividualProfileReducer,
    addbulkProfile: addBulkProfileReducer,
    letestteacherlist: getLetestTeacherReducer,
    getallCoursesByTeacherId: getAllCoursesByTeacherIdReducer,
    getteacherbyTeacherId: getTeacherByTeacherIdReducer,
    getdatalist: getdataListReducer,
    getstudentByStudentId: getStudentByStudentIdReducer,
    getStudentEnrolledCourses: getStudentEnrolledCoursesReducer,
    assgnAnalytics: assignmentAnalyticsReducer,
    navigateHistory: historyReducer,
    profileDetails: getProfileDetailsReducer,
    notificationList: notificationListReducer,
    sentInvitations: sentInvitationListReducer,
    updateTeacherProfile: updateTeacherProfileReducer,
    assignmentStats: getAssignmentStatsReducer,
    lessonStats: getLessonStatsReducer,
    allcoursesList: getAllCoursesReducer,
    superadminCount: adminDashboardCountReducer,
    schoolDetails: getSchoolDetailsReducer,
    updateSchoolDetails: updateSchoolReducer,
    adminDashboardGraph: adminDashboardGraphReducer,
    deleteCourseById: deleteCourseReducer,
    addSchoolAdmin: AddSchoolAdminReducer,
    getFileIdByName: getFileIdByNameReducer,
    getFileUploadStatus: getUploadFileStatusReducer,
    deleteLesson: deleteLessonReducer,
    deleteAssignment: deleteAssignmentReducer,
    removestudentfromcourse: removeStudentsFromCourseReducer,
    texttospeech: getTextToSpeechReducer,
    getcourseImage: courseImageGenerateReducer,
    getassignmentsbyLesson: getAssignmentsByLessonReducer,
    coursefileUploads: couseFileUploadReducer,
    courseFileUploadStatus: courseFileUploadStatusReducer,
    getCourseFileUploads: getCourseFileUploadsReducer,
    deleteUploadedCourseFile: deleteUploadedCourseFileReducer,
    publishtoSchoology: publishtoSchoologyReducer,
    syncSchoology: syncSchoologyReducer,
    submitGoogleToken: submitGoogleTokenReducer,
    getGoogleAccessToken: getGoogleTokenReducer,
    googleDriveSync: googleDriveSyncReducer,
    googleDriveCourseSync: googleDriveCourseSyncReducer,
    unlinkgdrive: unlinkGoogleDriveReducer,
    getPracticeAssignments: getPracticeAssignmentsReducer,
    generatePracticeAssignment: generatePracticeAssignmentReducer
  },
  middleware: customizedMiddleware,
  enhancers: (defaultEnhancers) => [sentryReduxEnhancer, ...defaultEnhancers],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
export default store;
