import React, { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { CircularProgress, Grid } from '@mui/material';

// Register the components you need for this specific chart
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const DashboardBarGraph = ({ graph, isLoading }) => {

  const data = {
    labels: graph.map(g => g?.month),
    datasets: [{
      label: 'Total Count',
      data: graph.map(g => g?.total_count),
      backgroundColor: "#C933FF",
      borderColor: 'rgba(153, 102, 255, 1)', // Use a purple color for the border
    }]
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        display: true, // Display the x-axis labels
        grid: {
          display: false, // Hide x-axis grid lines
          drawBorder: false, // Hide x-axis line
        },
        ticks: {
          display: true, // Show x-axis labels
          color: "black"
        }
      },
      y: {
        display: false, // Hide the y-axis completely
        grid: {
          display: false, // Hide y-axis grid lines
          drawBorder: false, // Hide y-axis line
        },
        ticks: {
          display: false, // Hide y-axis labels
        }
      },
    },
    plugins: {
      legend: {
        position: 'top',
      },
      datalabels: {
        color: '#C933FF',
        anchor: 'end',
        align: 'top',
        formatter: function (value, context) {
          return value;
        }
      }
    },
  };



  return (
    <>
      {isLoading ?
        <Grid
          height={"50vh"}
          xs={12}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress size={40} />
        </Grid>
        :
        <Bar className='admin-bar' data={data} options={options} />}
    </>
  )
};

export default DashboardBarGraph;
