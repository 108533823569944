import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import PaginationView from "../../../components/Pagination/PaginationView";
import { useAuth } from "../../../features/auth/AuthContext";
import DashboardListTable from "../../../modules/admin/Dashboard/DashboardList/DashboardListTable";
import {
  fetchAllSchoolData,
  resetAllTeacherBySchool,
} from "../../../state/actions/getAllTeacherBySchoolAction";

const TeacherStudentTab = ({ roletype }) => {
  const { school_id } = useParams(); // Call it unconditionally at the beginning
  const { token } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { teachers,students,count, next, previous, totalPages, error } = useSelector(
    (state) => state.fetchSchoolData
  );
  const { role } = useSelector((state) => state.globalState);
  useEffect(() => {
    getAllteacherDetails();
    window.scrollTo(0, 0);
  }, [currentPage, token]);

  useEffect(() => {
    return () => {
      dispatch(resetAllTeacherBySchool());
    };
  }, []); // Unconditionally called at the beginning

  const getAllteacherDetails = () => {
    const type = roletype; // Define the type variable
    dispatch(fetchAllSchoolData(currentPage, school_id, token, type, role));
  };

  return (
    <>
      <div className="Teacher_Student_Table">
        <div className="Teacher_Student_Table_wrapper">
          <div className="Teacher_Student_Table_container">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <table className="Dashboard-List-Recently-Added-table">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Teacher ID</th>
                        <th>Email</th>
                        <th>Joining Date</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roletype === "Teacher"
                        ? teachers.map((teacher, index) => (
                            <DashboardListTable
                              avatar={teacher.ai_avatar}
                              name={teacher.name}
                              teacherId={teacher.teacher_id}
                              email={teacher.email}
                              joiningDate={dayjs(teacher.joined_at).format("MMM DD, YYYY")}
                            />
                          ))
                        : students.map((student, index) => (
                            <DashboardListTable
                              avatar={student.ai_avatar}
                              name={student.name}
                              id={student.student_id}
                              email={student.email}
                              joiningDate={dayjs(student.joined_at).format("MMM DD, YYYY")}
                            />
                          ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {totalPages > 1 && (
        <PaginationView
          totalPages={totalPages}
          next={next}
          previous={previous}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
export default TeacherStudentTab;
