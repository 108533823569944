import React, { useEffect, useState } from "react";
import { profile_image, email_image, call_image } from "../../assets/images/images";
import "./addprofile.css";
import { addindividualProfile, resetindividualProfile } from "../../state/actions/addIndividualProfileAction";
import { useAuth } from "../../features/auth/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import {
    isRequired,
    isValidEmail,
} from "../../utils/validator";
import { toast } from "react-toastify";

const AddProfile = ({ toggleshowAddProfile, entity }) => {

    const { token } = useAuth();

    const [formData, setFormData] = useState({
        entityName: "",
        email: "",
    });
    const [formErrors, setFormErrors] = useState({
        entityName: "",
        email: "",
    });

    const dispatch = useDispatch();
    const { school_id } = useSelector((state) => state.globalState);
    const addIndividualProfile = useSelector((state) => state.addIndividualProfile);

    const handleChange = (e) => {
        const { name, value } = e.target;

        let error = "";

        if (name === "entityName") {
            error = isRequired(value, "name");
        } else if (name === "email") {
            error = isRequired(value, "email") || isValidEmail(value);
        }

        setFormErrors({
            ...formErrors,
            [name]: error,
        });

        setFormData({
            ...formData,
            [name]: value,
        });
    }

    const submitHandler = async (e) => {
        e.preventDefault();
        let errors = {};

        errors.email =
            isRequired(formData.email, "email") || isValidEmail(formData.email);
        errors.entityName =
            isRequired(formData.entityName, "name")

        setFormErrors(errors);

        if (!Object.values(errors).some((error) => error)) {
            await addIndividualProfileRequest();
        }
    }

    const addIndividualProfileRequest = () => {
        const req = {
            school_id,
            email: formData.email,
            role: entity === "Student" ? 1 : 2,
        }

        dispatch(addindividualProfile(req, token))
    }

    useEffect(() => {
        const failedErrorMessage =
            "Oops, something went wrong. Please try again later.";

        if (addIndividualProfile?.message?.length) {
            toast.success(addIndividualProfile?.message, {
                position: toast.POSITION.TOP_CENTER,
            });
            toggleshowAddProfile();
        } else if (addIndividualProfile?.error?.length) {
            toast.error(addIndividualProfile?.error || failedErrorMessage, {
                position: toast.POSITION.TOP_CENTER,
            });
            dispatch(resetindividualProfile());
        }
    }, [addIndividualProfile])

    useEffect(() => {
        return () => {
            dispatch(resetindividualProfile());
        };
    }, []);

    return (
        <>
            <div className="dashboard-popup-teacher-profile-details-form-container" id="add-profile-form">
                <div className="dashboard-popup-teacher-profile-details-input">
                    <label>Name</label>
                    <input type="name" name="entityName" placeholder="Full name" value={formData.entityName} onChange={handleChange} error={!!formErrors.entityName} />
                    <div className="form-icon">
                        <img src={profile_image} alt="" />
                    </div>
                    <p>{formErrors.entityName}</p>
                </div>
                <div className="dashboard-popup-teacher-profile-details-input">
                    <label>Email</label>
                    <input type="text" placeholder="email id" name="email" value={formData.email} onChange={handleChange} error={!!formErrors.email} />
                    <div className="form-icon">
                        <img src={email_image} alt="" />
                    </div>
                    <p>{formErrors.email}</p>
                </div>
                <div className="add-profile-btn-container">
                    <button type="button" className="add-profile-save-btn add-profile-close-btn" onClick={toggleshowAddProfile}>Close</button>
                    <button type="button" className="add-profile-save-btn" onClick={submitHandler}>Save</button>
                </div>
            </div>
        </>
    );
};
export default AddProfile;
