import LockIcon from "@mui/icons-material/Lock";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../../../../api/api";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import { AssignmentType } from "../../../../../../utils/consts";
import QuestionNew from "../../../questions/Question_new";
import { CircularProgress, Grid } from "@mui/material";
import StudentAssignmentScore from "./StudentAssignmentScore";
import dayjs from "dayjs";

const LessonCard = ({ lesson, index, course, courseId, inial_value }) => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showScorePopup, setshowScorePopup] = useState(false);
  const [detailedResult, setdetailedResult] = useState(false);
  const [assignment, setAssignment] = useState();
  const [assignments, setAssignments] = useState([]);
  const [assignmentIndex, setAssignmentIndex] = useState(0);
  const [arrayIndex, setArrayIndex] = useState(0);

  const toggleDropdown2 = () => {
    setShowDropdown2(!showDropdown2); // Toggle the visibility state
  };


  function fetchAssignments() {
    api
      .get(`assignments/get-assignments-by-lesson/${lesson.lesson_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setAssignments(response?.data?.data || []);
          checkSubmissions(response?.data?.data || [])
        }
      })
      .catch((error) => {
        setAssignments([]);
      });
  }

  useEffect(() => {
    fetchAssignments()
  }, [lesson.lesson_id, token]);

  function checkSubmissions(assignments) {
    const anyNullSubmissions = assignments.some(assignment => assignment?.assignment_status?.status == 'Submission-Progress');
    if (anyNullSubmissions) {
      setTimeout(fetchAssignments, 15000);
    }
  }


  const getAssignmentTypeName = (type) => {
    return Object.values(AssignmentType).find((item) => {
      return item.value === type;
    })?.name;
  };

  const onLesssonClick = (lesson) => {
    if (lesson.lesson_status !== "Completed") {
      const formData = new FormData();
      formData.append("status", 2);
      formData.append("lesson_id", lesson?.lesson_id);
      const url = "lessons/update-lesson-status/";
      const headers = {
        Authorization: `Token ${token}`,
      };
      api
        .post(url, formData, { headers })
        .then((response) => {
          if (response.data.status === 200) {
            // Update status if necessary
          }
        })
        .catch((error) => {
          // Handle error
        });
    }
   
    const course_Id = courseId =='' || courseId== undefined  ? course?.course_id : courseId;
    navigate(`/course/${course_Id}/lesson/${lesson?.lesson_id}/overview`, {
      state: {
        course,
        lesson,
      },
    });
  };

  const showScore = (assgn, array) => {
    setAssignment(assgn);
    setAssignmentIndex(0);
    setArrayIndex(array);
    setdetailedResult(!detailedResult);

  };

  const showDetailedResult = () => {
    setAssignmentIndex(0);
    setdetailedResult(!detailedResult);
    setshowScorePopup(false);
  };

  const getAssignmentCount = (assignment, index) => {
    return <h5>{`Assignment: ${index + 1}`}</h5>
  }
  const renderAssignmentButton = (assignment, index) => {
    return assignment?.submission != null ? (
      <>
        <div key={index} className="StudentViewCourseDetails-lesson-inner-assignments-folder">
          <button
            onClick={() =>
              showScore(assignment, assignment?.submission?.student_answers_json)
            }
            type="button"
            className="StudentViewCourseDetails_view_Score"
          >
            View Result
          </button>
        </div>
      </>
    ) : (assignment?.assignment_status?.status && assignment?.assignment_status?.status == 'Submission-Progress' ? (
        <>
        <div key={index} className="StudentViewCourseDetails-lesson-inner-assignments-folder">
          <button
            disabled={true}
            type="button"
            className="StudentViewCourseDetails_view_Score_progress result-in-progress"
          >
              Grading in progress ...
            <CircularProgress size={15} className="circular-progress" />
          </button>
        </div>
      </>

    ) : null);
  };

  const currentDate = dayjs(); // Get current date
  const courseStartDate = dayjs(course?.start_date);
  const courseEndDate = course?.end_date ? dayjs(course?.end_date) : null;
  const isButtonDisabled = currentDate.isBefore(courseStartDate) || (courseEndDate && currentDate.isAfter(courseEndDate));

  return (
    <>
      <div className="Student-Active-main-wrapper">
        <div key={index} className="Student-Active-Lesson-folder">
          <div
            className="Student-Active-Lesson-title-folder lesson-dropdown-tab"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              toggleDropdown2();
            }}
          >
            <i
              className={`fa-solid ${showDropdown2 ? "fa-angle-down" : "fa-angle-right"
                }`}
            ></i>
            <h2>
              {inial_value + index > 8
                ? "Lesson " + (inial_value + index + 1)
                : "Lesson 0" + (inial_value + index + 1)}
            </h2>
          </div>
          <p className="Student-Active-Lesson-des">{lesson?.name}</p>

          <div className="view-result-assignment-progress-folder">
            <h5>{`Assignment: ${assignments.length}`}</h5>
          </div>
          <button
            disabled={course?.status === "Pending" || course?.status === "" || course?.status === null}
            // disabled={isButtonDisabled || course?.status === "Pending"}
            onClick={() => onLesssonClick(lesson)}
            className="Student-Active-start-Lesson"
          >
            {(course?.status === "Pending" || course?.status === "" || course?.status === null) === 3 && <LockIcon />}{" "}
            {lesson?.lesson_status === null
              ? "Start"
              : lesson?.lesson_status === "Completed"
                ? "View"
                : "Continue"}{" "}
            Lesson
          </button>
        </div>
        {showDropdown2 && (
          <div className="StudentViewCourseDetails-lesson-inner-assignments-container">
            <h2>{lesson?.name}</h2>
            <div className="StudentViewCourseDetails-lesson-inner-assignments-wrapper">
              {assignments?.map((assignment, i, array) => (
                <div
                  key={i}
                  className="StudentViewCourseDetails-lesson-inner-assignments-folder"
                >
                  <div className="StudentViewCourseDetails-inner-assignments-text-feild">
                    <h2>
                      Assignment: <span>{i + 1}</span>:{" "}
                      <span>{assignment?.name}</span>
                    </h2>
                    <p>{getAssignmentTypeName(assignment?.type)}</p>
                  </div>
                  {assignment?.submission != null && (
                    <button
                      onClick={() => showScore(assignment,assignment?.submission?.student_answers_json)}
                      type="button"
                      className="StudentViewCourseDetails_view_Score"
                    >
                      View Result
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        {showScorePopup && (
          <StudentAssignmentScore
            view={"lesson"}
            showScore={showScore}
            getSubmissiondtls={assignment?.submission?.ai_evaluation_json}
            onclick={showDetailedResult}
            title={"View Detailed Result"}
          />
        )}
        {detailedResult && (
          <div className="AssignmentViewProgress-mock-test-wrapper">
            <div className="AssignmentViewProgress-mock-test-container">
              {
                <QuestionNew
                  assignment={
                    assignment?.submission?.student_answers_json[
                    assignmentIndex
                    ]
                  }
                  ai_evaluation_json={
                    assignment?.submission?.ai_evaluation_json
                  }
                  index={assignmentIndex}
                  arrayIndex={arrayIndex}
                  currentStep={assignmentIndex + 1}
                  totalSteps={
                    assignment?.submission?.student_answers_json.length
                  }
                  handleNext={() =>
                    assignmentIndex <
                      assignment?.submission?.student_answers_json.length - 1
                      ? setAssignmentIndex((p) => p + 1)
                      : null
                  }
                  handleBack={() => setAssignmentIndex((p) => p - 1)}
                  isLast={
                    assignmentIndex ===
                    assignment?.submission?.student_answers_json.length - 1
                  }
                  isFirst={assignmentIndex === 0}
                  close={
                    <span
                      onClick={showDetailedResult}
                      className="AssignmentViewProgress-mock-test-close-btn"
                    >
                      <i className="fa-solid fa-x"></i>
                    </span>
                  }
                  questions={assignment?.submission?.ai_evaluation_json?.questions}
                />
              }
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LessonCard;