import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api, { shareUrl } from "../../../../../../api/api";
import { Create_lesson_logo } from "../../../../../../assets/images/images";
import UniversalChatbot from "../../../../../../components/universalChatbot/UniversalChatbot";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import { changeScrollPopup } from "../../../../../../utils/utlis";
import CourseDetailsHeader from "./CourseDetailsHeader";
import CourseTab from "./CourseTab";
import FilteredInput from "../../../../../../components/FilteredInput/FilteredInput";
import { resetdeleteLesson } from "../../../../../../state/actions/deleteLessonAction"
import { resetdeleteAssignment } from "../../../../../../state/actions/deleteAssignmentAction"

const TeacherViewCourseDetails = () => {
  localStorage.removeItem("Assingment_Id");
  localStorage.removeItem("Rubric_id");
  localStorage.removeItem("lesson_formState");
  localStorage.removeItem("globleOnChangeData");
  localStorage.removeItem("GlobleSaveData");
  localStorage.removeItem("lessonDetails");
  localStorage.removeItem("roomId");
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [inputValue, setInputValue] = useState("");
  const globalState = useSelector((state) => state.globalState);
  const [course, setCourse] = useState(location.state?.course || {}); // default to Student if no role is passed
  const [totalLessons, setTotalLessons] = useState(0);
  const [totalStudent, setTotalStudent] = useState(0);
  const [invitationSuccessfull, setinvitationSuccessfull] = useState(false);
  const { token } = useAuth();
  const { id } = useParams();
  const teacher_id =
    location.state?.teacher_id ??
    globalState.teacher_details?.data?.teacher_id ??
    "";
  const [loading, setLoading] = useState(false);

  const [showDropdown, setShowDropdown] = useState(false); // State to control visibility

  const toggleDropdown = () => {
    onShare(getPlatform.SHARE, false);
    setShowDropdown(!showDropdown); // Toggle the visibility state
    changeScrollPopup("auto", "view-course-details-share-form");
  };

  const [showinvite, setShowinvite] = useState(false); // State to control visibility
  const modalRef = useRef(null);

  // Event listener to detect clicks outside the modal
  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowinvite(false); // Close the modal
    }
  };

  const toggleinvite = () => {
    setInputValue(""); // reset input value
    setFormErrors({
      students: "",
    });
    setStudents([]); // reset students array
    setShowinvite(!showinvite); // Toggle the visibility state
    changeScrollPopup("auto", "view-course-details-share-form");
  };

  const onEditCourseClick = () => {
    navigate(`/course/${course.course_id}/edit`, {
      state: { course, teacher_id },
    });
  };

  const onInvitedStudents = () => {
    navigate(`/invited-students/${id}`, { state: { teacher_id } });
  };
  const onCreateLesson = () => {
    navigate("/NewLessons", { state: { course, teacher_id } });
  };

  const [formErrors, setFormErrors] = useState({
    students: "",
  });
  const validateOnInvite = () => {
    let studentsError = "";
    if (students.length === 0) {
      studentsError = "Please enter atleast one student email";
    }
    const regxIsValidEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    for (let i = 0; i < students.length; i++) {
      if (!regxIsValidEmail.test(students[i].value)) {
        studentsError = "Please enter a valid email";
        break;
      }
    }

    if (studentsError) {
      setFormErrors({
        students: studentsError,
      });
      return false;
    }
    return true;
  };

  const onInvite = async () => {
    setLoading(true);

    if (!course?.course_id) {
      toast.error("Course not found");
      toggleinvite();
      setLoading(false);
      return;
    }

    await api
      .post(
        `courses/encrypt-course-id/?teacher_id=${teacher_id}`,
        {
          course: course?.course_id,
          emails: students.map((student) => student),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => {
        toast.success("Invitation sent successfully", {
          position: toast.POSITION.TOP_CENTER,
        });
        setinvitationSuccessfull(!invitationSuccessfull);
        setInputValue("");
        setLoading(false);
        // clear students array
        setStudents([]);
        return res?.data?.encrypted_id;
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ||
            err?.response?.data?.error ||
            "Something went wrong!",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        setLoading(false);
        return null;
      });
    setLoading(false);
    toggleinvite();
  };

  const [students, setStudents] = useState([]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };
  const validateEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
        event.preventDefault();
        const inputValues = inputValue.trim().split(/[\s,]+/); // Split by spaces or commas
        inputValues.forEach((value) => {
            processInputVal(value.trim());
        });
        setInputValue(""); // Clear the input field after processing
    }
};

  const handlePaste = (event) => {
    event.preventDefault();
    let pastedText = event.clipboardData.getData("text");
    let emailArray = pastedText
      .split(/,|\s+/)
      .filter((email) => email.trim() !== "");
    emailArray.forEach((email) => processInputVal(email.trim()));
  };

  const processInputVal = (newVal) => {
    if (newVal && validateEmail(newVal) && !students.includes(newVal)) {
        setStudents((oldValues) => [...oldValues, newVal]);
        setFormErrors({
            ...formErrors,
            students: "",
        });
    } else if (!validateEmail(newVal)) {
        setFormErrors({
            ...formErrors,
            students: "Please enter a valid email",
        });
    } else if (students.includes(newVal)) {
        setFormErrors({
            ...formErrors,
            students: "Email already added",
        });
    }
};
  const handleAddStudent = (e) => {
    e.preventDefault();
    // handle if student is not valid email
    let error = "";
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(inputValue)) {
      error = "Please enter a valid email";
    }
    setFormErrors({
      ...formErrors,
      students: error,
    });
    // also if formErrors.students is not empty then don't add to students array
    if (formErrors.students !== "") {
      return;
    }

    // Add the new student to the list
    setStudents([...students, inputValue.trim()]);

    // Clear the input
    setInputValue("");
  };
  const handleKeyPress = (e) => {
    // handle if student is not valid email
    const { name, value } = e.target;
    let error = "";
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(value)) {
      error = "Please enter a valid email";
    }
    setFormErrors({
      ...formErrors,
      [name]: error,
    });
    // also if formErrors.students is not empty then don't add to students array
    if (formErrors.students !== "") {
      return;
    }

    if (e.key === "Enter" && inputValue.trim() !== "") {
      e.preventDefault();
      // Create a new student object with the input value
      const newStudent = {
        id: Date.now(), // Use a unique identifier (e.g., timestamp) as the ID
        value: inputValue.trim(),
      };

      // Add the new student to the list
      setStudents([...students, newStudent]);

      // Clear the input
      setInputValue("");
    }
  };

  const handleDeleteClick = (selStudent) => {
    setStudents((students) =>
      students.filter((student) => student !== selStudent)
    );
  };
  const getShareLink = ({ platform, course, baseURL }) => {
    let shareUrl = "";
    baseURL = `${baseURL}`; // Append /view to the baseURL

    const courseDescription =
      course?.description?.length > 100
        ? `${course?.description?.substring(0, 100)}...`
        : course?.description;
    // trim the description to 150 characters

    const data = `Hi! You're invited to enroll in the new course, ${course?.name} by ${course?.title} ${course?.teacher}. Check it out here:`;

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          baseURL
        )}&quote=${encodeURIComponent(data)}`;
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          data
        )} ${encodeURIComponent(baseURL)}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
          data
        )} ${encodeURIComponent(baseURL)}`;
        break;
      case "instagram":
        shareUrl = `https://www.instagram.com/?url=${encodeURIComponent(
          baseURL
        )}`;
        break;
      case "share":
        shareUrl = baseURL;
        break;
      default:
        shareUrl = baseURL;
        return;
    }

    return shareUrl;
  };
  const getPlatform = {
    FACEBOOK: "facebook",
    WHATSAPP: "whatsapp",
    TWITTER: "twitter",
    INSTAGRAM: "instagram",
    SHARE: "share",
  };
  const [sharelink, setSharelink] = useState("");
  const getURL = `${shareUrl}/course/${course?.course_id}/view`;
  const [linkBaseUrl, setLinkBaseUrl] = useState(getURL);
  const [encryptCourseId, setEncryptCourseId] = useState("");

  const onShare = async (platform, isOpenNewWindow = true) => {
    setLoading(true);

    if (!course?.course_id) {
      toast.error("Course not found");
      toggleinvite();
      setLoading(false);
      return;
    }

    setLoading(false);
    if (isOpenNewWindow) {
      toggleDropdown();
    }

    const getShareableLink = getShareLink({
      platform: platform,
      course,
      baseURL: getURL,
    });
    setLinkBaseUrl(getURL);
    setSharelink(getShareableLink);
    if (isOpenNewWindow) {
      window.open(getShareableLink, "_blank");
    }
  };
  const onClickCopy = () => {
    toggleDropdown();
    navigator.clipboard.writeText(linkBaseUrl);
    toast.success("Copied to clipboard", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const onClickCopyInvite = () => {
    const redirectPath = `course/${course.course_id}/view`;

    const fullUrl = `${shareUrl}/${redirectPath}`;
    try {
      // Check if the Clipboard API is available
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard
          .writeText(fullUrl)
          .then(() => {
            toast.success("Copied to clipboard", {
              position: toast.POSITION.TOP_CENTER,
            });
          })
          .catch((error) => {
            toast.error("Failed to copy text", {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      } else {
        document.execCommand("copy");
        toast.success("Copied to clipboard", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      toast.error("Failed to copy text", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  useEffect(() => {
    api
      .get(
        `courses/get-course/${id ??
          location.state?.course_id ??
          location.state?.course?.course_id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          if (response?.data) {
            setCourse(response.data.data);
          }
        }
      })
      .catch((error) => {});
    AOS.init({
      duration: 500,
    });
  }, [id]);

  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);
  const paragraphRef = useRef(null);

  const descriptionToggle = () => {
    setDescriptionOpen(!descriptionOpen);
  };

  useEffect(() => {
    const descriptionLength = course?.description?.length || 0;

    if (paragraphRef.current) {
      const lineHeight = parseFloat(
        window.getComputedStyle(paragraphRef.current).lineHeight
      );
      const height = paragraphRef.current.clientHeight;
      const lineCount = height / lineHeight;

      setShowViewMore(lineCount > 5 || descriptionLength > 1000);
    }
  }, [course?.description]);

  useEffect(() => {
    if (showDropdown || showinvite) {
      changeScrollPopup("hidden", "view-course-details-share-form");
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropdown, showinvite]);
  const containerRef = useRef(null); // Ref for the container
  const lastElementRef = useRef(null); // Ref for the last element
  const [activeTabCourse, setactiveTabCourse] = useState("lessons");
  // Effect to scroll to the last element when the list changes
  useEffect(() => {
    if (lastElementRef.current) {
      containerRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [students]); // Dependency array includes inputValues

  const deleteLesson = useSelector((state) => state.deleteLesson);
  const deleteAssignment = useSelector((state) => state.deleteAssignment);

  useEffect(() => {
    if (deleteLesson?.success) {
      toast.success("Lesson has been deleted succesfully.");
      dispatch(resetdeleteLesson());
    }
    else if (deleteLesson?.error?.length) {
      toast.error("Something went wrong.");
      dispatch(resetdeleteLesson());
    }
    return () => {
      dispatch(resetdeleteLesson());
    }
  }, [deleteLesson])

  useEffect(() => {
    if (deleteAssignment?.success) {
      toast.success("Assignment has been deleted succesfully.");
      dispatch(resetdeleteAssignment());
    }
    else if (deleteAssignment?.error?.length) {
      toast.error("Something went wrong.");
      dispatch(resetdeleteAssignment());
    }
    return () => {
      dispatch(resetdeleteAssignment());
    }
  }, [deleteAssignment])

  return (
    <>
      <div className="dashboard" id="viewcourseDetails-section">
        <div className="view-course-Details-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div
                  className="view-course-Details-back-btn-folder"
                  onClick={() =>
                    navigate(-1, {
                      state: { course, teacher_id },
                    })
                  }
                >
                  <i class="fa-solid fa-chevron-left"></i> Back
                </div>
                <CourseDetailsHeader
                  course={course}
                  onEditCourseClick={onEditCourseClick}
                  toggleinvite={toggleinvite}
                  totalLessons={totalLessons}
                  totalStudent={totalStudent}
                  setactiveTabCourse={setactiveTabCourse}
                  onInvitedStudents={onInvitedStudents}
                />
                {totalLessons === 0 && (
                  <div className="view-course-details-Create-lesson-container">
                    <div className="view-course-details-Create-lesson-container-image">
                      <img src={Create_lesson_logo} alt="" />
                    </div>
                    <p>Lets create your first lesson</p>
                    <button type="button" onClick={onCreateLesson}>
                      Create Lesson
                    </button>
                  </div>
                )}
                <CourseTab
                  course={course}
                  teacher_id={teacher_id}
                  setTotalStudent={setTotalStudent}
                  setTotalLessons={setTotalLessons}
                  addlessonBtn={globalState.role !== "SuperAdmin" &&
                    totalLessons !== 0 && (
                      <div type="button" className="add-lesson-btn">
                        <button type="button" onClick={onCreateLesson}>
                          <i className="fa-solid fa-plus"></i>
                          Add Lesson
                        </button>
                      </div>
                    )
                  }
                  activeTabCourse={activeTabCourse}
                  invitationSuccessfull={invitationSuccessfull}
                />
              </div>
            </div>
          </div>
        </div>

        {showinvite && (
          <div className="view-course-details-share-form">
            <div ref={modalRef} className="view-course-details-share-folder">
              <h2>Invite Students</h2>
              <div className="view-course-details-inner-wrapper">
                <p>Add Student Email ID</p>
                <div className="copy-link-or-path-folder invite-course-wrapper invite-student-popup-folder">
                  <div className="Subjects-you-teach">
                    <input
                      type="text"
                      style={
                        formErrors.students ? { border: "1px solid red" } : null
                      }
                      name="students"
                      placeholder="example@example.com"
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyDown={handleKeyDown}
                      onPaste={handlePaste}
                    />
                    {
                      <button
                        className="add-student-btn"
                        disabled={inputValue === "" || formErrors.students}
                        onClick={handleAddStudent}
                        type="button"
                      >
                        Add
                      </button>
                    }
                  </div>

                  <p
                    style={{
                      color: "red",
                      fontSize: "0.8rem",
                      textAlign: "left",
                      margin: "0.5rem 0",
                    }}
                  >
                    {formErrors.students}
                  </p>
                  {students.length > 0 && (
                    <div
                      ref={containerRef}
                      className="Subjects-you-teach-feilds-folder"
                    >
                      {students.map((student, index) => (
                        <div
                          key={`${student}-${index}`}
                          ref={
                            index === students.length - 1
                              ? lastElementRef
                              : null
                          }
                          className="Subjects-you-teach-feilds invite-student-feilds-folder"
                        >
                          <p>{student}</p>
                          <a
                            href="#"
                            className="subject-delete-btn"
                            onClick={() => handleDeleteClick(student)}
                          >
                            <i className="fa-solid fa-xmark"></i>
                          </a>
                        </div>
                      ))}
                    </div>
                  )}
                  {loading ? (
                    <div className="view-course-details-loader" />
                  ) : (
                    <button
                      className="invite-student-btn"
                      disabled={loading || students.length <= 0}
                      onClick={onInvite}
                      type="button"
                    >
                      Invite
                    </button>
                  )}
                </div>
                <i
                  onClick={toggleinvite}
                  data-aos="zoom-in"
                  className="fa-solid fa-xmark shocial-cross-btn invite-student-close-btn"
                ></i>
                <p className="or-title">Or</p>
                {linkBaseUrl !== "" && (
                  <div className="copy-link-or-path-folder">
                    <div
                      className="invite-copy-link"
                      onClick={onClickCopyInvite}
                    >
                      <span>
                        <i class="fa-solid fa-link"></i>
                      </span>
                      <span> Copy link</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <UniversalChatbot />
    </>
  );
};

export default TeacherViewCourseDetails;
