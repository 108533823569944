import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { course_demo_image } from "../../../../../../assets/images/images";
//import { MenuItem } from "@mui/base";
import MenuItem from "@mui/material/MenuItem";
import dayjs from "dayjs";
import { CircularProgress, Grid, Pagination } from "@mui/material";
import StyledMenu from "../../../../../../components/StyleMenu";
import { getAllCoursesByTeacherId, resetCourseList } from "../../../../../../state/actions/getAllCoursesByTeacherIdAction";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import PaginationView from "../../../../../../components/Pagination/PaginationView";

const MyCourses = ({
    toggleDeleteCourse,
    setSelectedCourse,
    nextUrl,
    prevUrl,
    handlePageChange,
  }) => {
    const globalState = useSelector((state) => state.globalState);
  const { courses, next, previous, totalPages, isLoading } = useSelector((state) => state.getallCoursesByTeacherId);
  const { message } = useSelector(
    (state) => state.syncSchoology
  );
    const [activeMenuCourseId, setActiveMenuCourseId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const { token } = useAuth();
  const dispatch = useDispatch();
    const navigate = useNavigate();
  const myCourses = courses ?? [];
    const teacher_id = globalState?.teacher_details?.data?.teacher_id;
    const deleteCourse = async (course_id) => {
      setSelectedCourse(course_id);
      toggleDeleteCourse();
      handleClose();
      setShowOptions(null);
      document.body.style.overflow = "hidden";
    };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAllCoursesByTeacherId(currentPage, teacher_id, token))
  }, [currentPage, teacher_id,message])

  useEffect(() => {
    return () => {
      dispatch(resetCourseList())
    }
  }, [])

    const menuRef = useRef(); // Reference to the dropdown menu
  
    // Function to close the dropdown when clicking outside of it
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setTimeout(() => {
          setShowOptions(null);
        }, 100);
      }
    };
  
    // Setup click event listener
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
  
      // Cleanup event listener on component unmount
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);
  
    const toggleOptions = (index) => {
      if (showOptions === index) {
        setShowOptions(null); // If the options for this course are currently displayed, hide them
      } else {
        setShowOptions(index); // Otherwise, show the options for this course
      }
    };
    const [showOptions, setShowOptions] = useState(false);
  
    const onViewCourseClick = async (course) => {
      navigate(`/course/${course.course_id}/view`, {
        state: { course, teacher_id },
      });
    };
    const onEditCourseClick = async (course) => {
      setShowOptions(null);
      navigate(`/course/${course.course_id}/edit`, {
        state: { course, teacher_id },
      });
    };
  
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event, courseId) => {
      setAnchorEl(event.currentTarget);
      setActiveMenuCourseId(courseId);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setActiveMenuCourseId(null);
    };
  
    return (
      <>
        <div className="created-my-courses-container">
          {isLoading ?
            <Grid
              height={"50vh"}
              xs={12}
              container
              justifyContent={"center"}
              alignItems={"center"}
            >
              <CircularProgress size={80} />
            </Grid>
            :
            myCourses.map((course, index) => (
            <div key={index} className="created-my-courses-folder">
              <div className="created-my-courses-folder-image-left-folder">
                <div className="created-my-courses-folder-image" onClick={() => onViewCourseClick(course)}>
                  <img src={course.image || course_demo_image} alt="" />
                </div>
              </div>
              <div className="created-my-courses-des-wrapper">
                <div className="created-my-courses-title-folder">
                  <div className="created-my-courses-title-wrapper">
                    <h2 onClick={() => onViewCourseClick(course)}>{course.name}</h2>
                  </div>
                  <div className="edit-course-dropdown-menu-container">
                    <div
                      className="edit-course-or-delete-course"
                      onClick={(e) => handleClick(e, course.course_id)}
                      >
                      <i className="fa-solid fa-ellipsis-vertical"></i>
                    </div>
                    <StyledMenu
                      id="demo-customized-menu"
                      anchorEl={anchorEl}
                      open={Boolean(
                        anchorEl && activeMenuCourseId === course.course_id
                      )}
                      onClose={handleClose}
                    >
                      <MenuItem
                        onClick={() => {
                          if (activeMenuCourseId === course.course_id) {
                            onEditCourseClick(course);
                          }
                          handleClose();
                        }}
                        disableRipple
                      >
                        <button
                          type="button"
                          className="course-edit-or-delete-folder"
                        >
                          <a href="#">
                            <i className="fa-solid fa-pencil"></i>
                          </a>
                          <p>Edit</p>
                        </button>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          if (activeMenuCourseId === course.course_id) {
                            deleteCourse(course.course_id);
                          }
                          handleClose();
                        }}
                        disableRipple
                      >
                        <button
                          type="button"
                          className="course-edit-or-delete-folder"
                        >
                          <a href="#">
                            <i className="fa-solid fa-trash"></i>
                          </a>
                          <p>Delete</p>
                        </button>
                      </MenuItem>
                    </StyledMenu>
                  </div>
                </div>
                <div className="Students-courses-details-container">
                  <div className="Students-courses-details-folder" onClick={() => onViewCourseClick(course)}>
                    <span>Students Enrolled</span>
                    <div className="Students-courses-details-counts">
                      <p>{course.enrolled_students}</p>
                      <p>
                        {course.enrolled_students > 1 ? "Students" : "Student"}{" "}
                      </p>
                    </div>
                  </div>
                  
                  <div className="Students-courses-details-folder" onClick={() => onViewCourseClick(course)}>
                    <span>Total Lessons</span>
                    <div className="Students-courses-details-counts">
                      <p>{course.lesson_count}</p>
                      <p>{course.lesson_count > 1 ? "Lessons" : "Lesson"} </p>
                    </div>
                  </div>
                  <div className="Students-courses-details-folder" onClick={() => onViewCourseClick(course)}>
                    <span>Course Start Date</span>
                    <div className="Students-courses-details-counts">
                      <p>{course.start_date}</p>
                    </div>
                  </div>
                  <div className="Students-courses-details-folder" onClick={() => onViewCourseClick(course)}>
                    <span>Course Expiry Date</span>
                    <div className="Students-courses-details-counts">
                      <p>
                        {course.end_date !== null && course.end_date !== ""
                          ? dayjs(course.end_date).format("MMM DD, YYYY")
                          : "- -"}
                      </p>
                    </div>
                  </div>
                </div>
                <p className="Students-des" onClick={() => onViewCourseClick(course)}>{course.description}</p>
                <button
                  type="button"
                  onClick={() => onViewCourseClick(course)}
                  className="View-Course"
                >
                  View Course
                </button>
              </div>
            </div>
          ))}
        </div>
        {totalPages > 1 && (
          <PaginationView
            totalPages={totalPages}
            next={next}
            previous={previous}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        )}
      </>
    );
  };

  export default MyCourses;