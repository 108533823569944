import React, { useEffect, useRef } from "react";
import katex from "katex";
import "katex/dist/katex.min.css";
import { BlockMath, InlineMath } from "react-katex";

const PreprocessAndRenderLatex = ({
  content,
  ref,
  isBlockMath,
  wrapperStyle,
}) => {
  // // Split the content into sections based on LaTeX delimiters ($$)
  const preprocessedContent = content?.replace(/\\\\/g, '\\').replace(/\\\(.*?\\\)/g, (match) => `$$${match.slice(2, -2)}$$`);

  const sections = preprocessedContent?.split(/\$\$/);
  // console.log(content)
  // const preprocessedContent = content

  // const sections = preprocessedContent?.split(/\$\$/);
  // console.log("sections>>>>>", sections);
  // const sections = oldsections
  //   ?.map(section => section.split(/\n\n/))
  //   .flat();
  const renderPlainHtml = (htmlString) => {

    const doc = new DOMParser().parseFromString(htmlString, "text/html");

    return doc.body.textContent || "";
  };

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      // Render LaTeX content within the HTML
      const mathElements = containerRef.current.querySelectorAll('.math-node');
      // console.log("latex element>>>>>", mathElements);
      mathElements.forEach((element) => {
        if(element.classList.contains("added")) return;
        element.classList = element.classList + " added";
        const latex = element.textContent || '';
        if (latex) {
          katex.render(latex, element, { throwOnError: false });
        }
      });
    }
  }, [sections]); // Re-run effect if `section` changes
  return (
    <div ref={ref} style={wrapperStyle}>
      {sections?.map((section, index) => {
        // Check if the section is a LaTeX math expression
        if (index % 2 === 1) {
          return (
            <span
              key={index}
              className="loading-chat-answer"
            >
              {isBlockMath ? (
                <BlockMath math={renderPlainHtml(section)} />
              ) : (
                  <InlineMath math={renderPlainHtml(section)} />
              )}
            </span>
          );
        } else {
          return (
            <span
            ref={containerRef}
              key={index}
              className="loading-chat-answer"
              dangerouslySetInnerHTML={{ __html: section }}
            ></span>
          );
        }
      })}
    </div>
  );
};

export default PreprocessAndRenderLatex;
