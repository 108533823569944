import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../../../../../api/api";
import { course_bg_image, course_demo_image } from "../../../../../../assets/images/images";
import StudentLessonChatbot from "../../../../../../components/studentLessonChatbot/StudentLessonChatbot";
import { useAuth } from "../../../../../../features/auth/AuthContext";
import DescriptionCoursePopup from "../../teacher/course/DescriptionCoursePopup";
import LessonCard from "./LessonCard";
import BackButton from "../../../../../../components/BackButton/BackButton";
import { useCustomNavigation } from "../../../../../../hooks/useCustomNavigation";
import Pagination from "@mui/material/Pagination";
import { CircularProgress, Grid } from "@mui/material";
import Helper from "../../../../../../utils/Helper";

const StudentCourseLesson = (props) => {
  const navigate = useNavigate();
  const globalState = useSelector((state) => state.globalState);
  const { token } = useAuth();
  const location = useLocation();
  const { id } = useParams();
  const { navigateBackOrDashboard } = useCustomNavigation();
  const [course, setCourse] = useState(location.state?.course || {});
  const [courseId, setCourseId] = useState(
    course?.course_id ?? location.state?.course_id
  );
  const [lessons, setlessondetails] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const itemsPerPage = 12;
  const [currentPage, setCurrentPage] = useState(1);
  const [courseLoading, setcourseLoading] = useState(false);
  const [lessonLoading, setlessonLoading] = useState(false);
  const assignmentsCount =
    globalState?.student_details?.data?.my_courses?.pending_assignments;
  useEffect(() => {
    if (!token) {
      navigate(`/signup?redirect=/course/${id}`);
    }
    else {
      localStorage.removeItem("redirectAfterSignup");
      localStorage.removeItem("redirectToCourse");
    }
  }, [token, id, navigate]);

  useEffect(() => {
    if (id !== "undefined") {
      // Attempt to convert id to a number
      const numericId = Number(id);
    
      if (isNaN(numericId)) {
        // If id cannot be converted to a number, treat it as a string
    
        getDecryptCourseId(id);
      } else {
        // If id is a valid number, use it directly

        fetchCourse(numericId);
        fetchLessons(numericId ?? courseId, currentPage);
      }
    }
    else if (
      (courseId !== null && courseId !== "" && typeof courseId === "number") ||
      (id && location.state?.from === "universal_search")
    ) {
      fetchCourse(id ?? courseId);
      fetchLessons(id ?? courseId, currentPage);
    } else if (token && location.state?.from !== "universal_search") {
      getDecryptCourseId(id);
    }
  }, [courseId, currentPage, id]);

  const handlePageChange = (event, value) => {
    window.scrollTo(0, 0);
    setCurrentPage(value);
  };
  const inial_value = (currentPage - 1) * itemsPerPage;
  const getDecryptCourseId = async (encrypted_id) => {
    const decrypted_id = await api
      .get(`courses/course-invite/?id=${encrypted_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          return res?.data?.course_id;
        }

        if (res.status === 400) {
          navigate("/dashboard/home");
          return null;
        }
      })
      .catch((err) => {
        navigate("/dashboard/home");
        return null;
      });
    if (!decrypted_id) {
      return;
    }
    await fetchCourse(decrypted_id);
    await fetchLessons(decrypted_id);
    setCourseId(decrypted_id);
  };

  const fetchLessons = (course_id, page) => {
    setlessonLoading(true);
    api
      .get(`lessons/get-all-lessons/course/${course_id}/`, {
        params: {
          page: page,
        },
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`, // `Token ${token}`
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setlessonLoading(false);
          setlessondetails(response?.data?.data ?? []);
          setTotalPages(
            response.data.count >= 12
              ? Math.ceil(response.data.count / itemsPerPage)
              : Math.floor(response.data.count / itemsPerPage)
          ); // Calculate total pages
          setNextUrl(response.data.next); // Update next URL
          setPrevUrl(response.data.previous); // Update previous URL
        }
      })
      .catch((error) => {
        setlessonLoading(false);
        //
      });
  };

  const fetchCourse = (course_id) => {
    setcourseLoading(true);
    api
      .get(`courses/get-course/${course_id}/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setcourseLoading(false);
          if (response?.data) {
            setCourse(response.data.data);
          }
        }
      })
      .catch((error) => {
        setcourseLoading(false);
      });
  };

  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [showViewMore, setShowViewMore] = useState(false);

  const paragraphRef = useRef(null);
  const descriptionToggle = () => {
    setDescriptionOpen(!descriptionOpen);
  };

  const AssignmentCard = ({ lesson, assignment }) => {
    const truncate = (str, num) => {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    };

    return (
      <div className="preview-assignments-inner-folder">
        <ol>
          <li>{truncate(assignment.name, 15)}</li>
        </ol>
        <div className="Created-assignments-date">
          <p className="Created">Created:</p>
          <p>{dayjs(assignment.start_date).format("MMM DD, YYYY")}</p>
        </div>
        <div className="Created-assignments-date Created-assignments-due-date">
          <p className="due-date">Due date:</p>
          <p>{dayjs(assignment.end_date).format("MMM DD, YYYY")}</p>
        </div>
        <div
          className="View-Progress-btn"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          View Progress
        </div>
        <button
          type="button"
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          Preview assignment
        </button>
      </div>
    );
  };

  useEffect(() => {
    
    const descriptionLength = course?.description?.length || 0;
    if (paragraphRef.current) {
      const lineHeight = parseFloat(
        window.getComputedStyle(paragraphRef.current).lineHeight
      );
      const height = paragraphRef.current.clientHeight;
      const lineCount = height / lineHeight;

      // Set the condition to show the 'View More' button for either more than 3 lines or more than 500 characters
      setShowViewMore(lineCount > 5 || descriptionLength > 1000);
    }
  }, [course?.description]);

  // console.log("course", courseId);
  // console.log("location", location);
  return (
    <>
      {!token ?
        <Grid
          height={"50vh"}
          xs={12}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress size={80} />
        </Grid>
        :
      <div id="StudentActiveLesson-section">
        <div className="view-course-Details-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                {/* <div className="view-course-Details-back-btn-folder">
                  <BackButton buttonName={"Back"} />
                </div> */}
                <div className="view-course-Details-back-btn-folder">
                  {(location.state === null) ? (
                    <div className="view-course-Details-back-btn-folder" onClick={() => navigate("/")}>
                        <i class="fa-solid fa-chevron-left"></i>
                        <h2>Back</h2> 
                    </div>
                    ) : (
                    <BackButton buttonName={"Back"} />
                  )}
                </div>

                <div className="view-course-details-container">
                  <div className="view-course-details-folder student-view-course-Detaails-folder">
                      {courseLoading || lessonLoading ?
                        <Grid
                          height={"50vh"}
                          xs={12}
                          container
                          justifyContent={"center"}
                          alignItems={"center"}
                        >
                          <CircularProgress size={80} />
                        </Grid>
                        :
                        <><div className="view-course-details-left-section">
                      <div className="view-course-details-image">
                        <img
                          src={
                            course?.image_url ||
                            course?.image ||
                            course_demo_image
                          }
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="view-course-details-right-section">
                      <h2>
                        {course?.name}
                        {course?.source && course?.source === "Enrolled" && (
                          <button
                            type="button"
                            style={{
                              fontSize: 16, // This is correct
                              color: "#0068ff", // Corrected from Color to color
                              border: "1px solid #0068ff", // Corrected from Border to border
                              background: "unset", // Corrected from Background to background
                              borderRadius: "5px", // Corrected from BorderRadius to borderRadius
                              padding: "5px 15px", // Corrected from Padding to padding
                              marginLeft: "15px",
                            }}
                            disabled={true}
                          >
                            Enrolled
                          </button>
                        )}
                      </h2>
                      <div className="teacherviewcoursedetails-des-wrapper">
                        <p
                          className="teacherviewcoursedetails-des"
                          ref={paragraphRef}
                        >
                          {course?.description}
                          {showViewMore && (
                            <span
                              className="view-more"
                              onClick={descriptionToggle}
                            >
                              ...View More
                            </span>
                          )}
                        </p>
                        {descriptionOpen && (
                          <DescriptionCoursePopup
                            title="My Course Description"
                            data={course?.description}
                            onClick={descriptionToggle}
                          />
                        )}
                      </div>
                      <div className="view-course-details-container">
                        <div className="view-Students-course-details-folder">
                          <span>Total Lessons</span>
                          <div className="view-Students-course-details-counts">
                            <p>{lessons?.length ?? 0}</p>
                            <p>{lessons?.length > 1 ? "Lessons" : "Lesson"} </p>
                          </div>
                        </div>
                        <div className="view-Students-course-details-folder">
                          <span>Total Assignments</span>
                          <div className="view-Students-course-details-counts">
                            <p>{course.total_assignments}</p>
                            <p>{course.total_assignments > 1 ? "Assignments" : "Assignment"}{" "}</p>
                          </div>
                        </div> 
                        <div className="view-Students-course-details-folder">
                          <span>Course Start Date</span>
                          <div className="view-Students-course-details-counts">
                            <p>
                              {" "}
                              {
                                Helper.formatDateTime(course?.start_date).date
                              }{" "}
                            </p>
                          </div>
                        </div>
                        <div className="view-Students-course-details-folder">
                          <span>Course Expiry Date:</span>
                          <div className="view-Students-course-details-counts">
                            <p>
                              {course?.end_date
                                ? Helper.formatDateTime(course.end_date).date
                                : "Date not set"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                        </>}
                  </div>
                </div>
                <div className="Student-Active-Lesson-container">
                    {
                      lessonLoading ? <></>
                        :
                      lessons?.length > 0 ? (
                    lessons?.map((lesson, index) => (
                      <LessonCard
                        key={index}
                        lesson={lesson}
                        index={index}
                        course={course}
                        courseId={courseId}
                        inial_value={inial_value}
                      />
                    ))
                  ) : (
                    <p className="no-lessons-available">No lessons available.</p>
                  )}

                  <Grid xs={12} container justifyContent={"center"}>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                      hideNextButton={
                        nextUrl === null || currentPage === totalPages
                      } // Disable if no next URL and on the last page
                      hidePrevButton={prevUrl === null || currentPage === 1} // Hide if no previous URL and on the first page
                    />
                  </Grid>

                  {course?.teacher_user_id && (
                    <StudentLessonChatbot
                      ai_name={`${course?.ai_name}`}
                      teacher_id={course?.teacher_user_id}
                      courseId={course?.course_id}
                      lesson_name={""}
                      ai_tone={course?.ai_tone}
                      ai_avatar={course?.ai_avatar}
                      ai_welcome_msg={course?.ai_welcome_msg}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>}
    </>
  );
};

export default StudentCourseLesson;