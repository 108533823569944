import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProfileDetails {
  profileDetails: null | any;
  isLoading: boolean;
  error: string | null;
}

const initialState: ProfileDetails = {
  profileDetails: null,
  isLoading: false,
  error: null,
};

const profileDetailsSlice = createSlice({
  name: "profileDetails",
  initialState,
  reducers: {
    setProfileDetails: (state: ProfileDetails, action: PayloadAction<ProfileDetails>) => {
      state.profileDetails = action.payload;
      state.isLoading = false;
      state.error = null;
    },
    setloading: (state: ProfileDetails, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
      state.error = null;
    },
    seterror: (state: ProfileDetails, action: PayloadAction<string>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    resetProfileDetails: () => {
      return initialState;
    },
  },
});

export const {
  setProfileDetails,
  setloading,
  seterror,
  resetProfileDetails,
} = profileDetailsSlice.actions;

export default profileDetailsSlice.reducer;
