import React, { useRef, useState } from "react";
import PreprocessAndRenderLatex from "../../../../../../components/ProcessRenderLatex";
import { addIdsToParagraphs } from "../../../../../../utils/utlis";
import "./StudentAssignmentPreview.css";

function StudentLessonPreview({ lesson }) {
    const scrollableDivRef = useRef(null);
    const [lessondtls, setLesson] = useState(lesson || {});
    const [lessonJson, setLessonJson] = useState([]);
    const list = [
        {
            list: "Cell Body (Soma): Contains the nucleus and essential organelles."
        },
        {
            list: "Dendrites: Receive incoming signals from other neurons or sensory receptors"
        },
        {
            list: "Axon: Transmits electrical signals (action potentials) away from the cell body"
        },
        {
            list: "Myelin Sheath: Insulates and speeds up signal transmission along the axon."
        },
        {
            list: "Axon Terminals: Release neurotransmitters to communicate with other neurons or target cells."
        },
        {
            list: "Cell Body (Soma): Contains the nucleus and essential organelles."
        },
        {
            list: "Cell Body (Soma): Contains the nucleus and essential organelles."
        },
        {
            list: "Cell Body (Soma): Contains the nucleus and essential organelles."
        },
        {
            list: "Cell Body (Soma): Contains the nucleus and essential organelles."
        },
    ]



    const modifiedDescription = addIdsToParagraphs(
        lessondtls.description,
        lessonJson
    );

    return (
        <>
            <div className="student-lesson-preview-section">
                <div className='student-lesson-preview-wrapper'>
                    <div className='student-lesson-preview-container'>
                                <div className='student-lesson-preview-folder'>
                                    <div className='student-lesson-preview-list'>
                                        <PreprocessAndRenderLatex
                                            ref={scrollableDivRef}
                                            content={
                                                modifiedDescription
                                            }
                                        />
                                    </div>
                                </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StudentLessonPreview;