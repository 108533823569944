import { useEffect, useRef, useState } from "react";
import {
  calender_image,
  dashboard_popup_teacher_profile_details_icon,
  dashboard_popup_welcome_image1,
  email_image,
  gender_image,
  location_image,
  profile_image,
  selectedImage1,
  update_image,
  devicon_linkedin,
} from "../../../../assets/images/images";

import { allCountries } from "country-telephone-data";
import { format } from "date-fns";
import Datetime from "react-datetime";
import "../../styles/dateTime.css";

import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useAuth } from "../../../../features/auth/AuthContext";
import {
  isRequired,
  isValidEmail,
  isValidMobileNumber,
} from "../../../../utils/validator";

import api from "../../../../api/api";

import AOS from "aos";
import "aos/dist/aos.css";
import dayjs from "dayjs";
import Autocomplete from "react-google-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { GOOGLE_MAP_KEY } from "../../../../consts/constants";
import { updateGlobalState } from "../../../../state/global/slice.tsx";
import { compressImage } from "../../../../utils/CompressImage";
import GetCountryCodeViaIp from "../../../../utils/GetCountryCode.js";
import { genders, titles } from "../../../../utils/consts.js";
import { changeScrollPopup, getTitle } from "../../../../utils/utlis.js";
import CropProfileImageModal from "./CropProfileImageModal";
import FilteredInput from "../../../../components/FilteredInput/FilteredInput";
import FilteredTextarea from "../../../../components/FilteredInput/FilteredTextArea";
import AutocompleteInput from "@mui/material/Autocomplete";

const PopupSection = (prop) => {
  const [subjectlist, setSubjectlist] = useState([]);
  const dispatch = useDispatch();
  const globalState = useSelector((state) => state.globalState);
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  useEffect(() => {
    if (prop.profilePopupStatus === true) {
      changeScrollPopup("hidden", "dashboard-popup-container");
    } else {
      changeScrollPopup("auto", "dashboard-popup-container");
    }
  }, [prop.profilePopupStatus]);
  const [subjects, setSubjects] = useState([]);

  const [Addtosubjects, setAddtosubjects] = useState();
  const { token } = useAuth();
  const [selectedImage, setSelectedImage] = useState(null);
  const [aiBotname, setaiBotName] = useState("");
  const [aiBotmessage, setaiBotmessage] = useState(
    "Hi, I am your AI assistant"
  );
  const [ai_welcome_msg_list, setAi_welcome_msg_list] = useState([
    `Hi! I am Ai Name, how may I help you?`,
    `Hi! I am Ai Name, What can I teach you today?`,
    `Hi! I am Ai Name, How can I support your learning today?`,
    "Other",
  ]);
  const [aiToneSelectedOptions, setAiToneSelectedOptions] = useState([]);
  const [aiToneShowDropdown, setAiToneShowDropdown] = useState(false);
  const [aiToneOtherTextArr, setaiToneOtherTextArr] = useState([])
  const [aiToneOtherText, setAiToneOtherText] = useState("");
  const aiToneDropdownRef = useRef(null);
  const aiToneInputRef = useRef(null);
  const [imageUploaded, setImageUploaded] = useState(false);

  const [uplodedImage, setUplodedImage] = useState({})

  const [aiNameEdited, setAiNameEdited] = useState(false);
  const [formState, setFormState] = useState(() => {
    // Attempt to load existing form data from local storage
    const savedFormData = localStorage.getItem("teacher_formState");
    var _savedFormData = {};
    try {
      _savedFormData = JSON.parse(savedFormData);
      // Assuming _savedFormData.subject_names is a string like "english,history,science"
      const subjectArray = _savedFormData.subject_names.split(",");
      const aitonename = _savedFormData.ai_tone_names.split(",");

      setAiToneSelectedOptions(aitonename);
      setSubjects(subjectArray);
      setaiBotName(_savedFormData?.ai_name);
      setaiBotmessage(_savedFormData?.ai_welcome_msg);
      setAi_welcome_msg_list([
        `Hi! I am ${_savedFormData?.ai_name}, how may I help you?`,
        `Hi! I am ${_savedFormData?.ai_name}, What can I teach you today?`,
        `Hi! I am ${_savedFormData?.ai_name}, How can I support your learning today?`,
        "Other",
      ]);
      if (_savedFormData?.dob)
        _savedFormData.dob = dayjs(new Date(_savedFormData?.dob));
    } catch (error) {
      // console.log(error);
    }

    return savedFormData
      ? _savedFormData
      : {
        name: "",
        country_code: "+1",
        dob: "",
        gender: "",
        email: globalState?.email || "",
        language_ids: "",
        location: "",
        introduction: "",
        subject_names: "",
        ai_avatar: null,
        ai_name: "",
        ai_tone_names: "",
        ai_welcome_msg: "",
        title: "",
        linkedin_url: "",
      };
  });


  const [formErrors, setFormErrors] = useState({
    name: "",
    country_code: "",
    // phone: "",
    dob: "",
    gender: "",
    email: "",
    language_ids: "",
    location: "",
    introduction: "",
    subject_names: "",
    ai_avatar: "",
    ai_name: "",
    ai_tone_names: "",
    ai_welcome_msg: "",
    title: "",
  });
  const [hasSelectedPlace, setHasSelectedPlace] = useState(false);

  const handlePlaceSelect = (place) => {
    if (place && place.formatted_address) {
      setFormState((prev) => ({
        ...prev,
        location: place.formatted_address,
      }));
      setFormErrors({ location: "" });
      setHasSelectedPlace(true);
    } else {
      setFormErrors({ location: "Please enter a valid location" });
    }
  };

  const handleAutocompleteInputChange = (event) => {
    setFormState((prev) => ({
      ...prev,
      location: event.target.value,
    }));
    if (event.target.value === "" || !hasSelectedPlace) {
      setFormErrors({ location: "Please enter a valid location" });
    } else {
      setFormErrors({ location: "" });
    }
  };

  const handleInputBlur = () => {
    if (!hasSelectedPlace && formState.location) {
      setFormState((prev) => ({
        ...prev,
        location: "",
      }));
      setFormErrors({ location: "Please enter a valid location" });
    }
  };

  const dropdownRef2 = useRef(null);

  const handleClickOutside2 = (event) => {
    if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
      setShowDropdown2(false);
    }
  };

  const today = new Date().toISOString().split("T")[0];
  const [showDropdown2, setShowDropdown2] = useState(false);

  const addLanguage = (language) => {
    let error = "";
    error = isRequired(language);
    setFormErrors({
      ...formErrors,
      language_ids: error,
    });
    // add  language to formState.language string separated by comma
    setFormState((prev) => ({
      ...prev,
      language_ids: prev.language_ids
        ? prev.language_ids + "," + language
        : language,
    }));
  };
  const removeLanguage = (language) => {
    let error = "";
    error = isRequired(language);
    setFormErrors({
      ...formErrors,
      language_ids: error,
    });
    // remove language from formState.language string separated by comma
    setFormState((prev) => ({
      ...prev,
      language_ids: prev.language_ids
        ?.split(",")
        ?.filter((item) => item !== language)
        .join(","),
    }));
  };

  const [selectedOption2, setSelectedOption2] = useState(
    "Hi! I am Synthia, how may I help you?"
  );
  const [dropdownOptions2, setDropdownOptions2] = useState([]);

  const [showInnerInput, setShowInnerInput] = useState(false);

  const innerInputRef2 = useRef(null);
  const handleOptionChange2 = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption2(selectedValue);
    setShowDropdown2(selectedValue === "Other");
    setDropdownOptions2([]);
    setaiBotmessage(selectedValue);
    let error = "";
    error = isRequired(selectedValue);
    setFormErrors({
      ...formErrors,
      ai_welcome_msg: error,
    });

    setFormState({ ...formState, ai_welcome_msg: selectedValue });

    if (selectedValue === "Other") {
      setShowInnerInput(true);
      setTimeout(() => {
        if (innerInputRef2.current) {
          innerInputRef2.current.focus();
          innerInputRef2.current.select();
        }
      }, 0);
    } else {
      setShowInnerInput(false);
    }
  };

  const [inputappends, setInputappends] = useState([]);

  const appendValue = (newValue) => {
    setInputappends([...inputappends, newValue]);
  };

  const removeValue = (valueToRemove) => {
    setInputappends(inputappends.filter((item) => item !== valueToRemove));
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside2);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  const handleInnerInputChange2 = (event) => {
    const innerInputValue = event.target.value;
    setaiBotmessage(innerInputValue);
    setFormState({ ...formState, ai_welcome_msg: innerInputValue });
  };

  const handleInputKeyPress = async (event) => {
    const innerInputValue = event.target.value;

    if (event.key === "Enter" && innerInputValue.trim() !== "") {
      event.preventDefault();
      // Append items to the array only if they don't exist
      const isDuplicate = subjects.some(subject => subject.toLowerCase() === innerInputValue.toLowerCase());
      if (isDuplicate) {
        toast.dismiss();
        toast.error(`Subject already added.`, {
          position: toast.POSITION.TOP_CENTER,
        });
        event.preventDefault(); // Prevent the default action if it's a duplicate
        return; // Exit the function early
      }
      if (!subjects?.includes(innerInputValue.trim())) {
        event.target.value = "";
        addSuggestion(innerInputValue.trim());
        setsubsinput("");
      } else {
        toast.dismiss();
        toast.error(`Subject already added.`, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };


  const handleAddsubs = async (event) => {
    event.preventDefault();

    // Assuming you have the input value in the state (replace 'inputValue' accordingly)
    const innerInputValue = subsinput;

    const isDuplicate = subjects.some(subject => subject.toLowerCase() === innerInputValue.toLowerCase());
    if (isDuplicate) {
      toast.dismiss();
      toast.error(`Subject already added.`, {
        position: toast.POSITION.TOP_CENTER,
      });
      event.preventDefault(); // Prevent the default action if it's a duplicate
      return; // Exit the function early
    }
    if (!subjects?.includes(innerInputValue.trim())) {
      event.target.value = "";
      addSuggestion(innerInputValue.trim());
      setsubsinput("");
    } else {
      toast.dismiss();
      toast.error(`Subject already added.`, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const addSuggestion = (suggestion) => {
    const exists = subjects?.includes(suggestion);

    if (exists) {
    } else {
      appendValue(suggestion);
      const tempsubj = globalState?.datalist?.subjects;
      let etsval = "";
      for (const id in tempsubj) {
        if (tempsubj[id].toLowerCase() === suggestion.toLowerCase()) {
          etsval = id;
        }
      }
      appendValue(etsval);

      setSubjects([...subjects, suggestion]);

      setFormState((prev) => ({
        ...prev,
        subject_names: [...subjects, suggestion]
          .filter((subject) => subject !== "")
          .join(","),
      }));
      let errors = {};
      if ([...subjects, suggestion].length < 1) {
        errors.subject_names = "Please enter alteast one subject.";
      }
      setFormErrors(errors);
    }
  };
  const removeSubject = (subjectToRemove) => {
    const tempsubj = globalState?.datalist?.subjects;
    let rmval = "";
    for (const id in tempsubj) {
      if (tempsubj[id] === subjectToRemove) {
        rmval = id;
      }
    }
    removeValue(rmval);
    const updatedSubjects = subjects?.filter(
      (subject) => subject !== subjectToRemove
    );
    setSubjects(updatedSubjects);

    setFormState((prev) => ({
      ...prev,
      subject_names: updatedSubjects
        .filter((subject) => subject !== "")
        .join(","),
    }));
    let errors = {};
    if (updatedSubjects.length < 1) {
      errors.subject_names = "Please enter alteast one subject.";
    }
    setFormErrors(errors);
  };

  const [step, setStep] = useState(1);

  const onNext = () => {
    setStep(step + 1);
  };

  const onBack = () => {
    setStep(step - 1);
  };

  const fields = [
    {
      id: 1,
      label: "Teacher Profile Details",
    },
    {
      id: 2,
      label: "About teacher",
    },
    {
      id: 3,
      label: "Subjects you teach",
    },
    {
      id: 4,
      label: "Personalize your AI Assistant",
    },
  ];
  useEffect(() => {
    api
      .get("/auth/get-datalist/", {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          //return 1;
          if (response?.data) {
            setAddtosubjects(Object.values(response.data?.top_ten_subjects));
            updateState("datalist", response?.data ?? {});

            setSubjectlist(response?.data?.subjects);
          }
        }
      })
      .catch((error) => { });

    if (globalState?.locationData === undefined) {
      GetCountryCodeViaIp().then((response) => {
        updateState("locationData", response);
        setSelectedCountry({
          name: response?.country_name,
          iso2: response?.country_code?.toLowerCase(),
          dialCode: response?.country_calling_code?.replace("+", ""),
          format: "+...-...-....",
          hasAreaCodes: false,
        });
      });
    }

    const aiToneHandleClickOutside = (event) => {
      if (
        aiToneDropdownRef.current &&
        !aiToneDropdownRef.current.contains(event.target) &&
        aiToneInputRef.current &&
        !aiToneInputRef.current.contains(event.target)
      ) {
        setAiToneShowDropdown(false);
      }
    };
    AOS.init({
      duration: 500,
    });

    document.addEventListener("mousedown", aiToneHandleClickOutside);
    return () => {
      document.removeEventListener("mousedown", aiToneHandleClickOutside);
    };
  }, []);
  const [selectedCountry, setSelectedCountry] = useState({
    name: globalState?.locationData
      ? globalState?.locationData?.country_name
      : "United States",
    iso2: globalState?.locationData
      ? globalState?.locationData?.country_code?.toLowerCase()
      : "us",
    dialCode: globalState?.locationData
      ? globalState?.locationData?.country_calling_code?.replace("+", "")
      : "1",
    format: "+...-...-....",
    hasAreaCodes: false,
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [subsinput, setsubsinput] = useState("");
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let error = "";
    if (name === "name") {
      error = isRequired(value);
    } else if (name === "title") {
      error = isRequired(value);
    } else if (name === "email") {
      error = isRequired(value) || isValidEmail(value);
    }
    else if (name === "gender") {
      error = isRequired(value);
    } else if (name === "language_ids") {
      error = isRequired(value);
    } else if (name === "location") {
      error = isRequired(value);
    } else if (name === "introduction") {
      error = isRequired(value);
    } else if (name === "subject_names") {
      error = isRequired(value);
    } else if (name === "ai_avatar") {
      error = isRequired(value);
    } else if (name === "ai_name") {
      error = isRequired(value);
    } else if (name === "ai_tone_names") {
      error = isRequired(value);
    } else if (name === "ai_welcome_msg") {
      error = isRequired(value);
    } else if (name === "country_code") {
      error = isValidMobileNumber(value, selectedCountry.dialCode);
    }

    setFormErrors({
      ...formErrors,
      [name]: error,
    });
    if (name === "subject_names") {
      setFormState((prev) => ({
        ...prev,
        subject_names: subjects?.filter((subject) => subject !== "").join(","),
      }));
    } else {
      setFormState((prev) => ({ ...prev, [name]: value }));

      if ((name === "name" || name === "title") && !aiNameEdited) {
        const newName = name === "name" ? value : formState?.name;
        const newTitle = name === "title" ? value : formState?.title;

        const SetAiName = getTitle(
          newTitle,
          `${newName.includes(" ") ? newName.trim().split(" ").pop() : newName} AI`
        );

        setFormState((prev) => ({ ...prev, ["ai_name"]: SetAiName }));
        setaiBotName(SetAiName);
        setAi_welcome_msg_list([
          `Hi! I am ${SetAiName}, how may I help you?`,
          `Hi! I am ${SetAiName}, What can I teach you today?`,
          `Hi! I am ${SetAiName}, How can I support your learning today?`,
          "Other",
        ]);
      }
    }

    // Check if the text length exceeds 1000 characters
    if (value.length >= 1000) {
      setFormErrors((prevState) => ({
        ...prevState,
        introduction: "The introduction cannot exceed 1000 characters.",
      }));
    } else {
      // Clear error if the text length is under 1000
      setFormErrors((prevState) => ({
        ...prevState,
        introduction: "",
      }));
      setFormState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleDateChanged = (value) => {
    let error = "";
    error = isRequired(value);
    setFormErrors({
      ...formErrors,
      dob: error,
    });
    setFormState((prev) => ({
      ...prev,
      dob: format(new Date(value), "yyyy-MM-dd"),
    }));
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const handleFileInput = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      setSrc(null);
      return;
    }

    if (file.size > 31457280) {
      // Handle the case where the file size is too large
      toast.error("The file size exceeds the maximum limit of 30 MB");
      setFormErrors({
        ...formErrors,
        ai_avatar: "The file size exceeds the maximum limit of 30 MB",
      });
      setSrc(null);
      return; // Exit the function
    }

    const compressedImage = await compressImage(file);
    setSrc(URL.createObjectURL(compressedImage));
    onOpenModal();

    let error = "";
    error = isRequired(file);
    setFormErrors({
      ...formErrors,
      ai_avatar: error,
    });
    event.target.value = null;
  };

  const onOpenModal = () => {
    setModalIsOpen(true);
  };

  const onCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleCountryChange = (e) => {
    const country = allCountries.find((c) => c.iso2 === e.target.value);
    setSelectedCountry(country);

    setFormErrors({
      ...formErrors,
      country_code: isValidMobileNumber(
        formState.phone,
        selectedCountry.dialCode
      ),
    });
    setFormState({
      ...formState,
      country_code: `+${country.dialCode}`,
    });
  };

  function isValidDate(val) {
    let today = new Date();
    let twentyOneYearsAgo = new Date();
    twentyOneYearsAgo.setFullYear(today.getFullYear() - 21);
    twentyOneYearsAgo.setHours(0, 0, 0, 0); // Set time to the start of the day

    let selectedDate = new Date(val);
    selectedDate.setHours(0, 0, 0, 0); // Set time to the start of the day

    // Check if the selected date is after today or if it is less than 21 years ago
    if (selectedDate > today || selectedDate > twentyOneYearsAgo) {
      return false;
    }
    return true;
  }
  const [loading, setLoading] = useState(false);

  const handleForm1Submit = async (e) => {
    e.preventDefault();
    let errors = {};
    errors.name = formState.name.trim() === '' && "This field is required";
    errors.title =
      formState.title === "" || formState.title === "0"
        ? "Please select title"
        : "";
    errors.dob = isRequired(formState.dob);
    errors.gender =
      formState.gender === "" || formState.gender === "0"
        ? "Please select gender"
        : "";
    errors.email = isRequired(formState.email);
    errors.language_ids = isRequired(formState.language_ids);
    errors.location =
      formState.location === "" ? "Please enter a valid location" : "";
    setFormErrors(errors);
    if (!Object.values(errors).some((error) => error)) {
      onNext();
      setLoading(false);
    }
  };
  const handleForm2Submit = async (e) => {
    e.preventDefault();
    let errors = {};
    errors.introduction =
      formState.introduction.trim() === ""
        ? "This field is required"
        : formState.introduction.length > 1000
          ? "The introduction cannot exceed 1000 characters."
          : "";
    setFormErrors(errors);
    if (!Object.values(errors).some((error) => error)) {
      onNext();
      setLoading(false);
    }
  };
  const handleForm3Submit = async (e) => {
    e.preventDefault();
    let errors = {};
    if (subjects?.length === 0) {
      errors.subject_names = "Please enter alteast one subject.";
    } else {
      errors.subject_names = "";
    }

    setFormErrors(errors);
    if (!Object.values(errors).some((error) => error)) {
      onNext();
      setLoading(false);
    }
  };
  const handleForm4Submit = async (e) => {
    e.preventDefault();
    let errors = {};
    errors.ai_avatar = isRequired(formState.ai_avatar);
    errors.ai_name = isRequired(formState.ai_name);
    errors.ai_tone_names = isRequired(formState.ai_tone_names);
    errors.ai_welcome_msg = isRequired(formState.ai_welcome_msg);
    setFormErrors(errors);

    if (!Object.values(errors).some((error) => error)) {
      await submitForm();
    }
  };

  const submitForm = async () => {
    toast.dismiss();
    try {
      let _formData = formState;
      if (formState.dob) {
        formState.dob = dayjs(new Date(formState?.dob)).format("YYYY-MM-DD");
      }
      await api
        .post("auth/create-teacher/", _formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response?.data?.status === 200) {
            localStorage.removeItem("courseFormState");
            prop.setWelcomePopupStatus(true);
            setTimeout(() => {
              prop.setWelcomePopupStatus(false);
              updateState('has_profile', true);
            }, 5000);
            prop.setProfilePopupStatus(false);
            updateState("teacher_details", response?.data ?? {});

            toast.success(
              response?.data?.message ||
              "Teacher profile has been created successfully.",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );

            api
              .get("/auth/get-teacher/", {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Token ${token}`,
                },
              })
              .then((response) => {
                if (response.status === 200) {
                  if (response?.data) {
                    updateState("teacher_details", response?.data ?? {});
                  }
                }
              }).catch((error) => {
              });;

            api
              .get("/auth/get-datalist/", {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Token ${token}`,
                },
              })
              .then((response) => {
                if (response.status === 200) {
                  updateState("datalist", response?.data ?? {});
                }
              })
              .catch((error) => {
                // console.log(error);
              });
          } else {
            toast.error(response?.data?.message || "Something went wrong!", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
        error?.response?.data?.error ||
        "Something went wrong!",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };

  const handleKeyUp = (e) => {
    const inputValue = e.target.value;
    setAiNameEdited(true);
    setaiBotName(inputValue);

    const aimsgarray = [
      "Hi! I am " + inputValue + ", how may I help you?",
      "Hi! I am " + inputValue + ", What can I teach you today?",
      "Hi! I am " + inputValue + ", How can I support your learning today?",
      "Other",
    ];
    setAi_welcome_msg_list(aimsgarray);
  };
  const [showWelcomeFolder, setShowWelcomeFolder] = useState(true);
  const handleBeginJourney = () => {
    setShowWelcomeFolder(false);
  };

  const aiToneDropdownOptions = [
    "Formal",
    "Informative",
    "Inspirational",
    "Enthusiastic",
    "Joyful",
    "Thoughtful",
    "Casual",
    "Other",
  ];

  function onChangeAiTone(arr) {
    let error = "";
    const data = arr.join(",");
    error = isRequired(data);
    setFormErrors({
      ...formErrors,
      ai_tone_names: error,
    });
    setFormState((prev) => ({
      ...prev,
      ai_tone_names: data,
    }));
  }

  const aiToneHandleOptionChange = (event) => {
    const value = event.target.value;

    if (aiToneSelectedOptions.includes(value)) {
      if (value === "Other") {
        const matchingElements = aiToneSelectedOptions.filter(element => aiToneOtherTextArr.includes(element));
        setAiToneSelectedOptions((prevState) =>
          prevState.filter((item) => item !== value && !matchingElements.includes(item))
        );
        onChangeAiTone(aiToneSelectedOptions.filter((item) => item !== value && !matchingElements.includes(item)));
      } else {
        setAiToneSelectedOptions((prevState) =>
          prevState.filter((item) => item !== value)
        );
        onChangeAiTone(aiToneSelectedOptions.filter((item) => item !== value));
      }
    } else {
      setAiToneSelectedOptions((prevState) => [...prevState, value]);

      onChangeAiTone([...aiToneSelectedOptions, value]);
    }
  };

  const aiToneHandleOtherTextSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setAiToneSelectedOptions((prevState) => [...prevState, aiToneOtherText]);
      setaiToneOtherTextArr((prevState) => [...prevState, aiToneOtherText]);
      setAiToneOtherText("");
      onChangeAiTone([...aiToneSelectedOptions, aiToneOtherText]);
    }
  };

  useEffect(() => {
    // Save the form state to local storage whenever it changes
    localStorage.setItem("teacher_formState", JSON.stringify(formState));
  }, [formState]);

  return (
    <>
      <div className="dashboard-popup-section">
        <div className="dashboard-popup-wrapper">
          <div className="dashboard-popup-container">
            {showWelcomeFolder ? (
              <div
                className="dashboard-popup-welcome-folder"
                data-aos="fade-right"
              >
                <div className="dashboard-popup-welcome-image">
                  <img src={dashboard_popup_welcome_image1} alt="" />
                </div>
                <h2>Welcome Aboard!</h2>
                <p>Let's Create Your Teacher Profile.</p>
                <button
                  type="button"
                  className="begin-journey-btn"
                  onClick={handleBeginJourney}
                >
                  Setup Profile
                </button>
              </div>
            ) : (
              <div className="dashboard-popup-form-wrapper">
                {fields.map((field, index) => {
                  if (step === 1) {
                    return (
                      <div
                        key={field.id}
                        className="dashboard-popup-teacher-profile-details-folder"
                      >
                        <div className="dashboard-popup-teacher-profile-details-title">
                          <div className="dashboard-popup-teacher-profile-details-image">
                            <img
                              src={dashboard_popup_teacher_profile_details_icon}
                              alt=""
                            />
                          </div>
                          <h2>{fields[step - 1].label}</h2>
                        </div>
                        <div className="dashboard-popup-teacher-profile-details-form">
                          <div className="dashboard-popup-teacher-profile-details-form-container">
                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Name</label>
                              <input
                                id="teacher-create-profile-input"
                                type="name"
                                name="name"
                                style={
                                  formErrors.name
                                    ? { border: "1px solid red" }
                                    : null
                                }
                                value={formState.name || ""}
                                placeholder="Full name"
                                onChange={handleInputChange}
                              />
                              <div className="teacher-create-popup-gender-input-folder">
                                <div className="form-icon">
                                  <img src={profile_image} alt="" />
                                </div>
                                <div className="setect-genter-list-dropdown">
                                  <select
                                    name="title"
                                    className="teacher-create-popup-gender-input"
                                    style={
                                      formErrors.title
                                        ? { border: "1px solid red" }
                                        : null
                                    }
                                    value={formState.title || ""}
                                    onChange={handleInputChange}
                                  >
                                    {titles.map((title) => {
                                      return (
                                        <option
                                          key={title.value}
                                          value={title.value}
                                        >
                                          {title.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              <p>
                                {formErrors.name}
                                {formErrors.title}
                              </p>
                            </div>
                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Email</label>
                              <input
                                type="text"
                                placeholder="email id"
                                name="email"
                                readOnly
                                style={
                                  formErrors.email
                                    ? { border: "1px solid red" }
                                    : null
                                }
                                value={formState.email || ""}
                                onChange={handleInputChange}
                              />
                              <div className="form-icon">
                                <img src={email_image} alt="" />
                              </div>
                              <p>{formErrors.email}</p>
                            </div>
                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Birth Date</label>
                              <Datetime
                                name="dob"
                                className={
                                  formErrors.dob ? "datetime-error" : "datetime"
                                }
                                timeFormat={false}
                                inputProps={{
                                  placeholder: "MM:DD:YYYY",
                                  readOnly: true,
                                }}
                                initialViewDate={
                                  formState.dob
                                    ? formState.dob
                                    : dayjs(today)
                                      .subtract(21, "year")
                                      .toDate()
                                }
                                max={today}
                                value={formState.dob || ""}
                                closeOnSelect={true}
                                onChange={handleDateChanged}
                                id="Birth-Date-input"
                                dateFormat="MMM DD, YYYY"
                                isValidDate={isValidDate}
                              />
                              <div className="form-icon">
                                <img src={calender_image} alt="" />
                              </div>
                              <p>{formErrors.dob}</p>
                            </div>
                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Gender</label>
                              <div className="dashboard-popup-teacher-profile-gender-folder">
                                <select
                                  id="gendar-select-feild"
                                  name="gender"
                                  style={
                                    formErrors.gender
                                      ? { border: "1px solid red" }
                                      : null
                                  }
                                  value={formState.gender || ""}
                                  onChange={handleInputChange}
                                >
                                  {genders.map((gender) => {
                                    return (
                                      <option
                                        key={gender.value}
                                        value={gender.value}
                                      >
                                        {gender.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="form-icon">
                                <img src={gender_image} alt="" />
                              </div>
                              <p>{formErrors.gender}</p>
                            </div>

                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>Location</label>
                              <Autocomplete
                                apiKey={GOOGLE_MAP_KEY}
                                onPlaceSelected={handlePlaceSelect}
                                defaultValue={formState.location}
                                onChange={handleAutocompleteInputChange}
                                onBlur={handleInputBlur}
                                options={{
                                  types: ["(cities)"],
                                }}
                              />
                              <div className="form-icon location-icon">
                                <img src={location_image} alt="" />
                              </div>
                              <p>{formErrors.location}</p>
                            </div>
                            <div className="dashboard-popup-teacher-profile-details-input">
                              <label>linkedin</label>
                              <input
                                type="text"
                                placeholder="linkedin"
                                name="linkedin_url"
                                value={formState.linkedin_url || ""}
                                onChange={handleInputChange}
                              />
                              <div className="form-icon">
                                <img src={devicon_linkedin} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="dashboard-popup-teacher-profile-details-input location-input">
                            <label>
                              Language <span>(Select Language)</span>
                            </label>
                            <div className="dashboard-popup-teacher-profile-select-Language-container">
                              <div className="Subjects-you-teach-suggestions-folder">
                                {Object.keys(globalState?.datalist?.languages)
                                  .filter(
                                    (key) =>
                                      !formState.language_ids
                                        .split(",")
                                        .filter((language) => language !== "")
                                        .includes(key)
                                  )
                                  .map((key) => (
                                    <a
                                      href="#"
                                      key={key}
                                      onClick={() => addLanguage(key)}
                                    >
                                      {" "}
                                      {globalState?.datalist?.languages[key]}
                                    </a>
                                  ))}
                                <span>More Languages coming soon</span>
                              </div>
                              {formState.language_ids
                                .split(",")
                                .filter((language) => language !== "").length >
                                0 && (
                                  <div className="dashboard-popup-teacher-profile-selected-Language-folder">
                                    <div className="Subjects-you-teach-feilds-folder">
                                      {formState.language_ids
                                        .split(",")
                                        .filter((language) => language !== "")
                                        .map((language) => (
                                          <div
                                            className="Subjects-you-teach-feilds"
                                            key={language}
                                          >
                                            <p>
                                              {
                                                globalState?.datalist?.languages[
                                                language
                                                ]
                                              }
                                            </p>
                                            <a
                                              className="subject-delete-btn"
                                              onClick={() =>
                                                removeLanguage(language)
                                              }
                                            >
                                              <i className="fa-solid fa-xmark"></i>
                                            </a>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                )}
                            </div>
                            <p>{formErrors.language_ids}</p>
                          </div>
                          <div className="dot-indicator">
                            {fields.map((field, index) => (
                              <div
                                key={field.id}
                                className={`dot ${index + 1 === step ? "active" : ""
                                  }`}
                              ></div>
                            ))}
                          </div>
                        </div>
                        {successMessage && (
                          <p className="success-message">{successMessage}</p>
                        )}
                        {errorMessage && (
                          <p className="error-message">{errorMessage}</p>
                        )}
                        <div className="form-buttons">
                          {loading ? (
                            <div className="loading-spinner"></div>
                          ) : (
                            <button
                              onClick={handleForm1Submit}
                              className="form-buttons dashboard-form-next-btn"
                            >
                              Next
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (step === 2) {
                    return (
                      <div
                        key={field.id}
                        className="dashboard-popup-teacher-profile-details-folder"
                      >
                        <div className="dashboard-popup-teacher-profile-details-title">
                          <div className="dashboard-popup-teacher-profile-details-image">
                            <img
                              src={dashboard_popup_teacher_profile_details_icon}
                              alt=""
                            />
                          </div>
                          <h2>{fields[step - 1].label}</h2>
                        </div>
                        <div className="dashboard-popup-teacher-profile-details-form">
                          <p className="introduce-yourself-title">
                            Introduce Yourself.
                          </p>
                          <textarea
                            name="introduction"
                            style={
                              formErrors.introduction
                                ? { border: "1px solid red" }
                                : null
                            }
                            value={formState.introduction || ""}
                            onChange={handleInputChange}
                            className="introduce-yourself-text"
                            type="text"
                            cols="30"
                            rows="10"
                            placeholder={`For Example:\n• Tell us about yourself.\n• What subjects do you teach?\n• What grades do you teach?\n• Do you teach at a school? If so, which one?`}
                            inputprops={{
                              style: { color: "red" },
                            }}
                          ></textarea>
                          <p className="errorState">
                            {formErrors.introduction}
                          </p>
                          <div className="dot-indicator">
                            {fields.map((field, index) => (
                              <div
                                key={field.id}
                                className={`dot ${index + 1 === step ? "active" : ""
                                  }`}
                              ></div>
                            ))}
                          </div>
                        </div>
                        <span className="you-can-update-the-details-title">
                          {" "}
                          Note: You can update these details anytime from
                          profile settings.
                        </span>
                        {successMessage && (
                          <p className="success-message">{successMessage}</p>
                        )}
                        {errorMessage && (
                          <p className="error-message">{errorMessage}</p>
                        )}

                        {loading ? (
                          <div className="loading-spinner"></div>
                        ) : (
                          <div className="form-buttons">
                            {step > 1 && <button onClick={onBack}>Back</button>}
                            {step < fields.length ? (
                              <button onClick={handleForm2Submit}>Next</button>
                            ) : (
                              <button onClick={() => { }}>Submit</button>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  }
                  if (step === 3) {
                    return (
                      <div
                        key={field.id}
                        className="dashboard-popup-teacher-profile-details-folder"
                      >
                        <div className="dashboard-popup-teacher-profile-details-title">
                          <div className="dashboard-popup-teacher-profile-details-image">
                            <img
                              src={dashboard_popup_teacher_profile_details_icon}
                              alt=""
                            />
                          </div>
                          <h2>{fields[step - 1].label}</h2>
                        </div>
                        <div className="dashboard-popup-teacher-profile-details-form">
                          <p className="subjects-title">
                            Write the subjects you teach
                          </p>
                          <div className="add_feild-btn">
                            <div className="Subjects-you-teach">
                              <input
                                type="text"
                                style={
                                  formErrors.subject_names
                                    ? { border: "1px solid red" }
                                    : null
                                }
                                value={subsinput}
                                placeholder="E.g. Math, Science, geography"
                                onKeyPress={handleInputKeyPress}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setsubsinput(e.target.value);
                                }}
                                spellCheck
                              />
                            </div>
                            <button
                              type="button"
                              onClick={handleAddsubs}
                              disabled={
                                subsinput.trim() === "" ||
                                subsinput === null ||
                                subsinput === undefined
                              }
                              style={{
                                backgroundColor: (subsinput.trim() === "" || subsinput === null || subsinput === undefined) && 'grey'
                              }}
                              className="add_subject_feild_btn"
                            >
                              Add
                            </button>
                          </div>
                          <div className="Subjects-you-teach-feilds-folder">
                            {subjects && subjects.length > 0 ? (
                              subjects?.map((subject, index) => (
                                <>
                                  {
                                    subject.trim() !== "" && <div
                                      key={index}
                                      className="Subjects-you-teach-feilds"
                                    >
                                      <p>{subject}</p>
                                      <a
                                        className="subject-delete-btn"
                                        onClick={() => removeSubject(subject)}
                                      >
                                        <i className="fa-solid fa-xmark"></i>
                                      </a>
                                    </div>
                                  }
                                </>
                              ))
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="Subjects-you-teach-suggestions-container">
                            {Addtosubjects &&
                              Addtosubjects?.length > 0 &&
                              Object.values(Addtosubjects)?.filter(
                                (valsubject) =>
                                  valsubject !== "" &&
                                  !subjects?.includes(valsubject)
                              ).length > 0 ? (
                              <p className="Subjects-you-teach-suggestions-title">
                                Suggestions
                              </p>
                            ) : null}

                            <div className="Subjects-you-teach-suggestions-folder">
                              {Addtosubjects && Addtosubjects.length > 0 ? (
                                Object.values(Addtosubjects)
                                  .filter(
                                    (valsubject) =>
                                      valsubject !== "" &&
                                      !subjects
                                        .map((subject) => subject.toLowerCase())
                                        .includes(valsubject.toLowerCase())
                                  )
                                  .slice(
                                    0,
                                    Addtosubjects.length >= 10
                                      ? 10
                                      : Addtosubjects.length
                                  )
                                  .map((valsubject, index) => (
                                    <a
                                      key={index}
                                      onClick={(e) => {
                                        e.preventDefault(); // Prevent the default anchor action
                                        addSuggestion(valsubject);
                                      }}
                                    >
                                      {valsubject}
                                    </a>
                                  ))
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <br />
                          <p className="errorState">
                            {formErrors.subject_names}
                          </p>
                          <div className="dot-indicator">
                            {fields.map((field, index) => (
                              <div
                                key={field.id}
                                className={`dot ${index + 1 === step ? "active" : ""
                                  }`}
                              ></div>
                            ))}
                          </div>
                        </div>

                        <div className="form-buttons">
                          {step > 1 && <button onClick={onBack}>Back</button>}
                          {step < fields.length ? (
                            <button onClick={handleForm3Submit}>Next</button>
                          ) : (
                            <button onClick={() => { }}>Submit</button>
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (step === 4) {
                    return (
                      <div
                        key={field.id}
                        className="dashboard-popup-teacher-profile-details-folder"
                      >
                        <div className="dashboard-popup-teacher-profile-details-title">
                          <div className="dashboard-popup-teacher-profile-details-image">
                            <img
                              src={dashboard_popup_teacher_profile_details_icon}
                              alt=""
                            />
                          </div>
                          <h2>{fields[step - 1].label}</h2>
                        </div>
                        <div className="dashboard-popup-teacher-profile-details-form">
                          <div className="Personalize-your-AI-Assistant-wrapper">
                            <div className="Personalize-your-AI-Assistant-left-section">
                              <div className="Personalize-your-AI-Assistant-uplode-image">
                                <img
                                  src={selectedImage ?? selectedImage1}
                                  alt=""
                                />
                                <div className="dashboard-popup-teacher-profile-details-image-plus-btn">
                                  <span className="profile-plus">
                                    <i class="fa-solid fa-plus"></i>
                                  </span>
                                </div>
                                <input
                                  type="file"
                                  id="choos-profile-image"
                                  name="ai_avatar"
                                  key={Math.random()} // Add a random key to reset the input field
                                  onChange={handleFileInput}
                                  accept="image/png, image/jpeg" // Accept only image files
                                  style={{
                                    height: 106,
                                    width: 106,
                                  }}
                                />

                                <p className="errorState">
                                  {formErrors.ai_avatar}
                                </p>
                              </div>
                              <p
                                className="subjects-title"
                                style={{ textAlign: "center" }}
                              >
                                Upload an image for your AI assistant
                              </p>
                              <div className="Personalize-your-AI-Assistant-form-folder">
                                <div className="Personalize-your-AI-Assistant-input-folder">
                                  <label>
                                    Write name for your AI assistant
                                  </label>
                                  <input
                                    name="ai_name"
                                    style={
                                      formErrors.ai_name
                                        ? { border: "1px solid red" }
                                        : null
                                    }
                                    value={formState.ai_name || ""}
                                    onKeyUp={handleKeyUp}
                                    onChange={handleInputChange}
                                    type="text"
                                    placeholder="eg: Mr Smith AI"
                                    inputProps={{
                                      maxLength: 50, // Set the maximum length of the input
                                    }}
                                  />
                                  <p className="errorState">
                                    {formErrors.ai_name}
                                  </p>
                                </div>
                                <div className="Personalize-your-AI-Assistant-input-folder">
                                  <label>
                                    Customize your AI assistant's tone
                                  </label>
                                  <div
                                    className="Customize-your-AI-assistant-tone-dropdown-folder"
                                    id="Customize-your-AI-dropdown"
                                    ref={aiToneInputRef}
                                  >
                                    <input
                                      type="text"
                                      style={
                                        formErrors.ai_tone_names
                                          ? { border: "1px solid red" }
                                          : null
                                      }
                                      name="ai_tone_names"
                                      placeholder="Select response"
                                      value={aiToneSelectedOptions
                                        .filter((option) => option !== "Other" && option.trim() !== "")
                                        .join(",")}
                                      readOnly
                                      onClick={() =>
                                        setAiToneShowDropdown(
                                          !aiToneShowDropdown
                                        )
                                      }
                                    />
                                    <p className="errorState errorState2">
                                      {formErrors.ai_tone_names}
                                    </p>
                                    {aiToneShowDropdown && (
                                      <div
                                        ref={aiToneDropdownRef}
                                        className="Customize-your-AI-assistant-tone-dropdown-inner-folder"
                                      >
                                        <p className="Personalize-your-AI-Assistant-input-folder-title">
                                          Customize your AI assistant’s tone
                                        </p>
                                        {aiToneSelectedOptions.includes(
                                          "Other"
                                        ) && (
                                            <input
                                              type="text"
                                              value={aiToneOtherText}
                                              onChange={(e) =>
                                                setAiToneOtherText(e.target.value)
                                              }
                                              onKeyDown={
                                                aiToneHandleOtherTextSubmit
                                              }
                                              placeholder="Enter your 'Other' option"
                                            />
                                          )}
                                        <div className="Customize-your-AI-assistant-tone-dropdown-select-option-container">
                                          {aiToneDropdownOptions.map(
                                            (option, index) => (
                                              <div
                                                key={index}
                                                className="Customize-your-AI-assistant-tone-dropdown-select-option"
                                              >
                                                <input
                                                  id={index}
                                                  type="checkbox"
                                                  value={option}
                                                  checked={aiToneSelectedOptions.includes(
                                                    option
                                                  )}
                                                  onChange={
                                                    aiToneHandleOptionChange
                                                  }
                                                />
                                                <label htmlFor={index}>{option}</label>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div className="Personalize-your-AI-Assistant-input-folder">
                                  <label>Customize your welcome message</label>
                                  <div
                                    className="Customize-your-AI-assistant-tone-dropdown-folder Customize-your-welcome-message"
                                    id="Customize-your-AI-assistant-tone-dropdown-folder2"
                                    ref={dropdownRef2}
                                  >
                                    <input
                                      onClick={() =>
                                        setShowDropdown2(!showDropdown2)
                                      }
                                      type="text"
                                      name="ai_welcome_msg"
                                      style={
                                        formErrors.ai_welcome_msg
                                          ? { border: "1px solid red" }
                                          : null
                                      }
                                      placeholder="E.g. Hi! This is Ms. Synthia’s assistant, how may I help you?"
                                      value={formState.ai_welcome_msg || ""}
                                      onChange={(event) => {
                                        handleInnerInputChange2(event);
                                        handleInputChange(event);
                                      }}
                                    />{" "}
                                    <p className="errorState errorState2">
                                      {formErrors.ai_welcome_msg}
                                    </p>
                                    {showDropdown2 && (
                                      <div className="Customize-your-AI-assistant-tone-dropdown-inner-folder">
                                        <p className="Personalize-your-AI-Assistant-input-folder-title">
                                          Customize your welcome message
                                        </p>
                                        {selectedOption2 === "Other" && (
                                          <input
                                            type="text"
                                            name="ai_welcome_msg"
                                            placeholder="Write here"
                                            className={`inner-input ${selectedOption2 === "Other"
                                              ? "blue-border"
                                              : ""
                                              }`}
                                            onChange={(event) => {
                                              handleInnerInputChange2(event);
                                              handleInputChange(event);
                                            }}
                                            style={{
                                              display: showInnerInput
                                                ? "block"
                                                : "none",
                                            }}
                                            autoFocus={
                                              selectedOption2 === "Other"
                                            }
                                          />
                                        )}
                                        <div className="Customize-your-AI-assistant-tone-dropdown-select-option-container">
                                          {ai_welcome_msg_list
                                            .map((option, index) => (
                                              <div
                                                key={option + index}
                                                className="Customize-your-AI-assistant-tone-dropdown-select-option"
                                              >
                                                <input
                                                  type="radio"
                                                  id={option + index}
                                                  name="options"
                                                  value={option || ""}
                                                  checked={
                                                    selectedOption2 === option
                                                  }
                                                  onChange={handleOptionChange2}
                                                />
                                                <label htmlFor={option + index}>
                                                  {option}
                                                </label>
                                              </div>
                                            ))}
                                        </div>
                                        {dropdownOptions2.length > 0 && (
                                          <div>
                                            <h2>Select an Item:</h2>
                                            <select>
                                              {dropdownOptions2.map(
                                                (option, index) => (
                                                  <option
                                                    key={index}
                                                    value={option || ""}
                                                  >
                                                    {option}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="Personalize-your-AI-Assistant-right-section">
                              <h2>{aiBotname}</h2>

                              <div className="Personalize-your-AI-Assistant-uplode-image uploded-image">
                                <img
                                  src={selectedImage ?? update_image}
                                  alt=""
                                />
                              </div>

                              <p>{aiBotmessage}</p>
                            </div>
                          </div>
                          <div className="dot-indicator">
                            {fields.map((field, index) => (
                              <div
                                key={field.id}
                                className={`dot ${index + 1 === step ? "active" : ""
                                  }`}
                              ></div>
                            ))}
                          </div>
                        </div>
                        <div className="form-buttons">
                          {step > 1 && <button onClick={onBack}>Back</button>}
                          {step < fields.length ? (
                            <button onClick={onNext}>Next</button>
                          ) : null}
                          <button
                            onClick={handleForm4Submit}
                            className="update-profile-image"
                          >
                            Update Profile
                          </button>
                        </div>
                      </div>
                    );
                  }

                  return null;
                })}
              </div>
            )}
          </div>
        </div>
      </div>
      {modalIsOpen && (
        <CropProfileImageModal
          src={src}
          setSrc={setSrc}
          setSelectedImage={setSelectedImage}
          setImageUploaded={setImageUploaded}
          setUplodedImage={setUplodedImage}
          modalStatus={modalIsOpen}
          setModalClose={onCloseModal}
          fieldName={"ai_avatar"}
          setFormState={setFormState}
          formState={formState}
        />
      )}
    </>
  );
};
export default PopupSection;
