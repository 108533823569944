import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

import api from "../../../../../api/api";
import {
  selectedImage1,
  update_image,
} from "../../../../../assets/images/images";
import { useAuth } from "../../../../../features/auth/AuthContext";
import { isRequired } from "../../../../../utils/validator";
import CropProfileImageModal from "../../dashboard/CropProfileImageModal";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateGlobalState } from "../../../../../state/global/slice";
import { compressImage } from "../../../../../utils/CompressImage";
import { capitalizeWordsInList, getTitle } from "../../../../../utils/utlis";

function Assistant() {
  const globalState = useSelector((state) => state.globalState);
  const [selectedImage, setSelectedImage] = useState(
    globalState.teacher_details?.data?.ai_avatar ?? null
  );
  const { teacher_id } = useParams();
  const dispatch = useDispatch();
  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };
  const [formErrors, setFormErrors] = useState({
    ai_avatar: "",
    ai_name: "",
    ai_tone_names: "",
    ai_welcome_msg: "",
  });

  const [formState, setFormState] = useState({
    ai_avatar: null,
    ai_name: globalState.teacher_details?.data?.ai_name ?? "",
    ai_tone_names:
      Object.values(globalState.teacher_details?.data?.ai_tone) ?? "",
    ai_welcome_msg: globalState.teacher_details?.data?.ai_welcome_msg ?? "",
  });

  const [aiBotname, setaiBotName] = useState(
    globalState.teacher_details?.data?.ai_name ?? "No Name"
  );
  const aiName = getTitle(
    globalState.teacher_details?.data?.title,
    `${
      globalState.teacher_details?.data?.name?.includes(" ")
        ? globalState.teacher_details?.data?.name?.split(" ").pop()
        : globalState.teacher_details?.data?.name
    } AI`
  );
  const [ai_welcome_msg_list, setAi_welcome_msg_list] = useState([
    `Hi! I am ${aiName}, how may I help you?`,
    `Hi! I am ${aiName}, What can I teach you today?`,
    `Hi! I am ${aiName}, How can I support your learning today?`,
    "Other",
  ]);
  const handleKeyUp = (e) => {
    const inputValue = e.target.value;
    setaiBotName(inputValue);

    const aimsgarray = [
      "Hi! I am " + inputValue + ", how may I help you?",
      "Hi! I am " + inputValue + ", What can I teach you today?",
      "Hi! I am " + inputValue + ", How can I support your learning today?",
      "Other",
    ];
    setAi_welcome_msg_list(aimsgarray);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let error = "";

    if (name === "ai_name") {
      error = isRequired(value);
    } else if (name === "ai_tone_names") {
      error = isRequired(value);
    } else if (name === "ai_welcome_msg") {
      error = isRequired(value);
    }
    setFormErrors({
      ...formErrors,
      [name]: error,
    });
    setFormState((prev) => ({ ...prev, [name]: value }));
  };
  const [aiToneSelectedOptions, setAiToneSelectedOptions] = useState(
    formState.ai_tone_names !== null &&
      ((Array.isArray(formState.ai_tone_names) &&
        formState.ai_tone_names.length > 0) ||
        (typeof formState.ai_tone_names === "string" &&
          formState.ai_tone_names.trim() !== ""))
      ? capitalizeWordsInList(
          Array.isArray(formState.ai_tone_names)
            ? formState.ai_tone_names
            : [formState.ai_tone_names]
        )
      : []
  );
  const [aiToneShowDropdown, setAiToneShowDropdown] = useState(false);
  const [aiToneOtherTextArr, setaiToneOtherTextArr] = useState([])
  const [aiToneOtherText, setAiToneOtherText] = useState("");
  const aiToneDropdownRef = useRef(null);
  const aiToneInputRef = useRef(null);

  const aiToneDropdownOptions = [
    "Formal",
    "Informative",
    "Inspirational",
    "Enthusiastic",
    "Joyful",
    "Thoughtful",
    "Casual",
    "Other",
  ];
  function onChangeAiTone(arr) {
    let error = "";
    const data = arr.join(",");
    error = isRequired(data);
    setFormErrors({
      ...formErrors,
      ai_tone_names: error,
    });
    setFormState((prev) => ({
      ...prev,
      ai_tone_names: data,
    }));
  }

  const aiToneHandleOptionChange = (event) => {
    const value = event.target.value;

    if (aiToneSelectedOptions.includes(value)) {
      if (value === "Other") {
        const matchingElements = aiToneSelectedOptions.filter(element => aiToneOtherTextArr.includes(element));
        setAiToneSelectedOptions((prevState) =>
          prevState.filter((item) => item !== value && !matchingElements.includes(item))
        );
        onChangeAiTone(aiToneSelectedOptions.filter((item) => item !== value && !matchingElements.includes(item)));
      } else {
        setAiToneSelectedOptions((prevState) =>
          prevState.filter((item) => item !== value)
        );
        onChangeAiTone(aiToneSelectedOptions.filter((item) => item !== value));
      }
    } else {
      setAiToneSelectedOptions((prevState) => [...prevState, value]);
      onChangeAiTone([...aiToneSelectedOptions, value]);
    }
  };

  const aiToneHandleOtherTextSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setAiToneSelectedOptions((prevState) => [...prevState, aiToneOtherText]);
      setaiToneOtherTextArr((prevState) => [...prevState, aiToneOtherText]);
      setAiToneOtherText("");
      onChangeAiTone([...aiToneSelectedOptions, aiToneOtherText]);
    }
  };
  const dropdownRef2 = useRef(null);
  const [showDropdown2, setShowDropdown2] = useState(false);

  const [selectedOption2, setSelectedOption2] = useState(
    "Hi! I am Synthia, how may I help you?"
  );
  const [dropdownOptions2, setDropdownOptions2] = useState([]);
  const [showInnerInput, setShowInnerInput] = useState(false);
  const innerInputRef2 = useRef(null);
  const [aiBotmessage, setaiBotmessage] = useState(
    globalState.teacher_details?.data?.ai_welcome_msg ??
      "Hi, I am your AI assistant"
  );
  const handleOptionChange2 = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption2(selectedValue);
    setShowDropdown2(selectedValue === "Other");
    setDropdownOptions2([]);
    setaiBotmessage(selectedValue);
    let error = "";
    error = isRequired(selectedValue);
    setFormErrors({
      ...formErrors,
      ai_welcome_msg: error,
    });

    setFormState({ ...formState, ai_welcome_msg: selectedValue });

    if (selectedValue === "Other") {
      setShowInnerInput(true);
      setTimeout(() => {
        if (innerInputRef2.current) {
          innerInputRef2.current.focus();
          innerInputRef2.current.select();
        }
      }, 0);
    } else {
      setShowInnerInput(false);
    }
  };
  const handleInnerInputChange2 = (event) => {
    const innerInputValue = event.target.value;
    setaiBotmessage(innerInputValue);
    setFormState({ ...formState, ai_welcome_msg: innerInputValue });
  };

  const handleForm4Submit = async (e) => {
    e.preventDefault();
    let errors = {};
    errors.ai_name = isRequired(formState.ai_name);
    errors.ai_tone_names = isRequired(formState.ai_tone_names);
    errors.ai_welcome_msg = isRequired(formState.ai_welcome_msg);
    setFormErrors(errors);

    if (!Object.values(errors).some((error) => error)) {
      setAiToneShowDropdown(false);
      setShowDropdown2(false);
      await submitForm();
    }
  };
  const { token } = useAuth();
  const submitForm = async () => {
    toast.dismiss();
    try {
      await api
        .put(`auth/update-teacher/?teacher_id=${teacher_id}`, formState, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        })
        .then((response) => {
          if (response?.data?.status == 200) {
            toast.success(
              response?.data?.message ||
                "Teacher profile has been updated successfully.",
              {
                position: toast.POSITION.TOP_CENTER,
              }
            );
            updateState("teacher_details", response?.data ?? {});
            setEditMode(false);
          } else {
            toast.error(response?.data?.message || "Something went wrong!", {
              position: toast.POSITION.TOP_CENTER,
            });
          }
        });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          "Something went wrong!",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  };

  const [editMode, setEditMode] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const handleFileInput = async (event) => {
    const file = event.target.files[0];

    if (!file) {
      setSrc(null);
      return;
    }

    if (file.size > 31457280) {
      // Handle the case where the file size is too large
      toast.error("The file size exceeds the maximum limit of 1 MB");
      setFormErrors({
        ...formErrors,
        ai_avatar: "The file size exceeds the maximum limit of 1 MB",
      });
      setSrc(null);
      return; // Exit the function
    }

    const compressedImage = await compressImage(file);
    setSrc(URL.createObjectURL(compressedImage));
    onOpenModal();

    let error = "";
    error = isRequired(file);
    setFormErrors({
      ...formErrors,
      ai_avatar: error,
    });
  };

  const onOpenModal = () => {
    setModalIsOpen(true);
  };

  const onCloseModal = () => {
    setModalIsOpen(false);
  };

  return (
    <>
      <div key={"assistant"} className="account_setting_tab_sec">
        <div className="account_setting_tab_sec_head">
          <h6>Personalize your AI Assistant</h6>
          {editMode ? (
            <button class="save_btn_set" onClick={handleForm4Submit}>
              Save
            </button>
          ) : (
            <button class="edit_btn_set" onClick={() => setEditMode(true)}>
              <i class="fa-solid fa-pen"></i>
              Edit
            </button>
          )}
        </div>
        <div className="dashboard-popup-teacher-profile-details-form">
          <div className="Personalize-your-AI-Assistant-wrapper">
            <div className="Personalize-your-AI-Assistant-left-section">
              <div
                className="Personalize-your-AI-Assistant-uplode-image"
                id="setting-Personalize-AI"
              >
                <img
                  src={selectedImage ?? selectedImage1}
                  alt=""
                  style={
                    editMode === true
                      ? { cursor: "pointer" }
                      : { cursor: "auto" }
                  }
                />
                <div
                  className="dashboard-popup-teacher-profile-details-image-plus-btn forprofile"
                  style={
                    editMode === true
                      ? { cursor: "pointer" }
                      : { cursor: "auto" }
                  }
                >
                  <span>
                    <i class="fa-solid fa-plus"></i>
                  </span>
                  {/* <img
                    src={dashboard_popup_teacher_profile_details_icon_plus_icon}
                    alt=""
                  /> */}
                </div>
                {editMode && (
                  <input
                    type="file"
                    id="choos-profile-image"
                    name="ai_avatar"
                    key={Math.random()} // Add a random key to reset the input field
                    onChange={handleFileInput}
                    accept="image/png, image/jpeg" // Accept only image files
                    style={{
                      height: 106,
                      width: 106,
                    }}
                  />
                )}
                <p className="errorState">{formErrors.ai_avatar}</p>
              </div>
              <p className="subjects-title" style={{ textAlign: "center" }}>
                Upload an image for your AI assistant
              </p>
              <div className="Personalize-your-AI-Assistant-form-folder">
                <div className="Personalize-your-AI-Assistant-input-folder">
                  <label>Write name for your AI assistant</label>
                  <input
                    name="ai_name"
                    style={
                      formErrors.ai_name ? { border: "1px solid red" } : null
                    }
                    value={formState.ai_name || ""}
                    onKeyUp={handleKeyUp}
                    onChange={handleInputChange}
                    type="text"
                    placeholder="eg: Mr Smith AI"
                    readOnly={editMode === false ? true : false}
                  />
                  <p className="errorState">{formErrors.ai_name}</p>
                </div>
                <div className="Personalize-your-AI-Assistant-input-folder">
                  <label>Customize your AI assistant's tone</label>
                  <div
                    className="Customize-your-AI-assistant-tone-dropdown-folder"
                    id="Customize-your-AI-dropdown"
                    ref={aiToneInputRef}
                  >
                    <input
                      type="text"
                      style={
                        formErrors.ai_tone_names
                          ? { border: "1px solid red" }
                          : null
                      }
                      name="ai_tone_names"
                      placeholder="Select response"
                      value={aiToneSelectedOptions
                        .filter((option) => option !== "Other")
                        .join(",")}
                      readOnly
                      onClick={() => {
                        if (editMode === true) {
                          setAiToneShowDropdown(!aiToneShowDropdown);
                        } else {
                          setAiToneShowDropdown(false);
                        }
                      }}
                    />
                    <p className="errorState errorState2">
                      {formErrors.ai_tone_names}
                    </p>
                    {aiToneShowDropdown && (
                      <div
                        ref={aiToneDropdownRef}
                        className="Customize-your-AI-assistant-tone-dropdown-inner-folder"
                      >
                        <p className="Personalize-your-AI-Assistant-input-folder-title">
                          Customize your AI assistant’s tone
                        </p>
                        {aiToneSelectedOptions.includes("Other") && (
                          <input
                            type="text"
                            value={aiToneOtherText}
                            onChange={(e) => {
                              setAiToneOtherText(e.target.value);
                            }}
                            onKeyDown={aiToneHandleOtherTextSubmit}
                            placeholder="Enter your 'Other' option"
                          />
                        )}
                        <div className="Customize-your-AI-assistant-tone-dropdown-select-option-container">
                          {aiToneDropdownOptions.map((option, index) => (
                            <div
                              key={index}
                              className="Customize-your-AI-assistant-tone-dropdown-select-option"
                            >
                              <input
                                id={index} 
                                type="checkbox"
                                value={option}
                                checked={aiToneSelectedOptions.includes(option)}
                                onChange={aiToneHandleOptionChange}
                              />
                              <label htmlFor={index}>{option}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="Personalize-your-AI-Assistant-input-folder">
                  <label>Customize your welcome message</label>
                  <div
                    className="Customize-your-AI-assistant-tone-dropdown-folder"
                    id="Customize-your-AI-assistant-tone-dropdown-folder2"
                    ref={dropdownRef2}
                  >
                    <input
                      onClick={() => {
                        if (editMode === true) {
                          setShowDropdown2(!showDropdown2);
                        } else {
                          setShowDropdown2(false);
                        }
                      }}
                      readOnly={editMode === false ? true : false}
                      type="text"
                      name="ai_welcome_msg"
                      style={
                        formErrors.ai_welcome_msg
                          ? { border: "1px solid red" }
                          : null
                      }
                      placeholder="E.g. Hi! This is Ms. Synthia’s assistant, how may I help you?"
                      value={formState.ai_welcome_msg || ""}
                      onChange={(event) => {
                        if (editMode === true) {
                          handleInnerInputChange2(event);
                          handleInputChange(event);
                        } else {
                          return false;
                        }
                      }}
                    />{" "}
                    <p className="errorState errorState2">
                      {formErrors.ai_welcome_msg}
                    </p>
                    {showDropdown2 && (
                      <div className="Customize-your-AI-assistant-tone-dropdown-inner-folder">
                        <p className="Personalize-your-AI-Assistant-input-folder-title">
                          Customize your welcome message
                        </p>
                        {selectedOption2 === "Other" && (
                          <input
                            type="text"
                            name="ai_welcome_msg"
                            placeholder="Write here"
                            className={`inner-input ${
                              selectedOption2 === "Other" ? "blue-border" : ""
                            }`}
                            onChange={(event) => {
                              handleInnerInputChange2(event);
                              handleInputChange(event);
                            }}
                            style={{
                              display: showInnerInput ? "block" : "none",
                            }}
                            autoFocus={selectedOption2 === "Other"}
                          />
                        )}
                        <div
                          className="Customize-your-AI-assistant-tone-dropdown-select-option-container"
                          id="Customize-inner-AI-assistant"
                        >
                          {ai_welcome_msg_list
                            // [
                            //     'Hi! I am Synthia, how may I help you?',
                            //     'Hi! I am Synthia, What can I teach you today?',
                            //     'Hi! I am Synthia, How can I support your learning today?',
                            //     'Other'
                            // ]
                            .map((option, index) => (
                              <div
                                key={option + index}
                                className="Customize-your-AI-assistant-tone-dropdown-select-option"
                              >
                                <input
                                  type="radio"
                                  id={option + index}
                                  name="options"
                                  value={option || ""}
                                  checked={selectedOption2 === option}
                                  onChange={handleOptionChange2}
                                />
                                <label htmlFor={option + index}>{option}</label>
                              </div>
                            ))}
                        </div>
                        {dropdownOptions2.length > 0 && (
                          <div>
                            <h2>Select an Item:</h2>
                            <select>
                              {dropdownOptions2.map((option, index) => (
                                <option key={index} value={option || ""}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="Personalize-your-AI-Assistant-right-section forprofile">
              <h2>{aiBotname}</h2>
              <div className="Personalize-your-AI-Assistant-uplode-image uploded-image">
                <img src={selectedImage ?? update_image} alt="" />
              </div>
              <p>{aiBotmessage}</p>
            </div>
          </div>
        </div>
      </div>
      {modalIsOpen && (
        <CropProfileImageModal
          src={src}
          setSrc={setSrc}
          setSelectedImage={setSelectedImage}
          modalStatus={modalIsOpen}
          setModalClose={onCloseModal}
          fieldName="ai_avatar"
          setFormState={setFormState}
          formState={formState}
        />
      )}
    </>
  );
}

export default Assistant;
