import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddSchool from "../../../components/AddSchool/AddSchool";
import DashboardMainGraph from "./DashboardBarFolder/DashboardMainGraph";
import DashboardList from "./DashboardList/DashboardList";
import DashboardSummary from "./DashboardSummary/DashboardSummary";
import { useAuth } from "../../../features/auth/AuthContext";
import { getSchoolDetails, resetSchoolDetails } from '../../../state/actions/getSchoolDetailsAction';
import { updateGlobalState } from "../../../state/global/slice";  

const Dashboard = () => {

  const [addSchool, setAddSchool] = useState(true);

  const { token } = useAuth();
  const dispatch = useDispatch();
  const { school_id } = useSelector((state) => state.globalState);
  const schoolDetails = useSelector((state) => state.schoolDetails);
  
  const toggleAddSchool = () => {
    setAddSchool(!addSchool);
  };

  const getSchoolDetailsReq = () => {
    dispatch(getSchoolDetails(school_id, token));
  }

  const updateState = (key, value) => {
    dispatch(updateGlobalState({ key: key, value: value }));
  };

  useEffect(() => {
    if (school_id !== null) {
      getSchoolDetailsReq();
    }
  }, [school_id, token]);

  useEffect(() => {
    if (Object.entries(schoolDetails).length) {
      updateState("school_details", schoolDetails?.school ?? {});
    }
  }, [schoolDetails]);

  return (
    <>
      <div className="admin-dashboard-section">
        <div className="admin-dashboard-wrapper">
          <h2 className="dashboard-name-title">Hello Admin!</h2>
          <DashboardSummary />
          <DashboardMainGraph />
          <DashboardList />
        </div>
      </div>
      {school_id === null && addSchool && <AddSchool setAddSchool={addSchool} toggleAddSchool={toggleAddSchool} />}
    </>
  );
};

export default Dashboard;
